import styled, { css } from 'styled-components'

export const DocumentContainer = styled.div`
    width: 100%;
    ${(props) =>
        props.showPrintScreen &&
        css`
            margin-bottom: 50px;
        `}
`

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const Navbar = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const Logo = styled.img`
    display: block;
    height: 30px;
    object-fit: contain;
`

export const NavbarText = styled.div`
    text-align: right;
    font-family: 'MuseoSans-medium';
    font-weight: 500;
    line-height: 20px;
    font-size: 15px;
`

export const MainContainer = styled.div`
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 480px) {
        padding: 30px 0px;
    }
`

export const Badge = styled.div`
    flex-shrink: 0;
    background-color: #ec7625;
    color: white;
    padding: 5px 10px;
    border-radius: 7px;
    font-family: 'MuseoSans-bold';
    font-size: 15px;
    width: fit-content;
`

export const OfferPrice = styled.div`
    color: #ec7625;
    font-family: 'MuseoSans-black';
    font-size: 30px;
    margin-top: 10px;
`

export const ContentContainer = styled.div`
    background-color: rgba(255, 209, 177, 0.22);
    padding: 10px 20px;
    margin-top: 20px;
`

export const OfferExpiresText = styled.div`
    font-family: 'PalmerLakePrint-regular';
    text-align: center;
    font-size: 35px;
    color: #1b6775;
    width: 100%;
    display: block;
`

export const OfferInfo = styled.div`
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
`

export const OfferInfoText = styled.div`
    font-family: 'MuseoSans-bold';
    color: '#525659';
    font-size: 16px;
`

export const OfferInfoDetail = styled.div`
    font-family: 'MuseoSans-medium';
    color: '#525659';
    font-size: 16px;
`

export const Teal = styled.div`
    color: #1b6775;
`

export const Orange = styled.div`
    color: #ec7625;
`

export const DisplayBlock = styled.div`
    display: block;
`

export const Bold = styled.div`
    font-family: 'MuseoSans-bold';
`

export const Divider = styled.hr`
    margin: 20px 0px;
    height: 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0.25px solid #1b6775;
`

export const InfoText = styled.div`
    display: block;
    width: 60%;
    color: #1b6775;
    font-family: 'MuseoSans-medium';
    font-size: 14px;
    margin-top: 8px;
`

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
`

export const FooterImage = styled.img`
    height: 60px;
`

export const FooterHeading = styled.div`
    text-align: center;
    font-family: 'MuseoSans-black';
    color: #525659;
    font-size: 20px;
    margin-top: 10px;
`

export const FooterText = styled.div`
    text-align: center;
    margin-top: 10px;
    font-family: 'MuseoSans-medium';
    font-size: 14px;
    color: #525659;
    line-height: 20px;
    text-align: center;
`
