import { Cont, Heading, Card, CardCont, HeadCont, Anchorlink, DateCont } from './Styles'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import Default from './default'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useEvents from 'shared/hooks/sell/events'
import { useSelector } from 'react-redux'

const ImportantDates = ({ mobile = false }) => {
    const events = useSelector((state) => state.events.events)

    const [futureEvents, setFutureEvents] = useState([])

    const navigate = useNavigate()
    const { getFutureEvents } = useEvents()
    const data = getFutureEvents(3)

    useEffect(() => {
        setFutureEvents(data)
    }, [events])

    return (
        <Cont slides={futureEvents.total} mobile={mobile}>
            <HeadCont>
                <Heading>Important Dates</Heading>
                {futureEvents.length !== 0 && <Anchorlink to="/sell/calendar">View All</Anchorlink>}
            </HeadCont>
            {futureEvents.total !== 0 ? (
                <Splide
                    tag="div"
                    options={{
                        rewind: true,
                        perPage: 1,
                        arrows: false,
                        drag: 'free',
                        gap: '1rem',
                        padding: '.2rem',
                        mediaQuery: 'min',
                        breakpoints: {
                            481: {
                                destroy: true,
                            },
                        },
                    }}
                >
                    {futureEvents?.sortedData?.map((event, i) => {
                        return (
                            <SplideSlide key={i + Date.now()}>
                                <Card
                                    onClick={() =>
                                        navigate(`/sell/calendar?date=${event.eventDate}`)
                                    }
                                >
                                    <DateCont month={event.month} date={event.date} green={i % 2} />
                                    <CardCont>
                                        <div>{event.title}</div>
                                        <span>
                                            {event.dayOfWeekShort}
                                            <span className={`dash ${i % 2 && 'green'}`}>
                                                &#x2012;
                                            </span>
                                            {event.month} {event.date}, {event.year}
                                        </span>
                                        {event.time !== '' && (
                                            <span>
                                                {event.time} ({event.timeZone})
                                            </span>
                                        )}
                                    </CardCont>
                                </Card>
                            </SplideSlide>
                        )
                    })}
                </Splide>
            ) : (
                <Default />
            )}
        </Cont>
    )
}

export default ImportantDates
