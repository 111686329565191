import React, { Fragment } from 'react'
import { Form } from 'shared/components'
import toast from 'shared/utils/toast'
import Logo from 'shared/asstes/logo.svg'
import {
    StyledDiv,
    StyledLogo,
    FormCont,
    FormHeading,
    FormElement,
    FormFieldRow,
    FormFieldCol,
    ActionButtonSignup,
    FormFooterStyled,
    FormFooterStyledAnchorTag,
    CheckboxContainer,
    TagLine,
    Container,
    FormHeadingImage,
    FormImage,
} from '../shared/Styles'
import useApi from 'shared/hooks/api'
import { enforceFormat, formatToPhone } from 'shared/utils/formatter'
import { useState } from 'react'
import SuccessScreen from './success'
import Clouds from '../assets/cloud-sun.svg'
import UnderLine from '../assets/underline.svg'

const Register = () => {
    const [success, setSuccess] = useState(false)
    const [checked, setChecked] = useState(false)
    const [{ isCreating }, login] = useApi.post('api/customer-auth/v2/register')

    return (
        <Fragment>
            <Container>
                {!success && (
                    <StyledDiv>
                        <StyledLogo src={Logo} alt="" />
                        <TagLine>We can help you do literally (almost) everything.</TagLine>
                    </StyledDiv>
                )}
                {!success ? (
                    <Form
                        enableReinitialize
                        initialValues={{
                            agent: false,
                            firstName: '',
                            lastName: '',
                            email: '',
                            phoneNumber: '',
                            password: '',
                            confirmPassword: '',
                        }}
                        validations={{
                            firstName: Form.is.required('Enter your first name'),
                            lastName: Form.is.required('Enter your last name'),
                            email: [
                                Form.is.required('Enter a valid email'),
                                Form.is.email('Enter a valid email'),
                            ],
                            phoneNumber: Form.is.validPhoneNumber(),
                            password: [
                                Form.is.required('Enter a valid password'),
                                Form.is.strongPassword(
                                    'Use 6 or more characters, a combination of uppercase, lowercase, a number and a symbol',
                                ),
                            ],
                            confirmPassword: Form.is.required('Enter a valid password'),
                        }}
                        onSubmit={async (values, form) => {
                            if (values.password !== values.confirmPassword) {
                                form.setFieldError('confirmPassword', 'Passwords do not match')
                                return
                            }
                            try {
                                values.agent = checked
                                await login({
                                    ...values,
                                    sendConfirmationEmail: true,
                                    sendPhoneNumberConfirmation: false,
                                    confirmEmailSuccessUrl: `${process.env.MARKETING_URL}/auth/login?success=true`,
                                    confirmEmailFailureUrl: `${process.env.MARKETING_URL}/auth/login?success=false&message=Error verifying email`,
                                    source: 'Connect.Web',
                                })
                                setSuccess(true)
                                toast.success(`Bingo! You're in, now let's do this!`)
                            } catch (error) {
                                error[0]?.code === 'DuplicateUserName' &&
                                    toast.error(error[0]?.description)
                                if (
                                    error[0]?.code === 'PasswordTooShort' ||
                                    error[0]?.code === 'PasswordRequiresNonAlphanumeric' ||
                                    error[0]?.code === 'PasswordRequiresDigit' ||
                                    error[0]?.code === 'PasswordRequiresUpper'
                                ) {
                                    form.setFieldError(
                                        'password',
                                        'Password must be at least 6 character long & must include one Number, Uppercase, Lowercase & Special character.',
                                    )
                                } else if (error[0]?.code === 'DuplicateUserName') {
                                    form.setFieldError('email', error[0]?.description)
                                } else {
                                    toast.error(error[0]?.description)
                                }
                            }
                        }}
                    >
                        <FormCont width="694px">
                            <FormImage src={Clouds} alt="" />
                            <FormElement>
                                <FormHeading size="xl">
                                    Sign up
                                    <FormHeadingImage src={UnderLine} alt="" />
                                </FormHeading>
                                <FormFieldRow>
                                    <FormFieldCol>
                                        <Form.Field.Input
                                            name="firstName"
                                            type="text"
                                            placeholder="First Name"
                                            size="lg"
                                            disabled={isCreating}
                                            iconType="name"
                                        />
                                    </FormFieldCol>
                                    <FormFieldCol>
                                        <Form.Field.Input
                                            name="lastName"
                                            type="text"
                                            placeholder="Last Name"
                                            size="lg"
                                            disabled={isCreating}
                                            iconType="name"
                                        />
                                    </FormFieldCol>
                                </FormFieldRow>
                                <FormFieldRow>
                                    <FormFieldCol>
                                        <Form.Field.Input
                                            name="email"
                                            type="email"
                                            placeholder="Email"
                                            size="lg"
                                            disabled={isCreating}
                                            iconType="email"
                                        />
                                    </FormFieldCol>
                                    <FormFieldCol>
                                        <Form.Field.Input
                                            name="phoneNumber"
                                            type="text"
                                            placeholder="Phone Number (Optional)"
                                            size="lg"
                                            disabled={isCreating}
                                            onKeyDown={(e) => enforceFormat(e)}
                                            onKeyUp={formatToPhone}
                                            iconType="phone"
                                        />
                                    </FormFieldCol>
                                </FormFieldRow>
                                <FormFieldRow>
                                    <FormFieldCol>
                                        <Form.Field.Input
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                            size="lg"
                                            disabled={isCreating}
                                            autoComplete="off"
                                            inputClass="fs-mask"
                                            showStrengthBox={true}
                                        />
                                    </FormFieldCol>
                                    <FormFieldCol>
                                        <Form.Field.Input
                                            name="confirmPassword"
                                            type="password"
                                            placeholder="Confirm Password"
                                            size="lg"
                                            disabled={isCreating}
                                            autoComplete="off"
                                            inputClass="fs-mask"
                                        />
                                    </FormFieldCol>
                                </FormFieldRow>
                                <Form.Field.Checkbox
                                    name="agent"
                                    checkboxLabel="I am a real estate agent"
                                    labelSize="14"
                                    size="lg"
                                    disabled={isCreating}
                                    labelColor="#1B6775"
                                    className={`hide-on-mobile rounded ${checked ? 'checked' : ''}`}
                                    value={checked}
                                    checked={checked}
                                    onChangeEvent={(e) => setChecked(e.target.checked)}
                                    agentCheck={true}
                                />
                                <CheckboxContainer>
                                    <p>I am a real estate agent</p>
                                    <Form.Field.ToggleSwitch
                                        name="agent"
                                        size="16"
                                        backgroundcolor="#1B6775"
                                        disabled={isCreating}
                                        onChangeEvent={(e) => setChecked(e.target.checked)}
                                    />
                                </CheckboxContainer>
                                <ActionButtonSignup
                                    type="submit"
                                    variant="primary"
                                    isWorking={isCreating}
                                    size="lg"
                                    id="fs_auth_signup_submit"
                                >
                                    Sign up
                                </ActionButtonSignup>
                            </FormElement>
                            <FormFooterStyled mt="40px">
                                Already have an account?
                                <FormFooterStyledAnchorTag
                                    to="/auth/login"
                                    id="fs_auth_signup_login"
                                >
                                    Sign in
                                </FormFooterStyledAnchorTag>
                            </FormFooterStyled>
                            <FormFooterStyled mt="17px">
                                By creating an account, you are agreeing to Offerpad’s
                                <FormFooterStyledAnchorTag
                                    to={process.env.TERMS_OF_USE_URL}
                                    target="_blank"
                                >
                                    Terms of Use
                                </FormFooterStyledAnchorTag>{' '}
                                and
                                <FormFooterStyledAnchorTag
                                    to={process.env.PRIVACY_URL}
                                    target="_blank"
                                >
                                    Privacy Policy
                                </FormFooterStyledAnchorTag>
                                .
                            </FormFooterStyled>
                        </FormCont>
                    </Form>
                ) : (
                    <SuccessScreen />
                )}
            </Container>
        </Fragment>
    )
}

export default Register
