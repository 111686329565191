import styled from 'styled-components'
import { font } from 'shared/utils/styles'

export const Cont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
`

export const Img = styled.img``

export const Heading = styled.div`
    font-size: 32px;
    text-transform: uppercase;
    ${font.black};
    margin-top: 20px;
    color: #262833;
`

export const Tag = styled.div`
    font-size: 18px;
    color: #525659;
    ${font.medium};
    margin-top: 30px;
`
