import api from 'shared/utils/api'
import { useDispatch, useSelector } from 'react-redux'
import { updateDocuments, updateLoading } from 'features/documentsSlice'
import moment from 'moment-timezone'
import { getStoredAuthToken } from 'shared/utils/authToken'
import { useNavigate } from 'react-router'
import toast from 'shared/utils/toast'
const useDocuments = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)

    const fetchDocuments = async () => {
        if (localStorage.currentTransactionId === undefined) {
            navigate('/sell/dashboard')
            return
        }

        const documentsFetchURL = `customer/v3/documents?transactionType=CashOffer&transactionId=${localStorage.currentTransactionId}`
        dispatch(updateLoading(true))
        try {
            const temp = []
            temp['completed'] = []
            temp['pending'] = []
            const res = await api.get(documentsFetchURL)

            res.map((response) => {
                if (response.status !== 'PendingUpload') {
                    let d = {}
                    response.documentBlobs.map((document) => {
                        const { property } = window.currentTransaction

                        const fileUrl = document.blobUrl.includes('token={user-token}')
                            ? document.blobUrl.replace(
                                  'token={user-token}',
                                  `token=${getStoredAuthToken()}`,
                              )
                            : document.blobUrl

                        const uploadedDate = moment(document.createdDateTime)
                            .tz(property.timeZoneId)
                            .format('MM/DD/YYYY')

                        const uploadedBy = document.createdBy === user?.email ? 'You' : 'Offerpad'

                        const canDelete =
                            response.status !== 'Approved' && uploadedBy !== 'Offerpad'

                        d = {
                            documentId: response.id,
                            boldId: document.id,
                            title: response.type,
                            uploadedDate,
                            uploadedBy,
                            fileUrl,
                            filtType: document.mimeType,
                            canDelete,
                        }
                    })
                    temp['completed'].push({ ...response, ...d })
                } else {
                    temp['pending'].push({ ...response, metadata: { documentId: response?.id } })
                }
            })
            dispatch(updateDocuments(temp))
            dispatch(updateLoading(false))
        } catch (e) {
            dispatch(updateLoading(false))
            toast.error('Something went wrong.')
            console.error(e)
        }
    }

    const deleteDocuments = async (documentId, blodId) => {
        let isTrue = false
        dispatch(updateLoading(true))
        try {
            await api.delete(
                `customer/v3/documents/${documentId}/blobs/${blodId}?transactionId=${window?.currentTransaction?.transactionId}`,
            )
            dispatch(updateLoading(false))
            isTrue = true
        } catch (error) {
            dispatch(updateLoading(false))
            console.error(error)
            toast.error(error?.error?.message)
            isTrue = false
        }

        return isTrue
    }

    return {
        fetch: fetchDocuments,
        delete: deleteDocuments,
    }
}

export default useDocuments
