import styled, { css } from 'styled-components'
import { color, fontSize, borderRadius, size, mixin } from 'shared/utils/styles'
import Check from './assets/check.svg'

export const StyledCheckbox = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    transition: background 0.1s;
`

export const InputCont = styled.div`
    position: relative;
    display: inherit;
`

export const StyledInput = styled.span`
    ${(props) => size[props.size]};
    border: 1px solid ${color.borderLight};
    ${(props) => borderRadius[props.size]};
    ${(props) =>
        props.checked && props.agentCheck
            ? css`
                  background-color: #336573;
                  border-color: #336573;
              `
            : props.checked
            ? css`
                  background-color: ${color.primary};
                  border-color: ${color.primary};
              `
            : ''};
    appearance: auto;
    -webkit-appearance: auto;
    &::after {
        display: block;
        content: '';
        background-image: url(${Check});
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        ${(props) => size[props.size]};
        width: auto;
    }
    ${(props) =>
        props.invalid &&
        css`
            border-color: ${color.danger};
        `}
`

export const ActualInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    appearance: auto;
    -webkit-appearance: auto;
    ${mixin.clickable}
    ${(props) => size[props.size]}
    opacity: 0;
`

export const StylesLabel = styled.label`
    margin-left: 10px;
    margin-top: 3px;
    color: ${color.textLight};
    ${(props) => css`
        font-size: ${props.labelSize}px;
    `}
    ${(props) =>
        !!props.labelColor &&
        css`
            color: ${props.labelColor};
        `}
`
