import { Routes, Route } from 'react-router-dom'
import Auth from 'Auth'
import Sell from 'Sell'
import Buy from 'Buy'
import Loan from 'Loan'
import Profile from 'Profile'
import Changepassword from 'ChangePassword'
import ResourceCenterDetails from 'ResourceCenter'
import MessageCenter from 'MessageCenter'
import TechSupport from 'TechSupport'
import PublicRoute from 'PublicRoute'
import {
    AppleAppSiteAssociation,
    RouteMiddleware,
    SmartRedirect,
    EmbeddedSigningMobile,
    PageLayout,
    NotFound,
    CashOffer,
} from 'shared/components'

const Router = () => {
    return (
        <RouteMiddleware>
            <Routes>
                <Route path="/auth/*" element={<Auth />} />
                <Route element={<PageLayout />}>
                    <Route path="*" element={<NotFound />} />
                    <Route path="/sell/*" element={<Sell />} />
                    <Route path="/buy/*" element={<Buy />} />
                    <Route path="/loan/*" element={<Loan />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="profile/delete-account" element={<Profile />} />
                    <Route path="change-password" element={<Changepassword />} />
                    <Route path="/message-center" element={<MessageCenter />} />
                    <Route path="/tech-support" element={<TechSupport />} />
                    <Route path="/resource-center" element={<ResourceCenterDetails />} />
                </Route>
                <Route path="/public/*" element={<PublicRoute />} />
                <Route
                    path="/view-offer/:offerKey"
                    element={
                        <div
                            style={{
                                width: '100%',
                                margin: '0 auto',
                            }}
                        >
                            <CashOffer standalone />
                        </div>
                    }
                />
                <Route path="embedded-signing-m" element={<EmbeddedSigningMobile />} />
                <Route path="/smart-redirect" element={<SmartRedirect />} />
                <Route path="/apple-app-site-association" element={<AppleAppSiteAssociation />} />
                <Route
                    path="/.well-known/apple-app-site-association"
                    element={<AppleAppSiteAssociation />}
                />
            </Routes>
        </RouteMiddleware>
    )
}

export default Router
