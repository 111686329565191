import { useEffect, useState } from 'react'
import { BellhopsCont } from './Styles'
import useTeams from 'shared/hooks/sell/teams'
import { useSelector } from 'react-redux'
import BellhopsLogo from 'shared/components/Bellhops/assets/bellhops_logo.svg'
import WebImg from 'shared/components/Bellhops/assets/web_img.svg'
import EmailImg from 'shared/components/Bellhops/assets/email_img.svg'
import PhoneImg from 'shared/components/Bellhops/assets/phone_img.svg'

const Bellhops = () => {
    const [bellhops, setBellhops] = useState(false)
    const agent = window.currentTransaction?.isListedByAgent

    const teams = useSelector((state) => state.teams.teams)
    const { getTeamByRole } = useTeams()
    const movingCompany = getTeamByRole('MovingCompanyContact')

    useEffect(() => {
        movingCompany.companyName === 'Bellhop' ||
            (movingCompany.companyName === 'Bellhops' ? setBellhops(true) : setBellhops(false))
    }, [teams])

    if (!bellhops) {
        return null
    }

    return (
        <BellhopsCont>
            <h1>Don't forget!</h1>
            {agent === true ? (
                <p>
                    Once approved, Please make sure your seller connects with Bellhop if the
                    extended stay date change will impact their moving date.
                </p>
            ) : (
                <p>
                    Please make sure you connect with Bellhop if your change of closing date will
                    impact your moving date.
                </p>
            )}

            <br />
            <h2>Here's their contact info:</h2>
            <img src={BellhopsLogo} alt="" className="bellhop-logo" />
            <div className="bellhop-info-cont">
                <div>
                    <img src={WebImg} alt="" />
                    <a href="//www.getbellhops.com" target="_blank">
                        www.getbellhops.com
                    </a>
                </div>
                <div>
                    <img src={EmailImg} alt="" />
                    <a href="mailto:offerpad@bellhop.com">offerpad@bellhop.com</a>
                </div>
                <div>
                    <img src={PhoneImg} alt="" />
                    <a href="tel:+1 888-296-1874">+1 888-296-1874</a>
                </div>
            </div>
        </BellhopsCont>
    )
}

export default Bellhops
