import { Cont } from './Styles'
import { useWizard } from 'react-use-wizard'
import CoePointer from 'OnDemandActions/Sell/CodDateChange/shared/assets/coe-info-pointer.png'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Step3 = ({ data, setData }) => {
    const { nextStep, previousStep } = useWizard()
    const agent = window.currentTransaction?.isListedByAgent
    const [accept, setAccept] = useState(data.accept ? data.accept : null)
    const teams = useSelector((state) => state.teams.teams)
    let titleCompany = teams.filter((team) => team.role === 'TitleContact')[0]

    if (titleCompany === undefined) {
        titleCompany = { companyName: `The  title company or attorney's office` }
    }

    const handleClick = () => {
        nextStep()
    }

    useEffect(() => {
        const a = { accept: accept }
        setData({ ...data, ...a })
    }, [accept])

    const renderText = () => {
        if (agent === true) {
            return (
                <>
                    <div className="coe-change-info">
                        <img src={CoePointer} alt="" />
                        <p>
                            You selected for your seller to stay in the home for{' '}
                            {data.extendedStayDays} {data.extendedStayDays > 1 ? 'days' : 'day'}{' '}
                            after the closing date.
                        </p>
                    </div>
                    <div className="coe-change-info">
                        <img src={CoePointer} alt="" />
                        <p>
                            {titleCompany?.companyName} will holdback a refundable $10,000 deposit
                            from the sale proceeds of your sellers home at closing.
                        </p>
                    </div>
                    <div className="coe-change-info">
                        <img src={CoePointer} alt="" />
                        <p>
                            To qualify for the Extended Stay Program, your seller will need to sign
                            a Post-Possession Addendum, which will be emailed to you after the
                            inspection has been completed.
                        </p>
                    </div>
                    <div className="coe-change-info">
                        <img src={CoePointer} alt="" />
                        <p>
                            During our final walk-through, we will confirm both that the home is
                            unoccupied, its condition is the same as it was during the inspection
                            and that it is also empty of all personal belongings and debris. Once we
                            confirm, we will instruct {titleCompany?.companyName} to release the
                            funds held back to your seller.
                        </p>
                    </div>
                    <div className="coe-change-info">
                        <img src={CoePointer} alt="" />
                        <p>
                            Your seller will need to vacate the home no later than 5:00 pm on the
                            final day of their Extended Stay period.
                        </p>
                    </div>
                </>
            )
        }

        return (
            <>
                <div className="coe-change-info">
                    <img src={CoePointer} alt="" />
                    <p>
                        You selected to stay in your home for an {data.extendedStayDays}{' '}
                        {data.extendedStayDays > 1 ? 'days' : 'day'} after your closing date.
                    </p>
                </div>
                <div className="coe-change-info">
                    <img src={CoePointer} alt="" />
                    <p>
                        {titleCompany?.companyName} will holdback a refundable $10,000 deposit from
                        the sale proceeds of your home at closing.
                    </p>
                </div>
                <div className="coe-change-info">
                    <img src={CoePointer} alt="" />
                    <p>
                        You will need to sign a Post-Possession Addendum once your Extended Stay is
                        approved.
                    </p>
                </div>
                <div className="coe-change-info">
                    <img src={CoePointer} alt="" />
                    <p>
                        During our final walk-through, we will confirm both that the home is
                        unoccupied, its condition is the same as it was during the inspection and
                        that it is also empty of all personal belongings and debris. Once we
                        confirm, we will instruct {titleCompany?.companyName} to release the funds
                        held back to you.
                    </p>
                </div>
                <div className="coe-change-info">
                    <img src={CoePointer} alt="" />
                    <p>
                        You will need to vacate the home no later than 5:00 pm on the final day of
                        your Extended Stay period.
                    </p>
                </div>
            </>
        )
    }

    return (
        <Cont>
            <h1>Got it! Here's a review of how our extended stay program works...</h1>
            <div className="coe-info-cont">
                {renderText()}
                <div className="coe-change-info">
                    <input
                        type="checkbox"
                        onChange={(e) => setAccept(e.target.checked)}
                        defaultChecked={accept === null ? false : accept}
                    />
                    <span>I acknowledge the above terms</span>
                </div>
            </div>
            <div className="btn-cont">
                <button id="fs_sell_cod_dateChange_pa_previous3" onClick={previousStep}>
                    Back
                </button>
                <button
                    className="next"
                    id="fs_sell_cod_dateChange_pa_next3"
                    onClick={handleClick}
                    disabled={accept === null || accept === false}
                >
                    Next
                </button>
            </div>
        </Cont>
    )
}

export default Step3
