import { css } from 'styled-components'
import Color from 'color'

export const color = {
    primary: '#EF7724',
    success: '#0B875B',
    danger: '#E13C3C',
    warning: '#F89C1C',
    secondary: '#F4F5F7',
    info: '#176775',

    textDarkest: '#172b4d',
    textDark: ' #545764',
    textMedium: '#5E6C84',
    textLight: '#979797',
    textLink: '#EF7724',
    textWhite: '#fff',
    textBlack: '#000000',
    textInfo: '#1B6775',

    backgroundDarkPrimary: '#0747A6',
    backgroundMedium: '#dfe1e6',
    backgroundLight: '#979797',
    backgroundLightest: '#e7f0f180',
    backgroundLightPrimary: '#D2E5FE',
    backgroundLightSuccess: '#E4FCEF',
    backgroundWhite: '#fff',
    backgroundInfo: '#176775',

    borderLightest: '#dfe1e6',
    borderLight: '#979797',
    borderInputFocus: '#4c9aff',
    borderInfo: '#176775',

    trackBarBackground: '#D9D9D9',
    trackBarFillCOlor: '#1b6775',
    trackerStatusHeaderColor: '#3C3C3C',
    trackerCurrentStatusColor: '#525659',
}

export const breakPoints = {
    xs: 0,
    sm: '575.98px',
    md: '767.98px',
    lg: '991.98',
    xl: '1199.92',
    xxl: '1399.98',
}

export const zIndexValues = {
    alert: 10002,
    modal: 10001,
    dropdown: 101,
    navLeft: 100,
}

export const font = {
    regular: 'font-family: MuseoSans-regular; font-weight: 300;',
    medium: 'font-family: MuseoSans-medium; font-weight: 500;',
    bold: 'font-family: MuseoSans-bold; font-weight: 700;',
    black: 'font-family: MuseoSans-black; font-weight: 900;',
    tag: 'font-family: PalmerLakePrint-regular; font-weight: 400;',
    size: (size) => `font-size: ${size}px;`,
}

export const fontSize = {
    sm: font.size(14),
    normal: font.size(16),
    lg: font.size(20),
    xl: font.size(32),
}

export const size = {
    sm: css`
        height: 14px;
        width: 14px;
    `,
    normal: css`
        height: 16px;
        width: 16px;
    `,
    lg: css`
        height: 20px;
        width: 20px;
    `,
    xl: css`
        height: 32px;
        width: 32px;
    `,
}

export const padding = {
    sm: 'padding: 4px 8px;',
    normal: 'padding: 6px 12px',
    lg: 'padding: 8px 16px',
    xl: 'padding: 10px 20px',
}

export const borderRadius = {
    sm: 'border-radius: 3.2px',
    normal: 'border-radius: 4px',
    lg: 'border-radius: 4.8px',
    xl: 'border-radius: 5.6px',
}

export const mixin = {
    darken: (colorValue, amount) => Color(colorValue).darken(amount).string(),
    lighten: (colorValue, amount) => Color(colorValue).lighten(amount).string(),
    rgba: (colorValue, opacity) => Color(colorValue).alpha(opacity).string(),
    boxShadowMedium: css`
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    `,
    boxShadowDropdown: css`
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
    `,
    truncateText: css`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `,
    clickable: css`
        cursor: pointer;
        user-select: none;
    `,
    hardwareAccelerate: css`
        transform: translateZ(0);
    `,
    cover: css`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    `,
    placeholderColor: (colorValue) => css`
        ::-webkit-input-placeholder {
            color: ${colorValue} !important;
            opacity: 1 !important;
        }
        :-moz-placeholder {
            color: ${colorValue} !important;
            opacity: 1 !important;
        }
        ::-moz-placeholder {
            color: ${colorValue} !important;
            opacity: 1 !important;
        }
        :-ms-input-placeholder {
            color: ${colorValue} !important;
            opacity: 1 !important;
        }
    `,
    scrollableY: css`
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    `,
    backgroundImage: (imageURL) => css`
        background-image: url('${imageURL}');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
    `,
    link: (colorValue = color.textLink) => css`
        cursor: pointer;
        color: ${colorValue};
        ${font.medium}
        &:hover, &:visited, &:active {
            color: ${colorValue};
        }
        &:hover {
            text-decoration: underline;
        }
    `,
}
