import { Route, Routes, useNavigate } from 'react-router-dom'
import Login from './Login'
import Register from './Register'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import useScrollTo from 'shared/hooks/scrollTo'
import {
    getStoredAuthToken,
    removeStoredAuthToken,
    removeStoredTokenExpiryTime,
} from 'shared/utils/authToken'
import { getStoredRefreshToken } from 'shared/utils/refreshToken'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { Footer } from 'shared/components'

const Auth = () => {
    const { scrollToTop } = useScrollTo()
    const navigate = useNavigate()
    const authToken = getStoredAuthToken()
    const refreshToken = getStoredRefreshToken()

    useEffect(() => {
        scrollToTop()
        if (!isEmpty(refreshToken)) {
            if (!isEmpty(authToken)) {
                navigate('/sell/dashboard')
            }
        } else {
            removeStoredAuthToken()
            removeStoredTokenExpiryTime()
        }
    }, [])

    return (
        <>
            <Routes>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password" element={<ResetPassword />} />
            </Routes>
            <Footer />
        </>
    )
}

export default Auth
