import styled from 'styled-components'

export const Input = styled.input`
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    border-radius: 50%;
    outline: none;
    border: 2px solid ${(props) => props.defaultColor};
    cursor: pointer;
    flex-shrink: 0;
    &::before {
        content: '';
        display: block;
        margin: 20% auto;
        width: 60%;
        height: 60%;
        border-radius: 50%;
    }
    &:checked {
        border-color: ${(props) => props.checkedColor};
    }
    &:checked::before {
        background: ${(props) => props.checkedColor};
    }
    &:disabled {
        border: 2px solid ${(props) => props.defaultColor};
        cursor: default;
    }
    &:checked:disabled::before {
        background: ${(props) => props.defaultColor};
    }
`
