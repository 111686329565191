import styled, { css } from 'styled-components'
import { font, color, mixin } from 'shared/utils/styles'
import { Button } from 'shared/components'

export const PropertySelectionButton = styled(Button)`
    display: flex;
    align-items: center;
    ${font.size(14)};
    ${font.medium};
    padding: 18px 30px;
    border-radius: 60px;
    border: 1px solid #dbe5e7;
    color: #898989;
    background-color: #f7fcfe;
    transition: 0.3s ease-in-out;
    height: 55px;
    &:hover {
        background-color: #f3f7f8 !important;
        box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.3);
    }
    @media (max-width: 1500px) {
        padding: 12px 20px;
    }
    @media (max-width: 950px) {
        padding: 8px 10px;
        ${font.size(14)};
    }
    @media (max-width: 820px) {
        border-radius: 4px;
        height: 50px;
        width: 278px;
    }
    @media (max-width: 480px) {
        width: 100%;
        border-radius: 8px;
        padding: 14px 18px;
        div {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
`

export const StyledButtonArrow = styled.img`
    margin-left: 34px;
    @media (max-width: 820px) {
        margin-left: 122px;
    }
`
export const ModalCont = styled.div`
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 480px) {
        height: 100%;
    }
    @media (min-width: 481px) {
        height: 100%;
        max-height: calc(100vh - 100px);
    }
    .m-body {
        height: auto;
        overflow-y: auto;
        overflow-x: hidden !important;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media (max-width: 480px) {
            overflow: scroll !important;
            height: 100%;
            gap: 0px;
        }
    }
`
export const ModalContHeading = styled.div`
    color: ${color.trackerCurrentStatusColor};
    ${font.size(22)};
    ${font.black};
    @media (max-width: 480px) {
        margin-top: 0px;
        margin-bottom: 10px;
    }
`
export const ModalFooter = styled.div`
    margin: 30px 0px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    @media (max-width: 480px) {
        margin: 10px 0px;
        margin-top: auto;
        display: block;
    }
`

export const ModalContFooter = styled.div`
    color: #525659;
    ${font.size(16)};
    ${font.black};
    display: flex;
    justify-content: center;
`

export const ModalContFooterButton = styled.a`
    padding-top: 0px;
    color: #1b6775;
    ${font.size(16)};
    ${font.bold};
    display: flex;
    justify-content: center;
    text-decoration: underline;
    @media (max-width: 480px) {
        margin-top: 20px;
    }
`

export const ModalBodyCont = styled.div`
    display: flex;
    justify-content: start;
    gap: 20px;
    @media (max-width: 480px) {
        gap: 14px;
        justify-content: flex-start;
        align-items: stretch;
    }
    ${(props) =>
        !!props.borderTop &&
        css`
            border-top: 1px solid #e6e6e6;
            padding-top: 20px;
            @media (max-width: 480px) {
                margin-top: 20px;
            }
        `}
    .modal-img {
        height: 91px;
        aspect-ratio: 800/629;
        border-radius: 5px;
        object-fit: cover;
        flex-direction: column;
        @media (max-width: 820px) {
            max-height: 200px;
            max-width: 200px;
        }
        @media (max-width: 480px) {
            align-items: flex-start;
            width: 122px;
            height: 82px;
            aspect-ratio: 122/82;
        }
    }
`

export const ModalBodyImg = styled.img`
    height: 82px;
    aspect-ratio: 800/629;
    border-radius: 5px;
    object-fit: cover;
    flex-direction: column;
    @media (max-width: 820px) {
        max-height: 200px;
        max-width: 200px;
    }
    @media (max-width: 480px) {
        align-items: flex-start;
        width: 122px;
        height: 82px;
        aspect-ratio: 122/82;
    }
`

export const ModalBodyData = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media screen and (max-width: 480px) {
        flex-direction: column;
        width: 100%;
        gap: 4px;
    }
`

export const ModalBodyDataHeading = styled.div`
    color: ${color.primary};
    ${font.bold};
    ${mixin.clickable};
    ${font.size(15)};
    line-height: 18px;
    display: inline;
    div {
        // display: block;
        margin-top: 5px;
        ${font.bold};
        ${font.size(12)};
        line-height: 14px;
        color: #61657e;
    }
`

export const PropertyViewCreated = styled.div`
    color: #898989;
    ${font.size(13)};
    line-height: 13px;
    ${font.bold};
    margin-top: 5px;
    @media (max-width: 480px) {
        ${font.size(11)};
        margin-top: 3px;
        ${font.medium};
    }
`

export const ModalBodyDataStatus = styled.div`
    ${font.medium}
    ${font.size(10)}
    line-height: 12px;
    color: #ec7625;
    padding: 3px 7px;
    background: #fadecb;
    border-radius: 44px;
    width: fit-content;
`

export const ModalBodyDataFooterCont = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    @media screen and (max-width: 480px) {
        flex-direction: column;
        gap: 8px;
        justify-content: start;
        align-items: flex-start;
    }
`
export const ModalBodyDataFooterData = styled.span`
    ${font.medium}
    ${font.size(12)}
color: ${color.textDarkest};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span {
        display: none;
        @media (max-width: 480px) {
            display: block;
            margin-left: 3px;
        }
    }
    ${(props) =>
        !!props.badge &&
        css`
            background-color: rgba(239, 119, 36, 0.5);
            padding: 7px 18px;
            border-radius: 20px;
            ${font.size(12)};
            color: black;
            line-height: 1;
            @media (max-width: 480px) {
                display: none;
            }
        `}
    @media (max-width: 480px) {
        color: #81828a;
        line-height: 12px;
    }
    ${(props) =>
        !!props.mobile &&
        css`
            @media (max-width: 480px) {
                color: #525659;
            }
        `}
`
export const ModalBodyDataFooterDataImage = styled.img`
    background-color: rgba(231, 240, 241, 0.5);
    padding: 5px;
    display: flex;
    flex-direction: row;
    border-radius: 50%;
    margin-right: 10px;
    @media (max-width: 480px) {
        transform: scale(0.8);
        margin-right: 9px;
    }
    ${(props) =>
        !!props.mobile &&
        css`
            @media (max-width: 480px) {
                display: none;
                transform: scale(0.8);
            }
        `}
`
