import { font } from 'shared/utils/styles'
import styled, { css, keyframes } from 'styled-components'
import Button from '../Button'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

export const Container = styled.div`
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    min-height: 300px;
    @media screen and (max-width: 480px) {
        padding: 30px 20px;
        min-height: 250px;
    }
`

export const Image = styled.img`
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @media screen and (max-width: 480px) {
        width: 100px;
    }
`

export const Heading = styled.div`
    ${font.bold};
    color: #363c4f;
    text-align: center;
    line-height: 40px;
    font-size: 27px;
    margin-top: 5px;
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @media screen and (max-width: 480px) {
        line-height: 33px;
        font-size: 20px;
        margin-top: 0px;
    }
`

export const Paragraph = styled.div`
    text-align: center;
    ${font.medium};
    color: #363948;
    line-height: 24px;
    font-size: 20px;
    margin-top: 18px;
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @media screen and (max-width: 480px) {
        line-height: 22px;
        font-size: 18px;
        margin-top: 10px;
    }
`

export const ActionButton = styled(Button)`
    width: 40%;
    margin-top: 20px;
    font-size: 20px;
    margin-top: 30px;
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    ${(props) =>
        props.variant === 'empty' &&
        css`
            color: #525659;
            border: 1px solid #8e91a0;
            &:hover {
                background: white !important;
                opacity: 0.75;
            }
        `}
    @media screen and (max-width: 480px) {
        width: 60%;
    }
`
