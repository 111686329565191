import styled, { css, keyframes } from 'styled-components'

import { color, mixin, zIndexValues, font } from 'shared/utils/styles'

export const ScrollOverlay = styled.div`
    z-index: ${zIndexValues.alert};
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    ${mixin.scrollableY}
    background: rgba(9, 30, 66, 0.54);
`

const opacityAndScale = keyframes`
    from {
        opacity: 0;
        top: 48%;
    }
    to {
        opacity: 1;
        top: 50%;
    }
`

export const StyledAlert = styled.div`
    background-color: white;
    padding: 47px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    width: 500px;
    max-width: calc(100vw - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation-duration: 0.5s;
    animation-name: ${opacityAndScale};
    animation-fill-mode: backwards;
    @media (max-width: 660px) {
        width: 400px;
    }
    @media (max-width: 460px) {
        width: calc(100vw - 60px);
        padding: 30px 20px;
    }
    .action-button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 27px;
        width: 100%;
    }
`

export const LoadingCont = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`

export const ActionButton = styled.button`
    width: auto;
    min-width: 150px;
    height: 62px;
    padding: 0px 20px;
    background: #ffffff;
    border: 1px solid #bdbdbd;
    color: #525659;
    border-radius: 4px;
    ${font.size(18)};
    line-height: 21.6px;
    ${font.bold};
    cursor: pointer;
    ${(props) =>
        props.primary === true &&
        css`
            background: #ec7625;
            color: white;
            border: none;
        `}

    @media (max-width: 480px) {
        height: 50px;
    }
`

export const Icon = styled.img`
    margin-bottom: 20px;
`

export const Heading = styled.h1`
    ${font.size(26)};
    line-height: 31px;
    ${font.black};
    color: #363948;
    margin-bottom: 18px;
    text-align: center;
    @media (max-width: 480px) {
        ${font.size(20)};
        margin-bottom: 0px;
        text-align: center;
    }
`

export const Div = styled.div`
    ${font.size(18)};
    line-height: 28px;
    ${font.medium};
    color: #363948;
    margin-bottom: 25px;
    text-align: center;
    @media (max-width: 480px) {
        margin-top: 10px;
        ${font.size(14)};
        line-height: 24px;
        text-align: center;
    }
`
