import styled, { css } from 'styled-components'
import { font } from 'shared/utils/styles'

export const Cont = styled.div`
    padding: 70px 50px;
    @media (max-width: 480px) {
        padding: 75px 40px 32px 40px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    h1 {
        ${font.black};
        ${font.size(22)};
        line-height: 26.4px;
        color: #000000;
    }
    .coe-info-cont {
        display: flex;
        flex-direction: column;
        gap: 27px;
        margin-top: 30px;
        .coe-change-info {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 12px;
            img {
                height: 20px;
                width: 20px;
            }
        }
        input {
            -webkit-appearance: checkbox;
            -moz-appearance: checkbox;
            height: 20px;
            width: 20px;
            cursor: pointer;
            accent-color: #336573;
            margin-top: 40px;
        }
        span {
            ${font.size(14)}
            line-height: 16.8px;
            color: #525659;
            ${font.medium};
            margin-top: 42px;
        }
        p {
            ${font.size(17)}
            line-height: 26px;
            color: #525659;
            ${font.medium};
        }
    }
    .btn-cont {
        margin-top: 39px;
        display: flex;
        gap: 22px;
        @media (max-width: 480px) {
            padding-bottom: 30px;
        }
        button {
            height: 62px;
            padding: 20px 44px;
            width: 129px;
            background: #ffffff;
            border: 1px solid #bdbdbd;
            border-radius: 4px;
            ${font.size(18)};
            ${font.medium};
            line-height: 22px;
            color: #525659;
            cursor: pointer;
            &:disabled {
                background: rgba(236, 118, 37, 0.5);
                cursor: initial;
            }
            @media (max-width: 480px) {
                width: 100%;
            }
        }
        .next {
            width: 180px;
            background: #ec7625;
            color: white;
            @media (max-width: 480px) {
                width: 100%;
            }
        }
    }
`
