import styled from 'styled-components'
import { color, font } from 'shared/utils/styles'

export const MenuCont = styled.button`
    padding: 17px 30px;
    background: #ffffff;
    border: 2px solid #eaeaea;
    border-radius: 35px;
    color: #7a7a7a;
    ${font.size(16)};
    line-height: 19px;
    ${font.bold};
    gap: 10px;
    cursor: pointer;
    @media (max-width: 820px) {
        border-radius: 8px;
        font-size: 15px;
        padding: 19px 20px;
        gap: 10px;
        line-height: 12px;
    }

    @media (max-width: 480px) {
        border: none;
        font-size: 15px;
        ${font.medium};
        padding: 19px 20px;
        gap: 10px;
        text-decoration: underline;
        color: #1b6775;
        line-height: 1.2rem;
        white-space: nowrap;
    }
`
