import { capitalizeFirstLetterEveryWord } from 'shared/utils/formatter'
import PropertyCard from '../PropertyCard'
import { CardContainer, Cont, FooterActionLink, FooterHeading, Heading, PageFooter } from './Styles'
import moment from 'moment'

const PropertyList = ({ transactions, handlePropertyClick }) => {
    return (
        <Cont>
            <Heading>Select your property</Heading>
            <CardContainer>
                {transactions.map((t, i) => (
                    <PropertyCard
                        key={Date.now() + i}
                        image={t?.property?.streetViewUrl}
                        badge={t?.displayToCustomer}
                        heading={capitalizeFirstLetterEveryWord(t?.property?.streetAddress)}
                        subHeading={`${t?.property?.city} ${t?.property?.state}, ${t?.property?.zip}`}
                        ctaText={'Details'}
                        ctaClick={() => handlePropertyClick(t.transactionId)}
                        footerText={
                            t?.offerRequestedDate
                                ? `Created: ${moment(t?.offerRequestedDate).format('MM/DD/YYYY')}`
                                : 'N/A'
                        }
                    />
                ))}
            </CardContainer>
            <PageFooter>
                <FooterHeading>Have another property?</FooterHeading>
                <FooterActionLink
                    onClick={() => window.open(process.env.REQUEST_PROPERTY_URL, '_blank')}
                    id="fs_sell_dashboard_request_offer"
                >
                    Request another offer
                </FooterActionLink>
            </PageFooter>
        </Cont>
    )
}

export default PropertyList
