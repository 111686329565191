import parse from 'html-react-parser'
import Modal from '../Modal'
import { ActionButton, ModalCont } from './Styles'
import Butterfly from 'shared/asstes/butterfly_.svg'
import CopyIcon from 'shared/asstes/copy.svg'
import MailIcon from 'shared/asstes/mail-white.svg'
import ThumbsUpIcon from 'shared/asstes/thumbsup.png'
import { Fragment } from 'react'
import useTeams from 'shared/hooks/sell/teams'
import { isEmpty } from 'lodash'
import toast from 'shared/utils/toast'
import { copyToClipboard } from 'shared/utils/browser'
import { convertObjectToBase64 } from 'shared/utils/converter'
import { encodeURIForMailTo, formatPhoneNumber } from 'shared/utils/formatter'
import { getMailDetails } from 'shared/constants/mail'
import useTodos from 'shared/hooks/sell/todos'
import useEvents from 'shared/hooks/sell/events'
import moment from 'moment'

const ReadAndAcknowledge = ({
    todo,
    isComplete = false,
    onMarkedTodoCompleteEvent = () => {},
    id = '',
}) => {
    const {
        id: todoId,
        identifier,
        title,
        modalTitle,
        actionButtonLabel,
        modalContent,
        persona,
    } = todo
    let description = modalContent

    const isCustomer = persona === 'Customer'
    const IsCompletedTitle = isCustomer ? 'Read & Acknowledge' : 'Read & Share'

    const { getTeamByRoleidentifier, getTeamByRole } = useTeams()
    const { markTodoCompleted } = useTodos()

    const events = window.currentEvents
    const { getEventUsingEventIdentifier } = useEvents()

    let inHomeInspection = getEventUsingEventIdentifier(events, 'InHomeInspectionEvent')

    const teams = window.currentTeams
    const currentTransaction = window.currentTransaction

    const inHomeRepresentative = getTeamByRole('InHomeOfferpadRepresentative')
    const homeMarketingConsultantName =
        inHomeRepresentative.firstName + ' ' + inHomeRepresentative.lastName
    const homeMarketingConsultantPhone = inHomeRepresentative.phoneNumber
    const homeMarketingConsultantEmail = inHomeRepresentative.email

    let titleCompany = getTeamByRoleidentifier(teams, 'TitleContact')

    const { extendedStay, transactionId } = currentTransaction

    if (isEmpty(Object.keys(titleCompany))) {
        titleCompany = {
            companyName: 'The title company or attorney’s office',
        }
    }

    let extStay = 3

    if (description) {
        const regex = /\d{3}-\d{3}-\d{4}/
        const phoneNumberMatch = description?.match(regex)
        let formattedPhoneNumber = ''
        if (phoneNumberMatch) {
            const phoneNumber = phoneNumberMatch[0]
            formattedPhoneNumber = formatPhoneNumber(phoneNumber)
        }
        description = description?.replace(
            phoneNumberMatch,
            `<a href="tel:${formattedPhoneNumber}">${formattedPhoneNumber}</a>`,
        )

        if (description?.includes('[titleCompanyName]')) {
            description = description?.replace('[titleCompanyName]', titleCompany.companyName)
        }
        if (description?.includes('[extendedStayDays]')) {
            if (extendedStay) {
                if (extendedStay.extendedStayDayEligibleDays !== null) {
                    description = description?.replace(
                        '[extendedStayDays]',
                        extendedStay.extendedStayDayEligibleDays,
                    )
                    extStay = extendedStay.extendedStayDayEligibleDays
                } else {
                    description = description?.replace('[extendedStayDays]', extStay)
                }
            } else {
                description = description?.replace('[extendedStayDays]', extStay)
            }
        }
    }

    const link = (base64Data) => `${process.env.MARKETING_URL}/public/resource/${base64Data}`

    const getIdUsingIdentifier = {
        CashOfferTransactionWhatToLeaveBehind: 46,
        CashOfferTransactionConditionRequirements: 47,
        CashOfferTransactionAcknowledgeFreeLocalMove: 48,
        CashOfferTransactionAcknowledgeExtendedStay: 49,
        CashOfferTransactionPrepareForInspection: 50,
    }

    const generateLink = () => {
        const date = Object.keys(inHomeInspection).length
            ? `${moment(inHomeInspection.eventDate, 'MM/DD/YYYY').format('MMMM DD, YYYY')} at ${
                  inHomeInspection.time
              } (${inHomeInspection.timeZone})`
            : ''

        const dataToEncode = {
            id: getIdUsingIdentifier[identifier],
            titleCompany: titleCompany.companyName,
            extStay,
            homeMarketingConsultantName: homeMarketingConsultantName,
            homeMarketingConsultantPhone: homeMarketingConsultantPhone,
            homeMarketingConsultantEmail: homeMarketingConsultantEmail,
            inspectionDate: date,
        }
        return link(convertObjectToBase64(dataToEncode))
    }

    const markTodoAsComplete = async () => {
        if (!isComplete) {
            await markTodoCompleted(transactionId, todoId)
            onMarkedTodoCompleteEvent()
        }
    }

    const copyLink = (close) => {
        copyToClipboard(generateLink())
        toast.success('Link copied successfully.')
        markTodoAsComplete()
        close()
    }

    return (
        <Modal
            testid="modal:ReadAndAcknowledge"
            width="875px"
            backgroundColor="white"
            withCloseIcon={true}
            variableWidth={true}
            onCloseEvent={markTodoAsComplete}
            renderLink={({ open }) => (
                <ActionButton variant="primary" onClick={open} isComplete={isComplete} id={id}>
                    {actionButtonLabel}
                </ActionButton>
            )}
            renderContent={({ close }) => (
                <ModalCont>
                    <div className="heading-container">
                        <h2 className="heading">{!isComplete ? title : IsCompletedTitle}</h2>
                        <img src={Butterfly} alt="" />
                    </div>
                    <span className="sub-heading">{modalTitle}</span>
                    <hr />
                    <div className="content">{parse(description)}</div>
                    {!isCustomer && (
                        <div className="note">
                            Please note: Offerpad does not collect or store any of your seller’s
                            contact information
                        </div>
                    )}
                    <div className="button-container">
                        {!isCustomer && (
                            <Fragment>
                                <a
                                    href={encodeURIForMailTo(
                                        getMailDetails(identifier, generateLink()),
                                    )}
                                >
                                    <ActionButton
                                        variant="primary"
                                        size="lg"
                                        onClick={() => {
                                            markTodoAsComplete()
                                            close()
                                        }}
                                    >
                                        <span>Share via Email</span>
                                        <img src={MailIcon} alt="e-mail" />
                                    </ActionButton>
                                </a>
                                <ActionButton
                                    variant="primary"
                                    size="lg"
                                    onClick={() => copyLink(close)}
                                >
                                    <span>Copy Link</span>
                                    <img src={CopyIcon} alt="copy" />
                                </ActionButton>
                            </Fragment>
                        )}
                        {isCustomer && !isComplete && (
                            <ActionButton
                                variant="primary"
                                size="lg"
                                onClick={() => {
                                    markTodoAsComplete()
                                    close()
                                }}
                            >
                                <span>Got it!</span>
                                <img src={ThumbsUpIcon} alt="thumbs-up" className="thumbs-up" />
                            </ActionButton>
                        )}
                    </div>
                </ModalCont>
            )}
        />
    )
}

export default ReadAndAcknowledge
