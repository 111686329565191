import {
    SurveyCont,
    ActionButton,
    Heading,
    UserInfoCont,
    PointImage,
    WrapperDiv,
    Extendedsubtext,
    DynamicIcons,
    ReqWrapper,
    ExtRequestedLine,
    DividerModal,
    DefaultE,
} from 'OnDemandActions/Sell/ExtendedStay/shared/Styles'
import Points from 'OnDemandActions/Sell/ExtendedStay/shared/assets/points.svg'
import TickIcon from 'OnDemandActions/Sell/ExtendedStay/shared/assets/Tick_icon.svg'
import Info from 'OnDemandActions/Sell/ExtendedStay/shared/assets/info-extended-stay.svg'
import Phone from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/phone-free-local.svg'
import Email from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/email-free-local.svg'
import CoePointer from 'OnDemandActions/Sell/CodDateChange/shared/assets/coe-info-pointer.png'
import { useWizard } from 'react-use-wizard'
import useTeams from 'shared/hooks/sell/teams'
import useEvents from 'shared/hooks/sell/events'
import moment from 'moment'
import { formatPhoneNumber } from 'shared/utils/formatter'
import { user } from 'shared/utils/jwt'

const Step1 = ({ data, setData, es = {} }) => {
    const { nextStep } = useWizard()
    const agent = window.currentTransaction?.isListedByAgent

    const { getEventByRole } = useEvents()
    const { eventDate } = getEventByRole('ClearedToClose')

    const { getTeamByRole } = useTeams()
    const successManager = getTeamByRole('SuccessManager')
    let titleCompany = getTeamByRole('TitleContact')

    if (!titleCompany) {
        titleCompany = {
            companyName: '“The title company or attorney’s office”',
        }
    }

    const timeOver = moment(eventDate, 'MM/DD/YYYY').diff(moment(), 'days') <= 7

    const renderText = () => {
        if (agent === true) {
            return (
                <>
                    <UserInfoCont>
                        <PointImage src={CoePointer} alt="" />
                        <p>
                            You selected for your seller to stay in the home for{' '}
                            {data.extendedStayDays} {data.extendedStayDays > 1 ? 'days' : 'day'}{' '}
                            after your closing date.
                        </p>
                    </UserInfoCont>
                    <UserInfoCont>
                        <PointImage src={CoePointer} alt="" />
                        <p>
                            {titleCompany?.companyName} will holdback a refundable $10,000 deposit
                            from the sale proceeds of your sellers home at closing.
                        </p>
                    </UserInfoCont>
                    <UserInfoCont>
                        <PointImage src={CoePointer} alt="" />
                        <p>
                            To qualify for the Extended Stay Program, your seller will need to sign
                            a Post-Possession Addendum, which will be emailed to you after the
                            inspection has been completed.
                        </p>
                    </UserInfoCont>
                    <UserInfoCont>
                        <PointImage src={CoePointer} alt="" />
                        <p>
                            During our final walk-through, we will confirm both that the home is
                            unoccupied, its condition is the same as it was during the inspection
                            and that it is also empty of all personal belongings and debris. Once we
                            confirm, we will instruct {titleCompany?.companyName} to release the
                            funds held back to your seller.
                        </p>
                    </UserInfoCont>
                    <UserInfoCont>
                        <PointImage src={CoePointer} alt="" />
                        <p>
                            Your seller will need to vacate the home no later than 5:00 pm on the
                            final day of their Extended Stay period.
                        </p>
                    </UserInfoCont>
                </>
            )
        }

        return (
            <>
                <UserInfoCont>
                    <PointImage src={CoePointer} alt="" />
                    <p>
                        You selected to stay in your home for an {data.extendedStayDays}{' '}
                        {data.extendedStayDays > 1 ? 'days' : 'day'} after your closing date.
                    </p>
                </UserInfoCont>
                <UserInfoCont>
                    <PointImage src={CoePointer} alt="" />
                    <p>
                        {titleCompany?.companyName} will holdback a refundable $10,000 deposit from
                        the sale proceeds of your home at closing.
                    </p>
                </UserInfoCont>
                <UserInfoCont>
                    <PointImage src={CoePointer} alt="" />
                    <p>
                        You will need to sign a Post-Possession Addendum once your Extended Stay is
                        approved.
                    </p>
                </UserInfoCont>
                <UserInfoCont>
                    <PointImage src={CoePointer} alt="" />
                    <p>
                        During our final walk-through, we will confirm both that the home is
                        unoccupied, its condition is the same as it was during the inspection and
                        that it is also empty of all personal belongings and debris. Once we
                        confirm, we will instruct {titleCompany?.companyName} to release the funds
                        held back to you.
                    </p>
                </UserInfoCont>
                <UserInfoCont>
                    <PointImage src={CoePointer} alt="" />
                    <p>
                        You will need to vacate the home no later than 5:00 pm on the final day of
                        your Extended Stay period.
                    </p>
                </UserInfoCont>
            </>
        )
    }

    return (
        <SurveyCont>
            <Heading mt="10">Extended Stay</Heading>
            <ReqWrapper mt="11">
                <DynamicIcons width="17" height="17" src={TickIcon} />
                <ExtRequestedLine>Requested</ExtRequestedLine>
            </ReqWrapper>
            <DividerModal />
            <Extendedsubtext mt="11">
                To help you understand how Extended Stay works, we’ve provided the additional
                information below.
            </Extendedsubtext>
            <br />
            <WrapperDiv>{renderText()}</WrapperDiv>
            <br />
            <br />
            <div className="button-cont">
                <center>
                    <ActionButton
                        width="250px"
                        id="fs_sell_extendedStay_pa_modify"
                        continue
                        variant="primary"
                        onClick={() => !timeOver && nextStep()}
                        disabled={timeOver}
                        grayed={`${timeOver ? 'true' : 'false'}`}
                    >
                        Modify
                    </ActionButton>
                </center>
            </div>
            {timeOver && (
                <DefaultE>
                    <div className="info-cont">
                        <span className="not-available-info">
                            <img src={Info} alt="" />
                            It may be too late to make changes to your Extended Stay, please reach
                            out directly to your Success Manager, {successManager?.firstName}{' '}
                            {successManager?.lastName}to confirm.
                        </span>
                        <div className="contacts">
                            {successManager?.phoneNumber && (
                                <div className="c-info">
                                    <img src={Phone} alt="" />
                                    <a
                                        href={`tel:${formatPhoneNumber(
                                            successManager?.phoneNumber,
                                        )}`}
                                    >
                                        {formatPhoneNumber(successManager?.phoneNumber)}
                                    </a>
                                </div>
                            )}
                            {successManager?.email && (
                                <div className="c-info">
                                    <img src={Email} alt="" />
                                    <a href={`mailto:${successManager?.email}`}>
                                        {successManager?.email}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                    <br />
                </DefaultE>
            )}
        </SurveyCont>
    )
}

export default Step1
