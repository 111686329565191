import { font } from 'shared/utils/styles'
import styled from 'styled-components'

export const Container = styled.div`
    width: auto;
    height: auto;
    padding: 20px 30px;
`

export const Heading = styled.div`
    ${font.bold};
    font-size: 15px;
    line-height: 18px;
    color: #000;
`

export const SubHeading = styled.div`
    font-size: 12px;
    color: rgb(82, 86, 89);
    ${font.medium};
    line-height: 14px;
    margin-top: 7px;
`
