import { font } from 'shared/utils/styles'
import { Button } from 'shared/components'
import styled, { keyframes } from 'styled-components'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

export const ChangePasswordCont = styled.div`
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    width: 100%;
    border-radius: 8px;
    border: 2px solid #eaeaea;
    padding: 45px 52px;
    margin: 30px 0px;
    @media (max-width: 820px) {
        padding: 20px;
    }
    @media (max-width: 480px) {
        padding: 0px;
        border: none;
    }
`

export const Heading = styled.div`
    ${font.bold};
    ${font.size(24)};
    line-height: 28.8px;
    color: #525659;
`

export const Tagline = styled.div`
    ${font.medium};
    ${font.size(13)};
    line-height: 21px;
    color: #8e91a0;
    margin-top: 8px;
`

export const FormCont = styled.div`
    margin-top: 32px;
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 17px 40px;
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
    div {
        margin-top: 0px;
    }
`

export const ActionButton = styled(Button)`
    ${font.medium};
    ${font.size(18)};
    line-height: 21.6px;
    color: #ffffff;
    padding: 21px;
    width: 210.21px;
    height: 53px;
`
