import styled, { keyframes } from 'styled-components'
import Icon from 'shared/components/Icon'
import { color, font, mixin } from 'shared/utils/styles'

const opacityAndScale = keyframes`
    from {
        opacity: 0;
        transform: scale(0.8)
    }
    to {
        opacity: 1;
        transform: scale(1)
    }
`

export const LightboxOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10005;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const LightboxOverlayImage = styled.img`
    max-width: 80%;
    max-height: 80%;
    animation-name: ${opacityAndScale};
    animation-duration: 0.3s;
    animation-fill-mode: both;
`

export const CloseIcon = styled(Icon)`
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 25px;
    color: white;
    transition: all 0.3s;
    ${mixin.clickable}
    padding: 3px 5px 0px 5px;
    border-radius: 4px;
    &:hover {
        transform: scale(1.5);
    }
`

export const ErrorText = styled.p`
    ${font.size(12)}
    ${font.medium};
    margin-top: 10px;
    color: #ef7724;
`
