import { Cont } from './Styles'
import { useWizard } from 'react-use-wizard'
import moment from 'moment'
import useApi from 'shared/hooks/api'
import { ScreenLoader } from 'shared/components'

const Step4 = ({ data, es = {} }) => {
    const { nextStep, previousStep, goToStep } = useWizard()
    const CoeEndPoint = `customer/v3/cash-offer-transactions/${localStorage.currentTransactionId}/change-coe`
    const ExtendedStayPoint = `customer/v3/cash-offer-transactions/${localStorage.currentTransactionId}/change-extended-stay`
    const [{ isCreating }, sendCoeData] = useApi.post(CoeEndPoint)
    const [{ isWorking }, sendExtendedData] = useApi.post(ExtendedStayPoint)

    const handleBack = () => {
        es.extendedStayEligible ? (data.extendedStay ? previousStep() : goToStep(1)) : goToStep(0)
    }

    const handleNext = async () => {
        try {
            const res = await sendCoeData({
                changeDate: moment(data.date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
            })
            if (res?.status === 503) {
                return
            }
            if (data.extendedStay) {
                const res1 = await sendExtendedData({
                    operation: 'Add',
                    extendedStayDays: data.extendedStayDays,
                })
                if (res1?.status === 503) {
                    return
                }
            }
            nextStep()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Cont>
            <ScreenLoader sL={isCreating || isWorking} size={100} />
            <h1>Ok, let’s review!</h1>
            <div className="coe-info-cont">
                <div className="coe-info">
                    <h2>Closing Date</h2>
                    <p>
                        You are requesting your closing date be changed
                        {data.currentData === 'undefined' ? (
                            <>
                                {' '}
                                from{' '}
                                <span>
                                    {moment(data.currentData, 'MM/DD/YYYY').format(
                                        'dddd, MMMM DD, YYYY',
                                    )}
                                </span>{' '}
                            </>
                        ) : (
                            ' '
                        )}
                        {/* <span>
                            {moment(data.currentData, 'MM/DD/YYYY').format('dddd, MMMM DD, YYYY')}
                        </span>{' '} */}
                        to{' '}
                        <span>{moment(data.date, 'MM/DD/YYYY').format('dddd, MMMM DD, YYYY')}</span>
                    </p>
                </div>
                {es?.extendedStayEligible && (
                    <div className="coe-info">
                        <h2>Extended Stay</h2>
                        {data.extendedStay ? (
                            <p>
                                You would like to add <span>{data.extendedStayDays}</span>{' '}
                                {data.extendedStayDays > 1 ? 'days' : 'day'} of our free extended
                                stay.
                            </p>
                        ) : (
                            <p>Not Needed.</p>
                        )}
                    </div>
                )}
            </div>
            <div className="btn-cont">
                <button id="fs_sell_cod_dateChange_na_previous4" onClick={handleBack}>
                    Back
                </button>
                <button id="fs_sell_cod_dateChange_na_next4" className="next" onClick={handleNext}>
                    Submit
                </button>
            </div>
        </Cont>
    )
}

export default Step4
