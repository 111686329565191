import { font } from 'shared/utils/styles'
import styled, { css, keyframes } from 'styled-components'
import Button from '../Button'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

export const Wrapper = styled.div`
    background-color: white;
    padding: 18px 30px;
    @media screen and (max-width: 480px) {
        padding: 14px 0px;
        height: 100%;
    }
`

export const Container = styled.div`
    padding: 70px 20px 30px 20px;
    overflow-y: auto;
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @media screen and (max-width: 480px) {
        padding: 80px 30px 30px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
`
export const DContainer = styled.div``

export const Heading = styled.div`
    ${font.black};
    font-size: 22px;
    line-height: 26.4px;
    color: black;
    span {
        color: #1b6775;
    }
`

export const ParagraphContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 7px;
`

export const Paragraph = styled.div`
    ${font.medium};
    font-size: 16px;
    line-height: 20px;
    color: black;
`

export const RadioGroup = styled.div`
    margin-top: 30px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`

export const RadioContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    ${font.medium};
    font-size: 16px;
    line-height: 19.2px;
    color: #525659;
    cursor: pointer;
    ${(props) =>
        props.active &&
        css`
            color: #ec7625;
        `}
`

export const InputContainer = styled.div`
    ${font.medium};
    font-size: 14px;
    line-height: 16.8px;
    color: #525659;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`

export const UnorderList = styled.ul`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const List = styled.li`
    display: flex;
    gap: 10px;
    ${font.medium};
    font-size: 16px;
    line-height: 20px;
    color: #525659;
    &::before {
        content: '•';
        color: #525659;
    }
`

export const ButtonGroup = styled.div`
    margin-top: 50px;
    display: flex;
    gap: 30px;
    @media screen and (max-width: 480px) {
        align-items: center;
        justify-content: center;
    }
`

export const ActionButton = styled(Button)`
    ${font.medium};
    font-size: 18px;
    line-height: 21.6px;
    padding: 14px 16px;
    width: 134px;
    @media screen and (max-width: 480px) {
        width: 50%;
    }
    ${(props) =>
        props.variant === 'empty' &&
        css`
            color: #8e91a0;
            border: 1px solid #8e91a0;
            &:hover {
                background: white !important;
                opacity: 0.75;
            }
        `}
`

export const Error = styled.span`
    color: #e13c3c;
`
