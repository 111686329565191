export const is = {
    match:
        (testFn, message = '') =>
        (value, fieldValues) =>
            !testFn(value, fieldValues) && message,
    string: (text) => (value) =>
        !!value &&
        // eslint-disable-next-line no-useless-escape
        !/^[a-zA-Z]+$/.test(value) &&
        `${text} is invalid.`,
    validPhoneNumber: () => (value) => {
        if (!!value) {
            // Remove all non-numeric characters from the input
            const numericPhone = value.replace(/\D/g, '')

            // Check if the numeric phone number matches US phone number format
            // eslint-disable-next-line no-useless-escape
            const regex = /^1?(\d{3})(\d{3})(\d{4})$/
            if (!regex.test(numericPhone)) {
                return 'Enter a valid phone number'
            }
        }
    },
    required: (text) => (value) => isNilOrEmptyString(value) && text,
    minLength: (min) => (value) =>
        !!value && value.length < min && `Must be at least ${min} characters`,
    maxLength: (max) => (value) =>
        !!value && value.length > max && `Must be at most ${max} characters`,
    notEmptyArray: () => (value) =>
        Array.isArray(value) && value.length === 0 && 'Please add at least one item',
    email: (text) => (value) => !!value && !/.+@.+\..+/.test(value) && text,
    validEmail: (email) => /.+@.+\..+/.test(email),
    validDocument: (fileName) => /\.(doc|docx|pdf)$/i.test(fileName),
    withInfileSize: (fileSize, sizeToCompare) => fileSize <= sizeToCompare * 1024 * 1024,
    url: () => (value) =>
        !!value &&
        // eslint-disable-next-line no-useless-escape
        !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
            value,
        ) &&
        'Must be a valid URL',
    checkboxChecked: (text) => (value) => !value && `${text} must be checked`,
    strongPassword: (text) => (value) => measurePasswordStrength(value).allPassed === false && text,
}

export const isNilOrEmptyString = (value) => value === undefined || value === null || value === ''

export const generateErrors = (fieldValues, fieldValidators) => {
    const errors = {}

    Object.entries(fieldValidators).forEach(([fieldName, validators]) => {
        ;[validators].flat().forEach((validator) => {
            const errorMessage = validator(fieldValues[fieldName], fieldValues)
            if (errorMessage && !errors[fieldName]) {
                errors[fieldName] = errorMessage
            }
        })
    })
    return errors
}

export const measurePasswordStrength = (password) => {
    const checks = {
        length: {
            passed: password.length >= 6,
            message: 'Password must be at least 6 characters long.',
        },
        uppercase: {
            passed: /[A-Z]/.test(password),
            message: 'Password must contain at least one uppercase letter.',
        },
        lowercase: {
            passed: /[a-z]/.test(password),
            message: 'Password must contain at least one lowercase letter.',
        },
        special: {
            passed: /[!@#$%^&*]/.test(password),
            message: 'Password must contain at least one special character.',
        },
        number: {
            passed: /[0-9]/.test(password),
            message: 'Password must contain at least one number.',
        },
    }

    const allPassed = Object.values(checks).every((check) => check.passed)

    const strength = Object.values(checks).every((check) => check.passed)
        ? 'Strong'
        : Object.values(checks).some((check) => check.passed)
        ? 'Medium'
        : 'Weak'

    const color = () => {
        if (strength === 'Weak') {
            return 'red'
        }
        if (strength === 'Medium') {
            return '#EF7724'
        }
        return '#62C467'
    }

    return { checks, strength, allPassed, color: color() }
}
