import { FormElement, FormFieldCol, FormFieldRow, Step } from '../Styles'
import { useWizard } from 'react-use-wizard'
import { Form, ScreenLoader } from 'shared/components'
import { enforceFormat, formatToPhone } from 'shared/utils/formatter'
import useApi from 'shared/hooks/api'
import toast from 'shared/utils/toast'
import { useState } from 'react'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

const Step2 = ({ close }) => {
    const { nextStep } = useWizard()
    const user = useSelector((state) => state.user)
    const FormsEndPoint = `Customer/v3/forms/`
    const [{ isCreating }, sendForm] = useApi.post(FormsEndPoint)
    const [preferred, setPreferred] = useState('')
    const [contact, setContact] = useState(user?.phoneNumber)

    const handleSubmit = async (element) => {
        await sendForm({
            formType: 'CalculatorRequest',
            items: [
                {
                    name: 'firstName',
                    value: element.firstName,
                },
                {
                    name: 'lastName',
                    value: element.lastName,
                },
                {
                    name: 'email',
                    value: element.email,
                },
                {
                    name: 'phone',
                    value: element.phoneNumber,
                },
                {
                    name: 'preferredContactMethod',
                    value: preferred,
                },
                {
                    name: 'source',
                    value: 'Connect.Web',
                },
            ],
        })
        nextStep()
    }

    const handleOnChnage = (e) => {
        setPreferred(e.target.value)
    }

    return (
        <Step>
            <ScreenLoader sL={isCreating} full={false} size={100} />
            <h2 className="left">Hi {user?.firstName},</h2>
            <p>
                We’re happy to connect you with additional information about Offerpad Home Loans.
                Can you please confirm the info below?
            </p>
            <Form
                enableReinitialize
                initialValues={{
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    email: user?.email,
                    phoneNumber: user?.phoneNumber,
                    preferredText: false,
                    preferredEmail: false,
                    preferredCall: false,
                }}
                validations={{
                    email: [
                        Form.is.required('Enter a valid email '),
                        Form.is.email('Enter a valid email '),
                    ],
                    firstName: Form.is.required('Enter your first name'),
                    lastName: Form.is.required('Enter your last name'),
                    phoneNumber: [
                        Form.is.required('Enter phone number'),
                        Form.is.validPhoneNumber(),
                    ],
                }}
                onSubmit={(values, form) => {
                    if (preferred === '') {
                        toast.error('Please select a preffered method')
                        return
                    }
                    handleSubmit(values)
                }}
            >
                <FormElement>
                    <FormFieldRow>
                        <FormFieldCol>
                            <Form.Field.Input
                                name="firstName"
                                type="text"
                                placeholder="First Name"
                                size="lg"
                                iconType="name"
                            />
                        </FormFieldCol>
                        <FormFieldCol>
                            <Form.Field.Input
                                name="lastName"
                                type="text"
                                placeholder="Last Name"
                                size="lg"
                                iconType="name"
                            />
                        </FormFieldCol>
                    </FormFieldRow>
                    <FormFieldRow>
                        <FormFieldCol>
                            <Form.Field.Input
                                name="email"
                                type="email"
                                placeholder="Email"
                                size="lg"
                            />
                        </FormFieldCol>
                        <FormFieldCol>
                            <Form.Field.Input
                                name="phoneNumber"
                                type="text"
                                placeholder="Phone Number"
                                size="lg"
                                iconType="phone"
                                onKeyDown={enforceFormat}
                                onKeyUp={formatToPhone}
                                onChangeEvent={setContact}
                            />
                        </FormFieldCol>
                    </FormFieldRow>
                    <h2 className="method">Preferred Contact Method:</h2>
                    <FormFieldRow noSpaceBetween>
                        <FormFieldCol noSpaceBetween>
                            <Form.Field.Checkbox
                                name="preferredText"
                                checkboxLabel="Text"
                                labelSize="14"
                                size="lg"
                                labelColor="black"
                                value="Text"
                                checked={preferred === 'Text'}
                                onChangeEvent={handleOnChnage}
                            />
                        </FormFieldCol>
                        <FormFieldCol noSpaceBetween>
                            <Form.Field.Checkbox
                                name="preferredEmail"
                                checkboxLabel="Email"
                                labelSize="14"
                                size="lg"
                                labelColor="black"
                                value="Email"
                                checked={preferred === 'Email'}
                                onChangeEvent={handleOnChnage}
                            />
                        </FormFieldCol>
                        <FormFieldCol noSpaceBetween>
                            <Form.Field.Checkbox
                                name="preferredCall"
                                checkboxLabel="Call"
                                labelSize="14"
                                size="lg"
                                labelColor="black"
                                value="Call"
                                checked={preferred === 'Call'}
                                onChangeEvent={handleOnChnage}
                            />
                        </FormFieldCol>
                    </FormFieldRow>

                    <div className="btn-cont align-left">
                        <button
                            className="next no-extra stick-bottom "
                            type="submit"
                            disabled={isCreating || isEmpty(preferred) || isEmpty(contact)}
                            id="fs_loan_dashboard_modalSubmit"
                        >
                            Submit
                        </button>
                    </div>
                </FormElement>
            </Form>
        </Step>
    )
}

export default Step2
