import Cookies from 'js-cookie'
import { getDomainWithoutSubdomain } from './formatter'
import { isNilOrEmptyString } from './validation'
import { decaodeJWT } from './jwt'

export const getStoredAuthToken = () => {
    var authToken = localStorage.getItem('authToken')
    if (isNilOrEmptyString(authToken)) {
        authToken = Cookies.get('authToken')
        if (!isNilOrEmptyString(authToken)) {
            const decodedToken = decaodeJWT(authToken)
            if (decodedToken?.emailConfirmed?.toLowerCase() === 'true') {
                storeAuthToken(authToken)
            }
        }
    }
    return authToken
}

export const storeAuthToken = (token) => (
    localStorage.setItem('authToken', token),
    Cookies.set('authToken', token, { domain: getDomainWithoutSubdomain(window.location.href) })
)

export const removeStoredAuthToken = () => (
    localStorage.removeItem('authToken'),
    Cookies.remove('authToken', { domain: getDomainWithoutSubdomain(window.location.href) })
)

export const getStoredTokenExpiryTime = () => localStorage.getItem('expiry')

export const storeTokenExpiryTime = (time) => localStorage.setItem('expiry', time)

export const removeStoredTokenExpiryTime = () => localStorage.removeItem('expiry')
