const useMobileWebView = () => {
    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get('token')

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        Authorization: `Bearer ${token}`,
    }

    const getPrams = (pram) => {
        const queryParameters = new URLSearchParams(window.location.search)
        return queryParameters.get(pram)
    }

    const deletePrams = (pram) => {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        params.delete(pram)
        const newUrl =
            params?.size === 0
                ? `${url.origin}${url.pathname}`
                : `${url.origin}${url.pathname}?${params.toString()}`
        window.history.pushState({ path: newUrl }, '', newUrl)
    }

    return {
        headers,
        getPrams,
        deletePrams,
    }
}

export default useMobileWebView
