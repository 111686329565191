import styled from 'styled-components'

export const Nav = styled.nav`
    width: 100%;
    height: 100px;
    background-color: white;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
    z-index: 1000;
    position: sticky;
    top: 0px;
    @media screen and (max-width: 850px) {
        height: auto;
    }
`

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 31px 0px;
    @media screen and (min-width: 1423px) {
        width: 100%;
        max-width: 1347px;
        margin: 0 auto;
    }
    @media screen and (max-width: 1422px) {
        padding: 31px 20px;
    }
    @media screen and (max-width: 850px) {
        padding: 20px;
        height: 140px;
        align-items: flex-start;
    }
`

export const StyledLogo = styled.img`
    height: 36px;
    cursor: pointer;
    @media (max-width: 480px) {
        height: 28px;
        margin-top: 8px;
    }
`

export const RightContainer = styled.div`
    display: flex;
    gap: 18px;
    align-items: center;
    @media screen and (max-width: 480px) {
        display: none;
    }
`
