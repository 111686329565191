import { capitalizeFirstLetterEveryWord } from 'shared/utils/formatter'
import {
    ActionButton,
    ButtonGroup,
    Container,
    DContainer,
    Heading,
    List,
    Paragraph,
    ParagraphContainer,
    UnorderList,
} from '../styles'

const Step3 = ({ state, close, todo }) => {
    return (
        <Container>
            <DContainer>
                <Heading>Success!</Heading>
                <ParagraphContainer>
                    <Paragraph>
                        You have successfully updated the email address for{' '}
                        <b>
                            {capitalizeFirstLetterEveryWord(
                                todo?.metadata?.sellerName?.toLowerCase(),
                            )}
                        </b>{' '}
                        to: <b>{state.email}</b>
                    </Paragraph>
                </ParagraphContainer>
                <UnorderList>
                    <List>We will send a portal invite to {state.email}.</List>
                    <List>
                        Any future addendums will be signed in this portal by each individual user.
                    </List>
                </UnorderList>
            </DContainer>
            <ButtonGroup>
                <ActionButton variant="primary" size="lg" onClick={close}>
                    Close
                </ActionButton>
            </ButtonGroup>
        </Container>
    )
}

export default Step3
