import Dashboard from './Dashboard'
import { Route, Routes } from 'react-router-dom'
import { NotFound, PageContainer } from 'shared/components'

const Buy = () => {
    return (
        <PageContainer>
            <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="dashboard" element={<Dashboard />} />
            </Routes>
        </PageContainer>
    )
}

export default Buy
