import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'

import { CanvasBody, ClickableOverlay, CloseIcon, ScrollOverlay } from './Styles'

const $root = document.getElementById('root')

const propTypes = {
    backgroundColor: PropTypes.string,
    size: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    variant: PropTypes.oneOf(['left', 'top', 'right', 'bottom']),
    showOnLoad: PropTypes.bool,
    isOpen: PropTypes.bool,
    renderLink: PropTypes.func,
    renderContent: PropTypes.func.isRequired,
    onCloseEvent: PropTypes.func,
    onOpenEvent: PropTypes.func,
    clickOutside: PropTypes.bool,
    withCloseIcon: PropTypes.bool,
}

const defaultProps = {
    backgroundColor: 'white',
    size: 600,
    variant: 'left',
    showOnLoad: false,
    isOpen: undefined,
    renderLink: () => {},
    renderContent: () => {},
    onCloseEvent: () => {},
    onOpenEvent: () => {},
    clickOutside: true,
    withCloseIcon: true,
}

const closeAnimations = {
    left: [{ left: '0' }, { left: '-1000px' }],
    top: [{ top: '0' }, { top: '-1000px' }],
    right: [{ right: '0' }, { right: '-1000px' }],
    bottom: [{ bottom: '0' }, { bottom: '-1000px' }],
}

const Offcanvas = ({
    backgroundColor,
    size,
    variant,
    showOnLoad,
    renderLink,
    renderContent,
    isOpen: propsIsOpen,
    onCloseEvent,
    onOpenEvent,
    clickOutside,
    withCloseIcon,
}) => {
    const [stateIsOpen, setStateOpen] = useState(false)
    const isControlled = typeof propsIsOpen === 'boolean'
    const isOpen = isControlled ? propsIsOpen : stateIsOpen

    const $canvasRef = useRef(null)
    const $clickableRef = useRef(null)

    const handleResize = useCallback(() => {
        if ($canvasRef.current) {
            const canvasWidth = $canvasRef.current.offsetWidth
            const innerWidth = window.innerWidth
            const widths = size
            if (innerWidth < canvasWidth) {
                $canvasRef.current.style.width = `100vw`
            } else if (canvasWidth >= widths) {
                $canvasRef.current.style.width = `${widths}px`
            }
        }
    }, [$clickableRef, $canvasRef, size])

    const handleClickOutside = useCallback(
        (e) => {
            if (!$clickableRef.current) return
            const containLink = $clickableRef.current.contains(e.target)
            const containCanavasBody = $canvasRef.current.contains(e.target)
            if (containLink && !containCanavasBody) {
                clickOutside && closeCanvas()
            }
        },
        [$clickableRef, $canvasRef],
    )

    const closeCanvas = () => {
        if (!isControlled && $canvasRef.current) {
            $canvasRef.current.animate(closeAnimations[variant], {
                duration: 350,
                easing: 'ease-in-out',
            })
            setTimeout(() => {
                setStateOpen(false)
                onCloseEvent()
            }, [300])
        }
    }

    const openCanvas = () => {
        setStateOpen(true)
        onOpenEvent()
    }

    useEffect(() => {
        showOnLoad && openCanvas()
    }, [showOnLoad])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        window.addEventListener('mousedown', handleClickOutside)
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <Fragment>
            {!isControlled &&
                renderLink({
                    open: openCanvas,
                    isOpen: stateIsOpen,
                })}
            {isOpen &&
                ReactDOM.createPortal(
                    <ScrollOverlay>
                        <ClickableOverlay ref={$clickableRef}>
                            <CanvasBody
                                size={size}
                                variant={variant}
                                backgroundColor={backgroundColor}
                                ref={$canvasRef}
                            >
                                {withCloseIcon && (
                                    <CloseIcon
                                        variant={variant}
                                        type="close"
                                        onClick={closeCanvas}
                                    />
                                )}
                                {renderContent({ close: closeCanvas, canvasRef: $canvasRef })}
                            </CanvasBody>
                        </ClickableOverlay>
                    </ScrollOverlay>,
                    $root,
                )}
        </Fragment>
    )
}

Offcanvas.propTypes = propTypes
Offcanvas.defaultProps = defaultProps

export default Offcanvas
