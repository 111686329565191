import { createSlice } from '@reduxjs/toolkit'

const myTransactionSlice = createSlice({
    name: 'myTransactions',
    initialState: {
        myTransactions: null,
        loading: false,
    },
    reducers: {
        updateMyTransactions: (state, action) => ({
            ...state,
            myTransactions: action.payload,
        }),
        updateLoading: (state, action) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const { updateMyTransactions, updateLoading } = myTransactionSlice.actions
export default myTransactionSlice.reducer
