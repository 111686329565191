import styled from 'styled-components'
import { borderRadius, color, fontSize, font } from 'shared/utils/styles'
import { Button } from 'shared/components'

export const NextCard = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    flex: 1;
    height: 230px;
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.3);
    padding: 18px 24px;
    ${(props) => props.borderRadius && borderRadius[props.borderRadius]};
    background-color: ${color.backgroundWhite};
`

export const NextHeader = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
`

export const NextHeaderImage = styled.img``

export const NextHeading = styled.span`
    ${fontSize['lg']}
    color: ${color.textBlack};
    ${font.black};
`

export const ActionButton = styled(Button)`
    width: 154px;
    margin-top: 15px;
    font-size: 13px;
`

export const NextScheduleInfo = styled.span`
    color: ${color.trackerCurrentStatusColor};
    ${fontSize['normal']};
    margin-top: 10px;
`

export const NextAnchor = styled.a`
    color: ${color.primary};
    text-decoration: underline;
    margin-top: 15px;
    ${font.size(13)};
`

export const Divider = styled.div`
    border: 1px solid #e6e6e6;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
`

export const BottomInfo = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 30px;
`

export const BottomInfoCont = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
`

export const BottomInfoImage = styled.img``

export const BottomInfoData = styled.span`
    text-decoration: underline;
    color: ${color.trackerCurrentStatusColor};
    ${fontSize['normal']};
    ${font.size(14)};
`
