import styled, { css } from 'styled-components'
import Button from '../Button'
import { font } from 'shared/utils/styles'

export const ActionButton = styled(Button)`
    height: fit-content;
    padding: 10px 17px;
    border-radius: 3px;
    ${font.size(13)};
    ${font.medium};
    margin: 0px 0px 0px auto;
    width: 168px;
    text-overflow: ellipsis;
    @media (max-width: 650px) {
        width: 168px;
        height: 47px;
        margin: 0px;
        text-overflow: ellipsis;
    }
    ${(props) =>
        props.isComplete &&
        props.isComplete === true &&
        css`
            margin: 0 0 0 auto;
            border-radius: 3px;
            border: 1px solid #1b6775;
            background-color: #f6f6f6;
            color: #1b6775;
            padding: 10px 31px;
            ${font.medium};
            font-size: 13px;
            line-height: 15.6px;
            width: 168px;
            text-overflow: ellipsis;
            &:hover {
                background: #f6f6f6 !important;
            }
        `}
`

export const ModalCont = styled.div`
    padding: 50px;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
    overflow-x: hidden;
    @media (max-width: 480px) {
        height: 100%;
        max-height: 100%;
        padding: 25px;
    }
    .heading-container {
        display: flex;
        align-items: center;
        gap: 19px;
        @media (max-width: 480px) {
            margin-top: 15px;

            width: 73vw;
        }
        .heading {
            color: black;
            ${font.size(25)};
            line-height: 33px;
            ${font.black};
            margin-bottom: 10px;
            @media (max-width: 480px) {
                ${font.size(20)};
                line-height: 28px;
            }
            @media (max-width: 380px) {
                ${font.size(15)};
                line-height: 23px;
            }
        }
        img {
            height: 33px;
            margin-top: -15px;
            @media (max-width: 480px) {
                height: 30px;
            }
        }
    }
    .sub-heading {
        color: #525659;
        ${font.size(18)};
        line-height: 25px;
        ${font.bold};
        margin-bottom: 10px;
    }

    hr {
        margin-top: 10px;
    }

    a {
        color: #ef7724;
        text-decoration: underline;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 15px;
        p {
            ${font.size(16)};
            ${font.medium};
            line-height: 25px;
            color: #525659;
            a {
                text-decoration: underline;
                color: #ef7724;
                //color: #209fff;
            }
        }
        ul {
            list-style: initial;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-left: 25px;
            li {
                ${font.size(16)};
                ${font.medium};
                line-height: 25px;
                color: #525659;
            }
        }

        i {
            ${font.size(14)};
            ${font.bold};
            line-height: 20px;
            color: #525659;
        }
    }

    .note {
        color: #000000;
        ${font.size(18)};
        line-height: 21.6px;
        text-align: center;
        ${font.bold};
        margin-top: 48px;
        margin-bottom: 28px;
    }

    .button-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 33px;
        @media (max-width: 480px) {
            gap: 10px;
            display: block;
        }
        button {
            box-sizing: border-box;
            margin: 0;
            margin-top: 20px;
            height: 74px;
            width: auto;
            min-width: 247px;
            ${font.size(20)};
            line-height: 24px;
            padding: 25px 32px;
            .thumbs-up {
                margin-top: -10px;
            }
            @media (max-width: 480px) {
                min-width: 100%;
                width: 100%;
            }
        }
    }
`
