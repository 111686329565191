import styled, { css, keyframes } from 'styled-components'
import { font } from 'shared/utils/styles'
import { Button } from 'shared/components'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

const opacity = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const Cont = styled.div`
    animation: ${opacity} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    padding-bottom: 50px;
    .card-wrap {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    @media (max-width: 900px) {
        flex-direction: column;
    }
    @media (max-width: 480px) {
        padding: 0px;
        padding-bottom: 30px;
        margin-top: 0px;
    }

    .top-section {
        animation: ${slideLeft} ease 0.7s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        height: 254px;
        background-color: #f7fcfe;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        margin-bottom: 66px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        padding: 25px 19px;
        img {
            @media (max-width: 480px) {
            }
        }
    }

    .pending-cont {
        justify-content: flex-start;
        align-items: flex-start;
        padding: 30px;
        height: auto;
        @media (max-width: 480px) {
            padding: 0px;
            background-color: transparent;
            border: 0px;
        }
    }

    .pending-heading {
        ${font.black}
    }

    .pending-cont-item {
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        width: 100%;
        @media (max-width: 1000px) {
            grid-template-columns: 1fr;
        }
        @media (max-width: 480px) {
            margin-top: 10px;
        }
    }

    .top-section > p {
        ${font.black}
        ${font.size(24)};
        line-height: 29px;
        color: #525659;
        @media (max-width: 480px) {
            text-align: center;
        }
    }

    .top-section > span {
        ${font.regular}
        ${font.size(16)};
        line-height: 22px;
        color: #525659;
        @media (max-width: 480px) {
            text-align: center;
        }
    }
    .doc-cont {
        animation: ${slideLeft} ease 0.7s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    .info-para-cont {
        display: flex;
        align-items: center;
        gap: 5px;
        ${font.regular}
        ${font.size(14)};
        line-height: 19px;
        color: #9ba7b5;
        @media (max-width: 480px) {
            display: none;
        }
    }
`

export const Heading = styled.div`
    ${font.medium}
    ${font.size(20)};
    line-height: 24px;
    color: #525659;
    margin-bottom: 27px;
    @media (max-width: 480px) {
        margin-bottom: 0px;
        color: black;
        ${font.bold};
    }
`

export const Table = styled.table`
    border-collapse: separate;
    border-spacing: 0px 24px;
    width: 100%;
    padding: 10px 18px;
    border: 2px solid #eaeaea;
    border-radius: 8px;

    @media (max-width: 480px) {
        border: none;
        padding: 0px;
        border-spacing: 0px 18px;
    }

    thead {
        @media (max-width: 480px) {
            display: none;
        }
    }

    tbody {
        @media (max-width: 480px) {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

    tbody td {
        text-align: left;
        padding: 15px;
        border-top: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        color: #525659;
        ${font.medium}
        ${font.size(16)};
        line-height: 19.2px;
        @media (max-width: 820px) {
            ${font.size(14)};
        }
        @media (max-width: 480px) {
            border: none;
            padding-top: 0px;
        }
    }

    tbody tr {
        @media (max-width: 480px) {
            display: flex;
            flex-direction: column;
            border: 1px solid #d6d6d6;
            border-radius: 6px;
        }
    }

    thead th {
        padding: 0px 15px;
        text-align: left;
    }

    tbody td:first-child {
        background: #ffffff;
        border-top: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        border-left: 1px solid #d6d6d6;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        @media (max-width: 480px) {
            border: none;
            padding-top: 20px;
        }
    }

    tbody td:last-child {
        border-right: 1px solid #d6d6d6;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        @media (max-width: 480px) {
            padding-bottom: 20px;
        }
    }

    thead th {
        ${font.medium}
        ${font.size(14)};
        line-height: 22px;
        color: #78828b;
        position: relative;
        img {
            background: #f3f7f8;
            border-radius: 3px;
            padding: 5px;
            margin-left: 10px;
            margin-top: 0px;
            height: 24px;
            position: absolute;
            cursor: pointer;
            @media (max-width: 820px) {
                margin-left: 5px;
            }
        }
    }

    .file-info {
        display: flex;
        align-items: center;
        gap: 18px;
        @media (max-width: 820px) {
            gap: 10px;
        }
        @media (max-width: 480px) {
            color: #1b6775;
            ${font.bold};
        }
        img {
            @media (max-width: 480px) {
                height: 30px;
            }
        }
    }

    .action-button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 15px;
        @media (max-width: 820px) {
            gap: 8px;
        }
        div {
            background: #f7fcfe;
            border: 1px solid #eaeaea;
            border-radius: 46px;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.3s;
            img {
                height: 20px;
                width: 20px;
            }
        }
        div:hover {
            transform: scale(1.2);
        }

        @media (max-width: 480px) {
            justify-content: flex-start;
        }
    }

    .info {
        @media (max-width: 480px) {
            color: black;
            ${font.bold};
        }
        span {
            display: none;
            @media (max-width: 480px) {
                display: inline-block;
                margin-right: 5px;
                color: #525659;
            }
        }
    }
`

export const DCont = styled.div`
    ${(props) =>
        props.overview
            ? css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  gap: 15px;
                  background-color: white;
                  border-radius: 8px;
                  border: 1px solid #d6d6d6;
                  padding: 16px 24px;
                  transition: all 0.3s;
                  ${(props) =>
                      !!props.mt &&
                      css`
                          margin-top: ${props.mt}px !important;
                      `}
                  @media (max-width: 1000px) {
                      flex-direction: column;
                      justify-content: start;
                      align-items: flex-start;
                  }
                  @media (max-width: 480px) {
                      margin-top: 0 !important;
                      padding: 0px;
                      background-color: transparent;
                      border: none;
                      ${(props) =>
                          !!props.last &&
                          css`
                              border-top: 1px solid #e6e6e6;
                              margin-top: 27px !important;
                              padding-top: 27px;
                              border-radius: 0px;
                          `}
                  }

                  a {
                      margin: 0px 0px 0px auto;
                      @media (max-width: 480px) {
                          margin: 0px;
                      }
                  }
                  @media (max-width: 480px) {
                      background-color: #f0f8fb;
                      padding: 20px 29px;
                      margin-top: 15px;
                      border-radius: 10px;
                      border: 1px solid #dbe5e7;
                  }
              `
            : css`
                  animation: ${slideLeft} ease 0.7s;
                  animation-iteration-count: 1;
                  animation-fill-mode: forwards;
                  flex: 1;
                  display: flex;
                  gap: 20px;
                  background-color: white;
                  padding: 20px;
                  border-radius: 10px;
                  border: 1px solid #d6d6d6;
                  align-items: center;
                  @media (max-width: 480px) {
                      flex-direction: column;
                      border: 1px solid #eaeaea;
                      background-color: rgba(231, 240, 241, 0.5);
                      width: 100%;
                      align-items: start;
                  }
                  a {
                      margin: 0px 0px 0px auto;
                      @media (max-width: 480px) {
                          margin: 0px;
                      }
                  }
                  button {
                      max-width: 168px;
                      margin: 0px 0px 0px auto;
                      @media (max-width: 480px) {
                          margin: 0px;
                      }
                  }
              `}
`

export const InfoCont = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
`

export const InfoHeading = styled.div`
    ${font.black};
    ${font.size(16)};
    color: #525659;
`

export const ActionButton = styled(Button)`
    height: fit-content;
    padding: 10px 17px;
    border-radius: 3px;
    ${font.size(13)};
    ${font.medium};
    margin: 0px 0px 0px auto;
    width: 168px;
    text-overflow: ellipsis;
    @media (max-width: 650px) {
        width: 168px;
        height: 47px;
        margin: 0px;
        text-overflow: ellipsis;
    }
    ${(props) =>
        props.review &&
        props.review === true &&
        css`
            margin: 0 0 0 auto;
            border-radius: 3px;
            border: 1px solid #1b6775;
            background-color: #f6f6f6;
            color: #1b6775;
            padding: 10px 31px;
            ${font.medium};
            font-size: 13px;
            line-height: 15.6px;
            width: 168px;
            text-overflow: ellipsis;
            &:hover {
                background: #f6f6f6 !important;
            }
        `}
`

export const TaskTagline = styled.div`
    ${font.medium};
    ${font.size(15)};
    line-height: 22px;
    color: #525659;
`
