import api from 'shared/utils/api'
import { useDispatch, useSelector } from 'react-redux'
import { updateEvents, updateLoading } from 'features/sell/events'
import moment from 'moment-timezone'
import { default as mj } from 'moment'
import useTeams from 'shared/hooks/sell/teams'
import useSingleTransactions from 'shared/hooks/sell/single-transaction'
import { useNavigate } from 'react-router'
import toast from 'shared/utils/toast'
import { sortByDate } from 'shared/utils/formatter'
import { isEmpty } from 'lodash'
import { useErrorBoundary } from 'react-error-boundary'

const useEvents = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { showBoundary } = useErrorBoundary()

    const st = useSingleTransactions()
    const te = useTeams()
    const { getTeamByRoleidentifier } = te

    const { events: d } = useSelector((state) => state.events)

    const formatText = (data, teams, timeZone) => {
        if (isEmpty(data)) return
        let info = { ...data[0] }
        if (info.details.includes('[Name]')) {
            if (info.eventIdentifier === 'InHomeInspectionEvent') {
                const userInfo = getTeamByRoleidentifier(teams, 'InHomeOfferpadRepresentative')
                if (!isEmpty(Object.keys(userInfo))) {
                    info = {
                        ...info,
                        details: info.details.replaceAll(
                            '[Name]:',
                            `:<br />${userInfo.firstName} ${userInfo.lastName}`,
                        ),
                    }
                } else {
                    info = { ...info, details: info.details.replaceAll('[Name]:', '') }
                }
            }
        }
        if (info.details.includes('[Phone Number]')) {
            if (info.eventIdentifier === 'InHomeInspectionEvent') {
                const userInfo = getTeamByRoleidentifier(teams, 'InHomeOfferpadRepresentative')
                if (!isEmpty(Object.keys(userInfo))) {
                    info = {
                        ...info,
                        details: info.details.replaceAll(
                            '[Phone Number]',
                            `${
                                !isEmpty(userInfo.phoneNumber)
                                    ? `<br /><a href='tel:${userInfo.phoneNumber}'>${userInfo.phoneNumber}</a>`
                                    : ''
                            }`,
                        ),
                    }
                } else {
                    info = { ...info, details: info.details.replaceAll('[Phone Number]', '') }
                }
            }
        }
        if (info.details.includes('[Email]')) {
            if (info.eventIdentifier === 'InHomeInspectionEvent') {
                const userInfo = getTeamByRoleidentifier(teams, 'InHomeOfferpadRepresentative')
                if (!isEmpty(Object.keys(userInfo))) {
                    info = {
                        ...info,
                        details: info.details.replaceAll(
                            '[Email]',
                            `${
                                !isEmpty(userInfo.email)
                                    ? `<br /><a href='mailto:${userInfo.email}'>${userInfo.email}</a>`
                                    : ''
                            }`,
                        ),
                    }
                } else {
                    info = { ...info, details: info.details.replaceAll('[Email]', '') }
                }
            }
        }
        if (info.details.includes('[AgentName]')) {
            if (info.eventIdentifier === 'PresentOfferReviewEvent') {
                const solutionAdvisor = getTeamByRoleidentifier(teams, 'SolutionAdvisor')
                const solutionExpert = getTeamByRoleidentifier(teams, 'SolutionExpert')
                let userInfo = {}
                if (!isEmpty(Object.keys(solutionAdvisor))) {
                    userInfo = solutionAdvisor
                } else if (!isEmpty(Object.keys(solutionExpert))) {
                    userInfo = solutionExpert
                }
                if (!isEmpty(Object.keys(userInfo))) {
                    info = {
                        ...info,
                        details: info.details.replaceAll(
                            '[AgentName]',
                            `${userInfo.firstName} ${userInfo.lastName}`,
                        ),
                    }
                }
            }
        }
        if (info.details.includes('[TitleCompanyName]')) {
            if (info.eventIdentifier === 'AttendSigningAppointmentEvent') {
                const userInfo = getTeamByRoleidentifier(teams, 'TitleContact')
                if (!isEmpty(Object.keys(userInfo))) {
                    info = {
                        ...info,
                        details: info.details.replaceAll(
                            '[TitleCompanyName]',
                            userInfo.companyName,
                        ),
                    }
                }
            }
        }
        if (info.details.includes('[MovingCompanyContactName]')) {
            if (info.eventIdentifier === 'MovingDayEvent') {
                const userInfo = getTeamByRoleidentifier(teams, 'MovingCompanyContact')
                if (!isEmpty(Object.keys(userInfo))) {
                    info = {
                        ...info,
                        details: info.details.replaceAll(
                            '[MovingCompanyContactName]',
                            userInfo.companyName,
                        ),
                    }
                }
            }
        }
        if (info.details.includes('[timezone]')) {
            if (info.eventIdentifier === 'LastDayOfPostPossessionEvent') {
                info = { ...info, details: info.details.replaceAll('[timezone]', timeZone) }
            }
        }
        return info
    }

    const makeEventArray = (events, response, teams, eventsInfo) => {
        var temp = []
        const t = []
        events.forEach((event) => {
            let eventData = eventsInfo.filter(
                (info) => info.eventIdentifier === event.eventIdentifier,
            )
            const eventDate = event.eventDate
                ? moment(event.eventDate).format('MM/DD/YYYY')
                : moment(event.eventDateTime)
                      .tz(response?.property?.timeZoneId || 'America/Phoenix')
                      .format('MM/DD/YYYY')
            const timeZone = moment
                .tz(response?.property?.timeZoneId || 'America/Phoenix')
                .format('z')
            var eventTime = event.eventDate
                ? ''
                : moment(event.eventDateTime)
                      .tz(response?.property?.timeZoneId || 'America/Phoenix')
                      .format('hh:mm A')
            const month = event.eventDate
                ? moment(event.eventDate).format('MMM')
                : moment(event.eventDateTime)
                      .tz(response?.property?.timeZoneId || 'America/Phoenix')
                      .format('MMM')
            const monthFull = event.eventDate
                ? moment(event.eventDate).format('MMMM')
                : moment(event.eventDateTime)
                      .tz(response?.property?.timeZoneId || 'America/Phoenix')
                      .format('MMMM')
            const year = event.eventDate
                ? moment(event.eventDate).format('YYYY')
                : moment(event.eventDateTime)
                      .tz(response?.property?.timeZoneId || 'America/Phoenix')
                      .format('YYYY')
            const date = event.eventDate
                ? moment(event.eventDate).format('DD')
                : moment(event.eventDateTime)
                      .tz(response?.property?.timeZoneId || 'America/Phoenix')
                      .format('DD')
            const dayOfWeekShort = event.eventDate
                ? moment(event.eventDate).format('ddd')
                : moment(event.eventDateTime)
                      .tz(response?.property?.timeZoneId || 'America/Phoenix')
                      .format('ddd')
            const dayOfWeek = event.eventDate
                ? moment(event.eventDate).format('dddd')
                : moment(event.eventDateTime)
                      .tz(response?.property?.timeZoneId || 'America/Phoenix')
                      .format('dddd')
            const title = eventData[0]?.title
            const calenderFormatedDate = event.eventDate
                ? moment(event.eventDate).format('dddd, MMMM DD, YYYY')
                : `${dayOfWeek}, ${monthFull} ${date}, ${year}, ${eventTime}`
            const isInFuture = mj(eventDate).isSameOrAfter(mj().format('MM/DD/YYYY'))
            const cancelEventUrl = event.cancelEventUrl
            const rescheduleEventUrl = event.rescheduleEventUrl
            const eventInfo = formatText(eventData, teams, timeZone)
            const description = eventInfo.details
            const eventIdentifier = event.eventIdentifier
            const relatedTransactionStatus = eventInfo.eventStatus

            !t.includes(eventDate) && t.push(eventDate)

            const s = {
                timeZone,
                title,
                description,
                date,
                month,
                year,
                dayOfWeekShort,
                dayOfWeek,
                eventDate,
                time: eventTime,
                calenderFormatedDate,
                isListedByAgent: response.isListedByAgent,
                transactionId: response.transactionId,
                isInFuture,
                cancelEventUrl,
                rescheduleEventUrl,
                eventIdentifier,
                relatedTransactionStatus,
            }

            temp.push(s)

            if (eventInfo.eventStatus === 'OfferExpired') {
                window.offerExpired = s
            }
        })
        const f = []
        t.forEach((t) => {
            f[t] = []
            temp.forEach((e) => {
                if (
                    response.transactionStatus !== 'RenewalRequested' &&
                    response.transactionStatus !== 'OfferReady' &&
                    response.transactionStatus !== 'PurchaseAgreementSent' &&
                    response.transactionStatus !== 'OfferExpired'
                ) {
                    if (e.relatedTransactionStatus === 'OfferExpired') {
                        return
                    }
                }
                if (e.eventDate === t) {
                    f[t].push(e)
                }
            })
        })
        return f
    }

    const fetchEventsInfo = async (response) => {
        const persona = response.isListedByAgent ? 'Agent' : 'Customer'
        const eventsInfoURL = `customer/v3/cash-offer-transactions?persona=${persona}`
        try {
            const response = await api.get(eventsInfoURL)
            return response
        } catch (error) {
            console.error(error)
        }
    }

    const fetchEvents = async () => {
        const eventsFetchURL = `customer/v3/cash-offer-transactions/${localStorage.currentTransactionId}/events`

        if (localStorage.currentTransactionId === undefined) {
            navigate('/sell/dashboard')
            return
        }

        dispatch(updateLoading(true))
        let events = []
        let response = window.currentTransaction
        let teams = window.currentTeams

        if (isEmpty(response)) {
            response = await st.fetch()
        }

        if (isEmpty(teams)) {
            teams = await te.fetch()
        }

        const eventsInfo = await fetchEventsInfo(response)

        try {
            const res = await api.get(eventsFetchURL)
            events = makeEventArray(res, response, teams, eventsInfo)
            events = Object.keys(events).reduce((acc, key) => {
                const value = events[key]
                if (Array.isArray(value) && value.length === 0) {
                    return acc
                } else {
                    return { ...acc, [key]: value }
                }
            }, {})
            dispatch(updateEvents(events))
            dispatch(updateLoading(false))
            window.currentEvents = events
            return events
        } catch (e) {
            dispatch(updateLoading(false))
            toast.error('Something went wrong.')
            console.error(e)
            showBoundary(e)
        }
    }

    const getEventByRole = (role) => {
        let temp = {}
        Object.keys(d).forEach((e) => {
            d[e].forEach((event) => {
                if (event.relatedTransactionStatus === role) {
                    temp = event
                }
            })
        })
        return temp
    }

    const getOfferExpiredData = () => {
        const offerExpired = useSelector((state) => state.events.offerExpired)
        const transaction = useSelector((state) => state.singleTransaction.transaction)
        if (!isEmpty(offerExpired)) {
            return {
                eventDate: moment(offerExpired.eventDateTime)
                    .tz(transaction?.property?.timeZoneId)
                    .format('MM/DD/YYYY'),
            }
        }
        return undefined
    }

    const getFutureEvents = (limit = 0) => {
        let temp = []
        Object.keys(d).forEach((event) => {
            d[event].forEach((e) => {
                e.isInFuture && temp.push(e)
            })
        })
        const sortedData = sortByDate(temp, 'eventDate', 'desc', 'MM/DD/YYYY')
        return limit === 0
            ? { total: sortedData.length, sortedData }
            : { total: sortedData.slice(0, limit).length, sortedData: sortedData.slice(0, limit) }
    }

    const getEventUsingEventIdentifier = (events, identifier) => {
        if (isEmpty(Object.keys(events))) return
        let temp = {}
        Object.keys(events).forEach((e) => {
            events[e].forEach((event) => {
                if (event.eventIdentifier === identifier) {
                    temp = event
                }
            })
        })
        return temp
    }

    const getFilterEventUsingRole = (events, role) => {
        if (isEmpty(Object.keys(events))) return
        let temp = {}
        Object.keys(events).forEach((e) => {
            events[e].forEach((event) => {
                if (event.relatedTransactionStatus === role) {
                    temp = event
                }
            })
        })
        return temp
    }

    return {
        fetch: fetchEvents,
        getEventByRole,
        getOfferExpiredData,
        getFutureEvents,
        getFilterEventUsingRole,
        getEventUsingEventIdentifier,
    }
}

export default useEvents
