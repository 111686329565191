import { createSlice } from '@reduxjs/toolkit'

const documentsSlice = createSlice({
    name: 'documents',
    initialState: {
        documents: [],
        loading: false,
    },
    reducers: {
        updateDocuments: (state, action) => ({
            ...state,
            documents: action.payload,
        }),
        updateLoading: (state, action) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const { updateDocuments, updateLoading } = documentsSlice.actions
export default documentsSlice.reducer
