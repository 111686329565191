import ArrowDown from './assets/arrow-todo.svg'
import {
    TodoCard,
    TodoHeadCont,
    TodoHeader,
    TodoHeading,
    TodoInfoImage,
    TodoAnchor,
} from './Styles'
import { useSelector } from 'react-redux'
import Default from './default'
import { PendingTodos } from 'shared/components'
import useTodos from 'shared/hooks/sell/todos'
import { Fragment, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import toast from 'shared/utils/toast'

const YourToDo = () => {
    const { fetch } = useTodos()
    const { todos: t, loading } = useSelector((state) => state.todos)
    const [todos, setTodos] = useState(t)
    const agent = window.currentTransaction?.isListedByAgent

    const get = async () => {
        const res = await fetch()
        setTodos(res)
    }

    const handleErrorEvent = (error) => error && toast.error('Something went wrong.')
    const handleOnCloseEvent = () => get()

    useEffect(() => {
        setTodos(t)
    }, [t])

    return (
        <TodoCard borderRadius="lg">
            <TodoHeader>
                <TodoHeadCont>
                    <TodoHeading>Action Items</TodoHeading>
                    <TodoInfoImage src={ArrowDown} alt="" />
                </TodoHeadCont>
                {!loading && !isEmpty(Object.keys(todos)) && !isEmpty(todos.pending) && (
                    <TodoAnchor to="/sell/action-items">
                        View All {todos?.pending?.length > 1 && `(${todos.pending.length})`}
                    </TodoAnchor>
                )}
            </TodoHeader>
            {!loading && !isEmpty(Object.keys(todos)) && !isEmpty(todos.pending) ? (
                todos.pending.slice(0, 2).map((todo, i) => {
                    return (
                        <Fragment key={Date.now() + i}>
                            <br />
                            <PendingTodos
                                todo={todo}
                                handleOnCloseEvent={handleOnCloseEvent}
                                handleErrorEvent={handleErrorEvent}
                                agent={agent}
                                overview
                                onMarkedTodoCompleteEvent={get}
                            />
                        </Fragment>
                    )
                })
            ) : (
                <Default />
            )}
        </TodoCard>
    )
}

export default YourToDo
