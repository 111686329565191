import styled, { css } from 'styled-components'
import { font } from 'shared/utils/styles'
import ArrowDownCOE from 'OnDemandActions/Sell/CodDateChange/shared/assets/arrow-down-coe.svg'

export const Cont = styled.div`
    padding: 70px 50px;
    @media (max-width: 480px) {
        padding: 75px 40px 32px 40px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    h1 {
        ${font.black};
        ${font.size(22)};
        line-height: 26.4px;
        color: #000000;
    }
    p {
        margin-top: 38px;
        ${font.size(17)};
        line-height: 26px;
        ${font.medium};
        color: #525659;
        @media (max-width: 480px) {
            margin-top: 20px;
        }
    }
    .opt-cont {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 36px;
        div {
            display: flex;
            align-items: center;
            input {
                margin-right: 16px;
            }
            label {
                ${font.medium};
                ${font.size(16)};
                line-height: 19.2px;
                color: #525659;
            }
        }

        .select-extended-date {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: -5px;
            margin-left: 30px;
            span {
                ${font.size(14)};
                line-height: 16.8px;
                ${font.medium};
                color: #525659;
            }
            .select-wrapper {
                width: 246px;
                height: 55px;
                margin-top: 18px;
                select {
                    padding: 19px 25px;
                    width: 100%;
                    height: 100%;
                    ${font.size(14)};
                    line-height: 17px;
                    ${font.medium};
                    cursor: pointer;
                    border: 1px solid #afafaf;
                    border-radius: 5px;
                    -webkit-appearance: none;
                    appearance: none;
                    -moz-appearance: none;
                    background-image: url(${ArrowDownCOE});
                    background-repeat: no-repeat;
                    background-position: calc(100% - 25px);
                    background-size: 10px;
                }
            }
        }
    }
    .btn-cont {
        margin-top: 70px;
        display: flex;
        gap: 22px;
        @media (max-width: 480px) {
            margin: 0;
            margin-top: auto;
        }
        button {
            height: 62px;
            padding: 20px 44px;
            width: 129px;
            background: #ffffff;
            border: 1px solid #bdbdbd;
            border-radius: 4px;
            ${font.size(18)};
            ${font.medium};
            line-height: 22px;
            color: #525659;
            cursor: pointer;
            &:disabled {
                background: rgba(236, 118, 37, 0.5);
                cursor: initial;
            }
            @media (max-width: 480px) {
                width: 100%;
            }
        }
        .next {
            width: 180px;
            background: #ec7625;
            color: white;
            @media (max-width: 480px) {
                width: 100%;
            }
        }
    }
`
