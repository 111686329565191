import styled, { css } from 'styled-components'
import { font, mixin, zIndexValues } from 'shared/utils/styles'
import { Icon } from 'shared/components'

export const Container = styled.div`
    z-index: ${zIndexValues.alert};
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0px);
    @media (max-width: 480px) {
        width: 100%;
        padding: 20px 20px 0px 20px;
    }
`

const ToastType = {
    success: css`
        border: 1px solid #2eab7e;
    `,
    info: css`
        border: 1px solid #46899c;
    `,
    warning: css`
        border: 1px solid #f6ca46;
    `,
    danger: css`
        border: 1px solid #ff4a22;
    `,
    notification: css`
        border: 1px solid #1b6775;
        border-radius: 15px;
        padding: 10px;
        gap: 20px;
        grid-template-columns: 77px 1fr;
    `,
}

export const StyledToast = styled.div`
    display: grid;
    grid-template-columns: 52px 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    position: relative;
    margin-bottom: 5px;
    width: 500px;
    max-width: 500px;
    @media (max-width: 480px) {
        width: 100%;
        max-width: 100%;
    }
    border-radius: 4px;
    color: #fff;
    background: #fff;
    ${(props) => ToastType[props.type]};
    transition: all 0.15s;
    ${mixin.clearfix}
    ${mixin.hardwareAccelerate}
    &.offerpad-toast-enter,
    &.offerpad-toast-exit.offerpad-toast-exit-active {
        opacity: 0;
        bottom: -10px;
    }
    &.offerpad-toast-exit,
    &.offerpad-toast-enter.offerpad-toast-enter-active {
        opacity: 1;
        bottom: 0;
    }
`
const tostType = {
    notification: css`
        padding: 0px;
    `,
}

export const TextContainer = styled.div`
    color: #525659;
    padding: 15px;
    flex: 1;
    margin-right: 40px;
    a {
        color: teal;
    }
    ${(props) => tostType[props.type]};
`

const IconType = {
    success: css`
        background-color: #2eab7e;
    `,
    info: css`
        background-color: #46899c;
    `,
    warning: css`
        background-color: #f6ca46;
    `,
    danger: css`
        background-color: #ff4a22;
    `,
    notification: css`
        width: fit-content;
    `,
}

export const IconContainer = styled.div`
    width: 51px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) => IconType[props.type]};
`

export const IconImg = styled.img``

export const CloseIcon = styled(Icon)`
    position: absolute;
    top: 50%;
    right: 14px;
    font-size: 22px;
    cursor: pointer;
    color: black;
    transform: translateY(-50%);
    ${(props) =>
        props.grey &&
        css`
            color: #525659;
        `}
`

export const Title = styled.div`
    padding-right: 22px;
    ${font.size(15)}
    ${font.medium}
`

export const Message = styled.div`
    white-space: pre-wrap;
    ${font.size(14)}
    ${font.medium}
`
