import {
    Group,
    Icons,
    ActionButton,
    Heading,
    TagLine,
    ExtendedGrid,
    Extdivider,
    DescriptionText,
    ExtSeperaor,
    Showinmid,
    ExtendedCont,
    ExtGroupCont,
    DefaultE,
} from 'OnDemandActions/Sell/ExtendedStay/shared/Styles'
import Houseicon from 'OnDemandActions/Sell/ExtendedStay/shared/assets/House_icon.svg'
import PortalLogo from 'OnDemandActions/Sell/ExtendedStay/shared/assets/Portal_logo.svg'
import CupIcon from 'OnDemandActions/Sell/ExtendedStay/shared/assets/Cup_logo.svg'
import Calender from 'OnDemandActions/Sell/ExtendedStay/shared/assets/Calender_logo.svg'
import Info from 'OnDemandActions/Sell/ExtendedStay/shared/assets/info-extended-stay.svg'
import { useWizard } from 'react-use-wizard'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import Phone from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/phone-free-local.svg'
import Email from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/email-free-local.svg'
import useEvents from 'shared/hooks/sell/events'
import useTeams from 'shared/hooks/sell/teams'
import { formatPhoneNumber } from 'shared/utils/formatter'

const Step1 = ({ es = {} }) => {
    const { nextStep } = useWizard()
    const transaction = useSelector((state) => state.singleTransaction.transaction)

    const { getEventByRole } = useEvents()
    const { eventDate } = getEventByRole('ClearedToClose')

    const { getTeamByRole } = useTeams()
    const successManager = getTeamByRole('SuccessManager')
    const agent = window.currentTransaction?.isListedByAgent

    const comparisonRole = [
        'OfferRequested',
        'OfferReady',
        'OfferExpired',
        'RenewalRequested',
        'PurchaseAgreementSent',
    ]

    const canRequest = !comparisonRole.includes(transaction.transactionStatus)

    const timeOver = moment(eventDate, 'MM/DD/YYYY').diff(moment(), 'days') <= 7

    return (
        <ExtendedCont width="888" height="548">
            <Heading>Extended Stay</Heading>
            <br />
            <ExtGroupCont>
                <div className="info">
                    What’s the rush?{' '}
                    <span>
                        {agent === true
                            ? `Your seller can stay in the current home for up to ${es?.extendedStayDayEligibleDays} days after we buy it!`
                            : `Stay in your current home for up to ${es?.extendedStayDayEligibleDays} days after we buy it!`}
                    </span>
                </div>
            </ExtGroupCont>
            <ExtendedGrid>
                {/* <ExtSeperaor>
                    <Icons src={Houseicon} />
                    <Group>
                        <TagLine>Take the money and...relax!</TagLine>
                        <DescriptionText>
                            {agent === true
                                ? `Our Extended Stay program let's your seller align their sale's closing date perfectly with their new home's closing.`
                                : `Sell and stay in your home until you find your next one.`}
                        </DescriptionText>
                    </Group>
                </ExtSeperaor> */}
                <Extdivider>
                    <ExtSeperaor>
                        <Icons src={PortalLogo} />
                        <Group>
                            <TagLine>Stress less</TagLine>
                            <DescriptionText>
                                Combine our Extended Stay with Offerpad’s free local move for a
                                “stress-less’ moving experience!
                            </DescriptionText>
                        </Group>
                    </ExtSeperaor>
                </Extdivider>
                <Extdivider>
                    <ExtSeperaor>
                        <Icons src={CupIcon} />
                        <Group>
                            <TagLine>No need to rush</TagLine>
                            <DescriptionText>
                                {agent === true
                                    ? `Your seller can avoid the typical chaos that comes with having to close and move on the same day.`
                                    : `Avoid the typical chaos that comes with having to close and move on the same day.`}
                            </DescriptionText>
                        </Group>
                    </ExtSeperaor>
                </Extdivider>
                <Extdivider>
                    <ExtSeperaor>
                        <Icons src={Calender} />
                        <Group>
                            <TagLine>Stay up to {es?.extendedStayDayEligibleDays} days</TagLine>
                            <DescriptionText>
                                {agent === true
                                    ? `Your seller can take up to ${es?.extendedStayDayEligibleDays} days to pack and move at no extra charge!`
                                    : `Take up to ${es?.extendedStayDayEligibleDays} days to pack and move at no extra charge!`}
                            </DescriptionText>
                        </Group>
                    </ExtSeperaor>
                </Extdivider>
            </ExtendedGrid>
            <Showinmid>
                <ActionButton
                    width="250px"
                    variant="primary"
                    id="fs_sell_extendedStay_na_request"
                    grayed={`${!canRequest || timeOver ? 'true' : 'false'}`}
                    onClick={() => canRequest && nextStep()}
                    disabled={timeOver}
                >
                    Request Extended Stay
                </ActionButton>
                <br />
                {!canRequest && !timeOver && (
                    <div>
                        <br />
                        <span>
                            <img src={Info} alt="" />
                            Extended stay available once under contract
                        </span>
                        <br />
                    </div>
                )}
            </Showinmid>
            {timeOver && (
                <DefaultE>
                    <div className="info-cont">
                        <span className="not-available-info">
                            <img src={Info} alt="" />
                            It may be too late to make changes to your Extended Stay, please reach
                            out directly to your Success Manager, {successManager?.firstName}{' '}
                            {successManager?.lastName}to confirm.
                        </span>
                        <div className="contacts">
                            {successManager?.phoneNumber && (
                                <div className="c-info">
                                    <img src={Phone} alt="" />
                                    <a
                                        href={`tel:${formatPhoneNumber(
                                            successManager?.phoneNumber,
                                        )}`}
                                    >
                                        {formatPhoneNumber(successManager?.phoneNumber)}
                                    </a>
                                </div>
                            )}
                            {successManager?.email && (
                                <div className="c-info">
                                    <img src={Email} alt="" />
                                    <a href={`mailto:${successManager?.email}`}>
                                        {successManager?.email}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                    <br />
                </DefaultE>
            )}
        </ExtendedCont>
    )
}

export default Step1
