import { createSlice } from '@reduxjs/toolkit'

const eventsSlice = createSlice({
    name: 'events',
    initialState: {
        events: {},
        offerExpired: {},
        loading: false,
    },
    reducers: {
        updateEvents: (state, action) => ({
            ...state,
            events: action.payload,
        }),
        updateOfferExpired: (state, action) => ({
            ...state,
            offerExpired: action.payload,
        }),
        updateLoading: (state, action) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const { updateEvents, updateOfferExpired, updateLoading } = eventsSlice.actions
export default eventsSlice.reducer
