import HelloSign from 'hellosign-embedded'
import axios from 'axios'
import useMobileWebView from 'shared/hooks/mobileWebview'
import { useEffect, useState } from 'react'

const EmbeddedSigningMobile = () => {
    const client = new HelloSign()
    const { headers, getPrams } = useMobileWebView()

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [completed, setComplete] = useState(false)
    const [close, setClosed] = useState(false)

    const openContract = async () => {
        setLoading(true)
        const contractId = getPrams('contractId')
        const signatureId = getPrams('signatureId')
        const token = getPrams('token')

        if (contractId === null || signatureId === null || token === null) {
            setError({
                message: 'Invalid parameters',
                contractId,
                signatureId,
                token: token !== null ? true : false,
            })
            setLoading(false)
            return
        } else {
            setError(null)
        }

        const URL = `${process.env.API_URL}customer/v3/contracts/${getPrams(
            'contractId',
        )}/embedded-sign-info/${getPrams('signatureId')}`

        axios({
            url: URL,
            method: 'get',
            headers,
        })
            .then((res) => {
                if (res.data === 'CustomerPortalService') {
                    setError({
                        message: 'Invalid URL',
                    })
                    setLoading(false)
                    return
                } else {
                    setError(null)
                }
                setLoading(false)
                client.open(res.data.embeddedSigningUrl, {
                    clientId: res.data.clientId,
                    skipDomainVerification: true,
                })
            })
            .catch((e) => {
                console.error(e.message)
                setError({
                    message: e.message,
                })
                setLoading(false)
            })
    }

    client.on('finish', () => {
        setLoading(false)
        setComplete(true)
    })

    client.on('close', () => {
        setLoading(false)
        setClosed(true)
    })

    client.on('open', () => {
        setClosed(false)
    })

    useEffect(() => {
        openContract()
    }, [])

    if (loading) {
        return (
            <span
                style={{
                    padding: '20px 30px',
                    margin: '20px 30px',
                }}
            >
                Loading....
            </span>
        )
    }

    if (error !== null && !loading) {
        return (
            <pre
                style={{
                    backgroundColor: '#1a1d21',
                    padding: '20px 30px',
                    margin: '20px 30px',
                    color: 'white',
                    borderRadius: '10px',
                    overflowX: 'auto',
                }}
            >
                {JSON.stringify(error, null, 2)}
            </pre>
        )
    }

    if (completed && !loading) {
        return (
            <span
                style={{
                    padding: '20px 30px',
                    margin: '20px 30px',
                }}
            >
                Completed. You can now close the window.
            </span>
        )
    }

    if (close && !loading) {
        return (
            <span
                style={{
                    padding: '20px 30px',
                    margin: '20px 30px',
                }}
            >
                You choose to close the document. You can exit and close the window.
            </span>
        )
    }

    return null
}

export default EmbeddedSigningMobile
