import styled, { css } from 'styled-components'
import { font } from 'shared/utils/styles'

export const Cont = styled.div`
    position: relative;
    display: flex;
    @media (max-width: 760px) {
        flex-direction: column;
        height: 100%;
    }
    .first {
        background-color: #fcf1e9;
        display: flex;
        flex-direction: column;
        width: 368px;
        max-width: 100%;
        padding: 45px;
        position: relative;
        @media (max-width: 820px) {
            width: 275px;
            padding: 45px 20px;
        }
        @media (max-width: 760px) {
            width: 100%;
            background-color: white;
            padding: 75px 40px 32px 40px;
        }
        h1 {
            ${font.black};
            ${font.size(29)};
            line-height: 34.8px;
            @media (max-width: 820px) {
                ${font.size(22)};
                line-height: 26.4px;
            }
        }
        img {
            position: absolute;
            left: 0px;
            bottom: 0px;
            @media (max-width: 820px) {
                width: 100%;
            }
            @media (max-width: 760px) {
                display: none;
            }
        }
        button {
            ${font.medium};
            ${font.size(12)};
            line-height: 14.4px;
            padding: 10px 15px;
            background-color: #fcf1e9;
            border: 2px solid #ec7625;
            border-radius: 32px;
            margin-top: 26px;
            @media (max-width: 820px) {
                padding: 10px;
            }
            @media (max-width: 760px) {
                ${font.size(15)};
                line-height: 18px;
            }
        }
    }
    .second {
        flex: 1;
        padding: 66px 50px 25px 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 820px) {
            padding: 40px 20px 25px 20px;
        }
        @media (max-width: 760px) {
            padding: 0px 40px 40px 40px;
        }
        .calendar-cont {
            @media (max-width: 760px) {
                border-top: 1px solid #e6e6e6;
                border-bottom: 1px solid #e6e6e6;
                padding: 32px 0px 32px 0px;
            }
            .coe-calendar {
                border-spacing: 0px;
                margin-top: 30px;
                thead {
                    tr {
                        th {
                            ${font.medium};
                            ${font.size(16)};
                            line-height: 19.2px;
                            color: #a7a7a7;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            ${font.medium};
                            ${font.size(16)};
                            line-height: 19.36px;
                            color: #3e454b;
                        }
                    }
                }
            }
        }
        span {
            ${font.size(12)};
            ${font.medium};
            line-height: 14.4px;
            color: white;
            background-color: #1b6775;
            padding: 10px 15px;
            border-radius: 32px;
            width: fit-content;
            margin: 0 auto;
            @media (max-width: 760px) {
                margin-top: 29px;
            }
        }
        button {
            ${font.size(18)};
            line-height: 21.6px;
            ${font.bold};
            color: white;
            background: rgba(236, 118, 37, 1);
            border-radius: 4px;
            cursor: pointer;
            height: 62px;
            width: 100%;
            padding: 20px 17px;
            &:disabled {
                background: rgba(236, 118, 37, 0.5);
                cursor: initial;
            }
            @media (max-width: 760px) {
                margin-top: 26px;
            }
        }
    }
`

export const Img = styled.img`
    ${(props) =>
        props.mask &&
        css`
            margin-left: -5px;
            @media (max-width: 760px) {
                display: none;
            }
        `}
`
