import { createSlice } from '@reduxjs/toolkit'

const coeSlice = createSlice({
    name: 'disbaledDates',
    initialState: {
        coe: {},
        loading: false,
    },
    reducers: {
        updateCoe: (state, action) => ({
            ...state,
            coe: action.payload,
        }),
        updateLoading: (state, action) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const { updateCoe, updateLoading } = coeSlice.actions
export default coeSlice.reducer
