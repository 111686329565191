import { Wizard } from 'react-use-wizard'
import { Wrapper } from 'OnDemandActions/Sell/CodDateChange/shared/Styles'
import Step1 from 'OnDemandActions/Sell/CodDateChange/NoAddendum/Steps/Step1'
import Step2 from 'OnDemandActions/Sell/CodDateChange/NoAddendum/Steps/Step2'
import Step3 from 'OnDemandActions/Sell/CodDateChange/NoAddendum/Steps/Step3'
import Step4 from 'OnDemandActions/Sell/CodDateChange/NoAddendum/Steps/Step4'
import Step5 from 'OnDemandActions/Sell/CodDateChange/NoAddendum/Steps/Step5'
import { useState } from 'react'

const NoAddendum = ({ close = () => {}, extendedStay = {}, coe = {} }) => {
    const [data, setData] = useState({
        extendedStay: '',
        extendedStayDays: '',
    })
    return (
        <Wizard wrapper={<Wrapper />}>
            <Step1 data={data} setData={setData} coe={coe} es={extendedStay} />
            <Step2 data={data} setData={setData} coe={coe} es={extendedStay} />
            <Step3 data={data} setData={setData} coe={coe} es={extendedStay} />
            <Step4 data={data} setData={setData} coe={coe} es={extendedStay} />
            <Step5 data={data} setData={setData} coe={coe} close={close} es={extendedStay} />
        </Wizard>
    )
}

export default NoAddendum
