import styled, { css } from 'styled-components'
import { font } from 'shared/utils/styles'

export const Cont = styled.div`
    padding: 70px 50px;
    position: relative;
    @media (max-width: 480px) {
        padding: 75px 40px 32px 40px;
        display: flex;
        flex-direction: column;
    }
    .heading-container {
        display: flex;
        align-items: center;
        gap: 32px;
        position: relative;
        ${(props) =>
            !!props.isTrue &&
            props.isTrue &&
            css`
                justify-content: space-between;
                align-items: center;
            `}
        @media (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
        }
        h1 {
            ${font.black};
            ${font.size(25)};
            line-height: 30px;
            color: #262833;
        }
        img {
            width: 100%;
            max-width: 559px;
            @media (max-width: 820px) {
                display: none;
            }
        }
    }
    .movingcompany-name {
        margin-top: 20px;
        ${font.size(14)};
        ${font.regular};
        line-height: 16.8px;
        color: #525658;
        ${(props) =>
            !!props.isTrue &&
            props.isTrue &&
            css`
                position: static;
            `}
    }
    .requested-info {
        display: flex;
        align-items: center;
        gap: 10px;
        ${font.size(14)};
        ${font.regular};
        line-height: 16.8px;
        color: #525658;
        margin-top: 11px;
        margin-bottom: -10px;
    }
    .feature-container {
        margin-top: 30px;
        display: grid;
        grid-template-columns: auto auto auto;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        @media (max-width: 820px) {
            gap: 20px;
        }

        @media (max-width: 480px) {
            grid-template-columns: auto;
        }
        div {
            display: flex;
            align-items: center;
            gap: 14px;
            h2 {
                ${font.size(15)};
                line-height: 18px;
                ${font.black};
                color: black;
            }
            img {
                @media (max-width: 480px) {
                    width: 40px;
                }
            }
            .pin {
                @media (max-width: 480px) {
                    width: 30px;
                    margin-right: 10px;
                }
            }
        }
    }
    hr {
        border: 1px solid #e6e6e6;
        margin-top: 15px;
        margin-bottom: 29px;
    }
    .terms-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .teams-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 480px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
            }
            h2 {
                ${font.size(18)};
                ${font.black};
                line-height: 21.6px;
                color: #525659;
            }
            a {
                ${font.size(13)};
                line-height: 15.6px;
                ${font.regular};
                color: #ec7625;
                text-decoration: underline;
            }
        }

        .free-local-info {
            display: flex;
            align-items: flex-start;
            gap: 6px;
            img {
                margin-top: 2px;
            }
            p {
                ${font.size(17)}
                ${font.medium};
                line-height: 26px;
                color: #525659;
            }
        }
    }

    .btn-cont {
        margin-top: 39px;
        display: flex;
        gap: 22px;
        justify-content: center;
        @media (max-width: 480px) {
            padding-bottom: 30px;
        }
        button {
            height: 62px;
            padding: 20px 44px;
            max-width: 300px;
            width: 100%;
            background: #ffffff;
            border: 1px solid #bdbdbd;
            border-radius: 4px;
            ${font.size(18)};
            ${font.medium};
            line-height: 22px;
            background-color: #ec7625;
            color: white;
            cursor: pointer;
            &:disabled {
                background: rgba(236, 118, 37, 0.5);
                cursor: not-allowed;
            }
            @media (max-width: 480px) {
                width: 100%;
                height: auto;
            }
        }
        .grayed {
            background-color: #afb3bf !important;
            pointer-events: none;
            cursor: not-allowed;
            &:hover {
                background-color: #afb3bf !important;
            }
        }
    }

    ${(props) =>
        !!props.moreDetails &&
        css`
            h2 {
                ${font.size(25)};
                ${font.black};
                line-height: 30px;
                color: #262833;
                text-align: center;
            }
            .free-local-info {
                display: flex;
                align-items: flex-start;
                gap: 6px;
                margin-top: 30px;
                p {
                    ${font.size(16)}
                    ${font.medium};
                    line-height: 26px;
                    color: #525659;
                }
            }
        `}

    .not-available {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        img {
            width: 24px;
        }
        ${font.size(14)};
        line-height: 16.8px;
        ${font.bold};
        color: #525658;
        @media (max-width: 480px) {
            line-height: 20px;
            margin-top: -20px;
        }
    }
`

export const DefaultE = styled.div`
    padding: 50px 50px 0px 50px;
    @media (max-width: 480px) {
        padding: 0px;
    }
    h2 {
        text-align: center;
        ${font.black};
        @media (max-width: 480px) {
            margin-top: 150px;
        }
    }
    p {
        margin-top: 18px;
        text-align: center;
    }
    .info {
        background: #f7fcfe;
        border: 1px solid #dbe5e7;
        border-radius: 10px;
        padding: 18px 56px;
        @media (max-width: 480px) {
            padding: 0px;
            margin: 0px;
        }
        h3 {
            text-align: center;
            ${font.size(16)}
            ${font.bold};
            line-height: 19.2px;
            @media (max-width: 480px) {
                text-align: start;
            }
        }
        .contacts {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 19px;
            @media (max-width: 480px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 21px;
                padding: 0px 20px 20px 20px;
                justify-content: center;
                align-items: center;
            }
            .c-info {
                display: flex;
                align-items: center;
                gap: 5px;
                a {
                    text-decoration-line: underline;
                    color: #ec7625;
                    ${font.size(14)}
                    ${font.medium};
                    line-height: 16.8px;
                }
            }
        }
        .not-available {
            align-items: flex-start;
            text-align: center;
            gap: 6px;
            @media (max-width: 480px) {
                margin: 0px;
                padding: 20px 20px 0px 20px;
                text-align: left;
            }
            img {
                width: 24px;
                margin-top: 0px;
                @media (max-width: 480px) {
                    margin-top: 3px;
                }
            }
            ${font.size(14)};
            line-height: 16.8px;
            ${font.bold};
            color: #525658;
        }
    }
`
