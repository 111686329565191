import styled, { css } from 'styled-components'
import { font, mixin, color, breakPoints } from 'shared/utils/styles'
import ArrowChat from 'MessageCenter/assets/arrow-down-messageCenter.svg'

export const Cont = styled.div`
    position: relative;
    ${(props) =>
        !!props.isDashboard && props.isDashboard
            ? css`
                  padding: 0 !important;
              `
            : css`
                  padding: 30px 0px;
                  @media screen and (min-width: 1423px) {
                      width: 100%;
                      max-width: 1347px;
                      margin: 0 auto;
                      padding-top: 52px;
                      padding-bottom: 72px;
                  }
                  @media screen and (max-width: 1422px) {
                      padding: 30px;
                  }
                  @media (max-width: 480px) {
                      padding: 30px 20px !important;
                  }
              `}
`

export const ChatCont = styled.div`
    position: relative;
    width: 100%;
    border: 2px solid #eaeaea;
    border-radius: 8px;
    padding-top: 45px;
    padding-left: 47px;
    @media (max-width: 480px) {
        border: none;
        padding: 0px;
    }
    .button {
        margin-left: 0;
        padding: 20px 40px;
        display: flex;
        margin-bottom: 44px;
    }
    .btn-cont {
        display: flex;
        align-items: center;
        gap: 15px;
    }
`

export const HeaderCont = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 9px;
`

export const Header = styled.p`
    ${font.black};
    ${font.size(24)};
    color: ${color.trackerCurrentStatusColor};
    line-height: 28.8px;
    display: flex;
    align-items: center;
    gap: 16px;
    position: relative;
    @media (max-width: 480px) {
        color: black;
    }

    span {
        min-width: 143px;
        background: rgba(27, 103, 117, 0.2);
        border-radius: 15px;
        padding: 6px 22px;
        ${font.regular};
        ${font.size(13)};
        color: ${color.textInfo};
        line-height: 15.6px;
        @media (max-width: 480px) {
            position: absolute;
            right: 0px;
            top: -30px;
            border-radius: 0px 0px 8px 8px;
        }
    }
`

export const SubHeader = styled.span`
    ${font.medium};
    ${font.size(13)};
    color: ${color.textInfo};
    line-height: 21px;
`

export const Select = styled.select`
    height: 60px;
    width: 700px;
    color: #898989;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    margin-top: 27px;
    padding: 19px 25px;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    background-image: url(${ArrowChat});
    background-repeat: no-repeat;
    background-position: calc(100% - 25px);
    background-size: 10px;
    ${font.size(16)};
    ${font.medium};
    line-height: 19px;

    option {
        color: #898989;
        display: flex;
        padding: 0px 2px 1px;
    }

    @media (max-width: 820px) {
        width: calc(100% - 25px);
    }
    @media (max-width: 480px) {
        width: 100%;
    }
`
export const TextArea = styled.textarea`
    width: 700px;
    height: 204px;
    resize: none;
    padding: 20px 0px 0px 20px;
    color: #898989;
    border: 1px solid #dddddd;
    border-radius: 8px;
    margin-top: 40px;
    margin-bottom: 35px;
    ${font.size(16)};
    ${font.medium};
    line-height: 19px;

    @media (max-width: 820px) {
        width: calc(100% - 25px);
    }
    @media (max-width: 480px) {
        width: 100%;
    }
`

export const SuccessCard = styled.div`
    background: #ffffff;
    border-radius: 10px;
    z-index: 1000;
    width: 100%;
    height: 100%;
`
