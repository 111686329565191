import { font } from 'shared/utils/styles'
import styled from 'styled-components'

export const MainContainer = styled.div`
    background: #f7fcfe;
    margin: 40px -50px 0px -50px;
    padding: 30px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #1b6775;
`

export const Container = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    .arrow {
        transform: scaleX(-1) rotate(-90deg);
        margin-top: -40px;
    }
    .thumbnail {
        cursor: pointer;
    }
`

export const Text = styled.div`
    text-align: end;
    text-decoration: underline;
    ${font.tag};
    font-size: 35px;
    color: #1b6775;
    line-height: 30px;
    @media screen and (max-width: 480px) {
        font-size: 30px;
    }
`

export const ModalBody = styled.div`
    height: 100%;
    padding: 100px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    iframe {
        z-index: 1000;
    }
`
