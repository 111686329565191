import React, { useState, useEffect, useRef } from 'react'
import pubsub from 'sweet-pubsub'
import { Heading, Icon, Div, ActionButton, ScrollOverlay, StyledAlert } from './Styles'
import parse from 'html-react-parser'

const Alert = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [alertData, setAlertDate] = useState({})

    const $alertRef = useRef(null)

    const handleEvent = ({ type, heading, message, icon, callback = () => {}, ...rest }) => {
        setAlertDate({
            type,
            heading: heading !== '' ? heading : 'Are you sure ?',
            message: message !== '' ? message : 'Do you really wanna delete this?',
            icon,
            ...rest,
        })
        setIsOpen(true)
        pubsub.once('alert-false', () => {
            callback(false)
        })
        pubsub.once('alert-true', () => {
            callback(true)
        })
    }

    const closeAlert = () => {
        if ($alertRef.current) {
            const animation = $alertRef.current.animate(
                [
                    { opacity: 1, top: '50%' },
                    { opacity: 0, top: '48%' },
                ],
                {
                    duration: 200,
                    easing: 'ease-in-out',
                    fill: 'forwards',
                },
            )

            animation.onfinish = () => setIsOpen(false)
        }
    }

    const handleNo = () => {
        pubsub.emit('alert-false')
        closeAlert()
    }

    const handleYes = () => {
        pubsub.emit('alert-true')
        closeAlert()
    }

    const renderActionButton = () => {
        switch (alertData.type) {
            case 'info':
            case 'custom':
                return (
                    <div className={`action-button ${alertData?.btnClass}`}>
                        <ActionButton id="fs_alert_ok" onClick={handleYes}>
                            {alertData?.buttonText ? alertData?.buttonText : `OK`}
                        </ActionButton>
                    </div>
                )
            default:
                return (
                    <div className="action-button">
                        <ActionButton id="fs_alert_no" onClick={handleNo}>
                            No
                        </ActionButton>
                        <ActionButton id="fs_alert_yes" onClick={handleYes} primary={true}>
                            Yes
                        </ActionButton>
                    </div>
                )
        }
    }

    useEffect(() => {
        pubsub.on('alert', handleEvent)
        return () => {
            pubsub.off('alert', handleEvent)
        }
    }, [])

    return (
        isOpen && (
            <ScrollOverlay>
                <StyledAlert className={alertData?.alertClass} ref={$alertRef}>
                    {alertData.icon && <Icon src={alertData.icon} alt="" />}
                    <Heading>{parse(alertData.heading)}</Heading>
                    <Div>{parse(alertData.message)}</Div>
                    {renderActionButton()}
                </StyledAlert>
            </ScrollOverlay>
        )
    )
}

export default Alert
