import { createGlobalStyle } from 'styled-components'

import { color, font, mixin } from 'shared/utils/styles'

export default createGlobalStyle`
  html, body, #root {
    height: 100%;
    width:100%;
    min-height: 100%;
    min-width: 100%;
    overflow-y: auto !important;
    overflow-x: hidden;
    letter-spacing: 0.3px;
  }

  body {
    color: ${color.textDarkest};
    -webkit-tap-highlight-color: transparent;
    line-height: 1.2;
    ${font.size(16)}
    ${font.regular}
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    ${font.regular}
  }

  *, *:after, *:before, input[type="search"] {
    box-sizing: border-box;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  ul, li, ol, dd, h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6, strong {
    ${font.bold}
  }

  button {
    background: none;
    border: none;
  }

  /* Workaround for IE11 focus highlighting for select elements */
  select::-ms-value {
    background: none;
    color: #42413d;
  }

  [role="button"], button, input, select, textarea {
    outline: none;
    &:focus {
      outline: none;
    }
    &:disabled {
      opacity: 1;
    }
  }
  [role="button"], button, input, textarea {
    appearance: none;
  }
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  select::-ms-expand {
    display: none;
  }
  select option {
    color: ${color.textDarkest};
  }

  p {
    line-height: 1.4285;
    a {
      ${mixin.link()}
    }
  }

  textarea {
    line-height: 1.4285;
  }

  body, select {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    touch-action: manipulation;
  }

  ${mixin.placeholderColor(color.textLight)}

  /* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #E3E3E3 #DFE9EB;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 15px;
  border: 2px solid #DDDDDD;
  border-radius: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
  background-clip: padding-box;
}

*::-webkit-scrollbar-track:hover {
  background-color: white;
}

*::-webkit-scrollbar-track:active {
  background-color: white;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border:2px solid white;
  background-color: #B8C0C2;
  width:10px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #B8C0C2;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #B8C0C2;
}

@media (max-width: 480px) {
      *::-webkit-scrollbar{
            display: none;
        }
    }
.calendar {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto;
}

.calendar-header {
  background-color: #f2f2f2;
  padding: 10px;
  text-align: center;
}

.calendar-header h3 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.calendar-body {
  display: flex;
  flex-wrap: wrap;
}

.calendar-weekdays {
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px;
  font-weight: bold;
}

.calendar-day {
  background-color: #fff;
  border: 1px solid #ccc;
  width: calc(100% / 7);
  height: 80px;
  text-align: center;
  position: relative;
}

.calendar-day.empty {
  background-color: #f2f2f2;
}

.calendar-day.selected {
  background-color: #ddd;
}

.calendar-day-number {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 16px;
  font-weight: bold;
}

.calendar-day-events {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.calendar-day-event {
  background-color: #ccc;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 5px;
  font-size: 12px;
}

.pending-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 15px;
  width: 100%;
  span {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: #EF7724;
  }
}

.rpv-open__input-wrapper, [data-testid="properties__menu"], [data-testid="theme__switch-button"], [data-testid="print__button"], [data-testid="open__menu"], [data-testid="print__menu"],[data-testid="theme__switch-menu"] {
  display: none;
}

.allow-overflow {
  overflow: inherit !important;
}

.hide-on-screen {
    height: 0;
    width: 0;
    overflow: hidden;
  }

  
@media print {
  @page {
    size: A4;
    margin: 15mm; 
  }

  body {
    padding: 0;
    margin: 0;
    overflow: hidden !important;
  }

  .hide-on-screen {
    height: auto;
    width: auto;
    overflow: auto;
  }

  .print-area {
    margin-top: 0px;
  }

}

.cashOffer-alert {
  background-color: #1B6775;
  padding: 20px 20px 40px 20px;
  border-radius: 17px;
  h1 {
    font-size: 24px;
    line-height: 24px;
  }
  div {
    font-size: 15px;
  }
  h1, div {
    color: white;
  }
}

.cashOffer-ok-btn button {
  background-color: #1B6775;
  border: 1px solid white;
  color:white;
  height: 50px;
  @media screen and (max-width: 480px) {
    height: 40px;
  }
}

.mb-3 {
  margin-bottom: 3px;
}

`
