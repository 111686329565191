import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    TrackerContainer,
    TrackerHeader,
    TrackerHeaderImage,
    TrackerHeaderHeading,
    TrackerHeaderTagline,
    TrackerBody,
    TActionButton,
    TActionButtonImg,
    ModalCont,
    ModalBody,
} from './Styles'
import TrackerBar from './TrackerBar'
import CloudAndSun from './assets/cloud-and-sun.svg'
import RightArrow from './assets/arrow-right-tracker.svg'
import { Modal } from 'shared/components'
import { default as ModalTracker } from '../Tracker'

const Tracker = ({ modalTracker = false }) => {
    const { tracker } = useSelector((state) => state.tracker)
    const transactionStatus = useSelector((state) => state?.transaction?.transactionStatus)
    const isPendingOrApprovedCancellationOrCancelled = [
        'PendingCancellation',
        'ApprovedCancellation',
        'Cancelled',
    ].includes(transactionStatus)

    const [mobile, setMobile] = useState(false)

    const isMobile = () => setMobile(window.innerWidth <= 820)

    useEffect(() => {
        isMobile()
        window.addEventListener('resize', isMobile)
        return () => window.removeEventListener('resize', isMobile)
    }, [])

    return (
        <TrackerContainer borderRadius="lg" modalTracker={modalTracker}>
            <TrackerHeader modalTracker={modalTracker}>
                <TrackerHeaderHeading modalTracker={modalTracker}>What’s Next</TrackerHeaderHeading>
                <TrackerHeaderTagline>{tracker?.whatsNext}</TrackerHeaderTagline>
                <TrackerHeaderImage src={CloudAndSun} alt="" modalTracker={modalTracker} />
            </TrackerHeader>
            <TrackerBody modalTracker={modalTracker}>
                <TrackerBar
                    modalTracker={modalTracker}
                    tracker={mobile ? tracker?.short : tracker?.full}
                    data={tracker?.full}
                />
            </TrackerBody>

            {!isPendingOrApprovedCancellationOrCancelled && (
                <Modal
                    testid="modal:TransactionTracker"
                    width="875px"
                    withCloseIcon={true}
                    variableWidth={true}
                    backgroundColor="white"
                    renderLink={(modal) => (
                        <TActionButton onClick={modal.open} modalTracker={modalTracker}>
                            <TActionButtonImg src={RightArrow} alt="" />
                        </TActionButton>
                    )}
                    renderContent={() => (
                        <ModalCont>
                            <ModalBody>
                                <ModalTracker modalTracker={true} />
                            </ModalBody>
                        </ModalCont>
                    )}
                />
            )}
        </TrackerContainer>
    )
}

export default Tracker
