import styled, { css } from 'styled-components'
import Spinner from 'shared/components/Spinner'
import { font } from 'shared/utils/styles'

export const Div = styled.div`
    top: 0px;
    left: 0px;
    background-color: ${(props) => props.backgroundColor};
    height: 100%;
    width: ${(props) => props.screenWidth};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    ${font.bold};
    color: #525659;
    ${(props) =>
        !!props.full && props.full
            ? css`
                  position: fixed;
                  z-index: 1010;
              `
            : css`
                  position: absolute;
                  z-index: 1000;
              `}
    ${(props) =>
        !!props.border &&
        css`
            border-radius: ${props.border}px;
        `}
`

export const StyledSpinner = styled(Spinner)``
