import styled, { css, keyframes } from 'styled-components'
import { font, color } from 'shared/utils/styles'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

const opacity = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const OutlineBox = styled.div`
    animation: ${opacity} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    display: grid;
    grid-gap: 60px;
    flex-wrap: wrap;
    background: #e7f1ff;
    width: 100%;
    height: auto;
    left: 48px;
    top: 190px;
    background: #ffffff;
    word-spacing: 0.6px;
    margin-bottom: 50px;
    @media (max-width: 550px) {
        background-color: white;
        padding: 0px;
        border: none;
    }
    @media (max-width: 820px) {
        border: none;
        padding: 0px;
        gap: 40px;
        grid-template-columns: repeat(auto-fill, 100%);
        margin-bottom: 30px;
    }
`

export const Card = styled.div`
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #fff1e8;
    border-radius: 30px;
    padding: 28px;
    position: relative;
    transition: all 0.3s;
    @media (max-width: 480px) {
        border-radius: 8px;
        height: 108px;
        overflow: hidden;
        padding: 17px;
        min-width: 100%;
    }
    ${(props) =>
        !!props.important &&
        css`
            background-color: #e7f1ff;
            @media (max-width: 480px) {
                height: calc(100% + 12px);
            }
        `}

    ${(props) =>
        !!props.length &&
        props.length === 1 &&
        css`
            width: 400px;
            @media (max-width: 480px) {
                width: 100%;
            }
        `}
`

export const ExpandArea = styled.div`
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    background-color: #fff1e8;
    @media (max-width: 480px) {
        display: flex;
    }
    ${(props) =>
        !!props.important &&
        css`
            background-color: #e7f1ff;
            img {
                transform: rotate(-180deg);
            }
        `}
`

export const ExpandAreaImg = styled.img``

export const Header = styled.div`
    position: relative;
    width: 100%;
`

export const HeaderBodyCont = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const Heading = styled.div`
    ${font.bold};
    ${font.size(20)};
    line-height: 24px;
    color: #231f20;
    max-width: 240px;
    width: 100%;
    @media (max-width: 480px) {
        ${font.size(18)};
        line-height: 21.6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export const Tagline = styled.div`
    background-color: white;
    border-radius: 19px;
    padding: 5px 10px;
    width: fit-content;
    // max-width: 200px;
    ${font.medium};
    ${font.size(11)};
    line-height: 22px;
    color: #525659;
    gap: 6px;
    margin-top: 3px;
    ${(props) =>
        !!props.showBadge &&
        css`
            display: flex;
            justify-content: space-between;
            div {
                display: block;
            }
        `}
`

export const Badge = styled.div`
    display: none;
    background-color: ${color.primary};
    color: white;
    ${font.size(10)};
    line-height: 22px;
    padding: 0 9px;
    border-radius: 19px;
    margin-right: -3px;
`

export const HeaderImg = styled.img`
    display: none;
    max-height: 65px;
    max-width: 100px;
    @media (max-width: 480px) {
        width: 70px;
        top: 0px;
    }
    ${(props) =>
        !!props.show &&
        css`
            display: block;
        `}
    ${(props) =>
        !!props.bi &&
        css`
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
        `}
`

export const Divider = styled.hr`
    border: 1px solid #ddd4f1;
    margin: 15px 0px;
`

export const Border = styled.hr`
    border: 1px solid #fae3d4;
    margin: 15px 0px;
`

export const Description = styled.div`
    ${font.medium};
    ${font.size(13)};
    line-height: 19px;
    color: #525659;
    .name {
        ${font.size(16)};
        line-height: 19.2px;
        ${font.bold};
        color: #231f20;
        display: block;
        margin-bottom: 10px;
    }
`

export const Footer = styled.div`
    display: flex;
    gap: 25px 30px;
    margin-top: 32px;
    flex-wrap: wrap;
`

export const FooterDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`

export const FooterImg = styled.img`
    margin-right: 5px;
    width: 14.17px;
    height: 14.17px;
`

export const FooterAnchor = styled.a`
    ${font.medium};
    ${font.size(12)};
    line-height: 14.4px;
    color: #ec7625;
    text-decoration: underline;
    white-space: nowrap;
`
export const DefaultOutlineBox = styled.div`
    animation: ${opacity} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 100px;
    padding: 47px 66px;
    border-radius: 8px;
    border-radius: 30px;
    border: 2px solid #eaeaea;
    height: 490px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 50px;
    @media (max-width: 1000px) {
        flex-direction: column;
        height: auto;
    }
    @media (max-width: 480px) {
        padding: 20px;
        border-radius: 10px;
        background: #f7fcfe;
    }
`
export const HeadingImage = styled.img`
    width: 125px;
`
export const HeaderText = styled.div`
    margin-top: 5px;
    ${font.black};
    ${font.size(26)};
    line-height: 31px;
    color: #525659;
`

export const DescriptionText = styled.div`
    ${font.medium};
    ${font.size(16)};
    line-height: 25px;
    color: #6c6c6c;
`
export const ConnectWithUs = styled.div`
    ${font.black};
    ${font.size(18)};
    line-height: 22px;
    color: #525659;
    margin-top: 48px;
    margin-bottom: 13px;
    @media (max-width: 480px) {
        margin-top: 20px;
    }
`
export const DefaultFooterDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 13px;
    margin-left: 20px;
    @media (max-width: 500px) {
        margin-top: 0px;
        margin-left: 0px;
    }
`
export const DefaultFooterImg = styled.img`
    margin-right: 8px;
    width: 14.17px;
    height: 14.17px;
`
export const YourTeamsPic = styled.img`
    width: auto;
    @media (max-width: 1000px) {
        width: inherit;
    }
    @media (max-width: 480px) {
        display: none;
    }
`
export const LineDivider = styled.hr`
    width: 100%;
    margin-top: 41px;
    margin-bottom: 32px;
    border: 1px solid #dddddd;
    @media (max-width: 480px) {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`
export const DefaultFooterAnchor = styled.a`
    ${font.medium};
    ${font.size(14)};
    line-height: 14.4px;
    color: #797979;
    white-space: nowrap;
`
export const DefaultFooter = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 18px 30px;
    @media (max-width: 500px) {
        grid-template-columns: 1fr;
    }
    @media (max-width: 480px) {
        margin-bottom: 40px;
    }
`
export const MainDiv = styled.div`
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    display: flex;
    flex-direction: column;
    width: 100%;
`
