import SellHomeIcon from 'shared/asstes/sellHomeIcon.svg'
import BuyHomeIcon from 'shared/asstes/buyHomeIcon.svg'
import LoneHomeIcon from 'shared/asstes/loneHomeIcon.svg'

const data = {
    sell: {
        agent: {
            image: SellHomeIcon,
            heading: 'Provide your sellers with more selling options',
            subHeading: `To get started, just request a free cash offer (don't worry; absolutely no obligation) on your seller's home and we'll get back to you in 24 hours.`,
            primaryCta: 'Start here',
            primaryCtaClick: () => window.open(process.env.REQUEST_PROPERTY_URL, '_blank'),
        },
        customer: {
            image: SellHomeIcon,
            heading: 'Looking to sell your home ?',
            subHeading: `Offerpad offers two ways to sell your home! List with our local experts or sell directly for cash. It’s all up to you!`,
            primaryCta: 'Start here',
            primaryCtaClick: () => window.open(process.env.REQUEST_PROPERTY_URL, '_blank'),
            footerHeading: 'Bundle & Save Thousands!',
            footerSubHeading: `Combine the sale of your existing home with the purchase of your new home and save thousands. Add mortgage financing and save even more!`,
            onFooterClick: () => window.open(process.env.BUNDLE_ID_LINK_URL, '_blank'),
        },
    },
    buy: {
        agent: {
            image: BuyHomeIcon,
            heading: 'Would you like to make an offer on an Offerpad owned home?',
            subHeading: `Please visit our Agent Offer Portal to submit or manage your buyer’s offer.`,
            primaryCta: 'Browse Offerpad Homes',
            primaryCtaClick: () => window.open(process.env.AGENT_OFFER_DASHBOARD, '_blank'),
            secondaryCta: 'Agent Offer Dashboard',
            secondaryCtaClick: () => window.open(process.env.AGENT_OFFER_DASHBOARD, '_blank'),
        },
        customer: {
            image: BuyHomeIcon,
            heading: 'In the market for a new home?',
            subHeading: `Offerpad has local expert agents in your market who can help you find your dream home. Plus, with Offerpad, you can <a href=${process.env.BUNDLE_ID_LINK_URL} target="_blank">bundle</a> &amp; save thousands!`,
            primaryCta: 'Browse Homes',
            primaryCtaClick: () => window.open(process.env.BUY_CUSTOMER_BROWSE_HOME, '_blank'),
            secondaryCta: 'Tell me More!',
            footerHeading: 'Are you an Agent?',
            footerSubHeading: `Looking to manage an offer you submitted on an Offerpad home? <a href="${process.env.AGENT_OFFER_DASHBOARD}" target="_blank">Click here >></a>`,
            onFooterClick: () => window.open(process.env.AGENT_OFFER_DASHBOARD, '_blank'),
        },
    },
    loan: {
        agent: {
            image: LoneHomeIcon,
            heading: 'Looking for a mortgage partner for your buyers?',
            subHeading: ` Offerpad Home Loans loves agents and works hard to support all agents - even those who don’t hang their license with Offerpad.`,
            primaryCta: 'Yes, I’m interested!',
        },
        customer: {
            image: LoneHomeIcon,
            heading: 'Looking for financing options?',
            subHeading: `Offerpad Home Loans makes the mortgage process easy. Ready to get started? <a href=${process.env.PRE_QUALIFIED_URL} target="_blank" id="fs_loan_dashboard_preQualified"
            >Get pre-qualified now.</a>`,
            primaryCta: 'I’m interested, tell me more',
            footerHeading: 'Bundle & Save Thousands!',
            footerSubHeading: `Use Offerpad Home Loans + the Offerpad brokerage to help you buy your new home and save thousands. Add the sale of your existing home and save even more!`,
            onFooterClick: () => window.open(process.env.BUNDLE_ID_LINK_URL, '_blank'),
        },
    },
}

export default {
    sell: data.sell,
    buy: data.buy,
    loan: data.loan,
}
