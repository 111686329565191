const messages = {
    UnderContract: {
        agent: `<b>Bravo!</b><div style='margin-top:5px'>You've sealed the deal and your seller is now officially selling their home to Offerpad. Let's celebrate!</div>`,
        customer: `<b>Bravo!</b><div style='margin-top:5px'>You've sealed the deal and you're now officially selling your home to Offerpad! Let's celebrate!</div>`,
    },
    EscrowOpened: {
        agent: `<b>Bravo!</b><div style='margin-top:5px'>You've sealed the deal and your seller is now officially selling their home to Offerpad. Let's celebrate!</div>`,
        customer: `<b>Bravo!</b><div style='margin-top:5px'>You've sealed the deal and you're now officially selling your home to Offerpad! Let's celebrate!</div>`,
    },
    Sold: {
        agent: `<b>Hot diggity dog!</b><div style='margin-top:5px'>Your seller has sold their home to Offerpad. Congrats to everyone!</div>`,
        customer: `<b>Hot diggity dog!</b><div style='margin-top:5px'>You did it! You've sold your home to Offerpad and are now on your way to your next adventure. Congrats!</div>`,
    },
}

export default messages
