import { formatMoney } from 'shared/utils/formatter'
import CashOfferTeamCard from '../CashOfferTeamCard'
import Radio from '../Radio'
import {
    ActionButton,
    Badge,
    CardDetails,
    Container,
    DefaultScreen,
    Details,
    ModalBody,
    NumberBadge,
    OfferCard,
    OfferPrice,
    Option,
    OptionCardContainer,
    OptionContainer,
    OptionMainContainer,
    Options,
    RoundedButton,
    Seprator,
    Span,
} from './Styles'

import RightArrowIcon from 'shared/asstes/rightArrowIcon.svg'
import SUNWITHCLOUD from 'shared/asstes/sun_with_cloud.svg'
import Tooltip from '../Tooltip'
import CashOfferVideoCard from '../CashOfferVideoCard'
import moment from 'moment-timezone'
import Modal from '../Modal'
import ScreenLoader from '../ScreenLoader'

const CashCard = ({ state, mergeState, closeScreen, buttonClick, standalone }) => {
    let options = [
        {
            heading: 'Need a free local move?',
            option1Text: 'Yes, please!',
            option2Text: 'No, thanks!',
            slag: 'localMove',
        },
        {
            heading: `How about our free ${
                state?.response?.isPartnerTier2 ? 5 : 3
            }-day extended stay?`,
            option1Text: 'Yes, please!',
            option2Text: 'No, thanks!',
            slag: 'extendedStay',
        },
        {
            heading: 'How about your closing date?',
            option1Text: 'Pick a day',
            option2Text: "I'm not sure",
            slag: 'calendar',
        },
    ]

    let showSpecificScreen = false

    if (state?.response?.accepted === false && state?.response?.listAccepted === false) {
        showSpecificScreen = true
    }
    if (state?.response?.hasCounteroffer || state?.response?.hasOfferRenewal) {
        showSpecificScreen = false
    }

    const handleToogle = (activeInfoScreen) => {
        if (activeInfoScreen === state.activeInfoScreen) {
            closeScreen()
        } else {
            mergeState({ activeInfoScreen })
        }
    }

    const OptionsCard = ({ count, heading, option1Text, option2Text, slag, state }) => {
        if (state?.hideLocalView) {
            options = options.filter((option) => option.slag !== 'localMove')
        }

        if (state?.hideExtendedStay) {
            options = options.filter((option) => option.slag !== 'extendedStay')
        }

        const handleClick = (key, value) => {
            if (slag !== 'calendar') {
                mergeState({ [key]: value })
            } else {
                if (value) {
                    mergeState({ activeInfoScreen: 'calendar' })
                } else {
                    mergeState({ [key]: value, newClosingDate: state.closingDate })
                }
            }
        }

        return (
            <OptionCardContainer>
                <NumberBadge>{count}</NumberBadge>
                <OptionContainer>
                    <Span bold>{heading}</Span>
                    <Options>
                        <Option checked={state[slag]} onClick={() => handleClick(slag, true)}>
                            <Radio name={slag} checked={state[slag]} />
                            <Span>{option1Text}</Span>
                        </Option>
                        <Option checked={!state[slag]} onClick={() => handleClick(slag, false)}>
                            <Radio name={slag} checked={!state[slag]} />
                            <Span>{option2Text}</Span>
                        </Option>
                    </Options>
                    {slag === 'calendar' && (
                        <Span
                            style={{
                                marginTop: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                            }}
                        >
                            <Tooltip
                                onResponsive="bottomSheet"
                                position="right"
                                content={`<b class='extra-bold'>Closing Date</b>${
                                    state.calendar
                                        ? `You picked ${state.newClosingDate}. If you need to change it in the future, no worries - we're flexible. Offerpad allows you to pick a date up to 90 days from the contract sign date.`
                                        : "We picked a date that we think might work for you, don't worry - you'll be able to change it, if needed."
                                }`}
                            >
                                <Span
                                    orange
                                    italic
                                    underline
                                    bold
                                    style={{ display: 'inline-block', fontSize: '14px' }}
                                >
                                    {state.calendar ? `Closing Date :` : `Temporary closing date :`}
                                </Span>
                            </Tooltip>
                            <Span style={{ fontSize: '15px' }} bold>
                                {state.newClosingDate}
                            </Span>
                        </Span>
                    )}
                </OptionContainer>
                <RoundedButton onClick={() => handleToogle(slag)}>
                    <img src={RightArrowIcon} alt="" />
                </RoundedButton>
            </OptionCardContainer>
        )
    }

    return (
        <Container standalone={standalone}>
            {state?.solution !== 'CashPlusList' && <Badge>Cash Offer</Badge>}
            <OfferPrice>{formatMoney(state?.response?.price)}</OfferPrice>
            {showSpecificScreen && (
                <Span center>
                    Looking for a{' '}
                    <a
                        onClick={() => mergeState({ showSpecificNumberScreen: true })}
                        id="View_Offer_Purchase_Agreement_Sent_Counter_Offer_Start"
                    >
                        specific number?
                    </a>
                </Span>
            )}
            <OfferCard>
                {!state?.response?.isUnderContract && (
                    <Seprator>
                        <span>{state?.seperatorText}</span>
                    </Seprator>
                )}
                <Details>
                    <CardDetails>
                        <Span bold>Offer price</Span>
                        <Span>{formatMoney(state?.response?.price)}</Span>
                    </CardDetails>
                    <CardDetails>
                        <Tooltip
                            onResponsive="bottomSheet"
                            position="right"
                            content={`Our service fee is ${state?.serviceFee}% and includes all the incredible benefits you get when you sell to Offerpad.`}
                        >
                            <Span bold underline>
                                Service Fee
                            </Span>
                        </Tooltip>
                        <Span>-{state?.serviceFee}%</Span>
                    </CardDetails>
                    <CardDetails>
                        <Tooltip
                            onResponsive="bottomSheet"
                            position="right"
                            content="When you buy your home with Offerpad, we provide a free home inspection. Our friendly team member and a licensed third-party inspector will visit your home to assess its condition."
                        >
                            <Span bold underline>
                                Repairs
                            </Span>
                        </Tooltip>
                        <Span italic>pending inspection</Span>
                    </CardDetails>
                    <CardDetails>
                        <Tooltip
                            onResponsive="bottomSheet"
                            position="right"
                            content="Buyer and Seller will each be responsible for their own customary closing costs typically assessed to each in the state where property is located."
                        >
                            <Span bold underline>
                                Closing costs
                            </Span>
                        </Tooltip>
                        <Span italic>varies by state</Span>
                    </CardDetails>
                    <CardDetails>
                        <Span teal bold>
                            Estimated Net Proceeds
                        </Span>
                        <Span teal bold>
                            {formatMoney(state?.response?.estimatedProceeds)}
                        </Span>
                    </CardDetails>
                </Details>
                <Seprator>
                    <span>
                        {' '}
                        {!state.response.accepted
                            ? 'pick your options below'
                            : 'OPTIONS AND CLOSING DATE'}
                    </span>
                </Seprator>
                {!state.response.accepted ? (
                    <>
                        <OptionMainContainer>
                            {options.map((v, i) => (
                                <OptionsCard key={i} count={i + 1} {...v} state={state} />
                            ))}
                        </OptionMainContainer>
                        <ActionButton
                            disabledColor="#1b6775"
                            variant="primary"
                            size="lg"
                            height={46}
                            onClick={buttonClick}
                        >
                            Next, view purchase agreement
                        </ActionButton>
                        {!state.compare && <CashOfferTeamCard state={state} />}
                    </>
                ) : (
                    <>
                        <Details>
                            <CardDetails>
                                <Span bold>Local Move</Span>
                                <Span italic>
                                    {state?.response?.freeMove ? 'requested' : 'not requested'}
                                </Span>
                            </CardDetails>
                            <CardDetails>
                                <Span bold>
                                    {state?.response?.isPartnerTier2 ? 5 : 3}-day Extended Stay
                                </Span>
                                <Span italic>
                                    {state?.response?.request?.flexClose
                                        ? 'requested'
                                        : 'not requested'}
                                </Span>
                            </CardDetails>
                            <CardDetails>
                                <Span bold>Closing Date</Span>
                                <Span italic>
                                    {moment(state?.response?.closeDateTime)
                                        .tz(state?.response?.property?.timeZoneId || '')
                                        .format('MM/DD/YYYY')}
                                </Span>
                            </CardDetails>
                        </Details>
                        <DefaultScreen>
                            <img src={SUNWITHCLOUD} alt="" />
                            <Span black style={{ fontSize: '22px', lineHeight: '23px' }}>
                                {!state?.response?.isUnderContract
                                    ? 'Your purchase agreement has been sent.'
                                    : 'You are under contract with Offerpad to sell your home.'}
                            </Span>
                            <Span>
                                {!state?.response?.isUnderContract
                                    ? 'We have sent the purchase agreement via email through our digital signing partner, HelloSign. Please check your email.'
                                    : 'Purchase agreement was signed by all parties. Please check documents section for details.'}
                            </Span>
                            {!state?.response?.isUnderContract && (
                                <Span>
                                    Questions about digitally signing, view our{' '}
                                    <Modal
                                        variableWidth
                                        backgroundColor="white"
                                        renderLink={({ open }) => (
                                            <Span
                                                onClick={open}
                                                orange
                                                style={{
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                helpful video.
                                            </Span>
                                        )}
                                        renderContent={({ close }) => (
                                            <ModalBody>
                                                <div
                                                    style={{
                                                        height: '90px',
                                                        marginTop: '-30px',
                                                        width: '100%',
                                                        position: 'absolute',
                                                    }}
                                                >
                                                    <ScreenLoader sL full={false} />
                                                </div>

                                                <iframe
                                                    src={
                                                        'https://player.vimeo.com/video/846687819?h=d57b59a475&autoplay=1&autopause=0&quality_selector=1&player_id=0&app_id=58479%5C'
                                                    }
                                                    width="100%"
                                                    height="338px"
                                                    frameBorder="0"
                                                    allowFullScreen
                                                ></iframe>
                                            </ModalBody>
                                        )}
                                    />
                                </Span>
                            )}
                        </DefaultScreen>
                    </>
                )}
            </OfferCard>
            {!state.compare && state.showVideoView && !state?.agent && (
                <CashOfferVideoCard state={state} />
            )}
        </Container>
    )
}

export default CashCard
