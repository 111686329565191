import React, { useEffect, useState } from 'react'
import {
    ActionButton,
    Cont,
    DCont,
    Heading,
    InfoCont,
    InfoHeading,
    Table,
    TaskTagline,
} from './Styles'
import Bin from './assets/document-bin.svg'
import Download from './assets/download.svg'
import CatNap from './assets/nap-cat.svg'
import PDF from './assets/pdf.svg'
import GrayInfo from 'shared/asstes/gray_info.svg'
import useDocuments from 'shared/hooks/sell/documents'
import { useSelector } from 'react-redux'
import { ScreenLoader, UploadDocuments } from 'shared/components'
import alert from 'shared/utils/alert'
import toast from 'shared/utils/toast'
import useTodos from 'shared/hooks/sell/todos'

function Documents() {
    const document = useDocuments()
    const { fetch } = useTodos()
    const documents = useSelector((state) => state.documents.documents)
    const loading = useSelector((state) => state.documents.loading)
    const [data, setData] = useState([])

    const notify = () => toast.success('Document deleted successfully.')

    const handleDelete = (documentId, boldId, docName) => {
        alert.confirm(
            'Delete Document',
            `Are you sure you want to delete ${docName} document?`,
            false,
            async (confirm) => {
                if (confirm) {
                    if (await document.delete(documentId, boldId)) {
                        notify()
                        await document.fetch()
                        await fetch()
                    }
                }
            },
        )
    }

    useEffect(() => {
        setData(documents)
    }, [documents])

    return (
        <Cont>
            {loading && (
                <div style={{ position: 'relative', height: '600px' }}>
                    <ScreenLoader sL={loading} full={false} />
                </div>
            )}
            {!loading && data?.pending?.length > 0 && (
                <div className="top-section pending-cont">
                    <Heading className="pending-heading">Upload your document</Heading>
                    <div className="info-para-cont">
                        <img src={GrayInfo} alt="" />
                        Supported file format - DOC, DOCX, PDF. Maximum file size 10 MB.
                    </div>
                    <div className="pending-cont-item">
                        {data?.pending?.map((todo) => (
                            <DCont key={todo.id}>
                                <InfoCont>
                                    <InfoHeading>Upload Requested Document</InfoHeading>
                                    <TaskTagline>
                                        Please provide us with a copy of your {todo.type}
                                    </TaskTagline>
                                </InfoCont>
                                <UploadDocuments
                                    todo={todo}
                                    onMarkedTodoCompleteEvent={fetch}
                                    renderContent={(browseDocuments) => {
                                        return (
                                            <ActionButton
                                                onClick={browseDocuments}
                                                variant="primary"
                                                id={`fs_sell_todo_${todo.identifier}`}
                                            >
                                                Upload
                                            </ActionButton>
                                        )
                                    }}
                                />
                            </DCont>
                        ))}
                    </div>
                </div>
            )}
            {!loading && data?.pending?.length === 0 && (
                <div className="top-section">
                    <img src={CatNap} alt="" />
                    <p>Time for a nap, you have nothing to do here.</p>
                    <span>You're all caught up on any document requests</span>
                </div>
            )}
            {!loading && data?.completed?.length > 0 && (
                <div className="doc-cont">
                    <Heading>Your documents</Heading>
                    <Table>
                        <thead>
                            <tr>
                                <th>File Name </th>
                                <th>Uploaded Date</th>
                                <th>Uploaded By</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.completed?.map((document, i) => (
                                <tr key={Date.now() + i}>
                                    <td className="file-info">
                                        <img src={PDF} />
                                        {document.title}
                                    </td>
                                    <td className="info">
                                        <span>Uploaded Date:</span>
                                        {document.uploadedDate}{' '}
                                    </td>
                                    <td className="info">
                                        <span>Uploaded By:</span>
                                        {document.uploadedBy}
                                    </td>
                                    <td>
                                        <div className="action-button">
                                            {document.canDelete && (
                                                <div
                                                    id="fs_sell_documents_delete"
                                                    onClick={() =>
                                                        handleDelete(
                                                            document.documentId,
                                                            document.boldId,
                                                            document.title,
                                                        )
                                                    }
                                                >
                                                    <img src={Bin} alt="" />
                                                </div>
                                            )}
                                            <div
                                                id="fs_sell_documents_download"
                                                onClick={() =>
                                                    window.open(document.fileUrl, '_blank')
                                                }
                                            >
                                                <img src={Download} alt="" />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </Cont>
    )
}

export default Documents
