import PropTypes from 'prop-types'
import { ActionButton, Card, Icon, TaskAndTagLineCont, TaskHeading } from './Styles'
import { useNavigate } from 'react-router'
import Todotickmark from 'shared/asstes/check_gray.svg'
import ReadAndAcknowledge from '../ReadAndAcknowledge'
import PhotosViewer from '../PhotosViewer'

const defaultProps = {
    todo: {},
}

const propTypes = {
    todo: PropTypes.object,
}

const CompletedTodos = ({ todo }) => {
    const identifier = todo.identifier
    const navigate = useNavigate()
    const { offerKey } = window.currentTransaction

    switch (true) {
        case identifier === 'CashOfferTransactionAddendumPropertyDisclosure':
        case identifier === 'CashOfferTransactionAddendumCredit':
        case identifier === 'CashOfferTransactionAddendumCreditAndRepair':
        case identifier === 'CashOfferTransactionReviewPurchaseAgreement':
        case identifier === 'CashOfferTransactionAddendumCancellation':
        case identifier === 'CashOfferTransactionAddendumPostPossessionCancellation':
        case identifier === 'CashOfferTransactionAddendumPostPossessionCancellationCOEChange':
        case identifier === 'CashOfferTransactionUpdateSecondarySeller':
            return (
                <Card>
                    <div>
                        <Icon src={Todotickmark} alt="" />
                        <TaskAndTagLineCont>
                            <TaskHeading>{todo.title}</TaskHeading>
                        </TaskAndTagLineCont>
                    </div>
                    {todo.actionButtonLabel && (
                        <ActionButton
                            variant="primary"
                            review={true}
                            onClick={() => navigate('/sell/documents')}
                            id={`fs_sell_todo_${todo.identifier}`}
                        >
                            {todo.actionButtonLabel}
                        </ActionButton>
                    )}
                </Card>
            )

        case identifier === 'CashOfferTransactionUploadPhotos':
        case identifier === 'CashOfferTransactionPhotosRequired':
            return (
                <Card>
                    <div>
                        <Icon src={Todotickmark} alt="" />
                        <TaskAndTagLineCont>
                            <TaskHeading>{todo.title}</TaskHeading>
                        </TaskAndTagLineCont>
                    </div>
                    <PhotosViewer
                        review={true}
                        btnText={todo.actionButtonLabel}
                        id={`fs_sell_todo_${todo.identifier}`}
                    />
                </Card>
            )

        case identifier === 'CashOfferTransactionReviewOffer':
        case identifier === 'CashOfferTransactionReviewBoomerangOffer':
            return (
                <Card>
                    <div>
                        <Icon src={Todotickmark} alt="" />
                        <TaskAndTagLineCont>
                            <TaskHeading>{todo.title}</TaskHeading>
                        </TaskAndTagLineCont>
                    </div>
                    <a
                        href={`${process.env.VIEW_OFFER_URL}/${offerKey}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        id={`fs_sell_todo_${todo.identifier}`}
                    >
                        <ActionButton variant="primary" review={true}>
                            {todo.actionButtonLabel}
                        </ActionButton>
                    </a>
                </Card>
            )

        case identifier === 'CashOfferTransactionReviewOffer':
        case identifier === 'CashOfferTransactionReviewBoomerangOffer':
            return (
                <Card>
                    <div>
                        <Icon src={Todotickmark} alt="" />
                        <TaskAndTagLineCont>
                            <TaskHeading>{todo.title}</TaskHeading>
                        </TaskAndTagLineCont>
                    </div>
                    <a
                        href={`${process.env.VIEW_OFFER_URL}/${offerKey}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        id={`fs_sell_todo_${todo.identifier}`}
                    >
                        <ActionButton variant="primary" review={true}>
                            {todo.actionButtonLabel}
                        </ActionButton>
                    </a>
                </Card>
            )

        case identifier === 'CashOfferTransactionPostInspectionSurvey':
        case identifier === 'CashOfferTransactionExitSurvey':
        case identifier === 'CashOfferTransactionUtilitiesInformation':
        case identifier === 'CashOfferTransactionAddendumCloseOfEscrow':
        case identifier === 'CashOfferTransactionAddendumGeneral':
        case identifier === 'CashOfferTransactionAddendumPurchasePriceAdjustment':
        case identifier === 'CashOfferTransactionAddendumPostPossessionCoeChange':
        case identifier === 'CashOfferTransactionAddendumEscrowHoldBack':
        case identifier === 'CashOfferTransactionAddendumSellerCreditToBuyer':
        case identifier === 'CashOfferTransactionAddendumTrashOut':
        case identifier === 'CashOfferTransactionAddendumBuyerCreditToSeller':
        case identifier === 'CashOfferTransactionRenewOffer':
        case identifier === 'CashOfferTransactionScheduleInspectionResultReview':
        case identifier === 'CashOfferTransactionUploadDocumentTrust':
        case identifier === 'CashOfferTransactionUploadDocumentLLC':
        case identifier === 'CashOfferTransactionUploadDocumentSigningAuthority':
        case identifier === 'CashOfferTransactionUploadDocumentHeirship':
        case identifier === 'CashOfferTransactionUploadDocumentPermit':
        case identifier === 'CashOfferTransactionUploadDocumentSurvey':
        case identifier === 'CashOfferTransactionUploadDocumentSolar':
        case identifier === 'CashOfferTransactionUploadDocumentHOA':
        case identifier === 'CashOfferTransactionUploadDocumentProofOfPayment':
        case identifier === 'CashOfferTransactionUploadDocumentWellAgreement':
        case identifier === 'CashOfferTransactionUploadDocumentOther':
        case identifier === 'CashOfferTransactionCreateFirstAmericanAccount':
        case identifier === 'CashOfferTransactionProvideSellerInformationToFirstAmerican':
        case identifier === 'CashOfferTransactionReviewExpressOffer':
        case identifier === 'CashOfferTransactionRenewExpressOffer':
            return (
                <Card>
                    <div>
                        <Icon src={Todotickmark} alt="" />
                        <TaskAndTagLineCont>
                            <TaskHeading>{todo.title}</TaskHeading>
                        </TaskAndTagLineCont>
                    </div>
                    {todo.actionButtonLabel && (
                        <a href={todo.metadata?.redirectUrl} target="_blank">
                            <ActionButton
                                variant="primary"
                                review={true}
                                id={`fs_sell_todo_${todo.identifier}`}
                            >
                                {todo.actionButtonLabel}
                            </ActionButton>
                        </a>
                    )}
                </Card>
            )

        case identifier === 'CashOfferTransactionFinalizeMoveDateBellhops':
        case identifier === 'CashOfferTransactionPresentOfferAppointment':
            return (
                <Card>
                    <div>
                        <Icon src={Todotickmark} alt="" />
                        <TaskAndTagLineCont>
                            <TaskHeading>{todo.title}</TaskHeading>
                        </TaskAndTagLineCont>
                    </div>
                    <ActionButton
                        variant="primary"
                        review={true}
                        id={`fs_sell_todo_${todo.identifier}`}
                        onClick={() => navigate(`/sell/calendar?date=${todo.calDate}`)}
                    >
                        {todo.actionButtonLabel}
                    </ActionButton>
                </Card>
            )

        case identifier === 'CashOfferTransactionWhatToLeaveBehind':
        case identifier === 'CashOfferTransactionConditionRequirements':
        case identifier === 'CashOfferTransactionAcknowledgeExtendedStay':
        case identifier === 'CashOfferTransactionAcknowledgeFreeLocalMove':
        case identifier === 'CashOfferTransactionPrepareForInspection':
            return (
                <Card>
                    <div>
                        <Icon src={Todotickmark} alt="" />
                        <TaskAndTagLineCont>
                            <TaskHeading>{todo.title}</TaskHeading>
                            {/* {todo.bodyText !== null && <TaskTagline>{todo.bodyText}</TaskTagline>} */}
                        </TaskAndTagLineCont>
                    </div>
                    <ReadAndAcknowledge todo={todo} isComplete />
                </Card>
            )
        default:
            return null
    }
}

CompletedTodos.propTypes = propTypes
CompletedTodos.defaultProps = defaultProps

export default CompletedTodos
