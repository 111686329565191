import { Cont, DefaultE } from './Styles'
import { useWizard } from 'react-use-wizard'
import LocationPin from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/location-pin-free-local.svg'
import Truck from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/truck-free-local.svg'
import FiveM from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/5m-free-local.svg'
import CoePointer from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/coe-info-pointer-free.png'
import Check from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/check-free-local.svg'
import Phone from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/phone-free-local.svg'
import Email from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/email-free-local.svg'
import Info from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/info-free-local.svg'
import { useSelector } from 'react-redux'
import { ScreenLoader } from 'shared/components'
import useApi from 'shared/hooks/api'
import moment from 'moment-timezone'
import useEvents from 'shared/hooks/sell/events'
import useTeams from 'shared/hooks/sell/teams'
import { formatPhoneNumber } from 'shared/utils/formatter'
import alert from 'shared/utils/alert'

const Step1 = ({ isTrue }) => {
    const { goToStep } = useWizard()
    const transaction = useSelector((state) => state.singleTransaction.transaction)
    const agent = window.currentTransaction?.isListedByAgent

    const { getEventByRole } = useEvents()
    const { eventDate } = getEventByRole('ClearedToClose')

    const { getTeamByRole } = useTeams()
    let movingCompany = getTeamByRole('MovingCompanyContact')

    if (!movingCompany) {
        movingCompany = {
            companyName: 'the moving company',
        }
    }

    const successManager = getTeamByRole('SuccessManager')

    const FreeLocalMoveEndPoint = `customer/v3/cash-offer-transactions/${localStorage.currentTransactionId}/change-free-local-move`

    const [{ isCreating }, sendFreeLocalMove] = useApi.post(FreeLocalMoveEndPoint)

    const comparisonRoleArray = [
        'OfferRequested',
        'OfferReady',
        'PurchaseAgreementSent',
        'RenewalRequested',
        'OfferExpired',
    ]

    const preliminaryComparissionArray = [
        'PreliminaryOfferExpired',
        'PreliminaryOfferReady',
        'PreliminaryOfferRequested',
        'InspectionScheduled',
        'InspectionInReview',
    ]

    const canApplyFreeLocalMove = transaction?.isPreContractInspection
        ? !preliminaryComparissionArray.includes(transaction.transactionStatus)
        : !comparisonRoleArray.includes(transaction.transactionStatus)

    const timeOver = moment(eventDate, 'MM/DD/YYYY').diff(moment(), 'days') <= 14

    const handleClick = async () => {
        if (!canApplyFreeLocalMove) return
        if (!isTrue) {
            await sendFreeLocalMove({
                operation: 'Add',
            })
            goToStep(1)
        } else {
            alert.confirm(
                'Cancel free local move',
                `Are you sure you want to request cancellation of your free local move?`,
                '',
                async (confirm) => {
                    if (confirm) {
                        await sendFreeLocalMove({
                            operation: 'Cancel',
                        })
                        goToStep(1)
                    }
                },
            )
        }
    }

    return (
        <Cont isTrue={isTrue}>
            <ScreenLoader sL={isCreating} />
            <div className="heading-container">
                <h1>Free Local Move</h1>
            </div>
            {isTrue && (
                <div className="requested-info">
                    <img src={Check} alt="" />
                    Requested
                </div>
            )}
            {movingCompany.companyName && (
                <p className="movingcompany-name">provided by {movingCompany.companyName}</p>
            )}
            <div className="feature-container">
                <div>
                    <img src={LocationPin} alt="" className="pin" />
                    <h2>Within 50 miles</h2>
                </div>
                <div>
                    <img src={Truck} alt="" />
                    <h2>We partner with professional moving companies</h2>
                </div>
                <div>
                    <img src={FiveM} alt="" />
                    <h2>Homes up to 2,800 sq. ft & 13,000 lbs</h2>
                </div>
            </div>
            <hr />
            <div className="terms-container">
                <div className="teams-heading">
                    {!isTrue ? <h2>Additional Details</h2> : <h2>Here’s what to expect.</h2>}

                    {isTrue && (
                        <a style={{ cursor: 'pointer' }} onClick={() => goToStep(2)}>
                            View full details of Free Local Move
                        </a>
                    )}
                </div>
                {!isTrue && (
                    <div className="free-local-info">
                        <img src={CoePointer} alt="" />
                        <p>
                            In order to take advantage of the free local move program, Offerpad may
                            require a minimum of a 7 to 21 days’ notice (depending upon the market
                            and time of year) prior to the close of escrow.
                        </p>
                    </div>
                )}
                {!isTrue && (
                    <div className="free-local-info">
                        <img src={CoePointer} alt="" />
                        <p>
                            Additional square footage, weight and other services may be purchased
                            and selected by the customer directly from the moving company.
                        </p>
                    </div>
                )}
                {!isTrue && (
                    <div className="free-local-info">
                        <img src={CoePointer} alt="" />
                        <p>
                            Our Free Local Move is only available to customers who are selling their
                            home to Offerpad.
                        </p>
                    </div>
                )}
                {!isTrue && (
                    <div className="free-local-info">
                        <img src={CoePointer} alt="" />
                        <p>
                            The move must originate from the home purchased by Offerpad and
                            delivered to a single local destination/address within a 50-mile radius
                            of customer's origin zip code.
                        </p>
                    </div>
                )}
                {!isTrue && (
                    <div className="free-local-info">
                        <img src={CoePointer} alt="" />
                        <p>
                            With Offerpad's Extended Stay program, free local moves are valid for{' '}
                            {transaction.extendedStay === null ||
                            transaction?.extendedStay?.extendedStayDayEligibleDays === 0
                                ? '3'
                                : transaction?.extendedStay?.extendedStayDayEligibleDays}{' '}
                            days after we purchase your home, depending on the Extended Stay option
                            you choose.
                        </p>
                    </div>
                )}
                {isTrue && (
                    <div className="free-local-info">
                        <img src={CoePointer} alt="" />
                        <p>
                            Once we’re under contract,{' '}
                            {movingCompany.companyName
                                ? movingCompany?.companyName
                                : 'the moving company'}{' '}
                            will connect{' '}
                            {agent === false
                                ? 'you'
                                : `with your
                            seller`}{' '}
                            directly to get things started.
                        </p>
                    </div>
                )}
                {isTrue && (
                    <div className="free-local-info">
                        <img src={CoePointer} alt="" />
                        <p>
                            Your {agent === true && `seller's`} move will be scheduled, don’t worry
                            — they can always reschedule if needed.
                        </p>
                    </div>
                )}
                {isTrue && (
                    <div className="free-local-info">
                        <img src={CoePointer} alt="" />
                        <p>
                            {agent === true
                                ? `Your seller will pack their personal belongings in boxes. If
                            they need packing services, they should reach out to ${
                                movingCompany.companyName
                                    ? movingCompany?.companyName
                                    : 'the moving company'
                            } directly. `
                                : `You pack you personal belongings in boxes. If you need packing services, please reach out to ${
                                      movingCompany.companyName
                                          ? movingCompany?.companyName
                                          : 'the moving company'
                                  } directly.`}
                        </p>
                    </div>
                )}
                {isTrue && (
                    <div className="free-local-info">
                        <img src={CoePointer} alt="" />
                        <p>
                            It’s moving day! Your professional moving team will get to work and
                            complete your {agent === true && `seller's `} move quickly, carefully,
                            and efficiently!
                        </p>
                    </div>
                )}
            </div>
            <div className="btn-cont">
                <button
                    className={`next ${(!canApplyFreeLocalMove || timeOver) && 'grayed'}`}
                    id={!isTrue ? 'fs_sell_freeLocalMove_request' : 'fs_sell_freeLocalMove_cancel'}
                    onClick={handleClick}
                    disabled={timeOver}
                >
                    {!isTrue ? 'Request Free Local Move' : 'Cancel Free Local Move'}
                </button>
            </div>
            {!canApplyFreeLocalMove && !timeOver && (
                <div>
                    <br />
                    <span className="not-available">
                        <img src={Info} alt="" />
                        Free local move available once under contract
                    </span>
                </div>
            )}
            {timeOver && (
                <DefaultE>
                    <div className="info">
                        <span className="not-available">
                            <img src={Info} alt="" />
                            It may be too late to make changes to your Free Local Move, please reach
                            out directly to your Success Manager, {successManager?.firstName}{' '}
                            {successManager?.lastName} to confirm.
                        </span>
                        {successManager?.email && successManager?.phoneNumber && (
                            <div className="contacts">
                                {successManager?.phoneNumber && (
                                    <div className="c-info">
                                        <img src={Phone} alt="" />
                                        <a
                                            href={`tel:${formatPhoneNumber(
                                                successManager?.phoneNumber,
                                            )}`}
                                        >
                                            {`${formatPhoneNumber(successManager?.phoneNumber)}`}
                                        </a>
                                    </div>
                                )}
                                {successManager?.email && (
                                    <div className="c-info">
                                        <img src={Email} alt="" />
                                        <a href={`mailto:${successManager?.email}`}>
                                            {`${successManager?.email}`}
                                        </a>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </DefaultE>
            )}
        </Cont>
    )
}

export default Step1
