import React from 'react'
import { Cont, Header, MessageCont, SubHeader, Text, Select } from './Styles'
import { ScreenLoader } from 'shared/components'
import { useWizard } from 'react-use-wizard'
import { useEffect, useState } from 'react'
import useMyTransactions from 'shared/hooks/sell/my-transactions'
import { capitalizeFirstLetterEveryWord } from 'shared/utils/formatter'

function Step1({ setId, setTotalNoOfProperties }) {
    const { nextStep, goToStep } = useWizard()
    const [myTransactions, setMyTransactions] = useState([])
    const [loading, setLoading] = useState(false)
    const { fetch } = useMyTransactions()

    const handleChange = (e) => {
        const value = e.target.value
        if (value === '') {
            return
        }
        setId(value)
        nextStep()
    }

    const init = async () => {
        setLoading(true)
        let res = await fetch()
        setTotalNoOfProperties(res.length)
        if (!res.length) {
            setId(null)
            goToStep(1)
            return
        }
        if (res.length === 1) {
            setId(res[0].transactionId)
            goToStep(1)
            return
        }
        setMyTransactions(res)
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <Cont>
            <ScreenLoader sL={loading} backgroundColor="white" />
            <MessageCont>
                <Header>Message Your Team</Header>
                <SubHeader>Manage messages, chat & support</SubHeader>
                <Text>
                    You have multiple properties associated with your account, please select the one
                    your message pertains to, so we can route it to the appropriate team member.
                </Text>
                <Select onChange={handleChange} defaultValue="">
                    <option value="" hidden disabled>
                        Select Property
                    </option>
                    {myTransactions &&
                        myTransactions.map((transaction) => (
                            <option
                                key={transaction?.transactionId}
                                value={transaction?.transactionId}
                            >
                                {capitalizeFirstLetterEveryWord(
                                    transaction?.property?.streetAddress,
                                )}{' '}
                                {transaction?.property?.city}, {transaction?.property?.state}{' '}
                                {transaction?.property?.zip}, USA
                            </option>
                        ))}
                </Select>
            </MessageCont>
        </Cont>
    )
}

export default Step1
