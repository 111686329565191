import ButterflyImage from './assets/butterfly.svg'
import { DefaultCont, DefaultImg, DefaultHeading, DefaultTagLine } from './Styles'
import moment from 'moment-timezone'
const Default = ({ date }) => {
    const formatedDate = moment(date, 'MM/DD/YYYY').format('MMM DD, YYYY')
    return (
        <DefaultCont>
            <DefaultImg src={ButterflyImage} alt="" />
            <DefaultHeading>No event on {formatedDate}</DefaultHeading>
            <DefaultTagLine>Nothing to see here and apparently nothing to do.</DefaultTagLine>
        </DefaultCont>
    )
}

export default Default
