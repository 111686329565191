import { Cont } from './Styles'
import { useWizard } from 'react-use-wizard'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { Radio } from 'shared/components'

const Step2 = ({ data, setData, coe, es }) => {
    const { nextStep, goToStep, previousStep } = useWizard()
    const agent = window.currentTransaction?.isListedByAgent
    const [extendedStay, setExtendedStay] = useState(
        data.extendedStay !== '' ? data.extendedStay : '',
    )
    const [extendedStayDays, setExtendedStayDays] = useState(
        data.extendedStayDays !== '' ? data.extendedStayDays : '',
    )
    const [isDisabled, setIsDisabled] = useState(false)

    const maxDays =
        es.extendedStayDayEligibleDays > es.currentExtendedStayDays
            ? es.extendedStayDayEligibleDays
            : es.currentExtendedStayDays

    const array = []
    for (let i = 0; i < maxDays; i++) {
        array.push(i + 1)
    }

    const handleClick = () => {
        if (data.extendedStay === false) {
            goToStep(1)
        }
        nextStep()
    }

    const checkIfDisaled = () => {
        if (data.extendedStay === false) {
            return false
        }
        if (data.extendedStay === '') {
            return true
        }
        if (data.extendedStayDays === '') {
            return true
        }
        return false
    }

    useEffect(() => {
        const e = { extendedStay: extendedStay }
        const d = { extendedStayDays: extendedStayDays }
        if (extendedStay) {
            setData({ ...data, ...e, ...d })
        } else {
            setData({ ...data, ...e })
        }
    }, [extendedStay, extendedStayDays])

    useEffect(() => {
        setIsDisabled(checkIfDisaled())
    }, [data])

    return (
        <Cont>
            {agent !== true ? (
                <h1>About your Extended Stay</h1>
            ) : (
                <h1>About your seller's Extended Stay</h1>
            )}
            {agent !== true ? (
                <p>
                    We noticed that you selected to take advantage of our free Extended Stay
                    benefit.
                    <br /> Do you still need to stay additional days after closing?
                </p>
            ) : (
                <p>
                    We noticed your seller selected to take advantage of our free Extended Stay
                    benefit. Do they still need to stay additional days after closing?
                </p>
            )}
            <div className="opt-cont">
                <div>
                    <Radio
                        type="radio"
                        name="free-extended-stay"
                        onChange={() => setExtendedStay(true)}
                        defaultChecked={extendedStay}
                    />
                    <label htmlFor="checkbox">Yes, still needed</label>
                </div>
                {extendedStay && (
                    <div className="extended-wrapper">
                        <div className="select-extended-date">
                            {agent !== true ? (
                                <span>How many extra days do you need?</span>
                            ) : (
                                <span>How many extra days does your seller need?</span>
                            )}
                            <div className="select-wrapper">
                                <select onChange={(e) => setExtendedStayDays(e.target.value)}>
                                    <option value="">Select days</option>
                                    {array.length &&
                                        array.map((arr) => (
                                            <option key={arr} value={arr}>
                                                {arr} {arr > 1 ? 'days' : 'day'}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        {extendedStayDays !== '' && (
                            <div className="extended-date-info">
                                New Extended Stay End Date:
                                <span>
                                    {moment(data.date, 'MM/DD/YYYY')
                                        .add(data.extendedStayDays, 'days')
                                        .format('MMM DD, YYYY')}
                                </span>
                            </div>
                        )}
                    </div>
                )}
                <div>
                    <Radio
                        name="free-extended-stay"
                        defaultChecked={extendedStay === false ? true : false}
                        onChange={() => setExtendedStay(false)}
                    />
                    <label htmlFor="checkbox">No longer needed </label>
                </div>
            </div>

            <div className="btn-cont">
                <button id="fs_sell_cod_dateChange_sa_previous2" onClick={previousStep}>
                    Back
                </button>
                <button
                    id="fs_sell_cod_dateChange_sa_next2"
                    className="next"
                    onClick={handleClick}
                    disabled={isDisabled}
                >
                    Next
                </button>
            </div>
        </Cont>
    )
}

export default Step2
