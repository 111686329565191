import styled, { css, keyframes } from 'styled-components'
import { Button } from 'shared/components'
import { color, font } from 'shared/utils/styles'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

const opacity = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const Cont = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const LogoCont = styled.div`
    margin-top: 66px;
`
export const GroupCont = styled.div`
    display: flex;
    gap: 52px;
    align-items: center;
`
export const ExtGroupCont = styled.div`
    display: flex;
    gap: 3px;
    align-items: center;
`
export const UtilityGroup = styled.div`
    display: flex;
    gap: 52px;
    align-items: center;
`
export const Showinmid = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const CnterAlignItems = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
`
export const ExtShowinmid = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const GroupContUtility = styled.div`
    display: flex;
    gap: 52px;
    align-items: flex-start;
`

export const UtilityGroupCont = styled.div`
    display: flex;
    align-items: center;
    gap: 22px;
`

export const Group = styled.div``

export const ChecboxContainer = styled.div`
    margin-left: 40px;
    margin-top: 23px;
    gap: 26px;
`

export const Heading = styled.div`
    margin-top: 17px;
    ${font.black};
    ${font.size(29)};
    line-height: 36px;
    color: #262833;
    ${(props) =>
        !!props.color &&
        props.color &&
        css`
            color: ${props.color};
        `}
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const UtilityHeading = styled.div`
    margin-top: 56px;
    ${font.black};
    ${font.size(29)};
    line-height: 36px;
    color: #262833;
    ${(props) =>
        !!props.color &&
        props.color &&
        css`
            color: ${props.color};
        `}
`
export const UtilityHeadingp1 = styled.div`
    ${font.black};
    ${font.size(29)};
    line-height: 36px;
    color: #262833;
    ${(props) =>
        !!props.color &&
        props.color &&
        css`
            color: ${props.color};
        `}
`

export const TagLine = styled.div`
    margin-top: 11px;
    ${font.medium};
    ${font.size(18)};
    line-height: 28px;
    color: #797979;
    width: 100%;
    max-width: 630px;
`
export const ExtTagLine = styled.div`
    margin-top: 11px;
    ${font.medium};
    ${font.size(16)};
    line-height: 28px;
    color: #525659;
    width: 100%;
    max-width: 630px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const ExtRequestedLine = styled.div`
    ${font.medium};
    ${font.size(16)};
    line-height: 28px;
    color: #525659;
    width: 100%;
    max-width: 630px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`

export const UtilityTagLine = styled.div`
    margin-top: 11px;
    ${font.bold};
    ${font.size(20)};
    line-height: 28px;
    padding-left: 39px;
    color: #262833;
    width: 100%;
    max-width: 640px;
`
export const UtilityTagLinep1 = styled.div`
    ${font.bold};
    ${font.size(18)};
    line-height: 28px;
    color: #262833;
    width: 100%;
    max-width: 640px;
`
export const DescriptionText = styled.div`
    margin-top: 11px;
    ${font.medium};
    ${font.size(14)};
    line-height: 20px;
    color: #797979;
    width: 100%;
    max-width: 640px;
`
export const ExtendedTagLine = styled.div`
    margin-top: 11px;
    ${font.bold};
    ${font.size(20)};
    line-height: 26px;
    color: #797979;
    width: 100%;
    max-width: 813px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    ${(props) =>
        !!props.color &&
        props.color &&
        css`
            color: ${props.color};
        `}
`
export const ExtendedTagText = styled.div`
    margin-top: 11px;
    ${font.bold};
    ${font.size(20)};
    line-height: 26px;
    color: #797979;
    width: 100%;
    max-width: 158px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    ${(props) =>
        !!props.color &&
        props.color &&
        css`
            color: ${props.color};
        `}
`
export const ExtendedTag = styled.div`
    margin-top: 28px;
    ${font.medium};
    ${font.size(20)};
    line-height: 19.2px;
    color: #797979;
    width: 100%;
    max-width: 813px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const ExtendedTagInfo = styled.div`
    margin-top: 28px;
    ${font.bold};
    ${font.size(20)};
    line-height: 19.2px;
    color: #000000;
    width: 100%;
    max-width: 813px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`

export const Extdivider = styled.div`
    display: flex;
    flex-direction: row;
`
export const ExtSeperaor = styled.div`
    display: flex;
    margin-top: 40px;
    flex-direction: row;
`
export const ExtSeperaor2 = styled.div`
    display: flex;
    flex-direction: row;
`

export const BackgroundImage = styled.img`
    width: auto;
    max-width: 431px;
    object-fit: cover;
`
export const PutimageinCenter = styled.img`
    width: auto;
    width: 209px;
    height: 167px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const Companylogo = styled.img`
    width: auto;
    width: 76.11px;
    height: 18px;
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const PointImage = styled.img`
    width: 27px;
    height: 27px;
`
export const ThunderImage = styled.img`
    width: 60px;
    height: 60px;
    // object-fit: cover;
    margin-top: 56px;
    margin-left: 33px;
`
export const LightBulbImage = styled.img`
    width: 236px;
    height: 475px;
    background-color: #c0d7d7;
    ${(props) =>
        !!props.dhari &&
        css`
            width: auto;
            background-color: transparent;
            margin-left: -5px;
        `}
`

export const Wrapper = styled.div`
    position: relative;
    background-color: white;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
    overflow-x: hidden;
    animation: ${opacity} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @media (max-width: 480px) {
        max-height: 100%;
    }
    div {
        animation: ${slideLeft} ease 0.7s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
`

export const ButtonCont = styled.div`
    display: flex;
    gap: 22px;
    margin-top: 28px;

    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    ${(props) =>
        !!props.ml &&
        props.ml &&
        css`
            margin-left: ${props.ml}px;
        `}
`

export const SurveyCont = styled.div`
    display: flex;
    flex-direction: column;
    height: 475px;
    width: 969px;
    padding: 60px 39px;
    ${(props) =>
        !!props.height &&
        props.height &&
        css`
            height: ${props.height}px;
        `}
    ${(props) =>
        !!props.width &&
        props.width &&
        css`
            width: ${props.width}px;
        `}
`
export const ExtendedCont = styled.div`
    display: flex;
    flex-direction: column;
    height: 475px;
    width: 969px;
    padding: 30px 39px;
    ${(props) =>
        !!props.height &&
        props.height &&
        css`
            height: ${props.height}px;
        `}
    ${(props) =>
        !!props.width &&
        props.width &&
        css`
            width: ${props.width}px;
        `}
`
export const UtilityCont = styled.div`
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 475px;
    ${(props) =>
        !!props.height &&
        props.height &&
        css`
            height: ${props.height}px;
        `}
    ${(props) =>
        !!props.width &&
        props.width &&
        css`
            width: ${props.width}px;
        `}
`
export const UtilityContXL = styled.div`
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 631px;
    ${(props) =>
        !!props.height &&
        props.height &&
        css`
            height: ${props.height}px;
        `}
`
export const UtilityDivider = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
`

export const Logo = styled.img`
    width: 100%;
    max-width: 161px;
`

export const Icons = styled.img`
    width: 55px;
    height: 55px;
`
export const DynamicIcons = styled.img`
    ${(props) =>
        !!props.height &&
        props.height &&
        css`
            height: ${props.height}px;
        `}
    ${(props) =>
        !!props.width &&
        props.width &&
        css`
            width: ${props.width}px;
        `}
`
export const ThumbIcon = styled.img`
    width: 31px;
    height: 31px;
`
export const ActionButton = styled(Button)`
    width: 100%;
    max-width: 305px;
    height: 62px;
    ${font.medium};
    ${font.size(18)};
    line-height: 21.6px;
    ${(props) =>
        !!props.prev &&
        css`
            background-color: white;
            border: 1px solid #bdbdbd;
            max-width: 129px;
            &:hover {
                background-color: white !important;
                opacity: 0.8;
            }
        `}
    ${(props) =>
        !!props.continue &&
        css`
            max-width: 191px;
        `}
    ${(props) =>
        !!props.width &&
        props.width &&
        css`
            max-width: ${props.width}px;
        `}
`

export const UserInfoCont = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`

export const UserNameAvatarCont = styled.div`
    height: 57px;
    width: 57px;
    background-color: #1b6775;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    ${font.bold};
    ${font.size(24)};
`

export const UserNaveAvatar = styled.img``

export const UserNameAndTypeCont = styled.div`
    ${font.black};
    ${font.size(23)};
    line-height: 27.6px;
    color: #231f20;
    display: flex;
    flex-direction: column;
    gap: 7px;
    ${(props) =>
        !!props.reverse &&
        css`
            flex-direction: column-reverse;
            gap: 3px;
        `}
`

export const UserType = styled.div`
    background-color: #fff4e8;
    padding: 0px 14px;
    border-radius: 19px;
    max-height: 24px;
    ${font.medium};
    ${font.size(11)};
    line-height: 22px;
    ${(props) =>
        !!props.hide &&
        css`
            display: none;
        `}
    ${(props) =>
        !!props.noDiv &&
        css`
            background-color: transparent;
            padding: 0px;
            color: #525659;
            ${font.size(15)};
            line-height: 25px;
        `}
`

export const Question = styled.div`
    margin-top: 20px;
    ${font.bold};
    ${font.size(26)};
    line-height: 37px;
`

export const OptionCont = styled.div`
    margin-top: 36px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 25px;
`
export const OptionContUtility = styled.div`
    margin-top: 36px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 25px;
    margin-left: 40px;
`
export const UtilityOptionCont = styled.div`
    margin-top: 36px;
    display: grid;
    gap: 25px;
    margin-left: 42px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const ExtOptionCont = styled.div`
    margin-top: 36px;
    display: grid;
    gap: 25px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const ExtendedOptionCont = styled.div`
    margin-top: 36px;
    display: grid;
    gap: 25px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const ExtendedGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 25px;
`

export const OptionsCont = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
    ${font.medium};
    ${font.size(16)};
    line-height: 25px;
    color: #525659;
`

export const Option = styled.input`
    -webkit-appearance: radio;
    accent-color: ${color.primary} !important;
    height: 20px;
    width: 20px;
    cursor: pointer;
`

export const Textarea = styled.textarea`
    padding: 16px 19px;
    ${font.medium};
    ${font.size(15)};
    line-height: 25px;
    margin-left: 40px;
    color: #9d9d9d;
    margin-top: 26px;
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    width: 100%;
    max-width: 587px;
    height: 154px;
    resize: none;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const TextareaUtility = styled.textarea`
    padding: 16px 19px;
    ${font.medium};
    ${font.size(15)};
    line-height: 25px;
    margin-left: 40px;
    color: #9d9d9d;
    margin-top: 26px;
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    width: 100%;
    max-width: 587px;
    height: 154px;
    resize: none;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`

export const RatingCont = styled.div`
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    ${(props) =>
        !!props.mb &&
        props.mb &&
        css`
            margin-bottom: ${props.mb}px;
        `}
`

export const ShowRatingCont = styled.div`
    display: flex;
    padding-left: 17px;
    border-left: 1px solid #c8c8c8;
`

export const ShowRatingTextrAndStartCont = styled.div`
    display: flex;
    flex-direction: column;
    ${font.medium};
    ${font.size(15)};
    line-height: 25px;
    color: #525659;
`

export const ShowRatingEditButton = styled(Button)`
    margin-left: 17px;
    border-radius: 50%;
    width: 39px;
    height: 39px;
    background-color: #eaeaea;
    &:hover {
        background-color: #eaeaea !important;
    }
`

export const EditIcon = styled.img``

export const EmojiCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
`

export const Emoji = styled.img`
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`

export const EmojiText = styled.div`
    ${font.bold};
    ${font.size(32)};
    line-height: 40px;
    color: #262833;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`

export const TwoColumn = styled.div`
    display: flex;
    position: relative;
`

export const SurvayImage = styled.img`
    width: 35%;
    position: absolute;
    left: -40px;
    top: 8px;
`

export const SurvayCont = styled.div`
    margin-left: 340px;
`
export const UtilityInputText = styled.input`
    width: 337px;
    height: 53px;
    margin-left: 40px;
    margin-top: 20px;
    padding-left: 22px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #ffffff;
    border: 1px solid #979797;
    height: 53px;
    width: 337px;
    left: 40px;
    top: 175px;
    border-radius: 5px;
`

export const Mailboxcont = styled.div`
    padding-top: 30px;
    padding-bottom: 30px;
`
export const ExtendedCard = styled.div`
    width: 720px;
    height: 263px;
    background: rgba(231, 240, 241, 0.5);
    border: 1px solid #dbe5e7;
    border-radius: 10px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const ExtDescription = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #525659;
`
export const ExtDescriptionText = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #525659;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
`

export const ExtendedHeadine = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 35px;
    ${font.bold};
    ${font.size(20)};
    line-height: 19.2px;
    color: #000000;
    width: 100%;
    max-width: 813px;
`
export const Footer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 18px 30px;
    margin-top: 32px;
    @media (max-width: 450px) {
        grid-template-columns: 1fr;
    }
`
export const FooterImg = styled.img`
    margin-right: 5px;
    width: 14.17px;
    height: 14.17px;
`
export const FooterDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`

export const FooterAnchor = styled.a`
    ${font.medium};
    ${font.size(12)};
    line-height: 14.4px;
    color: #ec7625;
    text-decoration: underline;
    white-space: nowrap;
`

export const ExtDropdown = styled.div`
    width: 246px;
    height: 55px;
    left: 80px;
    top: 312px;
    background: #ffffff;
    border: 1px solid #afafaf;
    border-radius: 5px;
`
export const WrapperDiv = styled.div`
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    ${(props) =>
        !!props.ml &&
        props.ml &&
        css`
            margin-left: ${props.ml}px;
        `}
`
export const SelectDropDown = styled.select`
    width: 246px;
    height: 40px;
    left: 40px;
    top: 204px;
    padding-left: 16px;
    background: #ffffff;
    border: 1px solid #afafaf;
    border-radius: 5px;
`
export const Extendedsubtext = styled.div`
    width: 668px;
    height: 40px;
    ${font.bold};
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #525659;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`

export const ExtendedDescriptionText = styled.div`
    ${font.medium};
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
`
export const ReqWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const GroupText = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
`

export const DividerModal = styled.hr`
    width: 100%;
    border: 1px solid #e6e6e6;
    transform: rotate(-180deg);
`
