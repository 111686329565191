import styled from 'styled-components'
import { font } from 'shared/utils/styles'

export const Wrapper = styled.div`
    margin-top: auto;
    background-color: #f7fcfe;
    padding: 80px 102px 53px 102px;
    background-image: linear-gradient(
        to right,
        #db7634 25%,
        #b2c2d9 25%,
        #b2c2d9 50%,
        #42889b 50%,
        #42889b 75%,
        #f6ca42 75%
    );
    background-size: 100% 8px;
    background-repeat: no-repeat;
    background-position: 0% 0%;
    color: #3f3f3f;
    @media (max-width: 1423px) {
        padding: 80px 30px 53px 30px;
    }
    @media (max-width: 820px) {
        padding: 54px 30px 26px 30px;
    }

    .copy-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;
        border-top: 1px solid #e6edf0;
        padding: 26px 0px 0px 0px;
        .copy-right-content {
            text-align: left;
            color: #6b6b6b;
            ${font.medium};
            ${font.size(14)};
            line-height: 25.1px;
            a {
                color: #0089bb;
                ${font.medium};
                ${font.size(14)};
                line-height: 25.1px;
                text-decoration: underline;
                @media (max-width: 820px) {
                    ${font.size(12)};
                    line-height: 19px;
                }
                @media (max-width: 480px) {
                    ${font.size(13)};
                    line-height: 23.41px;
                }
            }
            @media screen and (max-width: 480px) {
                text-align: center;
            }
        }

        .img-cont {
            margin-top: 0px;
            border-radius: 4px;
            border: 1px solid #b7b7b7;
            background: #fff;
            width: 36px;
            height: 34px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            @media screen and (max-width: 480px) {
                display: none;
            }
            img {
                height: 18px;
            }
        }
    }
`

export const Contt = styled.div`
    color: #ffffff;
    @media screen and (min-width: 1423px) {
        width: 100%;
        max-width: 1347px;
        margin: 0 auto;
    }

    .footer-container {
        display: flex;
        gap: 172px;
        @media (max-width: 1250px) {
            gap: 100px;
        }
        @media (max-width: 1000px) {
            gap: 50px;
        }
        @media (max-width: 480px) {
            flex-direction: column;
            gap: 6px;
        }
        .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 22px;
            color: #3f3f3f;
            .logo {
                height: 42px;
            }
            a {
                color: #0089bb;
                ${font.medium};
                ${font.size(14)};
                line-height: 25.1px;
                text-decoration: underline;
                @media (max-width: 820px) {
                    ${font.size(12)};
                    line-height: 19px;
                }
                @media (max-width: 480px) {
                    ${font.size(13)};
                    line-height: 23.41px;
                }
            }
            .first {
                ${font.medium};
                ${font.size(14)};
                line-height: 25.1px;
                @media (max-width: 820px) {
                    ${font.size(12)};
                    line-height: 19px;
                }
                @media (max-width: 480px) {
                    ${font.size(13)};
                    line-height: 23.41px;
                }
            }
            .second {
                ${font.medium};
                ${font.size(14)};
                line-height: 25.1px;
                @media (max-width: 820px) {
                    ${font.size(12)};
                    line-height: 19px;
                }
                @media (max-width: 480px) {
                    ${font.size(13)};
                    line-height: 23.41px;
                }
                a {
                    color: #0089bb;
                    ${font.medium};
                    ${font.size(14)};
                    line-height: 25.1px;
                    text-decoration: underline;
                    @media (max-width: 820px) {
                        ${font.size(12)};
                        line-height: 19px;
                    }
                    @media (max-width: 480px) {
                        ${font.size(13)};
                        line-height: 23.41px;
                    }
                }

                span {
                    text-decoration: underline;
                }
            }
        }
        .terms {
            .contacts {
                .contacts-heading {
                    color: #3f3f3f;
                    font-size: 24px;
                    ${font.bold};
                    line-height: 49px;
                }
                .contact-container {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-top: 14px;
                    .info {
                        color: #3f3f3f;
                        font-size: 15px;
                        ${font.medium};
                        line-height: 24px;
                    }
                    .img-cont {
                        margin-top: 0px;
                        border-radius: 4px;
                        border: 1px solid #e4e4e4;
                        background: #fff;
                        width: 36px;
                        height: 34px;
                        flex-shrink: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            height: 18px;
                        }
                    }
                }
            }
            ul {
                display: flex;
                flex-direction: column;
                gap: 25px;
                color: #3f3f3f;
                li {
                    ${font.size(16)};
                    line-height: 19px;
                    text-decoration: underline;
                    ${font.medium};
                    @media (max-width: 820px) {
                        ${font.size(12)};
                        line-height: 14.4px;
                    }
                    @media (max-width: 480px) {
                        ${font.size(13)};
                        line-height: 15.6px;
                    }
                }
            }
            div {
                margin-top: 27px;
                color: #3f3f3f;
                p {
                    ${font.size(16)};
                    line-height: 19px;
                    ${font.medium};
                    @media (max-width: 820px) {
                        ${font.size(12)};
                        line-height: 14.4px;
                    }
                    @media (max-width: 480px) {
                        ${font.size(13)};
                        line-height: 15.6px;
                    }
                }
                .apps {
                    margin-top: 15px;
                    display: flex;
                    gap: 9px;
                    a {
                        img {
                            @media (max-width: 480px) {
                                max-width: 130px;
                            }
                        }
                    }
                }
            }
        }
    }
`

export const ImageBar = styled.img`
    width: 100%;
    height: 12px;
    object-fit: cover;
    transform: scale(1.1);
`
