import HelloSign from 'hellosign-embedded'
import api from 'shared/utils/api'
import {
    EmbeddedSigningModal,
    ActionButton,
    ActionButtonModal,
    Cont,
    EmbeddedSigningCont,
} from './Styles'
import RenderUser from './renderUser'
import { useState } from 'react'
import ScreenLoader from '../ScreenLoader'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const defaultProp = {
    title: '',
    review: false,
    seller: [],
    onErrorEvent: () => {},
    onCloseEvent: () => {},
    onDocumentSignedEvent: () => {},
}

const propTypes = {
    title: PropTypes.string,
    review: PropTypes.bool,
    seller: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onErrorEvent: PropTypes.func,
    onCloseEvent: PropTypes.func,
    onDocumentSignedEvent: PropTypes.func,
}

const EmbeddedSigning = ({
    title,
    review,
    seller,
    onErrorEvent,
    onCloseEvent,
    onDocumentSignedEvent,
    ...rest
}) => {
    const [loading, setLoading] = useState(false)
    const [signatureId, setSignatureId] = useState(null)
    const [canSign, setCanSign] = useState(false)
    const [signers, setSigners] = useState([])
    const [info, setInfo] = useState(null)

    const currentUser = useSelector((state) => state.user)

    const client = new HelloSign()

    const openContract = async (signId = undefined, modal = () => {}) => {
        setLoading(true)
        try {
            const EmbeddedSignInURL = `customer/v3/contracts/${
                seller.contractId
            }/embedded-sign-info/${signId || signatureId}`
            const res = await api.get(EmbeddedSignInURL)
            setLoading(false)
            client.open(res.embeddedSigningUrl, {
                clientId: res.clientId,
                skipDomainVerification: true,
            })
        } catch (e) {
            setLoading(false)
            if (e?.error?.message.includes('has already signed')) {
                modal.open()
                initSigner()
            } else {
                console.error(e)
                onErrorEvent(e)
            }
        }
    }

    const fetchUserStatus = async (SignatureId) => {
        const SignerStatusURL = `Customer/v3/contracts/${seller.contractId}/signer-status/${SignatureId}`
        setLoading(true)
        try {
            const res = await api.get(SignerStatusURL)
            setLoading(false)
            return res
        } catch (e) {
            console.log(['fetchEmbeddedSignInUser', e])
            setLoading(false)
            return
        }
    }

    client.on('error', (error) => {
        onErrorEvent(error)
    })

    client.on('close', () => {
        setSignatureId('')
        onCloseEvent()
    })

    const handleModalOpen = (modal) => {
        const { signers } = seller

        if (
            signers.length === 1 &&
            signers[0].SignerEmailAddress?.toLowerCase() === currentUser.email?.toLowerCase()
        ) {
            const firstSigners = signers[0]
            setSignatureId(firstSigners?.SignatureId)
            openContract(firstSigners?.SignatureId, modal)
            return
        }

        modal.open()
        initSigner()
    }

    const handleModalClose = () => {
        setSigners([])
        setInfo(null)
        setSignatureId(null)
        setCanSign(false)
    }

    const handleCallback = (signer) => {
        const { SignatureId, SignerName, email, isPending, index } = signer
        const pendingIndex = signers.find((signer) => signer.isPending)

        if (
            index === pendingIndex.index &&
            isPending &&
            (email?.toLowerCase() === currentUser?.email?.toLowerCase() || email === null)
        ) {
            setInfo(null)
            setSignatureId(SignatureId)
            setCanSign(true)
            return
        }

        if (
            index === pendingIndex.index &&
            isPending &&
            email?.toLowerCase() !== currentUser.email?.toLowerCase()
        ) {
            setInfo(`${SignerName} will need to sign from their account`)
            setSignatureId(null)
            setCanSign(false)
            return
        }

        if (index !== pendingIndex.index && isPending) {
            setInfo(`${pendingIndex.SignerName} must sign the document first`)
            setSignatureId(null)
            setCanSign(false)
            return
        }
    }

    const handleSubmit = (modal) => {
        modal.close()
        openContract()
    }

    async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array)
        }
    }

    const initSigner = async () => {
        const signers = seller.signers
        let temp = []
        let setFalse = false
        let setTrue = true
        let index = 0
        await asyncForEach(signers, async (signer) => {
            const { currentStatus, signerEmailAddress } = await fetchUserStatus(signer.SignatureId)

            const isPending = currentStatus === 'awaiting_signature' ? true : false

            let email =
                signerEmailAddress === null || signerEmailAddress === undefined
                    ? currentUser.email
                    : signerEmailAddress

            const isChecked = () => {
                if (currentUser?.email?.toLowerCase() === email?.toLowerCase() && setTrue) {
                    setTrue = false
                    return true
                }
                return false
            }
            const userStatus = () => {
                if (isPending && !setFalse) {
                    setFalse = true
                    return 'Required'
                }
                if (!isPending) {
                    return 'Completed'
                }

                return 'Awaiting other signer'
            }
            temp.push({
                ...signer,
                index,
                isPending,
                email: email,
                isChecked: isChecked(),
                userStatus: userStatus(),
            })
            index++
        })
        setSigners(temp)
    }

    return (
        <EmbeddedSigningCont>
            <EmbeddedSigningModal
                clickOutside={false}
                withCloseIcon={true}
                width="631px"
                floting={true}
                backgroundColor="white"
                className="embedded-signing-modal"
                onCloseEvent={handleModalClose}
                {...rest}
                renderLink={(modal) =>
                    seller?.embeddedSigning &&
                    seller?.signers?.length > 0 && (
                        <ActionButton
                            isWorking={loading}
                            id={`fs_embedded_signing_${title}`}
                            variant="primary"
                            review={review}
                            onClick={() => handleModalOpen(modal)}
                        >
                            {title}
                        </ActionButton>
                    )
                }
                renderContent={(modal) => (
                    <Cont>
                        <ScreenLoader sL={loading} backgroundColor="white" full={false} />
                        <h1>Review and Sign:</h1>
                        <p>Sellers need to sign in order listed</p>
                        <br />
                        {info && <div className="embedded-signing-user-info">{info}</div>}
                        <div className="embedded-signing-user-cont">
                            {signers.map((signer) => {
                                return (
                                    <RenderUser
                                        signer={signer}
                                        key={signer.SignatureId}
                                        callback={(data) => handleCallback(data)}
                                        currentSignatureId={signatureId}
                                    />
                                )
                            })}
                        </div>
                        <ActionButtonModal
                            variant="primary"
                            id="fs_embedded_signing_sign_document"
                            disabled={!canSign}
                            onClick={() => canSign && handleSubmit(modal)}
                        >
                            Sign Document
                        </ActionButtonModal>
                    </Cont>
                )}
            />
        </EmbeddedSigningCont>
    )
}

EmbeddedSigning.defaultProp = defaultProp
EmbeddedSigning.propTypes = propTypes

export default EmbeddedSigning
