import { Link } from 'react-router-dom'
import { font } from 'shared/utils/styles'
import styled, { css } from 'styled-components'

export const MenuLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;
`

export const Container = styled.div`
    display: flex;
    gap: 18px;
    align-items: center;
`

export const IconContainer = styled.div`
    background-color: #f3f7f8;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
`

export const Icon = styled.img``

export const Text = styled.div`
    ${font.medium};
    font-size: 15px;
    color: rgb(82, 86, 89);
    margin-right: 43px;
    ${(props) =>
        !!props.danger &&
        props.danger &&
        css`
            color: #e74136;
        `}
`

export const ArrowContainer = styled.div`
    background-color: #f3f7f8;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
`
