import { DefaultCont, DefaultHeading, DefaultTagline } from './Styles'

const Default = () => {
    return (
        <DefaultCont>
            <DefaultHeading>You don’t have any pending action items.</DefaultHeading>
        </DefaultCont>
    )
}

export default Default
