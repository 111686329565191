import React, { Fragment, useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Cont, DropdownCont } from './Styles'
import { isVerticalScrollbarVisible } from 'shared/utils/browser'
import { useLocation } from 'react-router-dom'

const propTypes = {
    className: PropTypes.string,
    offset: PropTypes.number,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onCloseEvent: PropTypes.func,
    renderLink: PropTypes.func,
    renderContent: PropTypes.func.isRequired,
}

const defaultProps = {
    className: undefined,
    offset: 0,
    isOpen: undefined,
    onClose: () => {},
    onCloseEvent: () => {},
    renderLink: () => {},
}

export const Dropdown = ({ className, offset, onCloseEvent, renderLink, renderContent }) => {
    const { pathname } = useLocation()
    const [stateIsOpen, setStateOpen] = useState(false)
    const [positionX, setPositionX] = useState(0)
    const [positionY, setPositionY] = useState(0)
    const $contRef = useRef()
    const $renderContRef = useRef()

    const closeDropdown = () => {
        setStateOpen(false)
        onCloseEvent()
    }

    const handleToggel = useCallback(() => {
        stateIsOpen ? closeDropdown() : setStateOpen(true)
        handleResize()
    }, [stateIsOpen, setStateOpen, closeDropdown, handleResize])

    const handleResize = useCallback(() => {
        if (!$contRef.current) return
        const { x, y, width, height } = $contRef.current.firstElementChild.getBoundingClientRect()
        const onScrollOffeset = isVerticalScrollbarVisible() ? 0 : 15
        setPositionX(window.innerWidth - x - width / 2 - offset + onScrollOffeset)
        setPositionY(y + height + 20)
    }, [$contRef, setPositionX, setPositionY])

    const handleClickOutside = useCallback(
        (e) => {
            if (!$renderContRef.current) return
            const link = $contRef.current
            const isLinkClick = link.contains(e.target)
            const root = $renderContRef.current
            const isClickInside = root.contains(e.target)
            if (!isClickInside && !isLinkClick) {
                closeDropdown()
            }
        },
        [$renderContRef, $contRef, closeDropdown],
    )

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        window.addEventListener('mousedown', handleClickOutside)
        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('mousedown', handleClickOutside)
        }
    }, [pathname, offset])

    return (
        <DropdownCont ref={$contRef}>
            {renderLink({ open: () => handleToggel() })}
            {stateIsOpen && (
                <Cont className={className} x={positionX} y={positionY} ref={$renderContRef}>
                    {renderContent({ close: closeDropdown })}
                </Cont>
            )}
        </DropdownCont>
    )
}

Dropdown.propTypes = propTypes
Dropdown.defaultProps = defaultProps

export default Dropdown
