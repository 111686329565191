import { useSelector } from 'react-redux'
import { Step } from '../Styles'
import CheckIcon from 'shared/asstes/checkIcon.svg'

const Step3 = ({ close }) => {
    const user = useSelector((state) => state.user)
    const isAgent = user?.agent?.toLowerCase() === 'true'

    const heading = isAgent ? 'We’re excited to connect.' : 'We’re on it.'

    const body = isAgent
        ? 'Your info has been sent over to the Offerpad Home Loans team. We’ll be in touch soon.'
        : 'Thanks for the info. Someone from our Offerpad Home Loans team will be in touch soon! '

    return (
        <Step>
            <div className="success">
                <img src={CheckIcon} alt="" />
                <h2>{heading}</h2>
                <p>{body}</p>
                <button id="fs_loan_dashboard_modalSuccess" className="loan-button" onClick={close}>
                    OK
                </button>
            </div>
        </Step>
    )
}

export default Step3
