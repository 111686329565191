import { Fragment } from 'react'
import Check from '../assets/check-auth.svg'
import { StyledDiv, StyledLogo, SuccessDiv, TagLine } from 'Auth/shared/Styles'
import Logo from 'shared/asstes/logo.svg'
import { useNavigate } from 'react-router-dom'

const SuccessScreen = () => {
    const navigate = useNavigate()
    return (
        <Fragment>
            <StyledDiv>
                <StyledLogo src={Logo} alt="" />
                <TagLine>We can help you do literally (almost) everything.</TagLine>
            </StyledDiv>
            <br />
            <br />
            <br />
            <SuccessDiv width="800px" className="success-screen">
                <img src={Check} alt="" />
                <h1>Good news!</h1>
                <br />
                <p>
                    You're just one step away from unlocking all the features of our app!
                    <br />
                    <br />
                    Check your inbox for an email from Offerpad, click on “Confirm My Email Address”
                    and you’re all set!
                </p>
                <br />
                <br />
                <button id="fs_auth_register_success" onClick={() => navigate('/auth/login')}>
                    Ok
                </button>
            </SuccessDiv>
            <br />
            <br />
            <br />
        </Fragment>
    )
}

export default SuccessScreen
