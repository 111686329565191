import styled, { css, keyframes } from 'styled-components'
import { Button } from 'shared/components'
import { color, font } from 'shared/utils/styles'

import ArrowDownCOE from 'OnDemandActions/Sell/CodDateChange/shared/assets/arrow-down-coe.svg'

export const Group = styled.div``

export const Icons = styled.img`
    width: 55px;
    height: 55px;
`
export const ActionButton = styled(Button)`
    width: 100%;
    max-width: 305px;
    height: 62px;
    ${font.medium};
    ${font.size(18)};
    line-height: 21.6px;
    ${(props) =>
        !!props.prev &&
        css`
            background-color: white;
            border: 1px solid #bdbdbd;
            max-width: 129px;
            &:hover {
                background-color: white !important;
                opacity: 0.8;
            }
        `}
    ${(props) =>
        !!props.continue &&
        css`
            max-width: 191px;
        `}

    ${(props) =>
        !!props.width &&
        props.width &&
        css`
            max-width: ${props.width};
        `}
        @media (max-width: 480px) {
        margin: 0 auto;
    }
    ${(props) =>
        !!props.grayed &&
        props.grayed === 'true' &&
        css`
            background-color: #afb3bf;
            pointer-events: none;
            cursor: not-allowed;
            &:hover {
                background-color: #afb3bf !important;
            }
        `}
`
export const Heading = styled.div`
    ${font.black};
    ${font.size(25)};
    line-height: 30px;
    color: #000000;
    ${(props) =>
        !!props.color &&
        props.color &&
        css`
            color: ${props.color};
        `}
`
export const TagLine = styled.div`
    ${font.black};
    ${font.size(20)};
    line-height: 24px;
    color: #525659;
    width: 100%;
    max-width: 630px;
`
export const ExtendedGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 25px;
    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        gap: 0px;
        margin-bottom: 40px;
    }
`
export const Extdivider = styled.div`
    display: flex;
    flex-direction: row;
`
export const DescriptionText = styled.div`
    margin-top: 3px;
    ${font.medium};
    ${font.size(14)};
    line-height: 20px;
    color: #797979;
    width: 100%;
    max-width: 640px;
`
export const ExtSeperaor = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 13px;
`
export const Showinmid = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        img {
            width: 24px;
        }
        ${font.size(16)};
        line-height: 28px;
        ${font.medium};
        color: #363948;
    }
`
export const ExtendedCont = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 60px 39px;
    height: 100%;
    @media (max-width: 480px) {
        padding: 75px 40px 32px 40px;
    }
    p {
        ${font.size(16)};
        line-height: 26px;
        ${font.medium};
        color: #525659;
        margin-top: 11px;
    }
    span {
        ${font.size(14)};
        line-height: 16.8px;
        ${font.bold};
        color: #525659;
    }
    .select-wrapper {
        width: 246px;
        height: 55px;
        margin-top: 18px;
        select {
            padding: 19px 25px;
            width: 100%;
            height: 100%;
            ${font.size(14)};
            line-height: 17px;
            ${font.medium};
            cursor: pointer;
            border: 1px solid #afafaf;
            border-radius: 5px;
            -webkit-appearance: none;
            appearance: none;
            -moz-appearance: none;
            background-image: url(${ArrowDownCOE});
            background-repeat: no-repeat;
            background-position: calc(100% - 25px);
            background-size: 10px;
        }
    }
`
export const ExtTagLine = styled.span`
    ${font.medium};
    width: 100%;
    text-align: left;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    @media (max-width: 480px) {
        margin-top: 0px;
    }
`
export const ExtendedTagText = styled.p`
    margin-top: 11px;
    ${font.bold};
    ${font.size(20)};
    line-height: 26px;
    color: #797979;
    width: 100%;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    ${(props) =>
        !!props.color &&
        props.color &&
        css`
            color: ${props.color};
        `}
`
export const ExtGroupCont = styled.div`
    display: flex;
    gap: 3px;
    align-items: start;
    @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
    }
    .info {
        ${font.size(16)};
        line-height: 19.2px;
        ${font.black};
        color: #525659;
        span {
            ${font.size(16)};
            line-height: 19.2px;
            ${font.bold};
        }
    }
`
export const ButtonCont = styled.div`
    display: flex;
    gap: 22px;
    margin-top: 28px;
    @media (max-width: 480px) {
        margin: 0;
        margin-top: auto;
    }
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    ${(props) =>
        !!props.ml &&
        props.ml &&
        css`
            margin-left: ${props.ml}px;
        `}
`
export const ExtendedTagLine = styled.div`
    margin-top: 11px;
    ${font.black};
    ${font.size(20)};
    line-height: 26px;
    color: #525659;
    width: 100%;
    max-width: 813px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    ${(props) =>
        !!props.color &&
        props.color &&
        css`
            color: ${props.color};
        `}
`
export const SelectDropDown = styled.select`
    width: 246px;
    height: 40px;
    left: 40px;
    top: 204px;
    padding-left: 16px;
    background: #ffffff;
    border: 1px solid #afafaf;
    border-radius: 5px;
    @media (max-width: 480px) {
        width: 100%;
    }
`
export const Extendedsubtext = styled.div`
    ${font.black};
    font-size: 17px;
    line-height: 20px;
    color: #525659;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const WrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    ${(props) =>
        !!props.ml &&
        props.ml &&
        css`
            margin-left: ${props.ml}px;
        `}
`
export const ThumbIcon = styled.img`
    width: 31px;
    height: 31px;
`
export const ExtShowinmid = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width: 480px) {
        align-items: flex-start;
    }
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    p {
        ${font.size(17)};
        line-height: 26px;
        ${font.medium};
        text-align: center;
        margin-top: 33px;
        span {
            color: #1b6775;
            ${font.bold};
        }
    }

    .bellhop {
        background: rgba(231, 240, 241, 0.5);
        border: 1px solid #dbe5e7;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 35px 56px;
        margin-top: 30px;
        @media (max-width: 480px) {
            padding: 35px 20px;
        }
        h1 {
            ${font.black};
            ${font.size(22)};
            line-height: 26.4px;
            color: #000000;
            text-align: center;
        }
        p {
            text-align: center;
            ${font.medium};
            ${font.size(16)};
            line-height: 20px;
            margin-top: 12px;
            color: #1b6775;
        }
        h2 {
            ${font.bold};
            ${font.size(16)};
            line-height: 19px;
            color: #000000;
            text-align: center;
            margin-top: 8px;
        }
        img {
            margin-top: 22px;
            width: 76px;
            @media (max-width: 480px) {
                width: 101px;
            }
        }
        .bellhop-info-cont {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 67px;
            margin-top: 30px;
            @media (max-width: 820px) {
                gap: 25px;
            }
            div {
                display: flex;
                align-items: center;
                gap: 9px;
                img {
                    width: 11px;
                    margin-top: 0px;
                }
                a {
                    ${font.medium};
                    ${font.size(12)}
                    line-height: 14px;
                    text-decoration-line: underline;
                    color: #ec7625;
                }
            }
        }
    }
`
export const Footer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 18px 30px;
    margin-top: 32px;
    @media (max-width: 450px) {
        grid-template-columns: 1fr;
    }
`
export const FooterImg = styled.img`
    margin-right: 5px;
    width: 14.17px;
    height: 14.17px;
`
export const FooterDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`

export const FooterAnchor = styled.a`
    ${font.medium};
    ${font.size(12)};
    line-height: 14.4px;
    color: #ec7625;
    text-decoration: underline;
    white-space: nowrap;
`
export const ExtendedCard = styled.div`
    width: 100%;
    background: rgba(231, 240, 241, 0.5);
    border: 1px solid #dbe5e7;
    border-radius: 10px;
    padding: 35px 20px 35px 20px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const ExtendedHeadine = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    ${font.bold};
    ${font.size(20)};
    line-height: 19.2px;
    color: #000000;
    width: 100%;
    max-width: 813px;
    text-align: center;
    @media (max-width: 480px) {
        text-align: left;
        align-items: flex-start;
    }
`
export const ExtDescriptionText = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #525659;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    text-align: center;
    @media (max-width: 480px) {
        text-align: left;
    }
`
export const Companylogo = styled.img`
    width: auto;
    width: 76.11px;
    height: 18px;
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ExtendedTagInfo = styled.div`
    margin-top: 28px;
    ${font.bold};
    ${font.size(20)};
    line-height: 19.2px;
    color: #000000;
    width: 100%;
    max-width: 813px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    text-align: center;
    @media (max-width: 480px) {
        text-align: left;
        align-items: flex-start;
    }
`
export const SurveyCont = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 60px 39px;
    @media (max-width: 480px) {
        padding: 75px 40px 32px 40px;
        justify-content: center;
    }
    @media (max-width: 380px) {
        height: auto !important;
        margin-top: 0px;
    }
    ${(props) =>
        !!props.height &&
        css`
            height: ${props.height};
        `}
`
export const UserInfoCont = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    p {
        ${font.size(17)};
        line-height: 26px;
        ${font.medium};
        color: #525659;
    }
`
export const DynamicIcons = styled.img`
    ${(props) =>
        !!props.height &&
        props.height &&
        css`
            height: ${props.height}px;
        `}
    ${(props) =>
        !!props.width &&
        props.width &&
        css`
            width: ${props.width}px;
        `}
`
export const ReqWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const ExtRequestedLine = styled.div`
    ${font.medium};
    ${font.size(16)};
    line-height: 28px;
    color: #525659;
    width: 100%;
    max-width: 630px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const DividerModal = styled.hr`
    width: 100%;
    border: 1px solid #e6e6e6;
    transform: rotate(-180deg);
`
export const PointImage = styled.img`
    width: 27px;
    height: 27px;
`
export const ExtendedDescriptionText = styled.div`
    ${font.medium};
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
`
export const ExtOptionCont = styled.div`
    margin-top: 36px;
    display: grid;
    gap: 25px;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`
export const OptionsCont = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
    ${font.medium};
    ${font.size(16)};
    line-height: 25px;
    color: #525659;
`
export const Option = styled.input`
    -webkit-appearance: radio;
    accent-color: ${color.primary} !important;
    height: 20px;
    width: 20px;
    cursor: pointer;
`
export const ExtendedTag = styled.div`
    margin-top: 28px;
    ${font.medium};
    ${font.size(20)};
    line-height: 19.2px;
    color: #797979;
    width: 100%;
    max-width: 813px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${(props) =>
        !!props.mt &&
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`

export const DefaultE = styled.div`
    padding: 50px 50px 0px 50px;
    @media (max-width: 480px) {
        padding: 0px;
        margin-top: 40px;
    }
    h2 {
        text-align: center;
        ${font.black};
        @media (max-width: 480px) {
            margin-top: 150px;
        }
    }
    p {
        margin-top: 18px;
        text-align: center;
    }
    .info-cont {
        background: #f7fcfe;
        border: 1px solid #dbe5e7;
        border-radius: 10px;
        padding: 18px 56px;
        @media (max-width: 480px) {
            padding: 0px;
            margin: 0px;
        }
        h3 {
            text-align: center;
            ${font.size(16)}
            ${font.bold};
            line-height: 19.2px;
            @media (max-width: 480px) {
                text-align: start;
            }
        }
        .contacts {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 19px;
            @media (max-width: 480px) {
                flex-direction: column;
                gap: 21px;
                padding: 0px 20px 20px 20px;
                justify-content: center;
                align-items: center;
            }
            .c-info {
                display: flex;
                align-items: center;
                gap: 5px;
                a {
                    text-decoration-line: underline;
                    color: #ec7625;
                    ${font.size(14)}
                    ${font.medium};
                    line-height: 16.8px;
                }
            }
        }
        .not-available-info {
            display: flex;
            align-items: flex-start;
            text-align: center;
            gap: 6px;
            @media (max-width: 480px) {
                margin: 0px;
                padding: 20px 20px 0px 20px;
                text-align: left;
            }
            img {
                width: 24px;
                @media (max-width: 480px) {
                    margin-top: 3px;
                }
            }
            ${font.size(14)};
            line-height: 16.8px;
            ${font.bold};
            color: #525658;
        }
    }
`
