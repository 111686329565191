import { useDispatch } from 'react-redux'
import { updateTracker, updateLoading } from 'features/sell/tracker'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'

import useTodos from './todos'
import useEvents from './events'

const useTracker = () => {
    const dispatch = useDispatch()
    const todo = useTodos()
    const event = useEvents()

    const makeTracker = (trackerData, currentTransaction, events) => {
        const ev = event.getEventUsingEventIdentifier(events, 'PreliminaryOfferExpiredEvent')
        const transactionStatus = currentTransaction.transactionStatus

        const currentTransactionStatusIndex = trackerData.findIndex(
            (d) => d.statusIdentifier === currentTransaction.transactionStatus,
        )

        let isCompleted = true
        let activeIndex = -1

        const full = trackerData.reduce((acc, data, index) => {
            const tracker = {
                ...data,
                completed: isCompleted,
                active:
                    currentTransactionStatusIndex !== -1
                        ? currentTransactionStatusIndex === index
                        : true,
            }
            acc.push(tracker)

            if (tracker.active) {
                activeIndex = index
                isCompleted = false
            }
            return acc
        }, [])

        let short
        const totalItems = full.length

        if (totalItems <= 3) {
            short = full
        } else if (activeIndex >= 1 && activeIndex <= totalItems - 2) {
            short = full.slice(activeIndex - 1, activeIndex + 2)
        } else if (activeIndex === 0) {
            short = full.slice(activeIndex, activeIndex + 3)
        } else {
            short = full.slice(totalItems - 3, totalItems)
        }

        let whatsNext = full.find((tracker) => tracker.active)

        let whatsNextDetails = !isEmpty(whatsNext) ? whatsNext?.whatsNextDetail : null

        if (!isEmpty(whatsNextDetails)) {
            if (transactionStatus === 'InspectionInReview') {
                if (!isEmpty(todo.getTodoUsingIdentifier('CashOfferTransactionAddendumCredit'))) {
                    whatsNextDetails =
                        'We have completed our review of the in-home inspection and are requesting a credit.'
                } else if (
                    !isEmpty(
                        todo.getTodoUsingIdentifier('CashOfferTransactionAddendumCreditAndRepair'),
                    )
                ) {
                    whatsNextDetails =
                        'We have completed our review of the in-home inspection and are requesting a credit or repairs to be made.'
                }
            }

            if (transactionStatus === 'PreliminaryOfferExpired') {
                if (!isEmpty(ev)) {
                    whatsNextDetails = whatsNextDetails.replace(
                        '[OfferExpirationEvent]',
                        moment(ev.eventDate).format('MMMM DD, YYYY'),
                    )
                } else {
                    whatsNextDetails = whatsNextDetails.replace(
                        '[OfferExpirationEvent]',
                        moment(currentTransaction?.offerExpirationDateTime)
                            .tz(currentTransaction?.property?.timeZoneId || 'America/Phoenix')
                            .format('MMMM DD, YYYY'),
                    )
                }
            }
        }

        return {
            full,
            short,
            whatsNext: whatsNextDetails,
        }
    }

    const makeTrackerArray = async () => {
        dispatch(updateLoading(true))
        let response = window?.currentTransaction
        const transactionInfo = window?.transactionInfo
        let events = window?.currentEvents

        if (isEmpty(events)) {
            events = await event.fetch()
        }

        const res = makeTracker(transactionInfo, response, events)
        dispatch(updateTracker(res))
        dispatch(updateLoading(false))
        return res
    }

    return {
        get: makeTrackerArray,
    }
}

export default useTracker
