import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DefaultDashboard, PropertyList } from 'shared/components'
import useOverview from 'shared/hooks/sell/overview'
import { isEmpty } from 'lodash'

import dashboard from 'shared/constants/dashboard'

const Dashboard = () => {
    const user = useSelector((state) => state.user)
    const navigate = useNavigate()
    const { init } = useOverview()
    const { sell } = dashboard

    const { myTransactions } = useSelector((state) => state.myTransaction)

    const dataToShow = user?.agent?.toLowerCase() === 'true' ? sell.agent : sell.customer

    const handleClick = async (id) => {
        localStorage.currentTransactionId = id
        window.confetti = false
        navigate('/sell/overview')
        await init(true)
    }

    return !isEmpty(myTransactions) ? (
        <PropertyList transactions={myTransactions} handlePropertyClick={handleClick} />
    ) : (
        <DefaultDashboard {...dataToShow} />
    )
}

export default Dashboard
