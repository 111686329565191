import { font } from 'shared/utils/styles'
import styled from 'styled-components'
import Button from '../Button'

export const Container = styled.div`
    width: 600px;
    height: 634px;
    background-color: rgba(255, 209, 177, 0.22);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 80px;
    margin-bottom: 40px;
    margin-top: 20px;
    @media screen and (max-width: 480px) {
        width: 100%;
        padding: 20px;
        margin-bottom: 0px;
    }
    img {
        height: 80px;
        margin-bottom: 20px;
    }
    h1 {
        text-align: center;
        color: #525657;
        font-family: ${font.black};
        font-size: 20px;
        margin-bottom: 10px;
    }
    p {
        text-align: center;
        color: #525657;
        font-family: ${font.bold};
        line-height: 25px;
        font-weight: 20px;
    }
`

export const ActionButton = styled(Button)`
    width: auto;
    height: 62px;
    padding: 0px 20px;
    background: #ffffff;
    border: 1px solid #bdbdbd;
    color: #525659;
    border-radius: 4px;
    font-size: 18px;
    line-height: 21.6px;
    ${font.bold};
    cursor: pointer;
    margin-top: 30px;
`
