import { createSlice } from '@reduxjs/toolkit'

const singleTransactionSlice = createSlice({
    name: 'singleTransaction',
    initialState: {
        transaction: null,
        loading: false,
    },
    reducers: {
        updateSingleTransaction: (state, action) => {
            const updatedData = { ...action.payload }
            return {
                ...state,
                transaction: updatedData,
            }
        },
        updateLoading: (state, action) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const { updateSingleTransaction, updateLoading } = singleTransactionSlice.actions

export default singleTransactionSlice.reducer
