import styled, { css } from 'styled-components'
import { color, font, mixin, fontSize, padding, borderRadius } from 'shared/utils/styles'
import BtnSpinner from './BtnSpinner'

export const StyledButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    line-height: 1.5;
    white-space: nowrap;
    transition: all 0.1s;
    appearance: none;
    ${mixin.clickable}
    ${(props) => buttonSize[props.size]}
  ${(props) => buttonVariants[props.variant]}
  &:disabled {
        opacity: 0.6;
        cursor: default;
    }
`

const colored = css`
    color: #fff;
    background: ${(props) => color[props.variant]};
    ${font.medium}
    &:not(:disabled) {
        &:hover {
            background: ${(props) => mixin.lighten(color[props.variant], 0.15)};
        }
        &:active {
            background: ${(props) => mixin.darken(color[props.variant], 0.1)};
        }
        ${(props) =>
            props.isActive &&
            css`
                background: ${mixin.darken(color[props.variant], 0.1)} !important;
            `}
    }
`

const secondaryAndEmptyShared = css`
    color: ${color.textDark};
    ${font.regular}
    &:not(:disabled) {
        &:hover {
            background: ${color.backgroundLight};
        }
        &:active {
            color: ${color.primary};
            background: ${color.backgroundLightPrimary};
        }
        ${(props) =>
            props.isActive &&
            css`
                color: ${color.primary};
                background: ${color.backgroundLightPrimary} !important;
            `}
    }
`

const buttonVariants = {
    primary: colored,
    success: colored,
    danger: colored,
    warning: colored,
    secondary: css`
        background: ${color.secondary};
        ${secondaryAndEmptyShared};
    `,
    empty: css`
        background: #fff;
        ${secondaryAndEmptyShared};
    `,
}

const buttonSize = {
    sm: css`
        ${fontSize.sm};
        ${padding.sm};
        ${borderRadius.sm};
        #spinner {
            display: inline-block;
            height: 13px;
            width: 13px;
        }
    `,
    normal: css`
        ${fontSize.normal};
        ${padding.normal};
        ${borderRadius.normal};
        #spinner {
            display: inline-block;
            height: 15px;
            width: 15px;
        }
    `,
    lg: css`
        ${fontSize.lg};
        ${padding.lg};
        ${borderRadius.lg};
        #spinner {
            display: inline-block;
            height: 16px;
            width: 16px;
        }
    `,
}

export const StyledSpinner = styled(BtnSpinner)`
    position: relative;
    top: 3px;
`

export const Text = styled.div`
    padding-right: ${(props) => (props.withPadding ? 7 : 0)}px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`
