import { Cont } from './Styles'
import { useWizard } from 'react-use-wizard'
import { useEffect, useState } from 'react'
import { Radio } from 'shared/components'

const Step2 = ({ data, setData, es }) => {
    const { nextStep, goToStep, previousStep } = useWizard()
    const agent = window.currentTransaction?.isListedByAgent
    const [extendedStay, setExtendedStay] = useState(
        data.extendedStay !== '' ? data.extendedStay : '',
    )
    const [extendedStayDays, setExtendedStayDays] = useState(
        data.extendedStayDays !== '' ? data.extendedStayDays : '',
    )
    const [isDisabled, setIsDisabled] = useState(false)

    const maxDays =
        es.extendedStayDayEligibleDays > es.currentExtendedStayDays
            ? es.extendedStayDayEligibleDays
            : es.currentExtendedStayDays

    const array = []
    for (let i = 0; i < maxDays; i++) {
        array.push(i + 1)
    }

    const handleClick = () => {
        if (data.extendedStay === false) {
            goToStep(2)
        }
        nextStep()
    }

    const checkIfDisaled = () => {
        if (data.extendedStay === false) {
            return false
        }
        if (data.extendedStay === '') {
            return true
        }
        if (data.extendedStayDays === '') {
            return true
        }
        return false
    }

    useEffect(() => {
        const e = { extendedStay: extendedStay }
        const d = { extendedStayDays: extendedStayDays }
        if (extendedStay) {
            setData({ ...data, ...e, ...d })
        } else {
            setData({ ...data, ...e })
        }
    }, [extendedStay, extendedStayDays])

    useEffect(() => {
        setIsDisabled(checkIfDisaled())
    }, [data])

    return (
        <Cont>
            <h1>Should we add our free extended stay benefit?</h1>
            {agent !== true ? (
                <p>
                    One of the most popular Offerpad benefits is our extended stay, which allows you
                    to stay in your home for up to {es.extendedStayDayEligibleDays} days past
                    closing. Would you like to add our Extended Stay?
                </p>
            ) : (
                <p>
                    One of the most popular Offerpad benefits is our extended stay, which allows
                    your seller to stay in the home for up to {es.extendedStayDayEligibleDays}-days
                    past closing. Would you like to add our Extended Stay?
                </p>
            )}
            <div className="opt-cont">
                <div>
                    <Radio
                        name="free-extended-stay"
                        onChange={() => setExtendedStay(true)}
                        defaultChecked={extendedStay}
                    />
                    <label htmlFor="checkbox">Yes, please add!</label>
                </div>
                {extendedStay && (
                    <div className="select-extended-date">
                        {agent !== true ? (
                            <span>How many extra days do you need?</span>
                        ) : (
                            <span>How many extra days does your seller need?</span>
                        )}
                        <div className="select-wrapper">
                            <select
                                onChange={(e) => setExtendedStayDays(e.target.value)}
                                value={extendedStayDays}
                            >
                                <option value="">Select days</option>
                                {array.length &&
                                    array.map((arr) => (
                                        <option key={arr} value={arr}>
                                            {arr} {arr > 1 ? 'days' : 'day'}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                )}
                <div>
                    <Radio
                        name="free-extended-stay"
                        defaultChecked={extendedStay === false ? true : false}
                        onChange={() => setExtendedStay(false)}
                    />
                    <label htmlFor="checkbox">Not needed</label>
                </div>
            </div>
            <div className="btn-cont">
                <button id="fs_sell_cod_dateChange_na_previous2" onClick={previousStep}>
                    Back
                </button>
                <button
                    id="fs_sell_cod__dateChange_na_next2"
                    className="next"
                    onClick={handleClick}
                    disabled={isDisabled}
                >
                    Next
                </button>
            </div>
        </Cont>
    )
}

export default Step2
