import { useSelector } from 'react-redux'
import { Step } from '../Styles'
import { useWizard } from 'react-use-wizard'

const Step1 = ({ close }) => {
    const { nextStep, goToStep } = useWizard()
    const user = useSelector((state) => state.user)

    const handleClick = () => {
        user?.agent?.toLowerCase() === 'true' ? goToStep(2) : nextStep()
    }

    return (
        <Step>
            <h2>
                Affiliated Business
                <br />
                Arrangement Disclosure
            </h2>
            <div className="info">
                This is to give you notice that Offerpad Holdings LLC (“Offerpad”) has a business
                relationship with Offerpad Brokerage LLC, Offerpad Brokerage CA Inc., and Offerpad
                Brokerage “FL” LLC (“Offerpad Brokerage”), Offerpad Mortgage, LLC and Offerpad
                Mortgage, Inc. d/b/a Offerpad Home Loans (“OPHL”), and First American Title Company
                (“First American”), in that Offerpad wholly owns Offerpad Brokerage and OPHL, and
                First American has a minority interest in Offerpad’s parent company. Because of
                these relationships, using any one of these companies may provide Offerpad, Offerpad
                Brokerage, OPHL, or First American or any of their principals and affiliates a
                financial or other benefit.
                <br />
                <br /> Set forth below are the types of settlement services offered by these
                companies and the estimated charge or range of charges for such settlement services.
                You are NOT required to use any of these companies as a condition for obtaining real
                estate brokerage services, mortgage brokerage services, title insurance or selling
                or purchasing a home with Offerpad. <br />
                <br /> THERE ARE FREQUENTLY OTHER SETTLEMENT SERVICE PROVIDERS AVAILABLE WITH
                SIMILAR SERVICES. YOU ARE FREE TO SHOP AROUND TO DETERMINE YOU ARE RECEIVING THE
                BEST SERVICES AND RATE FOR THESE SERVICES.
                <br />
                <br /> <b>Offerpad Brokerage</b>
                <br /> Real Estate Commissions <br />
                <br /> <b>Range of Charges</b>
                <br /> 0-6% of the purchase price
                <br />
                <br /> <b>OPHL</b> <br />
                Mortgage Broker and Lending Services <br />
                <br /> <b>Range of Charges</b>
                <br /> 0 – 4% of the loan amount depending on the rate you select. OPHL does not
                charge any application fees. If you choose to buy down your interest rate, it will
                result in a discount points fee. You will be charged certain third-party fees for an
                appraisal, credit reports and additional closing costs by the escrow / title
                company, closing attorney and lender. OPHL and/or the applicable lender will provide
                you with a Loan Estimate detailing credit costs and loan terms, including closing
                costs.
                <br />
                <br /> <b>First American Title Company</b> <br />
                Title insurance
                <br />
                <br /> Range of Charges Premium for title insurance is based upon the amount of
                insurance, the coverage requested, and other risk factors identified within the
                state’s filed, promulgated and/or published rates. For a quote specific to your
                transaction, contact First American Title Company.
                <br />
                <br />
                <b>ACKNOWLEDGMENT</b>
                <br />
                <br /> By clicking “Accept,” I/we acknowledge that I/we have read this disclosure
                form and understand that Offerpad is referring me/us to purchase the above-described
                settlement service(s) and may receive a financial or other benefit as the result of
                this referral.
            </div>
            <div className="btn-cont">
                <button id="fs_loan_dashboard_modalDecline" onClick={close}>
                    Decline
                </button>
                <button id="fs_loan_dashboard_modalAccept" className="next" onClick={handleClick}>
                    Accept
                </button>
            </div>
        </Step>
    )
}

export default Step1
