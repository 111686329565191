import { useEffect, useState } from 'react'
import NoAddendum from './NoAddendum'
import PendingAddendum from './PendingAddendum'
import SignedAddendum from './SignedAddendum'
import useCoe from 'shared/hooks/sell/coe'
import Default from './default'
import { useSelector } from 'react-redux'

const CodDateChange = ({ close = () => {}, extendedStay = {} }) => {
    const [coeD, setCoeD] = useState([])
    const coeData = useCoe()

    const transcation = useSelector((state) => state.singleTransaction.transaction)

    const comparisonRoleArray = [
        'OfferRequested',
        'OfferReady',
        'PurchaseAgreementSent',
        'RenewalRequested',
        'OfferExpired',
        'Lost',
    ]

    const preliminaryComparissionArray = [
        'PreliminaryOfferExpired',
        'PreliminaryOfferReady',
        'PreliminaryOfferRequested',
        'InspectionScheduled',
        'InspectionInReview',
    ]

    const canApplyFreeCoe = transcation?.isPreContractInspection
        ? !preliminaryComparissionArray.includes(transcation.transactionStatus)
        : !comparisonRoleArray.includes(transcation.transactionStatus)

    useEffect(() => {
        const init = async () => {
            const res = await coeData.fetch()
            setCoeD(res)
        }
        init()
    }, [])

    if (!canApplyFreeCoe) {
        return <Default es={extendedStay} />
    }

    if (
        extendedStay?.extendedStayStatus === 'NoAddendum' ||
        extendedStay?.extendedStayStatus === 'Canceled'
    ) {
        return <NoAddendum close={close} extendedStay={extendedStay} coe={coeD} es={extendedStay} />
    }

    if (extendedStay?.extendedStayStatus === 'PendingAddendum') {
        return (
            <PendingAddendum
                close={close}
                extendedStay={extendedStay}
                coe={coeD}
                es={extendedStay}
            />
        )
    }

    if (
        extendedStay?.extendedStayStatus === 'SignedAddendum' ||
        extendedStay?.extendedStayStatus === 'Canceling'
    ) {
        return (
            <SignedAddendum
                close={close}
                extendedStay={extendedStay}
                coe={coeD}
                es={extendedStay}
            />
        )
    }
}

export default CodDateChange
