import { Fragment, useState } from 'react'
import toast from 'shared/utils/toast'
import { is } from 'shared/utils/validation'
import Modal from '../Modal'
import ScreenLoader from '../ScreenLoader'
import api from 'shared/utils/api'
import { ActionButton, Container, Heading, Image, Paragraph } from './Styles'
import CheckWithCircle from 'shared/asstes/check_with_circle.svg'
import useDocuments from 'shared/hooks/sell/documents'
import { uniqueId } from 'lodash'

const UploadDocuments = ({ todo, renderContent, onMarkedTodoCompleteEvent }) => {
    const { fetch } = useDocuments()

    const buttonId = uniqueId('uploadDoc-')

    const [open, setOpen] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [success, setSuccess] = useState(false)

    const browserDocument = () => {
        const input = document.getElementById(buttonId)
        input.value = ''
        input.click()
    }

    const handleChange = (e) => {
        const { target } = e
        const documentId = target.getAttribute('data-document-id')
        const file = target.files[0]
        if (!is.validDocument(file.name)) {
            toast.error('Invalid file type. Supported file types are .doc, .docx and .pdf')
            return
        }
        if (!is.withInfileSize(file.size, 10)) {
            toast.error(
                'File size is more than 10 MB. Please select file within or equals to 10 MB.',
            )
            return
        }
        uploadDocument(file, documentId)
        setOpen(true)
    }

    const uploadDocument = async (file, documentId) => {
        try {
            setUploading(true)
            await api.post(
                'customer/v3/documents/add-blob',
                {
                    transactionId: window?.currentTransaction?.transactionId,
                    file,
                    documentId,
                },
                { 'Content-Type': 'multipart/form-data' },
            )
            setUploading(false)
            setSuccess(true)
        } catch (e) {
            if (e?.error?.message) {
                toast.error(e?.error?.message)
            } else {
                toast.error('Something went wrong. Unable to upload document.')
            }
            setOpen(false)
            setUploading(false)
            setSuccess(false)
        }
    }

    const handleClick = () => {
        setOpen(false)
        setUploading(false)
        setSuccess(false)
        fetch()
        onMarkedTodoCompleteEvent()
    }

    return (
        <Fragment>
            <input
                type="file"
                style={{ display: 'none' }}
                id={buttonId}
                accept=".doc,.docx,.pdf"
                data-document-id={todo?.metadata?.documentId}
                onChange={handleChange}
            />
            {renderContent(browserDocument)}
            <Modal
                showOnLoad={open}
                floting
                width="500px"
                withCloseIcon={false}
                clickOutside={false}
                backgroundColor="white"
                renderContent={() => (
                    <Container>
                        <ScreenLoader sL={uploading} loadingText={'Uploading please wait...'} />
                        {success && (
                            <Fragment>
                                <Image src={CheckWithCircle} alt="" />
                                <Heading>Upload Complete!</Heading>
                                <Paragraph>
                                    You can review your documents in the documents folder.
                                </Paragraph>
                                <ActionButton variant="empty" onClick={handleClick}>
                                    OK
                                </ActionButton>
                            </Fragment>
                        )}
                    </Container>
                )}
            />
        </Fragment>
    )
}

export default UploadDocuments
