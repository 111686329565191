import styled, { css, keyframes } from 'styled-components'
import { color, font, mixin } from 'shared/utils/styles'
import { Button } from 'shared/components'
import { Link } from 'react-router-dom'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

export const TodoCard = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    flex: 1;
    height: auto;
    padding: 0px 10px;
    position: relative;
    @media (max-width: 750px) {
        width: 100% !important;
    }
    @media (max-width: 480px) {
        padding: 0 !important;
    }
`

export const TodoHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const TodoHeadCont = styled.div`
    position: relative;
    justify-content: start;
    align-items: center;
`

export const TodoHeading = styled.span`
    ${font.size(25)};
    line-height: 30px;
    color: #000000;
    ${font.black};
    @media (max-width: 480px) {
        ${font.size(20)};
        line-height: 24px;
    }
`
export const TodoMobileCont = styled.div`
    /* animation-name: ${slideLeft};
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-duration: 0.5s;
    animation-delay: ${(props) => props.animationDelay}s; */
    @media (max-width: 480px) {
        background-color: #f0f8fb;
        padding: 20px 29px;
        margin-top: 15px;
        border-radius: 10px;
        border: 1px solid #dbe5e7;
    }
`

export const TodoInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-top: 22px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #d6d6d6;
    padding: 16px 24px;
    transition: all 0.3s;
    ${(props) =>
        !!props.mt &&
        css`
            margin-top: ${props.mt}px !important;
        `}
    @media (max-width: 1000px) {
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
    }
    @media (max-width: 480px) {
        margin-top: 0 !important;
        padding: 0px;
        background-color: transparent;
        border: none;
        ${(props) =>
            !!props.last &&
            css`
                border-top: 1px solid #e6e6e6;
                margin-top: 27px !important;
                padding-top: 27px;
                border-radius: 0px;
            `}
    }

    a {
        margin: 0px 0px 0px auto;
        @media (max-width: 480px) {
            margin: 0px;
        }
    }
`

export const TodoInfoCont = styled.div``

export const TodoInfoImage = styled.img`
    position: absolute;
    right: -100px;
    top: -25px;
    @media (max-width: 480px) {
        right: -90px;
    }
`

export const TodoInfo = styled.span`
    color: #525659;
    ${font.size(17)};
    ${font.black};
    line-height: 20.4px;
`

export const TodoTip = styled.div`
    color: ${color.trackerCurrentStatusColor};
    ${font.size(14)};
    line-height: 16.8px;
    margin-top: 5px;
    ${font.medium};
    span {
        color: #1b6775;
        ${font.black};
    }
`

export const ActionButton = styled(Button)`
    height: fit-content;
    padding: 10px 17px;
    border-radius: 3px;
    ${font.size(13)};
    ${font.medium};
    width: 168px;
    margin: 0px 0px 0px auto;
    @media (max-width: 650px) {
        height: 47px;
        margin: 0px;
    }
`

export const TodoAnchor = styled(Link)`
    color: ${color.primary};
    text-decoration: underline;
    margin-top: 15px;
    ${font.size(14)};
    ${font.medium};
`
export const ModalCont = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 25px;
`

export const ModalHeading = styled.div`
    ${font.black};
    ${font.size(27)};
    color: #363c4f;
`

export const ModalBody = styled.div`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    max-height: 680px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 10px;
`

export const CardCont = styled.div`
    position: relative;
`

export const Card = styled.div`
    width: 185px;
    height: 210px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: end;
    justify-content: end;
    border-radius: 10px;
    padding: 15px;
    ${(props) =>
        !!props.backgroundImage &&
        css`
            background-image: url(${props.backgroundImage});
            transition: 0.3s ease-in-out;
        `}
    gap:10px;
    ${(props) =>
        !!props.disabled &&
        props.disabled &&
        css`
            opacity: 0.5;
        `}
`

export const CheckImgIcon = styled.img`
    position: absolute;
    top: -10px;
    right: -10px;
    height: 40px;
`

export const CardActionButton = styled(Button)`
    transition: 0.2s ease-in-out;
    ${(props) =>
        !!props.primary &&
        css`
            background-color: ${color.primary};
        `}
    &:hover {
        transform: scale(1.1);
        background-color: #f4f5f7 !important;
        ${(props) =>
            !!props.primary &&
            `
        background-color:${color.primary} !important;
    `}
    }
`

export const CardActionButtonImg = styled.img``

export const ModalFooter = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px;
    gap: 20px;
`

export const InputWrapper = styled.div`
    width: 133px;
    height: 61px;
    position: relative;
    overflow: hidden;
    ${mixin.clickable};
    background-color: white;
    border: 1px solid #bdbdbd;
    border-radius: 4.8px;
    margin-top: 16px;
`

export const InputText = styled.span`
    color: #525659;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

export const Input = styled.input`
    width: 100%;
    height: 100%;
    ${mixin.clickable};
    opacity: 0;
`

export const ActionUploadButton = styled(Button)`
    width: 190px;
    margin-top: 15px;
    ${font.size(14)};
    padding: 20px 34px;
`

export const DefaultCont = styled.div`
    width: 100%;
    height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 17px;
    @media (max-width: 480px) {
        background: #f0f8fb;
        border: 1px solid #dbe5e7;
        border-radius: 10px;
        padding: 10px 10px;
        margin-top: 25px;
        gap: 12px;
    }
`

export const DefaultHeading = styled.div`
    ${font.size(20)};
    line-height: 24px;
    text-align: center;
    ${font.bold}
    color: #525659;
    @media (max-width: 480px) {
        ${font.size(18)};
        line-height: 21.6px;
    }
`

export const DefaultTagline = styled.div`
    ${font.size(14)};
    line-height: 22px;
    text-align: center;
    ${font.medium}
    color: #868686;
`
