import { ChangePasswordCont, Heading, FormCont, ActionButton } from './Styles'
import { Form, PageContainer } from 'shared/components'
import toast from 'shared/utils/toast'
import useApi from 'shared/hooks/api'
import { useSelector } from 'react-redux'

const Changepassword = () => {
    const [{ isCreating }, changePassword] = useApi.post('api/customer-auth/v2/reset-password')
    const user = useSelector((state) => state.user)
    return (
        <PageContainer>
            <ChangePasswordCont>
                <Heading>Change Password</Heading>
                {/* <Tagline>Change your password</Tagline> */}
                <Form
                    enableReinitialize
                    initialValues={{
                        oldPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                    }}
                    validations={{
                        oldPassword: Form.is.required('Enter a valid password'),
                        newPassword: [
                            Form.is.required('Enter a valid password'),
                            Form.is.strongPassword(
                                'Use 6 or more characters, a combination of uppercase, lowercase, a number and a symbol.',
                            ),
                        ],
                        confirmPassword: Form.is.required('Enter a valid password'),
                    }}
                    onSubmit={async (values, form) => {
                        if (values.newPassword !== values.confirmPassword) {
                            form.setFieldError('confirmPassword', 'Passwords do not match.')
                            return
                        }

                        try {
                            await changePassword({
                                email: user?.email,
                                oldPassword: values.oldPassword,
                                newPassword: values.newPassword,
                            })
                            toast.success('Password change successfully.')
                            form.resetForm()
                        } catch (e) {
                            if (e === 'User not found or invalid password') {
                                form.setFieldError('oldPassword', 'Current password is invalid.')
                                toast.error('Current password is invalid.')
                            } else if (e.succeeded === false) {
                                form.setFieldError(
                                    'newPassword',
                                    'Use 6 or more characters, a combination of uppercase, lowercase, a number and a symbol.',
                                )
                                toast.error(
                                    'Use 6 or more characters, a combination of uppercase, lowercase, a number and a symbol.',
                                )
                            } else {
                                console.log(e)
                            }
                        }
                    }}
                >
                    <Form.Element>
                        <FormCont>
                            <Form.Field.Input
                                name="oldPassword"
                                type="password"
                                placeholder="Current Password"
                                label="Current Password"
                                size="lg"
                                disabled={isCreating}
                            />
                            <Form.Field.Input
                                name="newPassword"
                                type="password"
                                placeholder="New Password"
                                label="New Password"
                                size="lg"
                                disabled={isCreating}
                                showStrengthBox={true}
                            />
                            <Form.Field.Input
                                name="confirmPassword"
                                type="password"
                                placeholder="Confirm New Password"
                                label="Confirm Password"
                                size="lg"
                                disabled={isCreating}
                            />
                        </FormCont>
                        <ActionButton
                            id="fs_auth_changePassword_submit"
                            variant="primary"
                            type="submit"
                            isWorking={isCreating}
                        >
                            Change Password
                        </ActionButton>
                    </Form.Element>
                </Form>
            </ChangePasswordCont>
        </PageContainer>
    )
}

export default Changepassword
