import api from 'shared/utils/api'
import { useState } from 'react'
import toast from 'shared/utils/toast'
import { isEmpty } from 'lodash'
import { capitalizeFirstLetterEveryWord } from 'shared/utils/formatter'

const useMessageCenter = (id) => {
    const [loading, setLoading] = useState(false)

    const teamsFetchURL = `customer/v3/cash-offer-transactions/${id}/my-team`
    const singleTransactionsURL = `customer/v3/cash-offer-transactions/${id}`

    const comparisonArray = [
        'SuccessManager',
        'SolutionAdvisor',
        'SolutionExpert',
        'InHomeOfferpadRepresentative',
    ]

    const option1 = ['Pricing', 'Offer Amount', 'Closing', 'Tenant Occupied or Rental', 'Other']

    const option2 = [
        'General question',
        'Closing date',
        'Free move',
        'Question about an addendum',
        'Question about credit or repair request',
        'Question about closing',
        'Other',
    ]

    const isNumberEmpty = (num) => {
        return num === 0 || isNaN(num)
    }

    const init = async () => {
        setLoading(true)

        if (isEmpty(id)) {
            if (typeof id === 'number') {
                if (isNumberEmpty(id)) {
                    console.log(3)
                    return {
                        title: `Solution Team`,
                        address: false,
                        role: false,
                        options: option1,
                    }
                }
            } else {
                return {
                    title: `Solution Team`,
                    address: false,
                    role: false,
                    options: option1,
                }
            }
        }

        let data
        try {
            const res = await api.get(teamsFetchURL)
            if (res?.status === 503) {
                return
            }
            let teams = res.contacts
            try {
                const trans = await api.get(singleTransactionsURL)
                if (res?.status === 503) {
                    return
                }
                const address = `${capitalizeFirstLetterEveryWord(
                    trans?.property?.streetAddress,
                )} ${trans?.property?.city}, ${trans?.property?.state}, ${trans?.property?.zip}`

                const isAnyTeamMemberAssigned = () => {
                    let count = 0
                    teams.forEach((team) => {
                        if (comparisonArray.includes(team.role)) {
                            count++
                        }
                    })
                    return count > 0 ? true : false
                }

                if (!isAnyTeamMemberAssigned()) {
                    data = {
                        title: `${trans?.property?.market} Solutions Team`,
                        address,
                        roleLabel: false,
                        role: false,
                        options: option1,
                    }
                } else {
                    let count = 0
                    comparisonArray.forEach((role) => {
                        teams.forEach((team) => {
                            if (count > 0) {
                                return
                            }
                            if (role === team.role) {
                                switch (role) {
                                    case 'SolutionExpert':
                                        data = {
                                            title: `${team.firstName} ${team.lastName}`,
                                            address,
                                            roleLabel: team.roleLabel,
                                            role: team.role,
                                            options: option1,
                                        }
                                        break
                                    case 'SolutionAdvisor':
                                        data = {
                                            title: `${team.firstName} ${team.lastName}`,
                                            address,
                                            roleLabel: team.roleLabel,
                                            role: team.role,
                                            options: option1,
                                        }
                                        break
                                    case 'SuccessManager':
                                        data = {
                                            title: `${team.firstName} ${team.lastName}`,
                                            address,
                                            roleLabel: team.roleLabel,
                                            role: team.role,
                                            options: option2,
                                        }
                                        break
                                    case 'InHomeOfferpadRepresentative':
                                        data = {
                                            title: `${team.firstName} ${team.lastName}`,
                                            address,
                                            roleLabel: team.roleLabel,
                                            role: team.role,
                                            options: option2,
                                        }
                                        break
                                }
                                count++
                            }
                        })
                    })
                }
                setLoading(false)
                return data
            } catch (e) {
                console.error(['messageCenterTransaction', e])
                toast.error('Something went wrong.')
                setLoading(false)
                return
            }
        } catch (e) {
            console.error(['messageCenterTeams', e])
            toast.error('Something went wrong.')
            setLoading(false)
            return
        }
    }

    return {
        init,
        isLoading: loading,
    }
}

export default useMessageCenter
