import Dropdown from '../Dropdown'
import NavbarRoundMenu from '../NavbarRoundMenu'
import MessageIcon from 'shared/asstes/messageIcon.svg'
import { Container, Heading, SubHeading } from './Styles'
import NavbarMenuItem from '../NavbarMenuItem'
import MessegeCenterTypingIcon from 'shared/asstes/message-center.svg'
import TechSupportIcon from 'shared/asstes/techSupportIcon.svg'

const MessageNavbarMenu = () => {
    return (
        <Dropdown
            testid="dropdown:message"
            withCloseIcon={true}
            offset={44}
            renderLink={(dropdown) => (
                <NavbarRoundMenu icon={MessageIcon} onClick={dropdown.open} />
            )}
            renderContent={(dropdown) => (
                <Container>
                    <Heading>Message Center</Heading>
                    <SubHeading>Manage messages, chat & support</SubHeading>
                    <NavbarMenuItem
                        to="/message-center"
                        onClick={dropdown.close}
                        leftIcon={MessegeCenterTypingIcon}
                        text="Message Your Team"
                        id="fs_navigation_message_team"
                    />
                    <NavbarMenuItem
                        to="/tech-support"
                        onClick={dropdown.close}
                        leftIcon={TechSupportIcon}
                        text="Tech Support"
                        id="fs_navigation_tech-support"
                    />
                </Container>
            )}
        />
    )
}

export default MessageNavbarMenu
