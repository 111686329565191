import React, { Fragment } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ResCont, NavCont, NavIntCont, StyledDiv, StyledLogo } from './Styles'
import Logo from 'shared/asstes/logo.svg'

function PublicPageNavigation() {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const handleClick = (path) => {
        !localStorage.currentTransactionId && navigate('/sell/dashboard')
        navigate(path)
    }
    return (
        <Fragment>
            <NavCont
                isDashboard={
                    pathname === '/sell/dashboard' ||
                    pathname === '/buy/dashboard' ||
                    pathname === '/loan/dashboard'
                }
            >
                <ResCont
                    isDashboard={
                        pathname === '/sell/dashboard' ||
                        pathname === '/buy/dashboard' ||
                        pathname === '/loan/dashboard'
                    }
                >
                    {pathname !== '/sell/dashboard' &&
                        pathname !== '/buy/dashboard' &&
                        pathname !== '/loan/dashboard' && (
                            <NavIntCont>
                                <StyledDiv>
                                    <StyledLogo
                                        src={Logo}
                                        alt=""
                                        onClick={() => handleClick('/sell/dashboard')}
                                    />
                                </StyledDiv>
                            </NavIntCont>
                        )}
                </ResCont>
            </NavCont>
        </Fragment>
    )
}

export default PublicPageNavigation
