import {
    ButtonCont,
    ActionButton,
    Heading,
    ExtendedCont,
} from 'OnDemandActions/Sell/ExtendedStay/shared/Styles'
import { useWizard } from 'react-use-wizard'
import useApi from 'shared/hooks/api'
import { ScreenLoader } from 'shared/components'
import { user } from 'shared/utils/jwt'

const Step2 = ({ data, setData, es = {} }) => {
    const { previousStep, nextStep } = useWizard()
    const agent = window.currentTransaction?.isListedByAgent

    const ExtendedStayPoint = `customer/v3/cash-offer-transactions/${localStorage.currentTransactionId}/change-extended-stay`

    const [{ isWorking }, sendExtendedData] = useApi.post(ExtendedStayPoint)

    const maxDays =
        es.extendedStayDayEligibleDays > es.currentExtendedStayDays
            ? es.extendedStayDayEligibleDays
            : es.currentExtendedStayDays

    const array = []
    for (let i = 0; i < maxDays; i++) {
        array.push(i + 1)
    }

    const handleClick = async () => {
        if (data !== null) {
            const res = await sendExtendedData({
                operation: 'Add',
                extendedStayDays: parseInt(data),
            })
            res?.status !== 503 && nextStep()
        }
    }

    return (
        <ExtendedCont>
            <ScreenLoader size={100} sL={isWorking} />
            <Heading>Request Extended Stay</Heading>
            {agent === true ? (
                <p>
                    One of the most popular Offerpad benefits is our Extended Stay, which allows
                    your seller to stay in the home for up to {maxDays}-days past closing. Would you
                    like to add our Extended Stay?
                </p>
            ) : (
                <p>
                    One of the most popular Offerpad benefits is our extended stay, which allows you
                    to stay in your home for up to {maxDays}-days past closing. Would you like to
                    add our Extended Stay?
                </p>
            )}

            <br />
            <br />
            <span>How many extra days do you need?</span>

            <div className="select-wrapper">
                <select onChange={(e) => setData(e.target.value)}>
                    <option value="">Select days</option>
                    {array.length &&
                        array.map((arr) => (
                            <option key={arr} value={arr}>
                                {arr} {arr > 1 ? 'days' : 'day'}
                            </option>
                        ))}
                </select>
            </div>
            <br />
            <br />

            <ButtonCont>
                <ActionButton id="fs_sell_extendedStay_na_previous2" prev onClick={previousStep}>
                    Back
                </ActionButton>
                <ActionButton
                    continue
                    variant="primary"
                    id="fs_sell_extendedStay_na_next2"
                    onClick={handleClick}
                    disabled={isWorking || data === null || data === ''}
                >
                    Next
                </ActionButton>
            </ButtonCont>
        </ExtendedCont>
    )
}

export default Step2
