import { useState } from 'react'
import ScreenLoader from '../ScreenLoader'
import { ImageCont } from './Styles'
import FallbackImage from 'shared/components/ImageWithLoader/assets/default-property-image-iwl.svg'

const ImageWithLoader = ({ image = '', className = '', renderOnTop = () => {} }) => {
    const [loaded, setLoaded] = useState(false)
    const [isError, setIsError] = useState(false)

    const handelError = () => {
        setIsError(true)
        setLoaded(true)
    }

    return (
        <ImageCont>
            <img
                src={isError ? FallbackImage : image}
                alt=""
                onLoad={() => setLoaded(true)}
                onError={handelError}
                className={className}
            />
            <div className="on-top">{renderOnTop()}</div>
            <ScreenLoader sL={!loaded} full={false} />
        </ImageCont>
    )
}

export default ImageWithLoader
