import {
    QuickViewCont,
    TrackerAndResourceCenterCont,
    TrackerAndResourceCenterAndResCenterCont,
    QuickLinkAndImportantDate,
} from './Styles'
import { PropertyImage, YourToDo, Tracker, ResourceCenter } from './Cards'
import QuickLinks from './Cards/QuickLinks'
import ImportantDates from './Cards/ImportantDates'
import { Confetti, ShowIf } from 'shared/components'

const Dashboard = () => {
    const transaction = window.currentTransaction

    const comparisonArray = ['PendingCancellation', 'ApprovedCancellation', 'Cancelled']

    const showQuickLinks = !comparisonArray.includes(transaction?.transactionStatus)

    return (
        <>
            <Confetti currentTransaction={transaction} />
            <QuickViewCont borderRadius="lg">
                <PropertyImage />
                <YourToDo />
            </QuickViewCont>
            <TrackerAndResourceCenterCont>
                <div>
                    <ImportantDates mobile />
                    <Tracker />
                    {showQuickLinks && <QuickLinks tab />}
                </div>
                <TrackerAndResourceCenterAndResCenterCont className="tt">
                    <QuickLinkAndImportantDate>
                        {showQuickLinks && <QuickLinks />}
                        <ImportantDates />
                    </QuickLinkAndImportantDate>
                    <ShowIf condition={!transaction?.isPreContractInspection}>
                        <ResourceCenter />
                    </ShowIf>
                </TrackerAndResourceCenterAndResCenterCont>
            </TrackerAndResourceCenterCont>
        </>
    )
}

export default Dashboard
