import { Fragment, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { AppBanner, Footer, Navbar } from 'shared/components'
import { getStoredAuthToken } from 'shared/utils/authToken'

const PageLayout = () => {
    const navigate = useNavigate()
    const authToken = getStoredAuthToken()

    useEffect(() => {
        !authToken && navigate('/auth/login', { replace: true })
    }, [])

    return authToken ? (
        <Fragment>
            <AppBanner />
            <Navbar />
            <Outlet />
            <Footer />
        </Fragment>
    ) : null
}

export default PageLayout
