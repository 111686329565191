import { Cont } from './Styles'
import { useWizard } from 'react-use-wizard'
import moment from 'moment'
import useApi from 'shared/hooks/api'
import { ScreenLoader } from 'shared/components'

const Step3 = ({ data, es = {} }) => {
    const { nextStep, previousStep, goToStep } = useWizard()
    const agent = window.currentTransaction?.isListedByAgent
    const CoeEndPoint = `customer/v3/cash-offer-transactions/${localStorage.currentTransactionId}/change-coe`
    const ExtendedStayPoint = `customer/v3/cash-offer-transactions/${localStorage.currentTransactionId}/change-extended-stay`
    const [{ isCreating }, sendCoeData] = useApi.post(CoeEndPoint)
    const [{ isWorking }, sendExtendedData] = useApi.post(ExtendedStayPoint)

    const handleBack = () => {
        es.extendedStayEligible ? (data.extendedStay ? previousStep() : goToStep(1)) : goToStep(0)
    }

    const handleNext = async () => {
        const res = await sendCoeData({
            changeDate: moment(data.date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        })
        if (res?.status === 503) {
            return
        }
        if (data.extendedStay) {
            const res1 = await sendExtendedData({
                operation: 'Add',
                extendedStayDays: data.extendedStayDays,
            })
            if (res1?.status === 503) {
                return
            }
        }
        nextStep()
    }

    return (
        <Cont>
            <ScreenLoader sL={isCreating || isWorking} size={100} />
            <h1>Ok, let’s review!</h1>
            <div className="coe-info-cont">
                <div className="coe-info">
                    <h2>Closing Date</h2>
                    <p>
                        You are requesting your closing date be changed to{' '}
                        <span>{moment(data.date, 'MM/DD/YYYY').format('MMM DD, YYYY')}</span>
                    </p>
                </div>
                {es?.extendedStayEligible && (
                    <div className="coe-info">
                        <h2>Extended Stay</h2>
                        {data.extendedStay ? (
                            agent !== true ? (
                                <p>
                                    Your new Extended Stay End Date will be{' '}
                                    <span>
                                        {moment(data.date, 'MM/DD/YYYY')
                                            .add(data.extendedStayDays, 'days')
                                            .format('MMM DD, YYYY')}
                                    </span>
                                </p>
                            ) : (
                                <p>
                                    Your seller's new Extended Stay end date will be{' '}
                                    <span>
                                        {moment(data.date, 'MM/DD/YYYY')
                                            .add(data.extendedStayDays, 'days')
                                            .format('MMM DD, YYYY')}
                                    </span>
                                </p>
                            )
                        ) : (
                            <p>No longer needed.</p>
                        )}
                    </div>
                )}
            </div>
            <div className="btn-cont">
                <button id="fs_sell_cod_dateChange_pa_previous4" onClick={handleBack}>
                    Back
                </button>
                <button id="fs_sell_cod_dateChange_pa_next4" className="next" onClick={handleNext}>
                    Submit
                </button>
            </div>
        </Cont>
    )
}

export default Step3
