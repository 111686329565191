import { Button } from 'shared/components'
import { font } from 'shared/utils/styles'
import styled, { css, keyframes } from 'styled-components'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

export const ProfileCont = styled.div`
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    width: 100%;
    border-radius: 8px;
    border: 2px solid #eaeaea;
    padding: 45px 52px;
    margin-bottom: 2rem;
    position: relative;
    margin: 30px 0px;
    @media (max-width: 820px) {
        padding: 20px;
    }
    @media (max-width: 480px) {
        padding: 0px;
        border: none;
    }
`

export const Heading = styled.div`
    ${font.bold};
    ${font.size(24)};
    line-height: 28.8px;
    color: #525659;
`

export const Tagline = styled.div`
    ${font.medium};
    ${font.size(13)};
    line-height: 21px;
    color: #8e91a0;
    margin-top: 8px;
`

export const FormCont = styled.div`
    margin-top: 32px;
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 17px 40px;
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
    div {
        margin-top: 0px;
        div {
            input {
                color: #363948;
                ${font.medium};
                font-size: 16px !important;
                line-height: 21px;
            }
        }
    }
`

export const DeleteAccount = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    color: red;
    ${font.medium};
    cursor: pointer;
    img {
        background-color: rgb(247, 247, 247);
        border-radius: 50%;
        padding: 8px;
        height: 30px;
    }
`

export const HeadingCont = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`

export const ActionButton = styled(Button)`
    background-color: #f6f6f6;
    height: 40px;
    width: 40px;
    border: 1px solid #dbe5e7;
    border-radius: 50%;
    ${(props) =>
        props.active &&
        css`
            background-color: #1b6775;
        `}
`

export const ButtonCont = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > button {
        width: 210px;
        ${font.medium};
        font-size: 18px !important;
    }
`
