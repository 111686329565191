import styled from 'styled-components'
import { font } from 'shared/utils/styles'

export const Cont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 75px 60px;
    @media (max-width: 550px) {
        padding: 75px 30px;
    }
    img {
        @media (max-width: 550px) {
            width: 90%;
        }
        // @media (max-height: 268.79px) {
        //     height:90%;
        // }
    }
    h1 {
        ${font.size(32)};
        ${font.black};
        text-transform: uppercase;
        color: #262833;
        line-height: 30px;
        margin-top: 20px;
        text-align: center;
        @media (max-width: 550px) {
            ${font.size(20)};
        }
    }
    p {
        ${font.size(20)};
        ${font.medium};
        color: #525659;
        line-height: 38px;
        margin-top: 21px;
        text-align: center;
        max-width: 600px;
        @media (max-width: 550px) {
            max-width: 100%;
            ${font.size(15)};
            line-height: 20px;
        }
    }
    button {
        margin-top: 34px;
        ${font.size(18)};
        ${font.medium};
    }
    div {
        display: flex;
        gap: 40px;
    }
`

export const IconCont = styled.div`
    cursor: pointer;
    margin-top: 40px;

    div {
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        width: 182px;
        height: 146px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        @media (max-width: 550px) {
            width: 100%;
            padding: 0px 20px;
        }
    }

    img {
        margin-top: 30px;
        width: 50px;
        @media (max-width: 550px) {
            width: 45.6px;
        }
    }

    span {
        line-height: 21px;
        ${font.size(16)};
        ${font.bold};
        color: #525659;
        white-space: nowrap;
    }
`
