import { useCallback, useState } from 'react'
import { TodoCard, Heading, Header, CardCont, Container } from './Styles'
import { CompletedTodos, PendingTodos, ScreenLoader } from 'shared/components'
import useTodos from 'shared/hooks/sell/todos'
import { useSelector } from 'react-redux'
import Default from './default'
import toast from 'shared/utils/toast'

const ToDos = () => {
    const { fetch } = useTodos()
    const { todos: t, loading } = useSelector((state) => state.todos)
    const [todos, setTodos] = useState(t)
    const agent = window.currentTransaction?.isListedByAgent

    const get = useCallback(async () => {
        const res = await fetch()
        setTodos(res)
    }, [todos])

    const handleErrorEvent = (error) => error && toast.error('Something went wrong.')
    const handleOnCloseEvent = () => get()

    if (loading) {
        return (
            <div style={{ position: 'relative', height: '300px' }}>
                <ScreenLoader sL={loading} full={false} />
            </div>
        )
    }

    return (
        Object.keys(todos).length > 0 && (
            <Container>
                <TodoCard>
                    {todos?.pending.length > 0 ? <Heading>Action Items</Heading> : <Default />}
                    <div className="card-cont">
                        {todos.pending.map((todo, i) => {
                            return (
                                <PendingTodos
                                    todo={todo}
                                    handleOnCloseEvent={handleOnCloseEvent}
                                    handleErrorEvent={handleErrorEvent}
                                    agent={agent}
                                    key={Date.now() + i}
                                    onMarkedTodoCompleteEvent={get}
                                />
                            )
                        })}
                    </div>
                </TodoCard>
                {todos?.completed.length > 0 && <Header>Completed tasks</Header>}
                {todos?.completed.length > 0 && (
                    <CardCont>
                        {todos?.completed.map((todo, i) => {
                            return <CompletedTodos todo={todo} key={Date.now() + i} />
                        })}
                    </CardCont>
                )}
            </Container>
        )
    )
}

export default ToDos
