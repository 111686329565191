import {
    SurveyCont,
    ActionButton,
    Heading,
    UserInfoCont,
    PointImage,
    ExtTagLine,
    WrapperDiv,
    ExtShowinmid,
    Extendedsubtext,
    DynamicIcons,
    ReqWrapper,
    ExtRequestedLine,
    DividerModal,
    DefaultE,
} from 'OnDemandActions/Sell/ExtendedStay/shared/Styles'
import Points from 'OnDemandActions/Sell/ExtendedStay/shared/assets/points.svg'
import TickIcon from 'OnDemandActions/Sell/ExtendedStay/shared/assets/Tick_icon.svg'
import Info from 'OnDemandActions/Sell/ExtendedStay/shared/assets/info-extended-stay.svg'
import Phone from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/phone-free-local.svg'
import Email from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/email-free-local.svg'
import { useWizard } from 'react-use-wizard'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import cf from 'currency-formatter'
import useTeams from 'shared/hooks/sell/teams'
import useEvents from 'shared/hooks/sell/events'
import { formatPhoneNumber } from 'shared/utils/formatter'
const Step1 = ({ es = {} }) => {
    const { nextStep } = useWizard()

    const transaction = useSelector((state) => state.singleTransaction.transaction)

    const { getEventByRole } = useEvents()
    const { eventDate } = getEventByRole('ClearedToClose')

    const { getTeamByRole } = useTeams()
    const successManager = getTeamByRole('SuccessManager')
    let titleCompany = getTeamByRole('TitleContact')

    if (!titleCompany) {
        titleCompany = {
            companyName: '“The title company or attorney’s office”',
        }
    }

    const timeOver = moment(eventDate, 'MM/DD/YYYY').diff(moment(), 'days') <= 7

    return (
        <SurveyCont>
            <Heading mt="10">Extended Stay</Heading>
            <ReqWrapper mt="11">
                <DynamicIcons width="17" height="17" src={TickIcon} />
                <ExtRequestedLine>Requested and Addendum Signed </ExtRequestedLine>
            </ReqWrapper>
            <DividerModal />
            <Extendedsubtext mt="11"> Details about your Extended Stay</Extendedsubtext>
            <br />
            <WrapperDiv>
                <UserInfoCont>
                    <PointImage src={Points} />
                    <p>
                        End of Extended Stay is{' '}
                        {moment(es.currentExtendedStayDateTime)
                            .tz(transaction.property.timeZoneId)
                            .format('dddd, MMMM DD, YYYY')}{' '}
                        ({es.currentExtendedStayDays} days past closing)
                    </p>
                </UserInfoCont>
                <UserInfoCont>
                    <PointImage src={Points} />
                    <p>
                        {titleCompany.companyName} will hold back{' '}
                        {cf.format(es.holdBackAmount, { code: 'USD' })} from the sale proceeds at
                        closing.
                    </p>
                </UserInfoCont>
                <UserInfoCont>
                    <PointImage src={Points} />
                    <p>
                        During our final walk-through, we will confirm both that the home is
                        unoccupied, its condition is the same as it was during the inspection and
                        that it is also empty of all personal belongings and debris. Once we
                        confirm, we will instruct the title company or attorney's office to release
                        the {cf.format(es.holdBackAmount, { code: 'USD' })} holdback to you.
                    </p>
                </UserInfoCont>
                <UserInfoCont>
                    <PointImage src={Points} />
                    <p>
                        You will need to vacate the home on{' '}
                        {moment(es.currentExtendedStayDateTime)
                            .tz(transaction.property.timeZoneId)
                            .format('dddd, MMMM DD, YYYY')}{' '}
                        by no later than{' '}
                        {moment(es.currentExtendedStayDateTime)
                            .tz(transaction.property.timeZoneId)
                            .format('hh:mm a (z)')}
                        .
                    </p>
                </UserInfoCont>
            </WrapperDiv>
            <br />
            <br />
            <div className="button-cont">
                <center>
                    <ActionButton
                        width="250px"
                        id="fs_sell_extendedStay_sa_modify"
                        continue
                        variant="primary"
                        onClick={() => !timeOver && nextStep()}
                        disabled={timeOver}
                        grayed={`${timeOver ? 'true' : 'false'}`}
                    >
                        Modify
                    </ActionButton>
                </center>
            </div>
            {timeOver && (
                <DefaultE>
                    <div className="info-cont">
                        <span className="not-available-info">
                            <img src={Info} alt="" />
                            It may be too late to make changes to your Extended Stay, please reach
                            out directly to your Success Manager, {successManager?.firstName}{' '}
                            {successManager?.lastName}to confirm.
                        </span>
                        <div className="contacts">
                            {successManager?.phoneNumber && (
                                <div className="c-info">
                                    <img src={Phone} alt="" />
                                    <a
                                        href={`tel:${formatPhoneNumber(
                                            successManager?.phoneNumber,
                                        )}`}
                                    >
                                        {formatPhoneNumber(successManager?.phoneNumber)}
                                    </a>
                                </div>
                            )}
                            {successManager?.email && (
                                <div className="c-info">
                                    <img src={Email} alt="" />
                                    <a href={`mailto:${successManager?.email}`}>
                                        {successManager?.email}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                    <br />
                </DefaultE>
            )}
        </SurveyCont>
    )
}

export default Step1
