import { formatMoney, simplifyMoney } from 'shared/utils/formatter'
import CashOfferTeamCard from '../CashOfferTeamCard'
import CheckTeal from 'shared/asstes/check-teal.svg'
import SUNWITHCLOUD from 'shared/asstes/sun_with_cloud.svg'
import {
    ActionButton,
    Badge,
    CardDetails,
    Container,
    DefaultScreen,
    Details,
    InfoContainer,
    OfferCard,
    OfferPrice,
    Seprator,
    Span,
} from './Styles'

import Tooltip from '../Tooltip'
import CashOfferVideoCard from '../CashOfferVideoCard'

const data = [
    {
        position: 'right',
        content:
            "<b classname='mb-3'>Local Real Estate Expert</b>Work with local Offerpad agents who know your city at the neighborhood level. We help you take care of everything from contract to close, including a personalized marketing plan to get you 'sold'!",
        heading: 'Local real estate expert',
    },
    {
        position: 'right',
        content:
            "<b classname='mb-3'>Free show-ready services</b>We get your home 'buy-me!' ready with free cleaning (including carpets), yardwork, handyman, touch-ups, pool service and more.**",
        heading: 'Free show-ready services',
    },
    {
        position: 'right',
        content:
            "<b classname='mb-3'>Home improvement advance</b>Could a fast facelift help get more for your home? We can help with things like new paint, countertops, flooring and other updates. (Our expert Renovations team can even do all the work!)",
        heading: 'Home improvement advance',
    },
    {
        position: 'right',
        content:
            "<b classname='mb-3'>Nationwide marketing muscle</b>Backed by the professional resources of our national marketing team, your local Offerpad real estate agent will put together a personalized marketing plan to get you sold as fast and easy as possible.",
        heading: 'Nationwide marketing muscle',
    },
    {
        position: 'right',
        content:
            "<b classname='mb-3'>Bundle with buy & save thousands</b>Buy, sell and get a home loan together with Offerpad, and you could save thousands! Mix and match services to your needs. The more you do, the more you save.",
        heading: 'Bundle with buy & save thousands',
    },
]

const ListCard = ({ state, standalone, buttonClick }) => {
    return (
        <Container standalone={standalone}>
            {state?.solution !== 'CashPlusList' && <Badge>Estimated List Range</Badge>}
            <OfferPrice>
                ${simplifyMoney(state?.response?.priceLow)} - $
                {simplifyMoney(state?.response?.priceHigh)}
            </OfferPrice>
            <Span center>Your home may list for this or more with our local Offerpad agent.</Span>
            <OfferCard>
                <Seprator>
                    <span>potential sales price & fees</span>
                </Seprator>
                <Details>
                    <CardDetails>
                        <Span bold>Estimated List Range</Span>
                        <Span>
                            ${simplifyMoney(state?.response?.priceLow)} - ${' '}
                            {simplifyMoney(state?.response?.priceHigh)}
                        </Span>
                    </CardDetails>
                    <CardDetails>
                        <Tooltip
                            onResponsive="bottomSheet"
                            position="right"
                            content="<b class='extra-bold'>Commissions</b>Estimates based on industry averages for the markets in which Offerpad does business."
                        >
                            <Span bold underline>
                                Commissions
                            </Span>
                        </Tooltip>
                        <Span>-6%</Span>
                    </CardDetails>
                    <CardDetails>
                        <Tooltip
                            onResponsive="bottomSheet"
                            position="right"
                            content="<b class='extra-bold'>Repairs</b>When you list your home and go under contract with a buyer, they will typically hire a third party inspector to assess the condition of the home and determine if there is anything they would like repaired before the closing."
                        >
                            <Span bold underline>
                                Repairs
                            </Span>
                        </Tooltip>
                        <Span italic>based on inspection</Span>
                    </CardDetails>
                    <CardDetails>
                        <Tooltip
                            onResponsive="bottomSheet"
                            position="right"
                            content="<b class='extra-bold'>Closing Costs</b>Buyer and Seller will each be responsible for their own customary closing costs typically assessed to each in the state where property is located."
                        >
                            <Span bold underline>
                                Closing costs
                            </Span>
                        </Tooltip>
                        <Span italic>varies by state</Span>
                    </CardDetails>
                    <CardDetails>
                        <Span teal bold>
                            Estimated Net Proceeds*
                        </Span>
                        <Span teal bold>
                            {formatMoney(state?.listEstimatedProceeds?.low)} -{' '}
                            {formatMoney(state?.listEstimatedProceeds?.high)}
                        </Span>
                    </CardDetails>
                </Details>
                <Seprator>
                    <span>Offerpad listing benefits</span>
                </Seprator>
                <InfoContainer>
                    {data.map((v, i) => (
                        <Span key={i} list>
                            <img src={CheckTeal} alt="" />
                            <Tooltip
                                onResponsive="bottomSheet"
                                position={v.position}
                                content={v.content}
                            >
                                <Span underline>{v.heading}</Span>
                            </Tooltip>
                        </Span>
                    ))}
                </InfoContainer>
                {!state?.response?.listAccepted ? (
                    <>
                        <ActionButton
                            onClick={buttonClick}
                            variant="primary"
                            teal
                            size="lg"
                            height={61}
                            style={{ marginBottom: state.compare ? '36px' : '0' }}
                        >
                            Get a no-obligation <br /> Comparative Market Report
                        </ActionButton>
                        {!state.compare && <CashOfferTeamCard state={state} />}
                    </>
                ) : (
                    <DefaultScreen>
                        <img src={SUNWITHCLOUD} alt="" />
                        <Span black style={{ fontSize: '22px', lineHeight: '23px' }}>
                            We're on it!
                        </Span>
                        <Span>
                            Our team will be reaching out shortly to review your home's comparative
                            marketing analysis.
                        </Span>
                    </DefaultScreen>
                )}
            </OfferCard>
            {!state.compare && state.showVideoView && !state?.agent && (
                <CashOfferVideoCard state={state} />
            )}
        </Container>
    )
}

export default ListCard
