import styled, { css, keyframes } from 'styled-components'
import { color, mixin, font, fontSize, breakPoints, padding } from 'shared/utils/styles'
import BackgroundImage from '../assets/background.svg'
import ResponsiveImage from '../assets/mobile-img.svg'
import { Button, Form, Icon } from 'shared/components'
import { Link } from 'react-router-dom'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

const opacity = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2% 5%;
    @media (max-width: 480px) {
        flex-direction: column;
        padding: 0;
    }
`

export const StyledLogo = styled.img`
    width: calc(9vw + 9vh + 5vmin);
    @media (max-width: ${breakPoints.sm}) {
        width: 200px;
        margin-top: -110px;
    }
`

export const TagLine = styled.p`
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 45px;
    ${font.tag};
    font-size: calc(2vw + 2vh + 2vmin);
    margin-top: 20px;
    padding: 0px 60px;
    @media (max-width: 820px) {
        line-height: 35px;
    }
    @media (max-width: 480px) {
        display: none;
    }
`

export const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: radial-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
        url(${BackgroundImage});
    width: 45%;
    min-height: 90vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 15% 100%;
    border-radius: 10px;
    @media (max-width: 480px) {
        background-image: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),
            url(${ResponsiveImage});
        min-height: 40vh;
        width: 100%;
        border-radius: 0;
    }
`
export const FormCont = styled.div`
    position: relative;
    padding: 65px 30px;
    ${(props) =>
        !!props.width
            ? css`
                  width: ${props.width};
              `
            : css`
                  width: 450px;
              `}
    margin: 0px auto;
    @media (max-width: 480px) {
        width: 100%;
        padding: 65px 20px;
        border-radius: 50px 50px 0 0;
        margin-top: -120px;
        background-color: white;
    }
    form {
        animation: ${slideLeft} ease 0.7s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
`

export const FormElement = styled(Form.Element)`
    width: 100%;
    max-width: 694px;
    display: flex;
    flex-direction: column;
    @media (max-width: 480px) {
        max-width: 100%;
        margin-top: -20px;
    }
    .hide-on-mobile {
        @media (max-width: 480px) {
            display: none;
        }
    }

    .rounded {
        span {
            border-radius: 50%;
        }
    }
`

export const FormImage = styled.img`
    position: absolute;
    top: 0;
    right: -80px;
    @media (max-width: 480px) {
        display: none;
    }
`

export const FormHeading = styled.h1`
    position: relative;
    padding: 6px 0;
    ${font.size(32)};
    line-height: 36px;
    ${font.black}
    color: #EC7625;
    margin-bottom: 20px;
`

export const FormHeadingImage = styled.img`
    position: absolute;
    top: 48px;
    left: 3px;
`

export const FormFieldRow = styled.div`
    display: flex;
    gap: 20px;
    justify-content: space-between;
    @media (max-width: ${breakPoints.sm}) {
        flex-direction: column;
        gap: 0;
    }
`

export const FormFieldCol = styled.div`
    flex: 1;
`

export const CheckboxContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;
    @media (max-width: 480px) {
        display: flex;
    }
    p {
        margin-top: 20px;
        ${font.size(16)};
        line-height: 21px;
        ${font.regular};
        color: #1b6775;
    }
    ${(props) =>
        !!props.showByDefault &&
        props.showByDefault === 'true' &&
        css`
            display: flex;
        `}
`

export const StyledAnchor = styled(Link)`
    margin-top: 20px;
    font-size: 14px;
    color: ${color.textInfo};
    ${font.medium};
    margin: 0px 0px 0px auto;
`

export const FormFooterStyled = styled.div`
    width: 100%;
    text-align: center;
    color: ${color.textLight};
    font-size: 15px;
    line-height: 30px;
    ${font.medium}
    padding: 0px 20px;
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    ${(props) =>
        !!props.mt &&
        css`
            margin-top: ${props.mt};
        `}
`
export const FormFooterStyledAnchorTag = styled(Link)`
    color: #1b6775;
    font-weight: 600;
    margin-left: 5px;
    text-decoration: underline;
`

export const ActionButton = styled(Button)`
    margin-top: 36px;
    width: 100%;
    ${font.size(18)};
    line-height: 22px;
    ${font.bold};
    height: 53px;
`

export const ActionButtonSignup = styled(Button)`
    margin-top: 30px;
    width: 335px;
    align-self: center;
    @media (max-width: ${breakPoints.sm}) {
        width: 100%;
    }
`

export const StyledBackButton = styled(Link)`
    background-color: ${color.backgroundLightest};
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 0px;
    margin-bottom: 22px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    ${mixin.clickable};
    &:hover {
        background-color: ${color.backgroundMedium};
    }
`
export const BackArrowIcon = styled(Icon)`
    color: ${color.textDark};
    margin-top: 5px;
`

export const ForgotPasswordSuccessDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #656565;
    ${font.medium};
    width: 400px;
    margin: 0 auto;
    @media (max-width: 480px) {
        padding: 20px;
        width: 100%;
    }
`

export const ForgotPasswordSuccessImage = styled.img``

export const ForgotPasswordUserEmail = styled.span`
    color: ${color.primary};
`

export const ForgotPasswordOkButton = styled.button`
    width: 134px;
    height: 62px;
    padding: 21.5px 56px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    color: #525659;
    ${font.size(16)};
    line-height: 19.2px;
    ${font.medium};
    transition: all 0.3s;
    &:hover {
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
    }
`

export const Cont = styled.div`
    background-color: white;
    max-width: calc(100vw - 100px);
    height: 100%;
    max-height: calc(100vh - 100px);
    border-radius: 10px;
    overflow: hidden;
    animation: ${opacity} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    overflow-y: auto;
    @media (max-width: 480px) {
        max-width: 100%;
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
    }

    div {
        animation: ${slideLeft} ease 0.7s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        display: flex;
        flex-direction: column;
        padding: 100px;
        text-align: center;
        justify-content: center;
        align-items: center;
        @media (max-width: 480px) {
            flex-direction: column;
            height: 100%;
            padding: 130px 20px 20px 20px;
        }
        img {
            width: 39px;
        }
        h1 {
            ${font.size(22)};
            line-height: 26.4px;
            ${font.black};
            color: black;
        }
        p {
            ${font.size(16)};
            line-height: 26px;
            ${font.medium};
            color: #525659;
        }
        button {
            width: 303px;
            div {
                padding: 0px;
            }
            @media (max-width: 480px) {
                width: 100%;
                margin: 0;
                margin-top: auto;
            }
        }
    }
`

export const SuccessDiv = styled.div`
    padding: 65px 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    ${(props) =>
        !!props.width
            ? css`
                  width: ${props.width};
              `
            : css`
                  width: 350px;
              `}
    margin: 0px auto;
    @media (max-width: 480px) {
        width: 100%;
        padding: 65px 20px;
        border-radius: 50px 50px 0 0;
        margin-top: -120px;
        background-color: white;
    }
    img {
        width: 100px;
    }
    h1 {
        ${font.size(22)};
        line-height: 26.4px;
        ${font.black};
        color: black;
    }
    p {
        ${font.size(16)};
        line-height: 26px;
        ${font.medium};
        color: #525659;
    }
    button {
        width: 134px;
        height: 62px;
        padding: 21.5px 56px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        cursor: pointer;
        color: #525659;
        ${font.size(16)};
        line-height: 19.2px;
        ${font.medium};
        transition: all 0.3s;
        &:hover {
            box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
        }
    }
`
