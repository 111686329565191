import styled, { css, keyframes } from 'styled-components'
import { font, mixin, zIndexValues } from 'shared/utils/styles'
import Button from '../Button'

const slideRightWithOpacity = keyframes`
    from{
        opacity: 0;
        transform: translateX(100%);
    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
`

const slideLeftWithOpacity = keyframes`
    from{
        opacity: 0;
        transform: translateX(-100%);
    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
`

export const slideBottomWithOpacity = keyframes`
    from{
        opacity: 0;
        transform: translateY(100%);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
`

export const slideTopWithOpacity = keyframes`
    from{
        opacity: 0;
        transform: translateY(-100%);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
`

export const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const flipY = keyframes`
    0% {
        opacity: 0;
      transform: perspective(2000px) rotateY(0deg);
    }
    100% {
        opacity: 1;
      transform: perspective(2000px) rotateY(360deg);
    }
`

export const Container = styled.div`
    position: relative;
    padding: 50px 50px 0px 50px;
    max-width: 100vw;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    ${(props) =>
        props.standalone
            ? css`
                  width: 715px;
              `
            : css`
                  height: 100%;
              `}
    animation: ${(props) => (props.standalone ? fadeIn : slideRightWithOpacity)} 0.3s ease-in-out;
    ${(props) =>
        props.standalone &&
        css`
            margin: 0 auto;
        `}
    ${(props) =>
        !props.compare &&
        css`
            padding: 50px 50px 0px 50px;
        `}
    @media screen and (max-width: 480px) {
        padding: 20px 20px 50px 20px;
    }
    .viewoffer-calendar-header {
        thead th:first-child,
        th:last-child {
            div {
                border: 1px solid #ced3de;
                border-radius: 10px;
                height: 30px;
                width: 30px;
                img {
                    width: 7px;
                }
            }
        }
        thead th:nth-child(2) {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 5px;
        }
        thead th:nth-child(2) span:first-child {
            color: #222b45;
            font-size: 20px;
            line-height: 16px;
            ${font.bold};
        }
        thead th:nth-child(2) span:last-child {
            color: #8f9bb3;
            font-size: 12px;
            line-height: 16px;
            ${font.bold};
        }
    }

    .viewoffer-calendar {
        border-spacing: 20px;
        thead tr th {
            color: #8f9bb3;
            font-size: 14px;
            line-height: 16px;
            ${font.bold};
        }
        tbody tr td {
            height: 25px;
            width: 25px;
            p {
                height: 25px;
                width: 25px;
                font-size: 15px;
                line-height: 20px;
                ${font.bold};
                background-color: inherit;
            }
            .current-selected-date {
                background-color: #ec7625;
                color: white;
                border-radius: 10px;
            }
        }
    }
`

export const Header = styled.div`
    ${(props) =>
        props.standalone &&
        css`
            max-width: 615px;
            margin: 0 auto;
        `}
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }
    ${(props) =>
        props.alignRightOnMobile &&
        css`
            @media screen and (max-width: 600px) {
                align-items: end;
            }
        `}
`

export const CloseIcon = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: #6f6c78;
    transition: all 0.1s;
    padding: 19px 20px;
    background-color: #f7f7f7;
    height: 55px;
    width: 55px;
    border-radius: 50% !important;
    border: 1px solid #dbe5e7;
    ${mixin.clickable}
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        line-height: 0%;
        ${font.size(20)}
    }
    ${(props) =>
        props.leftContainerCloseButton &&
        css`
            display: none;
            @media screen and (max-width: 480px) {
                display: flex;
                float: right;
                margin-bottom: 20px;
                position: absolute;
                top: 10px;
                right: 10px;
            }
        `}
    ${(props) =>
        props.showDefault &&
        css`
            display: flex !important;
        `}
    i {
        font-size: 25px;
        margin-top: 5px;
    }
    ${(props) =>
        props.top &&
        css`
            position: absolute;
            right: 20px;
            top: 20px;
        `}
`

export const IconImg = styled.img``

export const TabContainer = styled.div`
    height: 55px;
    flex: 1;
    border-radius: 52px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    background: ${(props) =>
        props.activeTab === 0 ? '#FDF4EE' : props.activeTab === 1 ? '#F4FDFF' : '#FDF4EE'};
    border: 0.25px solid
        ${(props) =>
            props.activeTab === 0
                ? 'rgba(236, 118, 37, 0.50)'
                : props.activeTab === 1
                ? 'rgba(27, 103, 117, 0.50)'
                : 'rgba(236, 118, 37, 0.50)'};
    transition: all 0.3s ease-in;
    max-width: ${(props) => (props.standalone ? '100%' : '563px')};
    @media screen and (max-width: 600px) {
        padding: 10px;
        width: 100%;
        margin-top: 40px;
        div:nth-child(3) {
            display: none;
            background-color: red;
        }
    }
`

export const TabButtons = styled.div`
    flex: 1;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    display: flex;
    height: 39px;
    padding: 9px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    font-size: 14px;
    font-style: normal;
    cursor: pointer;
    ${font.bold};
    z-index: 2;
    transition: all 0.3s ease-in;
    color: #363948;
    ${(props) =>
        props.active &&
        css`
            color: white;
            ${font.black};
        `}
    @media screen and (max-width: 600px) {
        flex: 1;
    }
`

export const Slider = styled.div`
    position: absolute;
    height: 39px;
    background-color: ${(props) => (props.activeTab !== 1 ? '#EC7625' : '#1B6675')};
    transition: left 0.3s ease, width 0.3s ease;
    width: calc(${(props) => `${100 / (props.showAllTabs ? 3 : 2)}%`} - 20px);
    left: calc(${(props) => `${props.activeTab * (100 / (props.showAllTabs ? 3 : 2))}%`} + 10px);
    z-index: 1;
    border-radius: 42px;
`

export const HelpCont = styled.div`
    position: absolute;
    bottom: -65px;
    pointer-events: none;
    margin-left: -50px;
    color: #6f6f6f;
    display: flex;
    transition: all 0.3s ease;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    ${(props) =>
        props.activeTab === 1 &&
        css`
            margin-left: -70%;
            flex-direction: row-reverse;
        `}
    @media screen and (max-width: 600px) {
        top: -90px;
        height: 100px;
        margin-left: 20px;
        ${(props) =>
            props.activeTab === 1 &&
            css`
                flex-direction: row-reverse;
            `}
    }
`

export const HelpSpan = styled.span`
    ${font.tag};
    font-size: 31px;
`

export const ArrowUpImg = styled.img`
    margin-top: -63px;
    ${(props) =>
        props.activeTab === 1 &&
        css`
            transform: scaleX(-1) rotate(0deg);
        `}
    @media screen and (max-width: 600px) {
        transform: scaleX(-1) rotate(-120deg);
        position: relative;
        margin-left: 10px;
        margin-top: 10px;
        ${(props) =>
            props.activeTab === 1 &&
            css`
                transform: scaleX(1) rotate(-120deg);
                margin-right: 10px;
                margin-left: 0px;
            `}
    }
`

export const Badge = styled.div`
    background-color: #ec7625;
    ${font.medium};
    font-size: 16px;
    line-height: 21px;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    width: fit-content;
    margin-bottom: 10px;
    font-style: italic;
    ${(props) =>
        props.teal &&
        css`
            background-color: #1b6775;
        `}
    @media screen and (max-width: 480px) {
        display: none;
    }
    ${(props) =>
        props.showDefault &&
        css`
            display: block !important;
        `}
`

export const OfferConatiner = styled.div`
    width: 100%;
    padding: 40px 0px;
    max-width: ${(props) => (props.compare ? '48%' : 'inherit')};
    position: relative;
    animation: ${fadeIn} 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: white;
    z-index: 1000;
    @media screen and (max-width: 600px) {
        margin-top: 0px;
        max-width: 100%;
    }
    @media screen and (max-width: 480px) {
        padding: 10px 0px;
    }
    ${(props) =>
        props.compare &&
        css`
            border: 1px solid #ec7625;
            border-radius: 20px;
            padding: 20px;
            margin-top: 80px;
            ${(props) =>
                props.teal &&
                css`
                    border: 1px solid #1b6775;
                `}
            @media screen and (max-width: 1000px) {
                max-width: 100%;
            }
        `}
    ${(props) =>
        props.blur &&
        css`
            opacity: 0.08;
            pointer-events: none;
            border: none;
            z-index: 1;
        `}
    ${(props) =>
        props.mt &&
        css`
            margin-top: ${(props) => `${props.mt}px`};
        `}
`

export const OfferPrice = styled.div`
    color: #ec7625;
    font-size: 57px;
    ${font.black};
    margin-bottom: 4px;
    transition: all 0.3s ease-in-out;
    @media screen and (max-width: 480px) {
        text-align: center;
        font-size: 47px;
    }
    ${(props) =>
        props.teal &&
        css`
            color: #1b6775;
        `}
    ${(props) =>
        props.showOnLeft &&
        css`
            @media screen and (max-width: 480px) {
                text-align: left;
            }
        `}
`

export const Span = styled.span`
    color: #525659;
    font-size: 16px;
    line-height: 20px;
    display: block;
    ${font.medium};
    ${(props) =>
        props.underline &&
        css`
            border-bottom: 1px dotted #000;
            text-decoration: none;
        `}
    a {
        color: #1b6775;
        text-decoration: underline;
        cursor: pointer;
    }
    .orange {
        color: #ec7625;
    }
    .primary-btn {
        background-color: #ec7625;
        color: white;
        border-radius: 5px;
        text-decoration: none;
        ${font.bold};
        font-size: 16px;
        padding: 10px 10px;
    }
    .teal-btn {
        background-color: #1b6775;
    }
    @media screen and (max-width: 480px) {
        ${(props) =>
            props.centerAtMobile &&
            css`
                text-align: center;
                float: right;
            `}
    }
    ${(props) =>
        props.showOnLeft &&
        css`
            @media screen and (max-width: 480px) {
                text-align: left !important;
            }
        `}
    img {
        cursor: pointer;
        width: 8px;
    }
    ${(props) =>
        props.flex &&
        css`
            display: flex;
            align-items: flex-start;
        `}
    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
        `}
    ${(props) =>
        props.mt &&
        css`
            margin-top: ${(props) => `${props.mt}px`};
        `}
    ${(props) =>
        props.fontLight &&
        css`
            ${font.regular};
        `}

        .check-teal {
        height: 24px;
        width: 24px;
    }
`

export const OfferCardContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const OfferCard = styled.div`
    padding: 36px;
    background-color: rgba(255, 209, 177, 0.22);
    border-radius: 20px;
    margin-top: 19px;
    flex: 1;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    border: 0.25px solid #1b6775;
    @media screen and (min-width: 600px) {
        width: 100%;
    }
    @media screen and (max-width: 480px) {
        margin-top: 35px;
        padding: 20px;
    }
    ${(props) =>
        props.teal &&
        css`
            background-color: #e4eef1;
        `}
    ${(props) =>
        props.noMargin &&
        css`
            @media screen and (max-width: 480px) {
                margin-left: -20px;
                margin-right: -20px;
                border-radius: 0px;
            }
        `}
`

export const CardDetails = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    span {
        ${(props) =>
            props.teal &&
            css`
                color: #1b6775;
                ${font.bold};
            `}
    }
    span:nth-child(2) {
        text-align: right;
        font-size: 16px;
    }

    .checkbox {
        span,
        input {
            height: 21px;
            width: 21px;
            background-color: white;
            border-radius: 2px;
            border: 1px solid #1b6775;
        }
        label {
            ${font.medium};
        }
        .checkbox-checked {
            background-color: #1b6775;
            &::after {
                height: 21px;
                width: 20px;
            }
        }
    }
    .flex-align-center {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    ${(props) =>
        props.mtom &&
        css`
            @media (max-width: 480px) {
                margin-top: 20px;
            }
        `}
`

export const Sperator = styled.div`
    display: flex;
    flex-direction: row;
    margin: 36px 0px;
    color: #1b6775;
    font-size: 14px;
    line-height: 26px;
    ${font.medium};
    font-style: italic;
    text-align: center;
    &::before {
        content: '';
        height: 0.25px;
        border-bottom: 0.25px solid #1b6775;
        flex: 1;
        margin: 12px 0px 0 0;
    }
    &::after {
        content: '';
        height: 0.25px;
        border-bottom: 0.25px solid #1b6775;
        flex: 1;
        margin: 12px 0 0 0px;
    }
    @media screen and (max-width: 480px) {
        margin: 12px 0px;
    }
    span {
        background-color: inherit;
        padding: 0px 15px;
        background-color: inherit;
        color: #1b6775;
        border-radius: 6px;
        ${font.tag};
        font-size: 31px;
        text-transform: uppercase;
    }
`

export const Overlay = styled.div`
    ${(props) =>
        props.compare &&
        css`
            position: absolute;
            top: 0px;
            right: -401px;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        `}
    ${(props) =>
        props.left &&
        css`
            right: 0;
            left: -401px;
        `}
    @media screen and (max-width: 600px) {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: ${zIndexValues.alert};
        background-color: rgba(9, 30, 66, 0.54);
        width: 100vw;
        height: 100vh;
    }
`

export const SlideLeftContainer = styled.div`
    position: relative;
    width: 400px;
    background-color: #f7fcfe;
    animation-name: ${(props) => (props.compare ? fadeIn : slideRightWithOpacity)};
    animation-fill-mode: forwards;
    animation-duration: 450ms;
    animation-delay: ${(props) => (props.compare ? 'inherit' : '0.15s')};
    margin-top: 19px;
    border-radius: 0px 40px 40px 0px;
    padding: 28px 37px;
    opacity: ${(props) => (props.compare ? '1' : '0')};
    z-index: 0;
    @media screen and (max-width: 600px) {
        animation-delay: inherit;
        position: absolute;
        bottom: 0;
        width: 100%;
        border-radius: 40px 40px 0px 0px;
        max-height: 100vh;
        overflow-y: auto;
        animation-name: ${slideBottomWithOpacity};
        padding: 20px;
        background-color: white;
        ${(props) =>
            props.sm_pd &&
            css`
                padding: 30px 30px;
            `}
    }
    ${(props) =>
        props.teal &&
        props.compare &&
        css`
            @media screen and (min-width: 600px) {
                border: 1px solid #1b6775;
                border-right: none;
                border-radius: 40px 0px 0px 40px;
            }
        `}
    ${(props) =>
        props.orange &&
        props.compare &&
        css`
            @media screen and (min-width: 600px) {
                border: 1px solid #ec7625;
                border-left: none;
            }
        `}
`

export const LeftInfoCont = styled.div`
    border-radius: 8px;
    border: 0.25px solid #1b6775;
    background: #fff;
    width: 100%;
    padding: 11px 17px;
    display: flex;
    margin-bottom: 14px;
    @media screen and (max-width: 600px) {
        gap: 10px;
        align-items: center;
        margin-top: 60px;
    }
    span {
        color: #525659;
        font-size: 13px;
        ${font.medium};
        line-height: 12px;
    }
    img {
        transform: scaleX(-1) rotate(-130deg);
        height: 33px;
    }
`

export const ActionButton = styled(Button)`
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    overflow: hidden;
    min-width: 100%;
    ${font.bold};
    @media screen and (max-width: 480px) {
        height: auto;
    }
    ${(props) =>
        props.teal &&
        css`
            background-color: #1b6775;
            &:not(:disabled):hover {
                background-color: #1b6775 !important;
                opacity: 0.9;
            }
        `}
    ${(props) =>
        props.disabledColor &&
        css`
            &:disabled {
                background-color: ${props.disabledColor};
                opacity: 0.5;
                cursor: not-allowed;
            }
        `}
`

export const OfferDiv = styled.div`
    width: 100%;
    .footer {
        text-align: center;
        display: inline-block;
        margin-top: 20px;
        padding: 0px 80px;
        line-height: 20px;
        @media screen and (max-width: 480px) {
            padding: 0px 10px;
        }
    }
`

export const CurrentDate = styled.div`
    font-style: italic;
    font-size: 16px;
    line-height: 26px;
    color: #525659;
    ${font.medium};
    margin-top: -15px;
    margin-left: 45px;
    span {
        color: #1b6775;
    }
`

export const MainConatiner = styled.div`
    margin-top: 80px;
    position: relative;
    @media screen and (max-width: 480px) {
        margin-top: 20px;
    }
    ${(props) =>
        props.compare &&
        css`
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            align-items: stretch;
        `}
    ${(props) =>
        props.marginTop &&
        css`
            margin-top: 40px;
        `}
    ${(props) =>
        props.marginBottom &&
        css`
            margin-bottom: 40px;
            @media screen and (max-width: 480px) {
                margin-bottom: 0px;
            }
        `}
`

export const HelpInfoHeading = styled.div`
    ${font.bold};
    font-size: 23px;
    line-height: 24px;
    color: #525659;
    margin-bottom: 10px;
`

export const HelpInfoPoints = styled.p`
    ${font.medium};
    font-size: 16px;
    line-height: 18px;
    color: #525659;
    margin-bottom: 5px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    &::before {
        content: '';
        height: 5px;
        width: 5px;
        background-color: #ec7625;
        flex-shrink: 0;
        margin-top: 6px;
    }
`

export const HelpInfoButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    margin: 20px 0px;
`

export const HelpInfoButton = styled(Button)`
    flex: 1;
    border-radius: 5px;
    border: 2px solid #ec7625;
    height: 50px;
    ${font.bold};
    ${(props) =>
        props.bordered &&
        css`
            background-color: white;
            color: #ec7625;
            border: 2px solid #ec7625;
        `}
`

export const TeamCardContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    flex-wrap: wrap;
    margin-bottom: 20px;
    animation: ${fadeIn} 0.6s ease-in-out;
`

export const TeamCard = styled.div`
    width: 100%;
    border-radius: 12px;
    border: 0.25px solid #1b6775;
    border-left: none;
    border-right: none;
    border-radius: none;
    border-bottom: none;
    background: inherit;
    display: flex;
    gap: 30px;
    padding: 30px 20px;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 480px) {
        width: 100%;
        gap: 18px;
    }
    ${(props) =>
        props.orange &&
        css`
            border-radius: 15px;
            border: 0.25px solid rgba(236, 118, 37, 0.5);
            background: #fdf4ee;
            padding: 10px 20px;
            width: 400px;
            box-shadow: none;
        `}
    ${(props) =>
        props.column &&
        css`
            flex-direction: column;
        `}
    ${(props) =>
        props.center &&
        css`
            justify-content: center;
            align-items: center;
        `}
    ${(props) =>
        props.compare &&
        css`
            width: auto;
            justify-content: space-between;
            align-items: center;
        `}
`

export const TeamMemberImage = styled.div`
    height: 70px;
    width: 70px;
    flex-shrink: 0;
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-size: contain;
    border: 0.5px solid #98a7b5;
    border-radius: 50%;
    margin-bottom: auto;
`

export const TeamInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    ${(props) =>
        props.compare &&
        css`
            flex-direction: row;
            align-items: center;
            gap: 30px;
        `}
`

export const MemberName = styled.div`
    ${font.bold};
    font-size: 18px;
    color: #525659;
`

export const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin-left: auto;
    a {
        width: 100%;
        text-align: center;
    }
    .orange {
        color: #ec7625;
    }
    .primary-btn {
        background-color: #ec7625;
        color: white;
        border-radius: 5px;
        text-decoration: none;
        ${font.bold};
        font-size: 16px;
        padding: 10px 30px;
    }
    .teal-btn {
        background-color: #1b6775;
    }
    ${(props) =>
        props.compare &&
        css`
            width: 350px;
            flex-direction: row;
            gap: 15px;
        `}
`

export const Dot = styled.div`
    height: 5px;
    width: 5px;
    background-color: #525659;
    border-radius: 50%;
`

export const Navbar = styled.div`
    position: sticky;
    top: 0;
    background-color: white;
    padding: 30px;
    z-index: 3;
    @media screen and (max-width: 480px) {
        padding: 20px;
    }
    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        width: 100%;
        max-width: 90%;
        @media screen and (max-width: 480px) {
            max-width: 100%;
        }
    }
    img {
        width: 200px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        @media screen and (max-width: 480px) {
            width: 150px;
        }
    }
    span {
        display: block;
        text-align: right;
    }
`

export const OptionCardContainer = styled.div`
    border-radius: 11px;
    border: 0.5px solid #898989;
    background: rgba(255, 255, 255, 0.4);
    width: 100%;
    padding: 17px 13px 17px 55px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

export const OptionContainer = styled.div`
    width: 100%;
`

export const Options = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    @media screen and (max-width: 600px) {
        display: block;
    }
`

export const Option = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 40%;
    cursor: pointer;
    input:before {
        margin: 19% 21%;
    }
    @media screen and (max-width: 600px) {
        width: 100%;
        margin-top: 10px;
    }
    ${(props) =>
        props.checked &&
        css`
            span {
                color: #ec7625;
                ${font.black};
            }
        `}
`

export const NumberBadge = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    border-bottom-right-radius: 11px;
    border: 0.5px solid #898989;
    border-left: 0px;
    border-top: 0px;
    background: #fff;
    padding: 5px 13px;
    color: #1b6775;
    ${font.black};
    font-size: 21px;
`

export const RoundedButtonCont = styled.div`
    background-color: #fff;
    border: 1px solid #dbe5e7;
    height: 37px;
    width: 37px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
        height: 15px;
        pointer-events: none;
    }
`
