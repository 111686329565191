import api from 'shared/utils/api'
import { useDispatch } from 'react-redux'
import { updateLoading, updateTodos } from 'features/todosSlice'
import { useNavigate } from 'react-router'
import toast from 'shared/utils/toast'
import moment from 'moment-timezone'
import useEvents from './events'
import { isEmpty } from 'lodash'
import { useErrorBoundary } from 'react-error-boundary'
import { States } from 'shared/constants/states'
import { capitalizeFirstLetterEveryWord } from 'shared/utils/formatter'
import useTeams from './teams'

const useTodos = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { showBoundary } = useErrorBoundary()
    const { getFilterEventUsingRole, getEventUsingEventIdentifier } = useEvents()
    const { getTeamByRoleidentifier } = useTeams()

    const makeTodoArray = (todos, todosInfo) => {
        const { property, offerExpirationDateTime, isListedByAgent } = window.currentTransaction
        const events = window.currentEvents
        const teams = window.currentTeams
        const tm = getTeamByRoleidentifier(teams, 'InHomeOfferpadRepresentative')
        const ev = getEventUsingEventIdentifier(events, 'PresentOfferReviewEvent')
        const ev2 = getFilterEventUsingRole(events, 'InspectionScheduled')
        const ev3 = getEventUsingEventIdentifier(events, 'PreliminaryOfferExpiredEvent')

        const temp = {
            pending: [],
            completed: [],
        }

        todos.forEach((todo) => {
            const matchingInfo = todosInfo.find(
                (info) =>
                    info.todoIdentifier === todo.identifier && info.todoStatus === todo.status,
            )

            if (matchingInfo) {
                let d = { ...matchingInfo }

                if (d.bodyText?.includes('[date]')) {
                    if (
                        d.todoIdentifier === 'CashOfferTransactionRenewOffer' &&
                        d.todoStatus === 'Incomplete'
                    ) {
                        d.bodyText = d.bodyText.replace(
                            '[date]',
                            moment(offerExpirationDateTime)
                                .tz(property?.timeZoneId || 'America/Phoenix')
                                .format('MMMM DD, YYYY'),
                        )
                    }
                }

                if (d.bodyText?.includes('[date]')) {
                    if (
                        !isEmpty(ev3) &&
                        todo.identifier === 'CashOfferTransactionRenewExpressOffer'
                    ) {
                        d.bodyText = d.bodyText.replace(
                            '[date]',
                            moment(ev3.eventDate).format('MMMM DD, YYYY'),
                        )
                    } else {
                        const date = moment(offerExpirationDateTime)
                            .tz(property?.timeZoneId || 'America/Phoenix')
                            .format('MMMM DD, YYYY')
                        d.bodyText = d.bodyText.replace('[date]', date)
                    }
                }

                if (d.title?.includes('[date]')) {
                    let date = moment(todo.modifiedDateTime)
                        .tz(property?.timeZoneId || 'America/Phoenix')
                        .format('MMMM DD, YYYY')
                    if (
                        !isEmpty(ev) &&
                        todo.identifier === 'CashOfferTransactionPresentOfferAppointment'
                    ) {
                        date = moment(ev.eventDate, 'MM/DD/YYYY').format('MMMM DD, YYYY')
                    }
                    d.calDate = moment(date, 'MMMM DD, YYYY').format('MM/DD/YYYY')
                    d.title = d.title.replace('[date]', date)
                } else {
                    d.calDate = moment(todo.modifiedDateTime).format('MM/DD/YYYY')
                }

                const datePlaceholders = [
                    '[DateSubmitted]',
                    '[DateSigned]',
                    '[DateCompleted]',
                    '[dateExecutred]',
                    '[Date]',
                    '[dateUploaded]',
                ]

                datePlaceholders.forEach((placeholder) => {
                    if (d.title?.includes(placeholder)) {
                        const formattedDate = moment(todo.modifiedDateTime)
                            .tz(property?.timeZoneId || 'America/Phoenix')
                            .format('MMMM DD, YYYY')
                        d.title = d.title.replace(placeholder, formattedDate)
                    }
                })

                if (d.title?.includes('[time]')) {
                    let time = moment(todo.modifiedDateTime)
                        .tz(property?.timeZoneId || 'America/Phoenix')
                        .format('hh:mm A')
                    if (
                        !isEmpty(ev) &&
                        todo.identifier === 'CashOfferTransactionPresentOfferAppointment'
                    ) {
                        time = ev.time
                    }
                    d.title = d.title.replace('[time]', time)
                }

                if (d.title?.includes('[time zone]')) {
                    let timezone = moment.tz(property?.timeZoneId || 'America/Phoenix').format('z')
                    if (
                        !isEmpty(ev) &&
                        todo.identifier === 'CashOfferTransactionPresentOfferAppointment'
                    ) {
                        timezone = ev.timeZone
                    }
                    d.title = d.title.replace('[time zone]', timezone)
                }

                if (d.title?.includes('[Sellername]')) {
                    d.title = d.title.replace(
                        '[Sellername]',
                        capitalizeFirstLetterEveryWord(todo?.metadata?.sellerName?.toLowerCase()),
                    )
                }

                if (d.bodyText?.includes('[Sellername]')) {
                    d.bodyText = d.bodyText.replace(
                        '[Sellername]',
                        capitalizeFirstLetterEveryWord(todo?.metadata?.sellerName?.toLowerCase()),
                    )
                }

                if (d.bodyText?.includes('[State]')) {
                    d.bodyText = d.bodyText.replace('[State]', States[property.state])
                }

                if (d.bodyText?.includes('[name]')) {
                    const { firstName, lastName } = tm || {}
                    if (
                        !isEmpty(tm) &&
                        todo.identifier === 'CashOfferTransactionScheduleInspection'
                    ) {
                        d.bodyText = d.bodyText.replace(
                            '[name]',
                            `${capitalizeFirstLetterEveryWord(
                                firstName,
                            )} ${capitalizeFirstLetterEveryWord(lastName)}`,
                        )
                    } else {
                        d.bodyText = d.bodyText.replace('[name],', '')
                    }
                }

                if (d.modalContent?.includes('[inspectionDate]')) {
                    if (
                        !isEmpty(ev2) &&
                        todo.identifier === 'CashOfferTransactionPrepareForInspection'
                    ) {
                        const { eventDate } = ev2
                        d.modalContent = d.modalContent.replace(
                            '[inspectionDate]',
                            moment(eventDate, 'MM/DD/YYYY').format('MMMM DD, YYYY'),
                        )
                    }
                }

                if (d.modalContent?.includes('[inspectionTime]')) {
                    if (
                        !isEmpty(ev2) &&
                        todo.identifier === 'CashOfferTransactionPrepareForInspection'
                    ) {
                        const { time } = ev2
                        d.modalContent = d.modalContent.replace('[inspectionTime]', time)
                    }
                }

                if (d.modalContent?.includes('[inspectionTimeZone]')) {
                    if (
                        !isEmpty(ev2) &&
                        todo.identifier === 'CashOfferTransactionPrepareForInspection'
                    ) {
                        const { timeZone } = ev2
                        d.modalContent = d.modalContent.replace('[inspectionTimeZone]', timeZone)
                    }
                }

                if (d.modalContent?.includes('[HomeMarketingConsultantName]')) {
                    const { firstName, lastName } = tm || {}
                    if (todo.identifier === 'CashOfferTransactionPrepareForInspection') {
                        if (!isEmpty(tm)) {
                            d.modalContent = d.modalContent.replaceAll(
                                '[HomeMarketingConsultantName]',
                                `${capitalizeFirstLetterEveryWord(
                                    firstName,
                                )} ${capitalizeFirstLetterEveryWord(lastName)}`,
                            )
                        } else {
                            d.modalContent = d.modalContent.replaceAll(
                                ', [HomeMarketingConsultantName]',
                                '',
                            )
                        }
                    }
                }

                if (d.modalContent?.includes('[HomeMarketingConsultantPhone]')) {
                    const { phoneNumber, email } = tm || {}
                    if (todo.identifier === 'CashOfferTransactionPrepareForInspection') {
                        if (!isEmpty(tm)) {
                            const dataToShow = phoneNumber
                                ? phoneNumber
                                : `<a href="mailto:${email}">${email}</a>`
                            d.modalContent = d.modalContent.replace(
                                '[HomeMarketingConsultantPhone]',
                                dataToShow,
                            )
                        } else {
                            d.modalContent = d.modalContent.replace(
                                ' at [HomeMarketingConsultantPhone].',
                                '.',
                            )
                        }
                    }
                }

                if (todo.status === 'Complete') {
                    temp.completed.push({ ...todo, ...d, visited: true })
                } else {
                    if (todo.metadata?.embeddedSigning === false && isListedByAgent === false) {
                        d.bodyText = `Check your email, we have sent you a ${d?.identifierFriendlyName} document for your review & signature.`
                    }
                    temp.pending.push({ ...todo, ...d })
                }
            }
        })

        window.currentTodos = temp
        return temp
    }

    const getCustomerTodoInfo = async () => {
        if (localStorage.currentTransactionId === undefined) {
            navigate('/sell/dashboard')
            return
        }

        const agent = window.currentTransaction?.isListedByAgent

        const todoInfoUrl = `customer/v3/customer-todo-info?persona=${
            agent === true ? 'Agent' : 'Customer'
        }`

        try {
            let res = await api.get(todoInfoUrl)
            res.forEach((info) => {
                info.visited = false
            })
            return res
        } catch (e) {
            dispatch(updateLoading(false))
            toast.error('Something went wrong.')
            console.error(e)
            showBoundary(e)
            return
        }
    }

    const fetchTodos = async () => {
        const todosURL = `customer/v3/cash-offer-transactions/${localStorage.currentTransactionId}/todos`
        dispatch(updateLoading(true))
        const todoInfo = await getCustomerTodoInfo()
        try {
            const res = await api.get(todosURL)
            const temp = makeTodoArray(res, todoInfo)
            dispatch(updateTodos(temp))
            dispatch(updateLoading(false))
            return temp
        } catch (e) {
            dispatch(updateLoading(false))
            toast.error('Something went wrong.')
            console.error(e)
            showBoundary(e)
        }
    }

    const getTodoUsingIdentifier = (identifier) => {
        if (isEmpty(identifier)) return undefined
        const allTodos = window?.currentTodos
        if (isEmpty(Object.keys(allTodos))) return undefined
        const temp = []
        Object.keys(allTodos).forEach((todos) => {
            allTodos[todos].forEach((todo) => {
                temp.push(todo)
            })
        })

        return temp.filter((todo) => todo.todoIdentifier === identifier)[0]
    }

    const markTodoCompleted = async (trtansactionId, todoId) => {
        const URL = `customer/v3/cash-offer-transactions/${trtansactionId}/todos/${todoId}/complete`

        try {
            await api.post(URL)
        } catch (error) {
            toast.error('Something went wrong.')
            console.log(error)
        }
    }

    return {
        fetch: fetchTodos,
        getTodoUsingIdentifier,
        markTodoCompleted,
    }
}

export default useTodos
