import { Route, Routes, useLocation } from 'react-router-dom'
import { Fragment, useEffect } from 'react'
import ResourceRenderer from 'ResourceCenter/StandalonePages/ResourceRenderer'
import { CashOffer, Footer, NotFound, PDFViewer, PublicPageNavigation } from 'shared/components'
import OFPDF from 'shared/asstes/PDF/offerpad-home-inspection-guide.pdf'

function PublicRoute() {
    const { pathname } = useLocation()

    useEffect(() => {
        const helpBtn = document.querySelector('.helpButton')
        if (helpBtn) {
            helpBtn.style.display = 'none'
        }
    }, [])

    return (
        <Fragment>
            {!pathname.includes('/print') && !pathname.includes('/view-offer') && (
                <PublicPageNavigation />
            )}
            <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path={`/resource/:data`} element={<ResourceRenderer />} />
                <Route
                    path={`/file/offerpad-home-inspection-guide`}
                    element={<PDFViewer src={OFPDF} />}
                />
                <Route
                    path="/view-offer/:offerKey"
                    element={
                        <div
                            style={{
                                width: '100%',
                                margin: '0 auto',
                            }}
                        >
                            <CashOffer standalone />
                        </div>
                    }
                />
                <Route path="/view-offer/:offerKey/print" element={<CashOffer standalone />} />
            </Routes>
            {!pathname.includes('/print') && <Footer />}
        </Fragment>
    )
}

export default PublicRoute
