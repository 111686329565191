import api from 'shared/utils/api'
import { useDispatch } from 'react-redux'
import { updateLoading, updateSingleTransaction } from 'features/sell/single-transaction'
import { useNavigate } from 'react-router'
import toast from 'shared/utils/toast'
import { useErrorBoundary } from 'react-error-boundary'
import { convertStringToBase64 } from 'shared/utils/converter'

const useSingleTransactions = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const fetchSingleTranscations = async () => {
        if (localStorage.currentTransactionId === undefined) {
            navigate('/sell/dashboard')
            return
        }

        const singleTransactionsURL = `Customer/v3/cash-offer-transactions/${localStorage.currentTransactionId}?includeTransactionMilestones=true`

        dispatch(updateLoading(true))

        try {
            const res = await api.get(singleTransactionsURL)
            const { cashOfferTransaction } = res
            dispatch(updateSingleTransaction({ ...cashOfferTransaction }))
            window.currentTransaction = res.cashOfferTransaction
            window.transactionInfo = res.transactionStatusDetails

            return res
        } catch (e) {
            if (e?.error?.code === 'Forbidden' || e?.error?.code === 'ClientError') {
                let msg = e.error.message
                if (e?.error?.code === 'Forbidden') {
                    msg = 'You are not authorized to access this property.'
                }
                window.location.href = `/sell/dashboard?error=${convertStringToBase64(msg)}`
            } else {
                toast.error('Something went wrong.')
                console.error(e)
            }
        } finally {
            dispatch(updateLoading(false))
        }
    }

    return {
        fetch: fetchSingleTranscations,
    }
}

export default useSingleTransactions
