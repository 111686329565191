import CheckColoredIcon from './assets/check-colored-icon.svg'
import CheckFadedIcon from './assets/check-faded-icon.svg'
import {
    NextCard,
    NextHeader,
    NextHeading,
    ActionButton,
    NextScheduleInfo,
    NextAnchor,
    Divider,
    BottomInfo,
    BottomInfoCont,
    BottomInfoImage,
    BottomInfoData,
} from './Styles'

const WhatsNext = () => {
    return (
        <NextCard borderRadius="lg">
            <NextHeader>
                <NextHeading>What’s Next</NextHeading>
            </NextHeader>
            <ActionButton id="fs_sell_overview_walkThrough" variant="primary">
                Virtual Walkthrough
            </ActionButton>
            <NextScheduleInfo>Wednesday, January 25 8:30 – 9:00pm</NextScheduleInfo>
            <NextAnchor href="#">View All Upcoming Events</NextAnchor>
            <Divider />
            <BottomInfo>
                <BottomInfoCont>
                    <BottomInfoImage src={CheckColoredIcon} alt="" />
                    <BottomInfoData>Free Local Move</BottomInfoData>
                </BottomInfoCont>
                <BottomInfoCont>
                    <BottomInfoImage src={CheckFadedIcon} alt="" />
                    <BottomInfoData>Extended Stay</BottomInfoData>
                </BottomInfoCont>
            </BottomInfo>
        </NextCard>
    )
}

export default WhatsNext
