import { createSlice } from '@reduxjs/toolkit'

const todosSlice = createSlice({
    name: 'todos',
    initialState: {
        todos: [],
        loading: false,
    },
    reducers: {
        updateTodos: (state, action) => ({
            ...state,
            todos: action.payload,
        }),
        updateLoading: (state, action) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const { updateTodos, updateLoading } = todosSlice.actions
export default todosSlice.reducer
