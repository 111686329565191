import styled from 'styled-components'

export const Container = styled.div`
    padding: 0px 20px;
    height: auto;
    @media screen and (min-width: 1423px) {
        width: 100%;
        max-width: 1347px;
        margin: 0 auto;
        padding: 0px;
    }
`
