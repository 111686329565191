import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { CollapsibleCont } from './Styles'

const propTypes = {
    className: PropTypes.string,
    testid: PropTypes.string,
    isOpen: PropTypes.bool,
    renderLink: PropTypes.func,
    renderContent: PropTypes.func.isRequired,
}

const defaultProps = {
    className: undefined,
    testid: 'collapsable',
    isOpen: undefined,
    renderLink: () => {},
    renderContent: () => {},
}

function Collapsible({
    className,
    testid,
    isOpen: propsIsOpen,
    renderLink,
    renderContent,
    ...rest
}) {
    const [stateIsOpen, setStateOpen] = useState(false)
    const isControlled = typeof propsIsOpen === 'boolean'
    const isOpen = isControlled ? propsIsOpen : stateIsOpen

    const toggle = useCallback(() => {
        setStateOpen((prevState) => !prevState)
    }, [])

    return (
        <CollapsibleCont className={`collapsible ${className}`} {...rest}>
            {!isControlled && renderLink({ toggle, isOpen })}
            {isOpen && <div className="content">{renderContent({ toggle, isOpen })}</div>}
        </CollapsibleCont>
    )
}

Collapsible.propTypes = propTypes
Collapsible.defaultProps = defaultProps

export default Collapsible
