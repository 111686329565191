import { DefaultDiv, DefaultImg, DefaultTitle, DefaultTagLine } from './Styles'
import ButterFlyImage from './assets/butterfly.svg'

const Default = () => {
    return (
        <DefaultDiv>
            <DefaultImg src={ButterFlyImage} alt="" />
            <div>
                <DefaultTitle>No upcoming events.</DefaultTitle>
                <DefaultTagLine>Nothing to see here and apparently nothing to do</DefaultTagLine>
            </div>
        </DefaultDiv>
    )
}

export default Default
