import styled, { keyframes } from 'styled-components'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

export const QuickViewCont = styled.div`
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    gap: 30px;
    padding: 20px 23px;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    background-color: #f7fcfe;
    height: auto;
    @media (max-width: 750px) {
        flex-direction: column;
        background-color: white;
        border: none;
        padding: 0px;
    }
`
export const TrackerAndResourceCenterCont = styled.div`
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    display: flex;
    gap: 30px;
    padding: 30px 0px 50px 0px;
    @media (min-width: 481px) and (max-width: 820px) {
        gap: 0px;
    }
    @media (max-width: 480px) {
        flex-direction: column;
    }
    @media (max-width: 690px) {
        flex-direction: column;
    }
`

export const TrackerAndResourceCenterAndResCenterCont = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 30px;
    width: 100%;
    @media (max-width: 480px) {
        margin-bottom: -41px;
        gap: 0px;
    }
`
export const QuickLinkAndImportantDate = styled.div`
    display: flex;
    gap: 30px;
    @media (max-width: 1250px) {
        flex-direction: column;
    }
`
