import { Container, MainContainer, ModalBody, Text } from './Styles'

import VideoThumbnail from 'shared/asstes/video-thumbnail.png'
import ArrowUpOrange from 'shared/asstes/curved-arrow-up-orange.svg'
import Modal from '../Modal'
import ScreenLoader from '../ScreenLoader'

const CashOfferVideoCard = ({ state }) => {
    const video1Link =
        'https://player.vimeo.com/video/871985508?h=0ab8e7b7bc&autoplay=1&autopause=0&quality_selector=1&player_id=0&app_id=58479%5C'
    const video2Link =
        'https://player.vimeo.com/video/826280112?h=50ad93d192&autoplay=1&autopause=0&quality_selector=1&player_id=0&app_id=58479'

    let linkToUse

    if (state.solution === 'CashPlusList') {
        linkToUse = video2Link
    } else {
        linkToUse = video1Link
    }

    return (
        <MainContainer>
            <Container>
                <Modal
                    variableWidth
                    backgroundColor="white"
                    renderLink={({ open }) => (
                        <>
                            {state.solution === 'CashPlusList' ? (
                                <Text onClick={open} style={{ cursor: 'pointer' }}>
                                    Not sure which option
                                    <br />
                                    is right for you?
                                </Text>
                            ) : (
                                <Text onClick={open} style={{ cursor: 'pointer' }}>
                                    Learn About What's Next!
                                </Text>
                            )}

                            <img src={ArrowUpOrange} alt="" className="arrow" />
                            <img src={VideoThumbnail} alt="" className="thumbnail" onClick={open} />
                        </>
                    )}
                    renderContent={() => (
                        <ModalBody>
                            <div
                                style={{
                                    height: '90px',
                                    marginTop: '-30px',
                                    width: '100%',
                                    position: 'absolute',
                                }}
                            >
                                <ScreenLoader sL full={false} />
                            </div>
                            <iframe
                                src={linkToUse}
                                width="100%"
                                height="338px"
                                frameBorder="0"
                                allowFullScreen
                            ></iframe>
                        </ModalBody>
                    )}
                />
            </Container>
        </MainContainer>
    )
}

export default CashOfferVideoCard
