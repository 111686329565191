export const TeamsArray = [
    'TransactionManager',
    'SolutionExpert',
    'SolutionAdvisor',
    'InHomeOfferpadRepresentative',
    'TitleContact',
    'MovingCompanyContact',
]

export const TeamsStatus = {
    TRANSACTION_MANAGER: 'TransactionManager',
    SOLUTION_EXPERT: 'SolutionExpert',
    SOLUTION_ADVISOR: 'SolutionAdvisor',
    SUCCESS_MANAGER: 'SuccessManager',
    IN_HOME_OFFERPAD_REPRESENTATIVE: 'InHomeOfferpadRepresentative',
    TITLE_CONTACT: 'TitleContact',
    MOVING_COMPANY_CONTACT: 'MovingCompanyContact',
}

export const TeamsDescription = {
    [TeamsStatus.TRANSACTION_MANAGER]: ({ firstName = '' }) =>
        `${firstName} is your main contact at Offerpad and will typically be the person to contact with questions and for details about your transaction.`,
    [TeamsStatus.SOLUTION_EXPERT]: ({ firstName = '' }) =>
        `${firstName} is your initial contact at Offerpad and is here to help you understand all of your options when it comes to selling your home to Offerpad.`,
    [TeamsStatus.SOLUTION_ADVISOR]: ({ firstName = '' }) =>
        `${firstName} is your initial contact at Offerpad and is here to help you understand all of your options when it comes to selling your home to Offerpad.`,
    [TeamsStatus.SUCCESS_MANAGER]: ({ firstName = '' }) =>
        `${firstName} is your main contact at Offerpad and will typically be the person to contact with questions and for details about your transaction.`,
    [TeamsStatus.IN_HOME_OFFERPAD_REPRESENTATIVE]: ({ firstName = '' }) =>
        `${firstName} is the company representative that visits your home in person. ${firstName} is a great resource for questions about the Offerpad sales process!`,
    [TeamsStatus.TITLE_CONTACT]: ({ firstName = '', companyName = '' }) =>
        `${firstName} is part of the team at ${companyName} who handles the earnest account and acts as an intermediary between all parties to record the necessary legal documents.`,
    [TeamsStatus.MOVING_COMPANY_CONTACT]: ({ companyName = '' }) =>
        `${companyName} is our preferred partner for your free local move. Offerpad has picked (${companyName}) because of their commitment to an amazing customer experience. Feel free to reach out to ${companyName} with any questions about your move.`,
}
