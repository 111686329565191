import {
    OutlineBox,
    Card,
    Header,
    HeaderBodyCont,
    Heading,
    Tagline,
    Badge,
    Divider,
    Description,
    Footer,
    FooterDiv,
    FooterImg,
    FooterAnchor,
    HeaderImg,
    ExpandArea,
    ExpandAreaImg,
    Border,
} from './Styles'
import CalenderIcon from './assets/Calender_icon.svg'
import EmailIcon from './assets/email_icon.svg'
import TelephoneIcon from './assets/phone_icon.svg'
import Arrow from './assets/arrow-down.svg'
import Default from './default'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { formatPhoneNumber } from 'shared/utils/formatter'

const YourTeam = () => {
    const { teams } = useSelector((state) => state.teams)

    const loading = useSelector((state) => state.loading.loading)

    const callback = (e) => {
        const parent = e.target.parentNode
        const grandParent = parent.parentNode
        if (window.getComputedStyle(grandParent).height !== '108px') {
            grandParent.style.height = '108px'
            e.target.style.transform = 'rotate(0deg)'
        } else {
            grandParent.style.height = 'calc(100% + 12px)'
            e.target.style.transform = 'rotate(-180deg)'
        }
    }

    const handleError = (e) => {
        e.target.style.display = 'none'
    }

    useEffect(() => {
        if (!loading) {
            const elements = document.querySelectorAll('[data-expand-click]')
            elements.forEach((element) => element.addEventListener('click', callback))
            return () => {
                elements.forEach((element) => element.removeEventListener('click', callback))
            }
        }
    }, [])

    return (
        <>
            {!loading && teams.length !== 0 ? (
                <OutlineBox>
                    {teams.map((d, i) => (
                        <Card
                            important={i === 0 ? true : false}
                            key={i + Date.now()}
                            length={teams.length}
                        >
                            <Header>
                                <HeaderBodyCont>
                                    <div>
                                        {d.role !== 'TitleContact' &&
                                        d.role !== 'MovingCompanyContact' ? (
                                            <Heading>
                                                {d.firstName} {d.lastName}
                                            </Heading>
                                        ) : (
                                            <Heading>{d.companyName}</Heading>
                                        )}
                                        <Tagline showBadge={i === 0 ? true : false}>
                                            {d.roleLabel}
                                            <Badge>Main Contact</Badge>
                                        </Tagline>
                                    </div>
                                    <HeaderImg src={d.iconUrl} alt="" show onError={handleError} />
                                </HeaderBodyCont>
                            </Header>
                            {i === 0 ? <Divider /> : <Border />}
                            <Description>
                                {d.role === 'TitleContact' && (
                                    <span className="name">
                                        {d.firstName} {d.lastName}
                                    </span>
                                )}
                                {d.description}
                            </Description>
                            <Footer>
                                {d.schedulingLink && (
                                    <FooterDiv>
                                        <FooterImg src={CalenderIcon} alt="" />
                                        <FooterAnchor
                                            href={d.schedulingLink + '/15-min'}
                                            target="_blank"
                                        >
                                            Schedule a call with {d.firstName}
                                        </FooterAnchor>
                                    </FooterDiv>
                                )}
                                {d.email && (
                                    <FooterDiv>
                                        <FooterImg src={EmailIcon} alt="" />
                                        <FooterAnchor href={`mailto:${d.email}`}>
                                            {d.email}
                                        </FooterAnchor>
                                    </FooterDiv>
                                )}
                                {d.phoneNumber && (
                                    <FooterDiv>
                                        <FooterImg src={TelephoneIcon} alt="" />
                                        <FooterAnchor
                                            href={`tel:${formatPhoneNumber(d.phoneNumber)}}`}
                                        >
                                            {formatPhoneNumber(d.phoneNumber)}
                                        </FooterAnchor>
                                    </FooterDiv>
                                )}
                            </Footer>
                            <ExpandArea important={i === 0 ? true : false} key={i + Date.now()}>
                                <ExpandAreaImg src={Arrow} alt="" data-expand-click />
                            </ExpandArea>
                        </Card>
                    ))}
                </OutlineBox>
            ) : (
                <Default />
            )}
        </>
    )
}

export default YourTeam
