import { Cont } from './Styles'
import { useWizard } from 'react-use-wizard'
import CoePointer from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/coe-info-pointer-free.png'
import Arrow from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/left-arrow-free-local.svg'
import { useSelector } from 'react-redux'

const Step3 = ({ isTrue }) => {
    const { goToStep } = useWizard()
    const agent = window.currentTransaction?.isListedByAgent
    const transcation = useSelector((state) => state.singleTransaction.transaction)

    return (
        <Cont>
            <div className="heading-extra-container">
                <div
                    id="fs_sell_freeLocalMove_previous"
                    className="back-button"
                    onClick={() => goToStep(0)}
                >
                    <img src={Arrow} alt="" />
                </div>
                <h1>Free Local Move</h1>
            </div>
            <br />
            <Cont moreDetails>
                <div className="free-local-info">
                    <img src={CoePointer} alt="" />
                    <p>
                        In order to take advantage of the free local move program, Offerpad may
                        require a minimum of a 7 to 21 days’ notice (depending upon the market and
                        time of year) prior to the close of escrow.
                    </p>
                </div>
                <div className="free-local-info">
                    <img src={CoePointer} alt="" />
                    <p>
                        Additional square footage, weight and other services may be purchased and
                        selected by the customer directly from the moving company.
                    </p>
                </div>
                <div className="free-local-info">
                    <img src={CoePointer} alt="" />
                    <p>
                        Our Free Local Move is only available to customers who are selling their
                        home to Offerpad.
                    </p>
                </div>
                <div className="free-local-info">
                    <img src={CoePointer} alt="" />
                    <p>
                        The move must originate from the home purchased by Offerpad and delivered to
                        a single local destination/address within a 50-mile radius of customer's
                        origin zip code.
                    </p>
                </div>
                <div className="free-local-info">
                    <img src={CoePointer} alt="" />
                    {agent === true ? (
                        <p>
                            With Offerpad's Extended Stay program, free local moves are valid for{' '}
                            {transcation.extendedStay === null
                                ? '3'
                                : transcation?.extendedStay?.extendedStayDayEligibleDays}{' '}
                            days after closing.
                        </p>
                    ) : (
                        <p>
                            With Offerpad's Extended Stay program, free local moves are valid for{' '}
                            {transcation.extendedStay === null ||
                            transcation?.extendedStay?.extendedStayDayEligibleDays === 0
                                ? '3'
                                : transcation?.extendedStay?.extendedStayDayEligibleDays}{' '}
                            days after we purchase your home, depending on the Extended Stay option
                            you choose.
                        </p>
                    )}
                </div>
            </Cont>
        </Cont>
    )
}

export default Step3
