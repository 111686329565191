import styled, { css, keyframes } from 'styled-components'
import { font, mixin, color, breakPoints } from 'shared/utils/styles'
import { Link } from 'react-router-dom'
import { Form } from 'shared/components'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
     }
`

export const Cont = styled.div`
    position: relative;
    padding: 65px 0px;
    @media (max-width: 750px) {
        padding: 35px 0px;
        width: 100%;
    }
    @media (max-width: 480px) {
        padding: 0px 20px;
        width: 100%;
    }
`

export const MenuCont = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    @media (max-width: 750px) {
        gap: 50px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: #f7fcfe;
        margin: 0px -20px;
        position: sticky;
        top: -1px;
        z-index: 999;
    }
    @media (max-width: 480px) {
        gap: 50px;
        padding: 10px 20px 0px 20px;
        justify-content: space-between;
        background-color: #f7fcfe;
        margin: 0px -20px;
        position: sticky;
        top: -1px;
        z-index: 999;
    }
`
const borderExpand = keyframes`
    from {
        border-bottom: 0px;
        color: #898989;
    }
    to {
        border-bottom: 4px solid #ef7724;
        color: black;
    }
`

export const Menu = styled(Link)`
    ${font.medium};
    ${font.size(30)}
    border-radius: 0;
    background-color: transparent;
    color: #898989;
    padding: 0;
    @media (max-width: 480px) {
        ${font.size(20)};
        padding-bottom: 10px;
    }
    ${(props) =>
        props.active === 'true' &&
        css`
            ${font.bold}
            color: #ef7724 !important;
            border-bottom: 4px solid #ef7724;
            animation-name: ${borderExpand};
            animation-duration: 0.3s;
            animation-fill-mode: forwards;
            padding-bottom: 0.4rem;
            @media (max-width: 480px) {
                color: black !important;
            }
        `}
    &:hover {
        background-color: white !important;
    }
`

export const StyledLogo = styled.img`
    width: 415px;
    @media (max-width: 480px) {
        width: 120px;
    }
`

export const PropertyViewCont = styled.div`
    width: auto;
    max-width: 810px;
    margin: 0 auto;
    padding: 30px;
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @media (max-width: 480px) {
        padding: 20px 0px;
    }
`

export const PropertyViewHeader = styled.div`
    text-align: center;
    ${font.size(24)};
    color: #231f20;
    ${font.bold};
    margin-bottom: 30px;
    @media (max-width: 480px) {
        ${font.size(18)};
        margin-top: 20px;
    }
`

export const CardContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 50px 50px;
    @media (max-width: 760px) {
        grid-template-columns: 1fr;
    }
`

export const PropertyViewCard = styled.div`
    width: 100%;
    padding: 13px;
    display: flex;
    flex-direction: column;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    ${mixin.clickable};
    ${(props) =>
        !!props.selected &&
        css`
            border-color: #ec7625;
        `}
    @media (max-width: 480px) {
        padding: 0px;
    }
`

export const ImgCont = styled.div`
    border-radius: 10px;
    overflow: hidden;
    height: 142px;
    @media (max-width: 480px) {
        height: 154px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
`

export const PropertyViewImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 147.13px;
`

export const PropertyViewFooterCont = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 480px) {
        margin: 0px;
        padding: 20px 13px;
    }
`

export const PropertyViewFooterHeader = styled.div`
    ${font.size(18)};
    color: #231f20;
    ${font.bold};
`

export const PropertyViewFooterPlace = styled.div`
    color: #61657e;
    ${font.size(14)};
    ${font.bold};
`

export const PropertyViewFooterStatus = styled.div`
    color: #ef7724;
    ${font.size(20)};
    ${font.bold};
    margin-top: 5.43px;
    @media (max-width: 480px) {
        ${font.size(18)};
        line-height: 32px;
    }
`

export const PropertyViewFooterTextCont = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.49px;
`

export const PropertyFooterIcon = styled.img`
    margin-top: -30px;
    margin-right: -25px;
`

export const FooterText = styled.div`
    text-align: center;
    margin-top: 41.88px;
    color: #000000;
    ${font.bold};
    ${font.size(20)};
    @media (max-width: 450px) {
        ${font.size(15)}
    }
`

export const FooterLink = styled.a`
    text-decoration: underline;
    color: #1b6775;
`

export const DefaultCont = styled.div`
    width: 100%;
    max-width: 750px;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    padding: 23px 32px;
    margin: 0 auto;
    position: relative;
    margin-top: 165px;
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @media (max-width: 750px) {
        height: auto;
        padding: 40px 30px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 15rem;
        width: auto;
    }
    @media (max-width: 480px) {
        margin: 250px 0 0 0;
        border: none;
        padding: 40px 36px;
    }
`

export const DefaultImageCont = styled.div`
    position: absolute;
    top: -55%;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: white;
`
export const ImageCont = styled.div`
    position: absolute;
    top: -55%;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: white;
    margin-top: 5rem;
`

export const DefaultImage = styled.img`
    max-width: 275px;
`

export const DefaultParagraph = styled.div`
    width: 100%;
    max-width: 750px;
    padding: 24px 26px;
    margin: 0 auto;
    position: relative;
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: 15px;

    @media (max-width: 480px) {
        text-align: center;
        padding: 10px 10px;
        margin-top: 15px;
    }

    h2 {
        ${font.medium};
        ${font.size(20)};
        line-height: 24px;
        color: #000000;
    }

    p {
        ${font.regular};
        ${font.size(15)};
        line-height: 30px;
        color: #525659;
        @media (max-width: 480px) {
            line-height: 25px;
        }
    }
`

export const DefaultFooter = styled.div`
    margin-top: 150px;
    display: flex;
    gap: 87px;
    margin-bottom: 4rem;
    align-items: center;
    @media (max-width: 750px) {
        flex-direction: column;
        gap: 40px;
        margin-top: 80px;
    }
`

export const DefaultBuyFooter = styled.div`
    margin-top: 120px;
    margin-bottom: 1.5rem;
    display: flex;
    gap: 50px;
    align-items: center;
    @media (max-width: 750px) {
        flex-direction: column;
        gap: 40px;
        margin-top: 80px;
    }
`

export const DefaultFooterCont = styled.div`
    position: relative;
    // margin-top: 2.5rem;
`

export const DefaultFooterImg = styled.img`
    width: 120px;
    height: 100px;
    position: absolute;
    right: -30%;
    top: -40%;
    @media (max-width: 750px) {
        display: none;
    }
`

export const BuyFooterImg = styled.img`
    position: absolute;
    right: -17%;
    top: -4rem;
    @media (max-width: 750px) {
        display: none;
    }
`

export const DeaultFooterHeading = styled.div`
    ${font.black};
    ${font.size(24)};
    line-height: 33px;
    color: #231f20;
    @media (max-width: 750px) {
        text-align: center;
    }
    @media (max-width: 480px) {
        ${font.size(20)};
        line-height: 24px;
    }
`

export const DefaultFootertagline = styled.div`
    margin-top: 15px;
    ${font.medium};
    ${font.size(15)};
    line-height: 25px;
    color: #525659;
    @media (max-width: 750px) {
        text-align: center;
    }
    @media (max-width: 480px) {
        ${font.size(15)};
        line-height: 23px;
        margin-top: 10px;
    }
`

export const DefaultFooterActionButton = styled.span`
    width: 100%;
    max-width: 237px;
    height: 53px;
    background-color: ${color.primary};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    ${font.size(18)};
    ${font.medium};
    line-height: 21.6px;
    cursor: pointer;
    @media (max-width: 480px) {
        max-width: 100%;
    }
`

export const DefaultBuyActionButton = styled.button`
    width: 100%;
    max-width: 200px;
    height: 53px;
    background-color: ${color.primary};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    ${font.size(18)};
    ${font.medium};
    line-height: 21.6px;
    cursor: pointer;
    margin-top: 2rem;
    margin-left: auto;
    @media (max-width: 480px) {
        max-width: 100%;
    }
`

export const Alert = styled.div`
    width: 100%;
    max-width: 750px;
    height: 100px;
    border-radius: 10px;
    border: 1px solid ${color.primary};
    background-color: rgba(231, 240, 241, 0.5);
    padding: 13px 14px;
    margin: 0 auto;
    position: relative;
    margin-top: 31px;
    display: flex;
    gap: 10px;
    border-left: 14px solid ${color.primary};
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @media (max-width: 750px) {
        height: auto;
        width: auto;
        margin-left: 10px;
        margin-right: 10px;
        flex-direction: column;
    }
    @media (max-width: 480px) {
        margin-bottom: 30px;
    }
`

export const AlertCont = styled.div``

export const AlterHeadingCont = styled.div`
    display: flex;
    align-items: center;
`

export const AlertHeadingImg = styled.img``

export const AlertHeading = styled.div`
    ${font.black};
    ${font.size(20)};
    line-height: 24px;
    color: #000000;
    margin-left: 10px;
`

export const AlertTagline = styled.div`
    ${font.medium};
    ${font.size(13)};
    line-height: 20px;
    color: #000000;
    margin-top: 10px;
`

export const AlertImg = styled.img`
    width: 50px;
    @media (max-width: 750px) {
        align-self: center;
        margin-top: 10px;
    }
`

export const InfoBox = styled.div`
    cursor: pointer;
    width: 100%;
    max-width: 750px;
    background: #f7fcfe;
    border: 1px solid #f3f5f8;
    border-radius: 10px;
    padding: 24px 26px;
    margin: 0 auto;
    position: relative;
    margin-top: 35px;
    animation: ${slideLeft} ease 0.7s;
    animation-delay: 0.5s;
    opacity: 0;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    display: flex;
    align-items: center;
    gap: 22px;
    @media (max-width: 750px) {
        height: auto;
        padding: 40px 30px;
        margin-left: 10px;
        margin-right: 10px;
        width: auto;
    }
    @media (max-width: 480px) {
        margin: 35px -20px 0px -20px;
        border-radius: 0px;
        padding: 20px;
    }

    .info-cont {
        h2 {
            ${font.size(20)}
            line-height: 24px;
            ${font.bold};
            color: black;
            @media (max-width: 480px) {
                ${font.size(16)}
                line-height: 24px;
            }
        }
        p {
            ${font.size(15)}
            line-height: 25px;
            ${font.medium};
            color: #525659;
            margin-top: 12px;
            @media (max-width: 480px) {
                ${font.size(10)}
                line-height: 15px;
                margin-top: 5px;
            }
        }
        img {
            cursor: pointer;
        }
    }
    .arrow-down {
        background-color: #ffffff;
        padding: 15px 13.5px;
        border-radius: 50%;
        transform: rotate(-90deg);
        transition: transform 0.3s;
        margin-left: auto;
        border: 1px solid #dbe5e7;
        cursor: pointer;
    }
`

export const BuyInfoBox = styled.div`
    width: 100%;
    max-width: 750px;
    background: #f7fcfe;
    border: 1px solid #ec7625;
    border-left: 14px solid #ec7625;
    border-radius: 10px;
    padding: 24px 26px;
    margin: 0 auto;
    position: relative;
    margin-top: 35px;
    animation: ${slideLeft} ease 0.7s;
    animation-delay: 0.5s;
    opacity: 0;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    display: flex;
    align-items: center;
    gap: 22px;
    @media (max-width: 750px) {
        height: auto;
        padding: 40px 30px;
        margin-left: 10px;
        margin-right: 10px;
        width: auto;
    }
    @media (max-width: 480px) {
        margin: 35px -20px 0px -20px;
        border-radius: 0px;
        padding: 20px;
    }

    .text-container {
        display: flex;
        flex-direction: column;
    }

    .text-container-top {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .info-cont {
        h2 {
            ${font.size(20)}
            line-height: 24px;
            ${font.black};
            color: black;
            @media (max-width: 480px) {
                ${font.size(16)}
                line-height: 24px;
            }
        }
        p {
            ${font.size(13)}
            line-height: 20px;
            ${font.regular};
            color: black;
            margin-top: 12px;
            @media (max-width: 480px) {
                ${font.size(10)}
                line-height: 15px;
                margin-top: 5px;
            }
        }
    }
    .arrow-down {
        padding: 15px 13.5px;
        border-radius: 50%;
        transition: transform 0.3s;
        margin-left: auto;
        margin-bottom: -1.5rem;
    }
`

const opacity = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const slideLefts = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

export const ContPopup = styled.div`
    padding: 70px 50px;
    position: relative;
    animation: ${opacity} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @media (max-width: 480px) {
        padding: 75px 40px 32px 40px;
        display: flex;
        flex-direction: column;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
    }
`

export const Step = styled.div`
    position: relative;
    animation: ${slideLefts} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    .buy-button {
        background: #ffffff;
        border: 1px solid #bdbdbd;
        border-radius: 4px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 70px;
        gap: 10px;
        cursor: pointer;
        margin-top: 4rem;
    }
    .success {
        padding: 0px 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 480px) {
            padding: 0px;
        }
        img {
            width: 100px;
            @media (max-width: 480px) {
                margin-top: 150px;
                margin-bottom: 20px;
            }
        }
        h2 {
            text-align: center;
        }
        p {
            margin-top: 18px;
            text-align: center;
        }
    }
    h2 {
        ${font.size(32)};
        ${font.black};
        line-height: 44px;
        color: black;
        @media (max-width: 480px) {
            ${font.size(22)};
            line-height: 26.4px;
        }
    }
    p {
        ${font.size(15)};
        ${font.regular};
        line-height: 25px;
        color: black;
        @media (max-width: 480px) {
            margin-top: 16px;
        }
    }
    .method {
        ${font.size(18)};
        ${font.black};
        line-height: 36px;
        color: black;
        margin-top: 34px;
    }
    .info {
        height: 100%;
        max-height: 409px;
        overflow-y: scroll;
        scroll-padding: 10px;
        margin-top: 10px;
        padding: 10px 10px 10px 0px;
        ${font.size(18)};
        ${font.regular};
        line-height: 32px;
        color: black;
        @media (max-width: 480px) {
            max-height: calc(100vh - 210px);
            flex: 1;
            padding: 0px;
            margin-bottom: 12px;
        }
    }
    .btn-cont {
        margin-top: 30px;
        display: flex;
        gap: 22px;
        @media (max-width: 480px) {
            margin: 0;
            margin-top: auto;
        }

        @media (max-height: 780px) {
            margin: 60px 0px 0px 0px !important;
        }
        button {
            height: 62px;
            padding: 20px 44px;
            width: auto;
            background: #ffffff;
            border: 1px solid #bdbdbd;
            border-radius: 4px;
            ${font.size(18)};
            ${font.medium};
            line-height: 22px;
            color: #525659;
            cursor: pointer;
            &:disabled {
                background: rgba(236, 118, 37, 0.5);
                cursor: initial;
            }
            @media (max-width: 480px) {
                height: 40px;
                width: 100%;
                padding: 10px;
                ${font.size(16)};
                line-height: 19.2px;
            }
        }
        .next {
            width: 180px;
            background: #ec7625;
            color: white;
            @media (max-width: 480px) {
                width: 100%;
            }
        }
        .no-extra {
            @media (max-width: 480px) {
                height: 62px;
                ${font.size(18)};
                ${font.medium};
                line-height: 22px;
            }
        }
        .stick-bottom {
            margin: auto 0;
        }
    }
`

export const FormFieldRow = styled.div`
    display: flex;
    gap: 20px;
    justify-content: space-between;
    @media (max-width: ${breakPoints.sm}) {
        flex-direction: column;
        gap: 0;
    }
    ${(props) =>
        props.noSpaceBetween &&
        css`
            justify-content: flex-start;
            gap: 51px;
            @media (max-width: 480px) {
                flex-direction: row !important;
                gap: 51px;
            }
        `}
`

export const FormFieldCol = styled.div`
    ${(props) =>
        !props.noSpaceBetween &&
        css`
            flex: 1;
        `}
`

export const FormElement = styled(Form.Element)`
    width: 100%;
    max-width: 694px;
    display: flex;
    flex-direction: column;
    @media (max-width: 480px) {
        max-width: 100%;
        height: 100%;
    }
    .hide-on-mobile {
        @media (max-width: 480px) {
            display: none;
        }
    }
`
export const HiddenButton = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
`
