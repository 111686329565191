import { ArrowContainer, Container, Icon, IconContainer, MenuLink, Text } from './Styles'
import RightArrowIcon from 'shared/asstes/rightArrowIcon.svg'

export const NavbarMenuItem = ({
    to = '',
    leftIcon = null,
    text = '',
    dangerText = false,
    ...rest
}) => {
    return (
        <MenuLink to={to} {...rest}>
            <Container>
                {leftIcon && (
                    <IconContainer icon={leftIcon}>
                        <Icon src={leftIcon} alt="" />
                    </IconContainer>
                )}
                <Text danger={dangerText}>{text}</Text>
            </Container>
            <ArrowContainer>
                <Icon src={RightArrowIcon} alt="" />
            </ArrowContainer>
        </MenuLink>
    )
}

export default NavbarMenuItem
