import { Wrapper } from 'OnDemandActions/Sell/CodDateChange/shared/Styles'
import NoAddendum from 'OnDemandActions/Sell/CodDateChange/shared/assets/coe-default.svg'

const Default = ({ es }) => {
    const agent = window.currentTransaction?.isListedByAgent

    return (
        <Wrapper>
            <div className="default">
                <img src={NoAddendum} alt="" />
                {agent !== true ? (
                    <h1>Pick your closing date</h1>
                ) : (
                    <h1>Closing dates are flexible</h1>
                )}
                {agent !== true ? (
                    <p>
                        With Offerpad, you have up to{' '}
                        {es?.extendedStayDayEligibleDays === 5 ? '120' : '60'} days to close.{' '}
                        {es !== null &&
                            `Plus
                        you can stay up to ${es?.extendedStayDayEligibleDays || 3}
                        days after so you don’t have to rush to move. `}
                        <br />
                        Pretty sweet, huh?
                    </p>
                ) : (
                    <p>
                        With Offerpad, your seller has up to{' '}
                        {es?.extendedStayDayEligibleDays === 5 ? '120' : '60'} days to close.{' '}
                        {es !== null &&
                            `Plus
                        you can stay up to ${es?.extendedStayDayEligibleDays || 3}
                        days after so you don’t have to rush to move. `}{' '}
                        <br />
                        Pretty sweet, huh?
                    </p>
                )}
            </div>
        </Wrapper>
    )
}

export default Default
