import { Container, Icon, Text } from './Styles'

const NavbarRoundMenu = ({
    icon = null,
    alt = '',
    text = null,
    backgroundColor = 'white',
    textColor = '#4A5E88',
    elevation = true,
    ...rest
}) => {
    return (
        <Container backgroundColor={backgroundColor} elevation={elevation.toString()} {...rest}>
            {text && icon === null && <Text textColor={textColor}>{text}</Text>}
            {icon && text === null && <Icon src={icon} alt={alt} />}
        </Container>
    )
}

export default NavbarRoundMenu
