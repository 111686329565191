import {
    ActionButton,
    CardBadge,
    CardBody,
    CardContainer,
    CardHeading,
    CardImage,
    CardSubHeading,
    FooterText,
} from './Styles'
import PropertyPlaceholderImage from 'shared/asstes/property-placeholder-img.svg'

const PropertyCard = ({
    image = null,
    badge = null,
    heading = null,
    subHeading = null,
    ctaText = null,
    ctaId = '',
    ctaClick = () => {},
    footerText = null,
}) => {
    const handleOnError = (e) => {
        e.target.src = PropertyPlaceholderImage
    }

    return (
        <CardContainer>
            <CardImage
                src={image || PropertyPlaceholderImage}
                onError={handleOnError}
                loading="eager"
            />
            {badge && <CardBadge>{badge}</CardBadge>}
            <CardBody>
                {heading && <CardHeading>{heading}</CardHeading>}
                {subHeading && <CardSubHeading>{subHeading}</CardSubHeading>}
                {ctaText && (
                    <ActionButton id={ctaId} onClick={ctaClick}>
                        {ctaText}
                    </ActionButton>
                )}
                {footerText && <FooterText>{footerText}</FooterText>}
            </CardBody>
        </CardContainer>
    )
}

export default PropertyCard
