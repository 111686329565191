import styled, { keyframes } from 'styled-components'
import { font } from 'shared/utils/styles'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

const opacity = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const Wrapper = styled.div`
    background-color: white;
    width: 888px;
    max-width: calc(100vw - 100px);
    height: 100%;
    max-height: calc(100vh - 100px);
    border-radius: 10px;
    overflow: hidden;
    animation: ${opacity} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    overflow-y: auto;
    @media (max-width: 480px) {
        max-width: 100%;
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
    }

    div {
        animation: ${slideLeft} ease 0.7s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    .default {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 94px 130px;
        @media (max-width: 480px) {
            padding: 75px 20px 20px 20px;
        }
        img {
            @media (max-width: 480px) {
                margin-top: -75px;
            }
        }
        h1 {
            ${font.black};
            ${font.size(22)}
            line-height: 26px;
            color: black;
            margin-top: 10px;
        }
        p {
            text-align: center;
            ${font.medium};
            ${font.size(16)}
            line-height: 32px;
            color: #525659;
            margin-top: 30px;
        }
    }
`
