import PropTypes from 'prop-types'
import { Div, StyledSpinner } from './Styles'

const propTypes = {
    full: PropTypes.bool,
    size: PropTypes.number,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    sL: PropTypes.bool,
    border: PropTypes.string,
    screenWidth: PropTypes.string,
    className: PropTypes.string,
    loadingText: PropTypes.string,
}

const defaultProps = {
    full: true,
    size: 100,
    color: '#EF7724',
    backgroundColor: 'rgba(255, 255, 255, 0.98)',
    sL: false,
    border: '0',
    screenWidth: '100%',
    className: undefined,
    loadingText: null,
}

const ScreenLoader = ({
    full,
    size,
    color,
    backgroundColor,
    sL,
    border,
    screenWidth,
    className,
    loadingText,
}) => {
    return (
        sL && (
            <Div
                full={full}
                border={border}
                backgroundColor={backgroundColor}
                screenWidth={screenWidth}
                className={className}
            >
                <StyledSpinner size={size} color={color} />
                {loadingText && loadingText}
            </Div>
        )
    )
}

ScreenLoader.propTypes = propTypes
ScreenLoader.defaultProps = defaultProps

export default ScreenLoader
