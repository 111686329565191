import { font } from 'shared/utils/styles'
import styled, { css } from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 21px 50px;
    margin: 20px -36px 0px -36px;
    border-radius: 12px 12px 0px 0px;
    border: 0.25px solid #1b6775;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
    @media screen and (max-width: 480px) {
        margin: 20px -20px 0px -20px;
        flex-direction: column;
        justify-content: start;
        gap: 20px;
        border-radius: 0;
        padding: 20px;
    }
    ${(props) =>
        props.compare &&
        css`
            width: 100%;
            max-width: 960px;
            margin: 50px auto 50px auto;
            border: 0.25px solid #1b6775;
            background: #fff5ee;
            border-radius: 12px;
            padding: 20px;
            justify-content: center;
            gap: 40px;
            align-items: center;
        `}
`

export const MemberImage = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 0.5px solid #98a7b5;
    overflow: hidden;
    flex-shrink: 0;
    img {
        width: 100%;
        height: 100%;
    }
`

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    ${(props) =>
        props.compare &&
        css`
            flex-direction: row;
            gap: 40px;
        `}
`

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`

export const ButtonContainer = styled.div`
    width: min-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media screen and (max-width: 480px) {
        width: 100%;
    }
    ${(props) =>
        props.compare &&
        css`
            flex-direction: row;
        `}
`

export const Span = styled.span`
    color: #525659;
    font-size: 16px;
    line-height: 20px;
    display: block;
    ${font.medium};
    word-wrap: break-word;
    a {
        cursor: pointer;
    }
    ${(props) =>
        props.teal &&
        css`
            color: #1b6775;
        `}
    ${(props) =>
        props.bold &&
        css`
            ${font.bold};
        `}
    ${(props) =>
        props.italic &&
        css`
            font-style: italic;
        `}
`

export const Button = styled.button`
    width: 135px;
    height: 35px;
    border-radius: 5px;
    background: #ec7625;
    color: white;
    ${font.bold};
    font-size: 14px;
    cursor: pointer;
    ${(props) =>
        props.teal &&
        css`
            background-color: #1b6775;
        `}
    @media screen and (max-width: 480px) {
        width: 100%;
        height: 40px;
        font-size: 16px;
    }
`
