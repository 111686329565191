import Radio from '../Radio'
import CheckIcon from 'shared/asstes/check-teal.svg'

const RenderUser = ({ signer, callback = () => {} }) => {
    const { isChecked, userStatus, isPending, email, SignerName, SignatureId } = signer

    const handleChange = (signer) => callback(signer)

    return (
        <div className={`embedded-sinning-user`}>
            <div className="embedded-sinning-cont">
                {!isPending ? (
                    <img src={CheckIcon} alt="" className="check-icon" />
                ) : (
                    <Radio
                        name="embedded-signing-user-select"
                        value={SignatureId}
                        onChange={() => handleChange(signer)}
                    />
                )}

                <div className="embedded-sinning-name-status-cont">
                    <div className="embedded-sinning-name-email-container">
                        <span className={`user-name ${!isChecked && 'disabled'}`}>
                            {SignerName}
                        </span>
                        <span className={`user-email ${!isChecked && 'disabled'}`}>
                            {email?.toLowerCase()}
                        </span>
                    </div>
                    <div
                        className={`embedded-sinning-user-status ${
                            isPending ? 'pending' : 'completed'
                        } `}
                    >
                        {userStatus}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RenderUser
