import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Fragment, useCallback, useState } from 'react'
import { StyledDiv, Body } from './Styles'

const propTypes = {
    title: PropTypes.string,
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
    onOpenEvent: PropTypes.func,
    isOpen: PropTypes.bool,
}

const defaultProps = {
    title: '',
    src: undefined,
    className: undefined,
    onOpenEvent: () => {},
    renderLink: () => {},
    isOpen: undefined,
}

const $root = document.getElementById('root')

const Iframe = ({ src, isOpen: propsIsOpen, title, onOpenEvent, renderLink }, ref) => {
    const [stateIsOpen, setStateOpen] = useState(false)
    const isControlled = typeof propsIsOpen === 'boolean'
    const isOpen = isControlled ? propsIsOpen : stateIsOpen

    const openIframe = useCallback(() => {
        setStateOpen(true)
        onOpenEvent()
    }, [isControlled])

    return (
        <Fragment>
            {!isControlled &&
                renderLink({
                    open: openIframe,
                    isOpen: stateIsOpen,
                })}
            {isOpen &&
                ReactDOM.createPortal(
                    <StyledDiv>
                        <Body src={src} title={title} />
                    </StyledDiv>,
                    $root,
                )}
        </Fragment>
    )
}

Iframe.propTypes = propTypes
Iframe.defaultProps = defaultProps

export default Iframe
