import React from 'react'
import {
    Cont,
    TrackerBarConatiner,
    TrackerBarr,
    TrackerBarFill,
    TrackerBarCircleIcon,
    TrackerBarCheckIcon,
    TrackerStatusCont,
    TrackerStatusHeader,
    TrackerCurrentStatus,
} from './Styles'
import CircleIcon from './assets/circle-icon.svg'
import CheckIcon from './assets/check-icon.svg'

const TrackerBar = ({ tracker = [], modalTracker, data = [] }) => {
    const statuses = modalTracker ? data : tracker

    return (
        <Cont modalTracker={modalTracker}>
            {statuses.map((status, i) => {
                const { completed, active, displayToCustomer, pastExplainer } = status

                return (
                    <TrackerBarConatiner key={`${displayToCustomer}-${i}`}>
                        <TrackerBarr hide={i === statuses.length - 1}>
                            {completed && i !== statuses.length - 1 && (
                                <TrackerBarFill last={active} animationDelay={i * 0.5} />
                            )}
                            <TrackerBarCircleIcon src={CircleIcon} alt="" />
                            {completed && (
                                <TrackerBarCheckIcon
                                    src={CheckIcon}
                                    alt=""
                                    animationDelay={i * 0.5}
                                />
                            )}
                        </TrackerBarr>
                        <TrackerStatusCont>
                            <TrackerStatusHeader>{displayToCustomer}</TrackerStatusHeader>
                            {completed && !active && (
                                <TrackerCurrentStatus animationDelay={i * 0.5} current={active}>
                                    {pastExplainer}
                                </TrackerCurrentStatus>
                            )}
                        </TrackerStatusCont>
                    </TrackerBarConatiner>
                )
            })}
        </Cont>
    )
}

export default TrackerBar
