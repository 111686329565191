import PropType from 'prop-types'

const propTypes = {
    condition: PropType.bool,
}

const defaultProps = {
    condition: true,
}

const ShowIf = ({ condition, children }) => {
    if (condition) {
        return children
    }
    return null
}

ShowIf.protoTypes = propTypes
ShowIf.defaultProps = defaultProps

export default ShowIf
