import { useWizard } from 'react-use-wizard'
import {
    ActionButton,
    ButtonGroup,
    Container,
    DContainer,
    Heading,
    Paragraph,
    ParagraphContainer,
} from '../styles'
import { capitalizeFirstLetterEveryWord } from 'shared/utils/formatter'

const Step2 = ({ state, todo, handleSubmit }) => {
    const { previousStep, goToStep } = useWizard()

    const handleClickSubmit = async () => {
        const res = await handleSubmit()
        if (res) {
            goToStep(2)
        }
    }

    return (
        <Container>
            <DContainer>
                <Heading>
                    Great! We will update the email address for{' '}
                    <span>
                        {capitalizeFirstLetterEveryWord(todo?.metadata?.sellerName?.toLowerCase())}
                    </span>{' '}
                    to:
                </Heading>
                <ParagraphContainer>
                    <Paragraph>{state.email}</Paragraph>
                </ParagraphContainer>
            </DContainer>
            <ButtonGroup>
                <ActionButton variant="empty" size="lg" onClick={previousStep}>
                    No, modify
                </ActionButton>
                <ActionButton variant="primary" size="lg" onClick={handleClickSubmit}>
                    Submit
                </ActionButton>
            </ButtonGroup>
        </Container>
    )
}

export default Step2
