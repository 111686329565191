import IOS from './assets/ios.svg'
import ANDROID from './assets/android.svg'
import { Contt, Wrapper } from './Styles'
import { Fragment } from 'react'
import Logo from 'shared/asstes/logo.svg'
import Mail from 'shared/asstes/mail.svg'
import PhoneIcon from 'shared/asstes/phoneIcon.svg'
import ArrowUpIcon from 'shared/asstes/arrowupIcon.svg'
import useScrollTo from 'shared/hooks/scrollTo'

const Footer = () => {
    const { scrollToTop } = useScrollTo()

    return (
        <Fragment>
            <Wrapper>
                <Contt>
                    <div className="footer-container">
                        <div className="info">
                            <img src={Logo} alt="logo" className="logo" />
                            <p className="first">
                                Offerpad Brokerages hold real estate brokerage licenses in multiple
                                states. Click here for a list of our real estate brokerage licenses.
                                TREC Info:{' '}
                                <span>
                                    <a
                                        href="https://prodofferpadstorage.blob.core.windows.net/op-press-resources/documents/TX_Brokerage.pdf"
                                        target="_blank"
                                    >
                                        Brokerage Services
                                    </a>
                                </span>{' '}
                                |{' '}
                                <span>
                                    <a
                                        href="https://prodofferpadstorage.blob.core.windows.net/op-press-resources/documents/Consumer_Protection_Notice.pdf"
                                        target="_blank"
                                    >
                                        Consumer Protection Notice
                                    </a>
                                </span>{' '}
                                <br />
                                Offerpad Brokerage CA Inc. Real Estate broker, California Department
                                of Real Estate California DRE #2087915
                            </p>
                            <p className="second">
                                Eligibility and prices vary. Real estate brokerage services provided
                                by Offerpad Brokerages. Terms and conditions apply. To learn more,
                                speak to your Offerpad representative or visit&nbsp;
                                <span>
                                    <a href={process.env.TERMS_OF_USE_URL} target="_blank">
                                        www.offerpad.com/terms-of-use
                                    </a>
                                </span>
                                .
                            </p>
                        </div>
                        <div className="terms">
                            <div className="contacts">
                                <span className="contacts-heading">Contact us</span>
                                <div className="contact-container">
                                    <div className="img-cont">
                                        <img src={Mail} alt="" />
                                    </div>
                                    <a className="info" href="mailto:info@offerpad.com">
                                        info@offerpad.com
                                    </a>
                                </div>
                                <div className="contact-container">
                                    <div className="img-cont">
                                        <img src={PhoneIcon} alt="" />
                                    </div>
                                    <a className="info" href="tel:844-388-4539">
                                        844-388-4539
                                    </a>
                                </div>
                            </div>
                            <div>
                                <p>Download our apps</p>
                                <div className="apps">
                                    <a
                                        href={process.env.IOS_APP_STORE_LINK}
                                        target="_blank"
                                        id="fs_ios_app_store"
                                    >
                                        <img src={IOS} alt="" />
                                    </a>
                                    <a
                                        href={process.env.ANDROID_APP_STORE_LINK}
                                        target="_blank"
                                        id="fs_android_app_store"
                                    >
                                        <img src={ANDROID} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copy-right">
                        <div className="copy-right-content">
                            &copy; 2023 Offerpad | All Rights Reserved |{' '}
                            <span>
                                <a href={process.env.PRIVACY_URL} target="_blank">
                                    {' '}
                                    Privacy Policy
                                </a>
                            </span>
                        </div>
                        <div className="img-cont" onClick={scrollToTop}>
                            <img src={ArrowUpIcon} alt="" />
                        </div>
                    </div>
                </Contt>
            </Wrapper>
        </Fragment>
    )
}

export default Footer
