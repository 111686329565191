import styled from 'styled-components'
import { font } from 'shared/utils/styles'

export const BellhopsCont = styled.div`
    background: rgba(231, 240, 241, 0.5);
    border: 1px solid #dbe5e7;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px 56px;
    margin-top: 30px;
    @media (max-width: 480px) {
        padding: 35px 20px;
    }
    h1 {
        ${font.black};
        ${font.size(22)};
        line-height: 26.4px;
        color: #000000;
        text-align: center;
    }
    p {
        text-align: center;
        ${font.medium};
        ${font.size(16)};
        line-height: 20px;
        margin-top: 12px;
        color: #1b6775;
    }
    h2 {
        ${font.bold};
        ${font.size(16)};
        line-height: 19px;
        color: #000000;
        text-align: center;
        margin-top: 8px;
    }
    img {
        margin-top: 22px;
        width: 150px;
        @media (max-width: 480px) {
            width: 101px;
        }
    }
    .bellhop-info-cont {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 67px;
        margin-top: 30px;
        @media (max-width: 820px) {
            gap: 25px;
        }
        div {
            display: flex;
            align-items: center;
            gap: 9px;
            img {
                width: 13px;
                margin-top: 0px;
            }
            a {
                ${font.medium};
                ${font.size(14)}
                line-height: 14px;
                text-decoration-line: underline;
                color: #ec7625;
            }
        }
    }
`
