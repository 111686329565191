import { Modal } from 'shared/components'
import Step1 from './Step1'
import Step2 from './Step2'
import { Wizard } from 'react-use-wizard'
import { ContPopup, HiddenButton } from './Styles'

const BuyDashboardModal = () => {
    return (
        <Modal
            backgroundColor="white"
            variableWidth={true}
            withCloseIcon={true}
            clickOutside={false}
            width="825px"
            className="allow-overflow"
            renderLink={(modal) => <HiddenButton onClick={modal.open}></HiddenButton>}
            renderContent={(modal) => (
                <Wizard wrapper={<ContPopup />}>
                    <Step1 close={modal.close} />
                    <Step2 close={modal.close} />
                </Wizard>
            )}
        />
    )
}

export default BuyDashboardModal
