import { Overlay } from './Styles'
import { useEffect, useState } from 'react'
import toast from 'shared/utils/toast'
import messages from 'shared/constants/confetti'
import confetti from 'canvas-confetti'

const colors = [
    '#ec7625',
    '#FADECB',
    '#1b6775',
    '#a4c2c8',
    '#134852',
    '#f6bb92',
    '#ADC2DA',
    '#cedae9',
    '#FFc709',
    '#ffe384',
    '#EC7625',
    '#1b6775',
    '#FADECB',
    '#134852',
    '#ADC2DA',
    '#FFc709',
    '#ffe384',
]

const comparisonArray = ['UnderContract', 'EscrowOpened', 'Sold']

const Confetti = ({ currentTransaction }) => {
    const [show, setShow] = useState(false)
    const { transactionStatus, isListedByAgent } = currentTransaction || {}
    const showConfetti = comparisonArray.includes(transactionStatus)

    const fire = (particleRatio, opts) => {
        const count = 200
        const defaults = { origin: { y: 0.95 }, colors }
        confetti(
            Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio),
            }),
        )
    }

    useEffect(() => {
        if (showConfetti && !localStorage.getItem('confetti')) {
            const message = isListedByAgent
                ? messages[transactionStatus]?.agent
                : messages[transactionStatus]?.customer
            setShow(true)

            const particleEffects = [
                { particleRatio: 0.25, opts: { spread: 26, startVelocity: 55 } },
                { particleRatio: 0.2, opts: { spread: 60 } },
                { particleRatio: 0.35, opts: { spread: 100, decay: 0.91, scalar: 0.8 } },
                {
                    particleRatio: 0.1,
                    opts: { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 },
                },
                { particleRatio: 0.1, opts: { spread: 120, startVelocity: 45 } },
                {
                    particleRatio: 0.1,
                    opts: { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 },
                },
                { particleRatio: 0.1, opts: { spread: 120, startVelocity: 45 } },
            ]

            particleEffects.forEach(({ particleRatio, opts }) => fire(particleRatio, opts))

            toast.show({
                type: 'success',
                message,
                duration: 15,
            })

            setTimeout(() => {
                setShow(false)
                localStorage.setItem('confetti', true)
            }, 5 * 1000)
        }
    }, [])

    return null
}

export default Confetti
