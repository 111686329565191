import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import store from './store'
import NormalizeStyles from './NormalizeStyles'
import BaseStyles from './BaseStyles'
import './fontStyles.css'
import Router from './Router'

const App = () => {
    return (
        <Provider store={store}>
            <NormalizeStyles />
            <BaseStyles />
            <Router />
        </Provider>
    )
}

export default App
