import React, { useState } from 'react'
import { Cont, ChatCont, Header, HeaderCont, Select, TextArea } from './Styles'
import { Button } from 'shared/components'
import Success from './Success'
import useApi from 'shared/hooks/api'
import toast from 'shared/utils/toast'
import { formatPhoneNumber } from 'shared/utils/formatter'
import { useSelector } from 'react-redux'

function TechSupport() {
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [done, setDone] = useState(false)
    const user = useSelector((state) => state.user)

    const MessageEndPoint = `Customer/v3/messages/0`
    const [{ isWorking }, sendMessage] = useApi.post(MessageEndPoint)

    const handleSubmit = async () => {
        if (message.length === 0) {
            toast.error('Message is empty')
        } else if (subject.length === 0) {
            toast.error('Please select a subject')
        } else {
            await sendMessage({
                subject: subject,
                message: `${message} <br />
                ------------------------------------------------------<br />
                Name: ${user?.firstName} ${user?.lastName}<br />
                Email Address: ${user?.email}<br />
                Phone Number: ${formatPhoneNumber(user?.phoneNumber)}<br />
                Device Information: ${navigator.userAgent}<br />
                Source: Connect.Web<br />
                App version: 1.0.0<br />
                ------------------------------------------------------ `,
                role: 'TechSupport',
            })
            setDone(true)
        }
    }

    return (
        <Cont>
            {!done ? (
                <ChatCont>
                    <Header>Tech Support</Header>
                    <Select
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        disabled={isWorking}
                    >
                        <option value="" hidden disabled>
                            Your subject line here
                        </option>
                        <option value="Account access issues">Account access issues</option>
                        <option value="App seems frozen">App seems frozen</option>
                        <option value="I don't see my property">I don't see my property</option>
                        <option value="Feature request or enhancement">
                            Feature request or enhancement
                        </option>
                        <option value=" am getting an error message">
                            I am getting an error message
                        </option>
                        <option value="Other">Other</option>
                    </Select>
                    <TextArea
                        disabled={isWorking}
                        placeholder="Type a message here"
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Button
                        variant="primary"
                        className="button"
                        id="fs_tech_support_submit"
                        onClick={handleSubmit}
                        isWorking={isWorking}
                        disabled={message.length <= 1 ? true : false}
                    >
                        Send Message
                    </Button>
                </ChatCont>
            ) : (
                <Success />
            )}
        </Cont>
    )
}

export default TechSupport
