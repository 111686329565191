import { font } from 'shared/utils/styles'
import styled from 'styled-components'

export const Container = styled.div`
    width: auto;
    height: auto;
    padding: 20px 30px;
`

export const Heading = styled.div`
    ${font.bold};
    font-size: 15px;
    line-height: 18px;
    color: #000;
`

export const Divider = styled.hr`
    border: 1px solid #e6e6e6;
    margin-top: 30px;
    margin-bottom: 21px;
`

export const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 18px;
`

export const ProfileDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
`

export const Header = styled.div`
    font-size: 14px;
    line-height: normal;
    ${font.bold};
    color: #4a5e88;
`

export const SubHeader = styled.span`
    font-size: 10px;
    line-height: normal;
    ${font.bold};
    color: rgba(74, 94, 136, 0.5);
`

export const InitialContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
`

export const DownArrow = styled.img``
