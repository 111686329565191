import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Label, Input, Span } from './Styles'

const propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    backgroundcolor: PropTypes.string,
    onChange: PropTypes.func,
    onChangeEvent: PropTypes.func,
}

const defaultProps = {
    className: undefined,
    size: '20',
    backgroundcolor: '#EF7724',
    onChange: () => {},
    onChangeEvent: () => {},
}

const ToggleSwitch = forwardRef(
    ({ className, size, backgroundcolor, onChange, onChangeEvent, ...rest }, ref) => {
        const [checked, setChecked] = useState(false)

        const handleOnChange = (e) => {
            onChange(e.target.checked, e)
            onChangeEvent(e)
            setChecked(e.target.checked)
        }

        return (
            <Label className={className} size={size}>
                <Input
                    type="checkbox"
                    onChange={handleOnChange}
                    backgroundcolor={backgroundcolor}
                    checked={checked}
                    {...rest}
                />
                <Span backgroundcolor={backgroundcolor} size={size} checked={checked}></Span>
            </Label>
        )
    },
)

ToggleSwitch.propTypes = propTypes
ToggleSwitch.defaultProps = defaultProps

export default ToggleSwitch
