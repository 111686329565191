import styled, { css } from 'styled-components'
import { font, mixin, color, breakPoints } from 'shared/utils/styles'

export const Cont = styled.div`
    position: relative;
    ${(props) =>
        !!props.isDashboard && props.isDashboard
            ? css`
                  padding: 0 !important;
              `
            : css`
                  padding: 30px 0px;
                  @media screen and (min-width: 1423px) {
                      width: 100%;
                      max-width: 1347px;
                      margin: 0 auto;
                      padding-top: 52px;
                      padding-bottom: 72px;
                  }
                  @media screen and (max-width: 1422px) {
                      padding: 30px;
                  }
                  @media (max-width: 480px) {
                      padding: 30px 20px !important;
                  }
              `}
`

export const SuccessCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.25rem;
    @media (max-width: 820px) {
        padding: 20px;
        border: none;
        background-color: transparent;
    }
`

export const ImageCont = styled.div`
    margin-top: 50px;
    @media (max-width: 480px) {
        margin: 0px;
    }
`

export const Circle = styled.img`
    position: absolute;
    @media (max-width: 480px) {
        width: 54px;
    }
`

export const CheckMark = styled.img`
    position: relative;
    top: -15px;
    left: 18px;
    @media (max-width: 480px) {
        width: 55px;
        top: -10px;
        left: 13px;
    }
`

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    justify-content: center;
    align-items: center;
`

export const Header = styled.div`
    color: #363c4f;
    ${font.black};
    ${font.size(27)};
    line-height: 40px;
    @media (max-width: 480px) {
        color: #656565;
        ${font.bold};
        ${font.size(20)};
        line-height: 27px;
    }
`

export const SubHeader = styled.div`
    color: #363c4f;
    ${font.medium};
    ${font.size(20)};
    line-height: 24px;
    text-align: center;
    @media (max-width: 480px) {
        color: #656565;
        ${font.size(18)};
        line-height: 27px;
    }
`

export const ActionButton = styled.button`
    width: 224px;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    color: #363c4f;
    ${font.regular};
    ${font.size(18)};
    line-height: 22px;
    ${mixin.clickable};
    @media (max-width: 480px) {
        width: 159px;
    }
`
