import api from 'shared/utils/api'
import { useDispatch, useSelector } from 'react-redux'
import { updateTeams, updateLoading } from 'features/sell/teams'
import { TeamsDescription } from 'shared/constants/teams'
import { useNavigate } from 'react-router'
import toast from 'shared/utils/toast'
import { useErrorBoundary } from 'react-error-boundary'

const useTeams = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { showBoundary } = useErrorBoundary()

    const fetchTeams = async () => {
        if (localStorage.currentTransactionId === undefined) {
            navigate('/sell/dashboard')
            return
        }

        const temp = []

        const teamsFetchURL = `customer/v3/cash-offer-transactions/${localStorage.currentTransactionId}/my-team`

        dispatch(updateLoading(true))

        try {
            const res = await api.get(teamsFetchURL)
            res.contacts.forEach((d) => {
                temp.push({
                    ...d,
                    description: TeamsDescription[d.role](d),
                })
            })
            dispatch(updateLoading(false))
            dispatch(updateTeams(temp))
            window.currentTeams = temp
            return temp
        } catch (e) {
            dispatch(updateLoading(false))
            toast.error('Something went wrong.')
            console.error(e)
            showBoundary(e)
        }
    }

    const getTeamByRole = (role) => {
        const teams = useSelector((state) => state.teams.teams)
        let temp = []
        teams.forEach((team) => {
            if (role === team.role) {
                temp = team
            }
        })
        return temp
    }

    const getTeamByRoleidentifier = (teams, role) => {
        let temp = {}
        teams.forEach((team) => {
            if (role === team.role) {
                temp = team
            }
        })
        return temp
    }

    return {
        fetch: fetchTeams,
        getTeamByRole,
        getTeamByRoleidentifier,
    }
}

export default useTeams
