import styled, { css, keyframes } from 'styled-components'
import { color, fontSize, font } from 'shared/utils/styles'

export const Cont = styled.div`
    margin-top: 20px;
    ${(props) =>
        !!props.modalTracker &&
        props.modalTracker &&
        css`
            @media (max-width: 1440px) {
                width: auto;
                min-width: 380px;
            }
            @media (max-width: 900px) {
                width: auto;
                max-width: 300px;
            }
        `}
`

export const TrackerBarConatiner = styled.div`
    display: flex;
`

export const TrackerBarr = styled.div`
    width: 10px;
    height: 80px;
    @media (max-width: 920px) {
        height: 90px;
    }
    @media (max-width: 480px) {
        height: 75px;
    }
    background-color: ${color.trackBarBackground};
    border: none;
    position: relative;
    ${(props) =>
        props.hide &&
        css`
            height: 10px;
            margin-bottom: 10px;
            @media (max-width: 920px) {
                height: 10px;
            }
        `}
`

const expandHeight = keyframes`
    from {
        height: 0px;
    }
    to {
        height: ${(props) =>
            !!props.last && props.last
                ? css`
                      height: 45px;
                  `
                : css`
                      height: 80px;
                  `}
    }
`

export const TrackerBarFill = styled.div`
    width: 10px;
    height: 80px;
    @media (max-width: 920px) {
        height: 90px;
    }
    @media (max-width: 480px) {
        height: 75px;
    }
    ${(props) =>
        !!props.last &&
        props.last === true &&
        css`
            height: 45px;
            @media (max-width: 920px) {
                height: 50px;
            }
        `}
    background-color: ${color.trackBarFillCOlor};
    border: none;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    animation-name: ${expandHeight};
    animation-duration: 0.5s;
    animation-fill-mode: both;
    ${(props) =>
        !!props.animationDelay &&
        css`
            animation-delay: ${props.animationDelay}s;
        `}
`

export const TrackerBarCircleIcon = styled.img`
    position: absolute;
    top: -10px;
    left: -10px;
    ${(props) =>
        !!props.delay &&
        css`
            animation-delay: ${props.delay}s;
        `}
`

const show = keyframes`
from {
opacity: 0;
transform: scale(1.5) translate(15px, 5px);
}
to {
opacity: 1;
transform: scale(1) translate(15px, 5px);
}
`

export const TrackerBarCheckIcon = styled.img`
    position: absolute;
    top: -20px;
    left: -20px;
    opacity: 1;
    transform: translate(15px, 5px);
    animation-name: ${show};
    animation-duration: 0.8s;
    animation-fill-mode: both;
    ${(props) =>
        !!props.animationDelay &&
        css`
            animation-delay: ${props.animationDelay}s;
        `}
`

export const TrackerStatusCont = styled.div`
    margin-top: -5px;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    @media (max-width: 900px) {
        width: 236px;
    }
    @media (max-width: 690px) {
        width: 100%;
    }
    @media (max-width: 820px) {
        gap: 0px;
    }
`

export const TrackerStatusHeader = styled.div`
    color: ${color.trackerStatusHeaderColor};
    ${font.black};
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 480px) {
        ${font.size(14)};
    }
`

const opacity = keyframes`
from {
opacity: 0;
}
to {
opacity: 1;
}
`

export const TrackerCurrentStatus = styled.div`
    color: #898989;
    ${font.size(15)};
    line-height: 18px;
    font-style: italic;
    ${font.medium};
    animation-name: ${opacity};
    animation-duration: 0.5s;
    animation-fill-mode: both;
    ${(props) =>
        !!props.animationDelay &&
        css`
            animation-delay: ${props.animationDelay}s;
        `}
    ${(props) =>
        !!props.last &&
        props.last &&
        css`
            color: #1b6775 !important;
            font-style: normal;
        `}
        ${(props) =>
        !!props.current &&
        props.current &&
        css`
            font-style: initial;
            color: #1b6775 !important;
        `}
        @media (max-width:820px) {
        ${font.size(13)};
        margin-top: 3px;
    }
`

export const TrackerCurrentStatusLink = styled.a`
    color: ${color.primary};
    ${fontSize['sm']}
    font-weight: 500;
    margin-left: 10px;
`
