import Dashboard from './Dashboard'
import Overview from './Overview'
import ToDos from './ToDos'
import CalendarPage from './CalendarPage'
import YourTeam from './YourTeam'
import Documents from './Documents'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { NotFound, Notification, PageContainer, ScreenLoader, TabMenus } from 'shared/components'
import { useSelector } from 'react-redux'
import useMyTransactions from 'shared/hooks/sell/my-transactions'
import { useEffect } from 'react'
import { isMatchingURL } from 'shared/utils/formatter'
import useOverview from 'shared/hooks/sell/overview'

const Sell = () => {
    const { transaction } = useSelector((state) => state.singleTransaction)
    const { myTransactions } = useSelector((state) => state.myTransaction)
    const { loading } = useSelector((state) => state.loading)
    const { init, loading: overViewLoading } = useOverview()
    const { fetch } = useMyTransactions()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const dashboardRoute = '/sell/dashboard'
    const sellRoutes = ['/sell/*']

    function checkMyTransaction() {
        if (!myTransactions) {
            fetch()
        }
    }

    function checkTransaction() {
        const currentTransactionId = localStorage.getItem('currentTransactionId')
        if (!currentTransactionId) {
            navigate(dashboardRoute)
        } else if (pathname !== dashboardRoute && isMatchingURL(pathname, sellRoutes)) {
            if (!transaction) {
                init(true)
            }
        }
    }

    useEffect(() => {
        checkMyTransaction()
        checkTransaction()
    }, [pathname])

    if (loading || overViewLoading) {
        return <ScreenLoader size={100} sL full={false} backgroundColor="rgba(0,0,0,.65)" />
    }

    return (
        <PageContainer>
            <TabMenus />
            <Notification />
            <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="overview" element={<Overview />} />
                <Route path="action-items" element={<ToDos />} />
                <Route path="calendar" element={<CalendarPage />} />
                <Route path="your-team" element={<YourTeam />} />
                <Route path="documents" element={<Documents />} />
            </Routes>
        </PageContainer>
    )
}

export default Sell
