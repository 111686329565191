import Dropdown from '../Dropdown'
import NavbarMenuItem from '../NavbarMenuItem'
import NavbarRoundMenu from '../NavbarRoundMenu'
import {
    Container,
    Heading,
    Divider,
    ProfileContainer,
    ProfileDetailContainer,
    SubHeader,
    Header,
    InitialContainer,
    DownArrow,
} from './Styles'
import ResourseIcon from 'shared/asstes/resourceIcon.svg'
import UserIcon from 'shared/asstes/userIcon.svg'
import LockIcon from 'shared/asstes/lockIcon.svg'
import PowerIcon from 'shared/asstes/powerIcon.svg'
import DownArrowIcon from 'shared/asstes/downArrowIcon.svg'
import { capitalizeFirstLetterEveryWord, getInitials } from 'shared/utils/formatter'
import { logout } from 'shared/utils/auth'
import { useSelector } from 'react-redux'

const ProfileNavbarMenu = () => {
    const user = useSelector((state) => state.user)

    return (
        <Dropdown
            testid="dropdown:profile"
            withCloseIcon={true}
            offset={44}
            renderLink={(dropdown) => (
                <ProfileContainer>
                    <ProfileDetailContainer>
                        <SubHeader>Your Profile</SubHeader>
                        <Header>
                            {capitalizeFirstLetterEveryWord(user?.firstName)}{' '}
                            {capitalizeFirstLetterEveryWord(user?.lastName)}
                        </Header>
                    </ProfileDetailContainer>
                    <InitialContainer onClick={dropdown.open}>
                        <NavbarRoundMenu
                            text={getInitials(user?.firstName, user?.lastName)}
                            backgroundColor="#EC7625"
                            textColor="white"
                            elevation={false}
                        />
                        <DownArrow src={DownArrowIcon} alt="" />
                    </InitialContainer>
                </ProfileContainer>
            )}
            renderContent={(dropdown) => (
                <Container>
                    <Heading>Settings</Heading>
                    {/* <NavbarMenuItem
                        to="/resource-center"
                        leftIcon={ResourseIcon}
                        onClick={dropdown.close}
                        text="Helpful info"
                        id="fs_navigation_settings_resource_center"
                    /> */}
                    <NavbarMenuItem
                        to="/profile"
                        leftIcon={UserIcon}
                        onClick={dropdown.close}
                        text="My Profile"
                        id="fs_navigation_settings_profile"
                    />
                    <NavbarMenuItem
                        to="/change-password"
                        leftIcon={LockIcon}
                        onClick={dropdown.close}
                        text="Change Password"
                        id="fs_navigation_settings_change_password"
                    />
                    <Divider />
                    <NavbarMenuItem
                        to=""
                        dangerText
                        leftIcon={PowerIcon}
                        onClick={(e) => {
                            logout(), dropdown.close(), e.preventDefault()
                        }}
                        text="Sign out"
                        id="fs_navigation_settings_sign_out"
                    />
                </Container>
            )}
        />
    )
}

export default ProfileNavbarMenu
