export default [
    '/sell/*',
    '/buy/*',
    '/loan/*',
    '/profile/*',
    '/change-password',
    '/message-center',
    '/tech-support',
    '/resource-center',
]
