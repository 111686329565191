import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { color } from 'shared/utils/styles'
import { StyledButton, StyledSpinner, Text } from './Styles'

const propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    variant: PropTypes.oneOf(['primary', 'success', 'danger', 'secondary', 'warning', 'empty']),
    size: PropTypes.oneOf(['sm', 'normal', 'lg']),
    disabled: PropTypes.bool,
    isWorking: PropTypes.bool,
    isWorkingText: PropTypes.string,
    onClick: PropTypes.func,
}

const defaultProps = {
    className: undefined,
    children: undefined,
    variant: 'secondary',
    size: 'normal',
    disabled: false,
    isWorking: false,
    isWorkingText: 'Please wait...',
    onClick: () => {},
}

const Button = forwardRef(
    (
        { children, variant, size, disabled, isWorking, isWorkingText, onClick, ...buttonProps },
        ref,
    ) => {
        const handleClick = () => {
            if (!disabled && !isWorking) {
                onClick()
            }
        }

        return (
            <StyledButton
                {...buttonProps}
                onClick={handleClick}
                variant={variant}
                size={size}
                disabled={disabled || isWorking}
                isWorking={isWorking}
                isWorkingText={isWorkingText}
                ref={ref}
            >
                {children && (
                    <Text withPadding={isWorking}>{isWorking ? isWorkingText : children}</Text>
                )}
                {isWorking && <StyledSpinner size={20} color={getSpinnerColor(variant)} />}
            </StyledButton>
        )
    },
)

const getSpinnerColor = (variant) =>
    ['secondary', 'empty'].includes(variant) ? color.textDark : '#fff'

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
