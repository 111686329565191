import { useSelector } from 'react-redux'
import { DefaultDashboard, LoanDashboardModal } from 'shared/components'
import dashboard from 'shared/constants/dashboard'

const Dashboard = () => {
    const user = useSelector((state) => state.user)
    const { loan } = dashboard

    const dataToShow = user?.agent?.toLowerCase() === 'true' ? loan.agent : loan.customer

    return (
        <>
            <DefaultDashboard
                {...dataToShow}
                renderCustomPrimaryButton={() => <LoanDashboardModal />}
            />
        </>
    )
}

export default Dashboard
