import React, { useEffect, useState } from 'react'
import { Cont, ChatCont, Header, HeaderCont, SubHeader, Select, TextArea } from './Styles'
import { BackButton, Button, ScreenLoader } from 'shared/components'
import useApi from 'shared/hooks/api'
import toast from 'shared/utils/toast'
import { useWizard } from 'react-use-wizard'
import useMessageCenter from 'shared/hooks/messageCenter'
import { formatPhoneNumber } from 'shared/utils/formatter'
import { useSelector } from 'react-redux'

function Step2({ id, setRole, setTitle, totalNoOfProperties }) {
    const { nextStep, goToStep } = useWizard()
    const { init: initlize, isLoading } = useMessageCenter(id)
    const [data, setData] = useState({})
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')

    const user = useSelector((state) => state.user)

    const MessageEndPoint = `Customer/v3/messages/${id}`
    const [{ isWorking }, sendMessage] = useApi.post(MessageEndPoint)

    const intiData = async () => {
        const res = await initlize()
        setData(res)
    }

    const handleSubmit = async () => {
        if (message.length === 0) {
            toast.error('Message is empty')
        } else if (subject.length === 0) {
            toast.error('Please select a subject')
        } else {
            await sendMessage({
                subject: subject,
                message: `${message} <br />
                ------------------------------------------------------<br />
                Name: ${user?.firstName} ${user?.lastName}<br />
                Email Address: ${user?.email}<br />
                Phone Number: ${formatPhoneNumber(user?.phoneNumber)}<br />
                Device Information: ${navigator.userAgent}<br />
                Source: Connect.Web<br />
                App version: 1.0.0<br />
                ------------------------------------------------------ `,
                role: data.role === false ? 'MarketDefault' : data.role,
            })
            setRole(data.role === false ? data?.title : data.roleLabel)
            setTitle(data.role === false ? '' : `${data.title}`)
            nextStep()
        }
    }

    useEffect(() => {
        intiData()
    }, [])

    return (
        <Cont>
            {(isLoading || isWorking) && Object.keys(data).length === 0 && (
                <div style={{ height: '300px' }}>
                    <ScreenLoader sL={isLoading || isWorking} backgroundColor="white" />
                </div>
            )}

            {Object.keys(data).length > 0 && (!isLoading || !isWorking) && (
                <ChatCont>
                    <div className="btn-cont">
                        {totalNoOfProperties > 1 && (
                            <BackButton
                                id="fs_message_back"
                                height="50px"
                                width="50px"
                                onClick={() => goToStep(0)}
                            />
                        )}
                        <HeaderCont>
                            <Header>
                                {data?.title}
                                {data?.role !== false && <span>{data?.roleLabel}</span>}
                            </Header>
                            {data?.address !== false && <SubHeader>{data?.address}</SubHeader>}
                        </HeaderCont>
                    </div>
                    <Select
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        disabled={isWorking}
                    >
                        <option value="">Your subject line here</option>
                        {data?.options.map((option, i) => {
                            return (
                                <option value={option} key={Date.now() + i}>
                                    {option}
                                </option>
                            )
                        })}
                    </Select>
                    <TextArea
                        disabled={isWorking}
                        placeholder="Type a message here"
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Button
                        variant="primary"
                        className="button"
                        id="fs_message_submit"
                        onClick={handleSubmit}
                        isWorking={isWorking}
                        disabled={message.length <= 1 ? true : false}
                    >
                        Send Message
                    </Button>
                </ChatCont>
            )}
        </Cont>
    )
}

export default Step2
