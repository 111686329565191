import { Cont, HeadingImg, Heading, Card, CardCont, ArrowImage } from './Styles'
import ArrowRightIcon from './assets/arrow-right.svg'
import HomeHeartIcon from './assets/homeheart.svg'
import CheckIcon from 'shared/asstes/check_with_circle.svg'
import { Modal, ScreenLoader, ShowIf } from 'shared/components'
import { useSelector } from 'react-redux'
import ExtendedStay from 'OnDemandActions/Sell/ExtendedStay'
import CodDateChange from 'OnDemandActions/Sell/CodDateChange'
import Default from 'OnDemandActions/Sell/CodDateChange/default'
import FreeLocalMove from 'OnDemandActions/Sell/FreeLocalMove'
import PhotosViewer from 'shared/components/PhotosViewer'
import alert from 'shared/utils/alert'
import api from 'shared/utils/api'
import useMergeState from 'shared/hooks/mergeState'
import useOverview from 'shared/hooks/sell/overview'
import useMyTransactions from 'shared/hooks/sell/my-transactions'
import toast from 'shared/utils/toast'

const comparissionArray = [
    'OfferRequested',
    'RenewalRequested',
    'OfferReady',
    'PurchaseAgreementSent',
    'OfferExpired',
]

const preliminaryInspectionComparissionArray = ['InspectionScheduled', 'InspectionInReview']

const preliminaryComparissionArray = [
    'PreliminaryOfferExpired',
    'PreliminaryOfferReady',
    ...preliminaryInspectionComparissionArray,
]

const viewOfferComparissionArray = ['nosolution', 'arn', 'pending', null]

const QuickLinks = ({ tab = false }) => {
    const [state, mergeState] = useMergeState({ isWorking: false })
    const { init } = useOverview()
    const myTransaction = useMyTransactions()

    const coe = useSelector((state) => state.offerpad.coe)

    const transaction = useSelector((state) => state.singleTransaction.transaction)
    const images =
        transaction?.imageUrls && transaction.imageUrls.length > 0 ? transaction.imageUrls : []

    const classification = transaction?.property?.propertyClassification

    let showPhotosInQuickLinks

    if (classification === 'D') {
        showPhotosInQuickLinks = false
    } else if (comparissionArray.includes(transaction?.transactionStatus)) {
        showPhotosInQuickLinks = true
    } else if (images.length == 0) {
        showPhotosInQuickLinks = false
    } else {
        showPhotosInQuickLinks = true
    }

    let solution = transaction?.availableSolution?.replace(/\s/g, '').toLowerCase() ?? null

    const showViewOffer = transaction?.isPreContractInspection
        ? !preliminaryInspectionComparissionArray.includes(transaction?.transactionStatus)
        : !viewOfferComparissionArray.includes(solution)

    const requestNewOffer = async () => {
        mergeState({ isWorking: true })
        try {
            await api.post(
                `customer/v3/cash-offer-transactions/${transaction?.transactionId}/reopen`,
            )
        } catch (e) {
            e?.error?.message && toast.error(e?.error?.message)
        }
        mergeState({ isWorking: false })
    }

    const showSuccessToastMessage = () =>
        alert.info(
            "We're on it.",
            'You request for an updated offer has been recieved and should take about 24 hours to complete. We will notify you by email when it is ready to review.',
            CheckIcon,
            () => fetchTransactions(),
        )

    const fetchTransactions = async () => {
        await myTransaction.fetch()
        await init(true)
    }

    const viewOfferLink = transaction?.isPreContractInspection
        ? preliminaryComparissionArray.includes(transaction?.transactionStatus)
            ? `${process.env.REQUEST_PROPERTY_URL}/${transaction?.preliminaryOfferKey}/self-scheduling-preliminary-offer-inspection`
            : `${process.env.VIEW_OFFER_URL}/${transaction?.offerKey}`
        : `${process.env.VIEW_OFFER_URL}/${transaction?.offerKey}`

    return (
        <Cont tab={tab}>
            <Heading>
                <HeadingImg src={HomeHeartIcon} alt="" />
                Quick Links
            </Heading>
            <Card>
                <ShowIf condition={showViewOffer && transaction?.transactionStatus === 'Lost'}>
                    <CardCont
                        onClick={async () => (await requestNewOffer(), showSuccessToastMessage())}
                        id="fs_sell_cash_offer"
                    >
                        Request New Offer
                        {!state.isWorking ? (
                            <ArrowImage src={ArrowRightIcon} alt="" />
                        ) : (
                            <ScreenLoader size={50} full={false} sL={state.isWorking} />
                        )}
                    </CardCont>
                </ShowIf>

                <ShowIf
                    condition={
                        showViewOffer &&
                        transaction?.transactionStatus !== 'Lost' &&
                        transaction?.transactionStatus !== 'OfferRequested' &&
                        transaction?.transactionStatus !== 'PreliminaryOfferRequested'
                    }
                >
                    <a
                        href={viewOfferLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        id="fs_sell_cash_offer"
                    >
                        <CardCont>
                            View Offer
                            <ArrowImage src={ArrowRightIcon} alt="" />
                        </CardCont>
                    </a>
                </ShowIf>

                <ShowIf condition={showPhotosInQuickLinks}>
                    <PhotosViewer
                        title="Your Photos"
                        overviewQuickLink
                        uploadRequired={false}
                        renderCustomLink={(modal) => (
                            <CardCont onClick={modal.open} id="fs_sell_photos">
                                Photos
                                <ArrowImage src={ArrowRightIcon} alt="" />
                            </CardCont>
                        )}
                    />
                </ShowIf>

                <ShowIf condition={transaction?.transactionStatus !== 'Lost'}>
                    <Modal
                        testid="Modal:ExtendedStay"
                        width="880px"
                        withCloseIcon={true}
                        clickOutside={false}
                        backgroundColor="transparent"
                        variableWidth={true}
                        renderLink={(modal) => {
                            return (
                                <CardCont onClick={modal.open} id="fs_sell_localMove">
                                    Local Move
                                    <ArrowImage src={ArrowRightIcon} alt="" />
                                </CardCont>
                            )
                        }}
                        renderContent={(modal) => <FreeLocalMove close={modal.close} />}
                    />
                </ShowIf>
                <ShowIf
                    condition={
                        Boolean(transaction?.extendedStay?.extendedStayEligible) &&
                        transaction?.transactionStatus !== 'Lost'
                    }
                >
                    <Modal
                        testid="Modal:ExtendedStay"
                        width="875px"
                        withCloseIcon={true}
                        clickOutside={false}
                        backgroundColor="transparent"
                        variableWidth={true}
                        renderLink={(modal) => {
                            return (
                                <CardCont onClick={modal.open} id="fs_sell_extendedStay">
                                    Extended Stay
                                    <ArrowImage src={ArrowRightIcon} alt="" />
                                </CardCont>
                            )
                        }}
                        renderContent={(modal) => (
                            <ExtendedStay
                                close={modal.close}
                                extendedStay={transaction?.extendedStay}
                            />
                        )}
                    />
                </ShowIf>

                <ShowIf
                    condition={
                        Boolean(transaction?.extendedStay) &&
                        transaction?.transactionStatus !== 'Lost'
                    }
                >
                    <Modal
                        testid="Modal:CoeDate"
                        withCloseIcon={true}
                        clickOutside={false}
                        variableWidth={true}
                        width="auto"
                        renderLink={(modal) => (
                            <CardCont onClick={modal.open} id="fs_sell_closingDate">
                                Closing Date
                                <ArrowImage src={ArrowRightIcon} alt="" />
                            </CardCont>
                        )}
                        renderContent={(modal) =>
                            !coe.showCoe ? (
                                <CodDateChange
                                    close={modal.close}
                                    extendedStay={transaction?.extendedStay}
                                />
                            ) : (
                                <Default />
                            )
                        }
                    />
                </ShowIf>
            </Card>
        </Cont>
    )
}

export default QuickLinks
