import styled, { css } from 'styled-components'
import { color, font } from 'shared/utils/styles'

export const TrackerContainer = styled.div`
    width: 480px;
    flex: 1;
    border: 1px solid #dbe5e7;
    background-color: #f7fcfe;
    padding: 23px 32px;
    border-radius: 10px;
    position: relative;
    height: 100%;
    @media (max-width: 820px) {
        height: auto;
    }
    @media (max-width: 900px) {
        width: 100%;
    }

    ${(props) =>
        !!props.modalTracker &&
        props.modalTracker &&
        css`
            height: auto !important;
            background-color: white;
            border: none;
            @media (max-width: 690px) {
                padding: 0px;
            }
        `}
`

export const TrackerHeader = styled.div`
    position: relative;
    ${(props) =>
        props.modalTracker &&
        props.modalTracker === true &&
        css`
            @media (max-width: 480px) {
                margin-top: 20px;
            }
        `}
`

export const TrackerHeaderImage = styled.img`
    position: absolute;
    right: -10px;
    top: -40px;
    @media (max-width: 900px) {
        width: 50%;
    }
    @media (max-width: 820px) {
        width: 120px !important;
        top: -13px;
    }
    @media (max-width: 480px) {
        width: 45% !important;
        top: -40px;
    }
    ${(props) =>
        !!props.modalTracker &&
        props.modalTracker &&
        css`
            right: 0;
            @media (max-width: 480px) {
                height: 55%;
                top: -35px;
            }
        `}
`

export const TrackerHeaderHeading = styled.div`
    position: relative;
    padding: 6px 0;
    ${font.size(27)};
    color: ${color.textBlack};
    ${font.black};
    white-space: nowrap;
    @media (max-width: 900px) {
        ${font.size(20)};
    }
    ${(props) =>
        !!props.modalTracker &&
        props.modalTracker &&
        css`
            font-size: 27px !important;
            line-height: 29px;
            max-width: 168px;
            white-space: normal;
        `}
`

export const TrackerHeaderTagline = styled.div`
    width: 100%;
    ${font.size(14)};
    ${font.medium};
    line-height: 21px;
    margin-top: 28px;
    color: #525659;
    background-color: #fcefe5;
    padding: 13px 21px;
    border-radius: 12px;
    @media (max-width: 480px) {
        margin-top: 24px;
    }
`

export const TrackerBody = styled.div`
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-x: hidden;
    @media (max-width: 820px) {
        height: 219.6px;
        overflow: hidden;
    }
    ${(props) =>
        !!props.modalTracker &&
        props.modalTracker &&
        css`
            height: auto !important;
            overflow: auto;
            margin-bottom: 0px;
            padding-bottom: 10px;
        `}
`

export const TActionButton = styled.button`
    height: 35px;
    width: 35px;
    color: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20px;
    margin-right: auto;
    cursor: pointer;
    @media (max-width: 820px) {
        display: flex;
    }
    ${(props) =>
        !!props.modalTracker &&
        props.modalTracker &&
        css`
            display: none !important;
        `}
`

export const TActionButtonImg = styled.img``

export const ModalCont = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 25px;
    height: 100%;
    margin-top: 50px;
    overflow: auto;

    @media (max-width: 480px) {
        overflow: hidden;
        padding-bottom: 66px;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none;

    -ms-overflow-style: none;
`

export const ModalHeading = styled.div`
    ${font.black};
    ${font.size(27)};
    color: #363c4f;
`

export const ModalBody = styled.div`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 10px;
    @media (max-width: 480px) {
        height: 100%;
        overflow: scroll !important;
        -moz-scrollbars-none: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none;

    -ms-overflow-style: none;
`
