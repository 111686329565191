import {
    Image,
    ImageContainer,
    Conatiner,
    Heading,
    SubHeading,
    CTAContainer,
    TopContainer,
    CTAButton,
    Footer,
    FooterHeading,
    FooterSubHeading,
    ArrowDownImage,
} from './Styles'
import CurvedArrowDownIcon from 'shared/asstes/curvedArrowDownIcon.svg'

import parse from 'html-react-parser'

const DefaultDashboard = ({
    image = null,
    heading = null,
    subHeading = null,
    primaryCta = null,
    primaryCtaClick = () => {},
    secondaryCta = null,
    secondaryCtaClick = () => {},
    footerHeading = null,
    footerSubHeading = null,
    renderCustomPrimaryButton = () => {},
    renderCustomSecondaryButton = () => {},
    onFooterClick = () => {},
}) => {
    return (
        <TopContainer>
            {image && (
                <ImageContainer>
                    <Image src={image} alt="" />
                </ImageContainer>
            )}
            <Conatiner>
                {heading && <Heading>{heading}</Heading>}
                {subHeading && <SubHeading>{parse(subHeading)}</SubHeading>}
                <CTAContainer>
                    {primaryCta && (
                        <CTAButton onClick={primaryCtaClick}>
                            {renderCustomPrimaryButton()}
                            {primaryCta}
                        </CTAButton>
                    )}
                    {secondaryCta && (
                        <CTAButton secondary onClick={secondaryCtaClick}>
                            {renderCustomSecondaryButton()}
                            {secondaryCta}
                        </CTAButton>
                    )}
                    <ArrowDownImage src={CurvedArrowDownIcon} alt="" />
                </CTAContainer>
                {(footerHeading || footerSubHeading) && (
                    <Footer onClick={onFooterClick}>
                        {footerHeading && <FooterHeading>{footerHeading}</FooterHeading>}
                        {footerSubHeading && (
                            <FooterSubHeading>{parse(footerSubHeading)}</FooterSubHeading>
                        )}
                    </Footer>
                )}
            </Conatiner>
        </TopContainer>
    )
}

export default DefaultDashboard
