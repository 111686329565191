import { createSlice } from '@reduxjs/toolkit'

const myTeamsSlice = createSlice({
    name: 'teams',
    initialState: {
        teams: [],
        loading: false,
    },
    reducers: {
        updateTeams: (state, action) => ({
            ...state,
            teams: action.payload,
        }),
        updateLoading: (state, action) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const { updateTeams, updateLoading } = myTeamsSlice.actions
export default myTeamsSlice.reducer
