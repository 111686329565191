window.Buffer = window.Buffer || require('buffer').Buffer

export const convertUnixTimestampToMillis = (unixTimestamp) => {
    return unixTimestamp * 1000
}

export const convertMillsTimestampToUnix = (millsTimestamp) => {
    return Math.floor(millsTimestamp / 1000)
}

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
            resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
            reject(error)
        }
    })
}

export const convertStringToBase64 = (stringData) => {
    if (stringData === null || stringData === undefined || stringData === '') return
    return Buffer.from(stringData).toString('base64')
}

export const convertBase64ToString = (base64Data) => {
    if (base64Data === null || base64Data === undefined || base64Data === '') return
    return Buffer.from(base64Data, 'base64').toString('ascii')
}

export const convertBase64ToJSON = (base64Data) => {
    if (base64Data === null || base64Data === undefined || base64Data === '') return
    const decodedString = Buffer.from(base64Data, 'base64').toString('ascii')
    return JSON.parse(decodedString)
}

export const convertObjectToBase64 = (string) => convertStringToBase64(JSON.stringify(string))
