import { Button } from 'shared/components'
import { Cont } from './Styles'
import NotFoundIMG from './assets/not-found.svg'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/sell/dashboard')
    }

    return (
        <Cont>
            <img src={NotFoundIMG} alt="" />
            <h1>Page NOt Found</h1>
            <p>
                The page you are looking for might have been removed or does not exist or is
                temporary unavailable.
            </p>
            <Button variant="primary" size="lg" onClick={handleClick}>
                Goto Dashboard
            </Button>
        </Cont>
    )
}

export default NotFound
