import styled from 'styled-components'
import { font } from 'shared/utils/styles'

export const Cont = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 70px 50px;
    @media (max-width: 480px) {
        padding: 75px 40px 32px 40px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    h1 {
        ${font.black};
        ${font.size(25)};
        line-height: 30px;
        color: #262833;
        margin-top: 16px;
    }
    img {
        width: 100%;
        max-width: 100px;
    }
    p {
        text-align: center;
        ${font.size(17)};
        line-height: 26px;
        ${font.medium}
        margin-top: 29px;
    }

    .btn-cont {
        margin-top: 39px;
        display: flex;
        gap: 22px;
        justify-content: center;
        @media (max-width: 480px) {
            padding-bottom: 30px;
        }
        button {
            height: 62px;
            padding: 20px 44px;
            max-width: 195px;
            width: 100%;
            background: #ffffff;
            border: 1px solid #bdbdbd;
            border-radius: 4px;
            ${font.size(18)};
            ${font.medium};
            line-height: 22px;
            background-color: #ec7625;
            color: white;
            cursor: pointer;
            &:disabled {
                background: rgba(236, 118, 37, 0.5);
                cursor: initial;
            }
            @media (max-width: 480px) {
                width: 100%;
            }
        }
    }
`
