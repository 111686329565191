import React, { useState } from 'react'
import { Wizard } from 'react-use-wizard'
import { Wrapper } from 'MessageCenter/Styles'
import Step1 from 'MessageCenter/MessageScreen/Steps/Step1'
import Step2 from 'MessageCenter/MessageScreen/Steps/Step2'
import Step3 from 'MessageCenter/MessageScreen/Steps/Step3'

function MessageScreen() {
    const [id, setId] = useState()
    const [totalNoOfProperties, setTotalNoOfProperties] = useState(0)
    const [role, setRole] = useState()
    const [title, setTitle] = useState()

    return (
        <Wizard wrapper={<Wrapper />}>
            <Step1 setId={setId} setTotalNoOfProperties={setTotalNoOfProperties} />
            <Step2
                id={id}
                setRole={setRole}
                totalNoOfProperties={totalNoOfProperties}
                setTitle={setTitle}
            />
            <Step3 role={role} title={title} />
        </Wizard>
    )
}

export default MessageScreen
