import { font, zIndexValues } from 'shared/utils/styles'
import styled, { css, keyframes } from 'styled-components'

export const slideBottomWithOpacity = keyframes`
    from{
        opacity: 0;
        transform: translateY(100%);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
`

export const Overlay = styled.div`
    @media screen and (max-width: 480px) {
        ${(props) =>
            props.onResponsive &&
            css`
                position: fixed;
                top: 0;
                left: 0;
                height: 100vh;
                width: 100vw;
                background-color: rgba(0, 0, 0, 0.75);
                z-index: 1001;
            `}
    }
`

export const TooltipContainer = styled.div`
    display: inline-block;
    cursor: pointer;
    position: relative;
`

export const TooltipBody = styled.div`
    z-index: calc(${zIndexValues.modal} + 30);
    width: 270px;
    position: absolute;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    ${font.medium};
    color: #707070;
    font-size: 14px;
    line-height: 18px;
    ${(props) => toltipPosition[props.position]};
    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
    }
    @media screen and (max-width: 480px) {
        ${(props) =>
            props.onResponsive &&
            css`
                position: absolute;
                bottom: 0px;
                left: 0px;
                top: auto;
                width: 100%;
                height: fit-content;
                padding: 30px 20px;
                border-radius: 25px 25px 0px 0px;
                display: flex;
                flex-direction: column;
                gap: 8px;
                transform: none;
                color: #525659;
                animation: ${slideBottomWithOpacity} 0.3s ease-in-out;
                b {
                    font-size: 20px;
                }
                font-size: 17px;
                line-height: 22px;
            `}
    }
    b {
        display: block;
    }
`

const toltipPosition = {
    bottom: css`
        top: calc(100% + 15px);
        left: 50%;
        transform: translateX(-50%);
        &:before {
            bottom: 100%;
            left: 50%;
            border-width: 0px ${(props) => props.height / 2}px ${(props) => props.height / 2}px;
            border-color: transparent transparent white transparent;
        }
    `,
    top: css`
        bottom: calc(100% + 15px);
        left: 50%;
        transform: translateX(-50%);
        &:before {
            top: 100%;
            left: 50%;
            border-width: ${(props) => props.height / 2}px ${(props) => props.height / 2}px 0px;
            border-color: white transparent transparent transparent;
        }
    `,
    left: css`
        top: 50%;
        right: calc(100% + 15px);
        transform: translateY(-50%);
        &:before {
            top: 50%;
            left: 100%;
            border-width: ${(props) => props.height / 2}px ${(props) => props.height / 2}px
                ${(props) => props.height / 2}px 0px;
            border-color: transparent white transparent transparent;
            transform: rotate(180deg) translateY(50%);
        }
    `,
    right: css`
        top: 50%;
        left: calc(100% + 15px);
        transform: translateY(-50%);
        &:before {
            top: 50%;
            right: 100%;
            border-width: ${(props) => props.height / 2}px 0px ${(props) => props.height / 2}px
                ${(props) => props.height / 2}px;
            transform: rotate(180deg) translateY(50%);
            border-color: transparent transparent transparent white;
        }
    `,
}
