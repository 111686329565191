import styled from 'styled-components'
import { font } from 'shared/utils/styles'

export const Cont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 75px 60px;
    margin-top: 55px;
    @media (max-width: 550px) {
        margin-top: 0px;
        padding: 75px 30px;
    }
    img {
        @media (max-width: 550px) {
            width: 90%;
        }
    }
    h1 {
        ${font.size(32)};
        ${font.black};
        text-transform: uppercase;
        color: #262833;
        line-height: 38px;
        margin-top: 34px;
        text-align: center;
    }
    p {
        ${font.size(20)};
        ${font.medium};
        color: #525659;
        line-height: 38px;
        margin-top: 21px;
        text-align: center;
        max-width: 600px;
        @media (max-width: 550px) {
            max-width: 100%;
        }
    }
    button {
        margin-top: 34px;
        ${font.size(18)};
        ${font.medium};
    }
`
