import { font } from 'shared/utils/styles'
import styled from 'styled-components'

export const Cont = styled.div`
    position: relative;
    padding: 65px 0px;
    @media (max-width: 750px) {
        padding: 35px 0px;
        width: 100%;
    }
    @media (max-width: 480px) {
        padding: 0px;
        width: 100%;
    }
`

export const Heading = styled.div`
    color: #525659;
    font-size: 20px;
    line-height: normal;
    ${font.bold};
    margin-bottom: 28px;
    @media screen and (max-width: 480px) {
        margin-top: 21px;
    }
`

export const CardContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 50px 50px;
    @media (max-width: 950px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 760px) {
        grid-template-columns: 1fr;
    }
`

export const PageFooter = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 50px 0px 0px 0px;
    @media screen and (max-width: 480px) {
        padding: 50px 0px;
        gap: 8px;
    }
`

export const FooterHeading = styled.div`
    color: #5e5456;
    text-align: center;
    font-size: 29px;
    ${font.black};
    line-height: 24px;
    @media screen and (max-width: 480px) {
        font-size: 21px;
    }
`
export const FooterActionLink = styled.div`
    color: #1b6775;
    text-align: center;
    font-size: 19px;
    ${font.bold};
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
    @media screen and (max-width: 480px) {
        font-size: 15px;
    }
`
