import {
    SurveyCont,
    ActionButton,
    Heading,
    ExtShowinmid,
} from 'OnDemandActions/Sell/ExtendedStay/shared/Styles'
import Cup from '../../../shared/assets/cup-es.svg'
import { useSelector } from 'react-redux'
import { Bellhops } from 'shared/components'
import useTodos from 'shared/hooks/sell/todos'

const Step4 = ({ close = () => {}, data }) => {
    const teams = useSelector((state) => state.teams.teams)
    const successManager = teams.filter((team) => team.role === 'SuccessManager')[0]

    const { fetch } = useTodos()

    return (
        <SurveyCont height="90%">
            <ExtShowinmid>
                <div>
                    <center>
                        {' '}
                        <img src={Cup} alt="" />
                    </center>
                    <center>
                        {' '}
                        <Heading>Extended Stay</Heading>
                    </center>
                    {!data.modify && (
                        <p>
                            Your request to <span>cancel your Extended Stay</span> has been
                            submitted and will be reviewed by your Success Manager,{' '}
                            {successManager.firstName} {successManager.lastName}.
                            <br />
                            <br />
                            Once approved, you will need to sign an addendum for the cancellation.
                        </p>
                    )}
                    {data.modify && (
                        <p>
                            Your request to <span>modify your Extended Stay</span> to{' '}
                            {data.noOfDays} day(s), ending {data.dateTodisplay} has been submitted
                            and will be reviewed by your Success Manager, {successManager.firstName}{' '}
                            {successManager.lastName}.
                            <br />
                            <br />
                            Hang tight, once approved, you will receive a modified addendum to sign.
                        </p>
                    )}
                </div>

                <Bellhops />
            </ExtShowinmid>
            <br />
            <br />
            <div className="button-cont">
                <center>
                    <ActionButton
                        id="fs_sell_extendedStay_sa_submit"
                        continue
                        variant="primary"
                        onClick={async () => {
                            close()
                            await fetch()
                        }}
                    >
                        Got it!
                    </ActionButton>
                </center>
            </div>
        </SurveyCont>
    )
}

export default Step4
