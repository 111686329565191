import PropTypes from 'prop-types'
import { Input } from './Styles'

const propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    size: PropTypes.string,
    defaultColor: PropTypes.string,
    checkedColor: PropTypes.string,
}

const defaultProps = {
    className: undefined,
    name: '',
    value: '',
    onChange: () => {},
    size: '20px',
    defaultColor: 'rgba(82, 86, 89, 0.5)',
    checkedColor: '#EC7625',
}

const Radio = ({
    name,
    onChange,
    value,
    className,
    size,
    defaultColor,
    checkedColor,
    defaultChecked,
    ...rest
}) => {
    return (
        <Input
            size={size}
            className={className}
            defaultColor={defaultColor}
            checkedColor={checkedColor}
            type="radio"
            name={name}
            value={value}
            onChange={onChange}
            {...rest}
        />
    )
}

Radio.propTypes = propTypes
Radio.defaultProps = defaultProps

export default Radio
