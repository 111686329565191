import api from 'shared/utils/api'
import { useDispatch } from 'react-redux'
import { updateMyTransactions } from 'features/sell/my-transactions'
import { updateLoading } from 'features/sell/loading'
import toast from 'shared/utils/toast'

const useMyTransactions = () => {
    const dispatch = useDispatch()

    const transactionsURL = 'customer/v3/my-transactions'

    const fetchMyTranscations = async () => {
        dispatch(updateLoading(true))
        try {
            const res = await api.get(transactionsURL)
            dispatch(updateLoading(false))
            dispatch(updateMyTransactions(res))
            return res
        } catch (e) {
            dispatch(updateLoading(false))
            if (e?.statusCode === 500) {
                toast.error(e.message)
            } else if (e?.status !== 503) {
                toast.error('Something went wrong.')
            }
            console.error(e)
        }
    }

    return {
        fetch: fetchMyTranscations,
    }
}

export default useMyTransactions
