import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { StyledDiv, DivMask, Header, Anchor, HeaderArrowImg, Heading, BodyCont } from './Styles'
import useResourceCenter from 'shared/hooks/resourceCenter'
import { Collapsible, ScreenLoader } from 'shared/components'
import PlusIcon from 'ResourceCenter/assets/plus_icon.svg'
import MinusIcon from 'ResourceCenter/assets/minus_icon.svg'
import parse from 'html-react-parser'
import { isEmpty } from 'lodash'
import ArrowDownIcon from './assets/arrow-down-left.svg'
import MaskImg from './assets/mask.svg'

const ResourceCenter = () => {
    const [resources, setResources] = useState([])
    const transaction = useSelector((state) => state.singleTransaction.transaction)
    const { getResourceForOverview, loading } = useResourceCenter()
    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/resource-center')
    }

    useEffect(() => {
        async function fetchResources() {
            const res = await getResourceForOverview(transaction?.transactionStatus, 4)
            setResources(res)
        }
        fetchResources()
    }, [transaction])

    if (isEmpty(resources)) {
        return null
    }

    return (
        <StyledDiv borderRadius="lg">
            <ScreenLoader sL={loading} backgroundColor="transparent" full={false} />
            <DivMask src={MaskImg} alt="" />
            <Header>
                <div>
                    <HeaderArrowImg src={ArrowDownIcon} alt="" />
                    <Heading>Helpful Info</Heading>
                </div>
                <Anchor onClick={handleClick} id="fs_sell_help_view_all">
                    View All
                </Anchor>
            </Header>
            <br />
            <BodyCont>
                {resources.map(({ id, title, resourceType, contents }, i) => (
                    <Collapsible
                        className={`body ${resourceType === 'ResourceArticle' && 'body-resource'}`}
                        key={`${id}-${i}`}
                        renderLink={(collaps) => (
                            <div onClick={collaps.toggle} className="heading-cont">
                                <img
                                    src={collaps.isOpen ? MinusIcon : PlusIcon}
                                    alt=""
                                    className="image"
                                />
                                <div className="title">{title}</div>
                                <div
                                    className={`badge ${
                                        resourceType === 'ResourceArticle' && 'resource-badge'
                                    }`}
                                >
                                    {resourceType === 'ResourceArticle'
                                        ? 'RESOURCE'
                                        : resourceType.toUpperCase()}
                                </div>
                            </div>
                        )}
                        renderContent={() => <div className="content">{parse(contents)}</div>}
                    />
                ))}
            </BodyCont>
        </StyledDiv>
    )
}

export default ResourceCenter
