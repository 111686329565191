import { Wizard } from 'react-use-wizard'
import { Wrapper } from 'OnDemandActions/Sell/FreeLocalMove/shared/Styles'
import Step1 from 'OnDemandActions/Sell/FreeLocalMove/FreeLocalMoveDefault/Steps/Step1'
import Step2 from 'OnDemandActions/Sell/FreeLocalMove/FreeLocalMoveDefault/Steps/Step2'
import Step3 from 'OnDemandActions/Sell/FreeLocalMove/FreeLocalMoveDefault/Steps/Step3'
import { useSelector } from 'react-redux'

const FreeLoaclMoveDefault = ({ close = () => {} }) => {
    const transcation = useSelector((state) => state.singleTransaction.transaction)
    const isTrue = transcation.freeMove
    return (
        <Wizard wrapper={<Wrapper />}>
            <Step1 isTrue={isTrue} />
            <Step2 isTrue={isTrue} close={close} />
            <Step3 isTrue={isTrue} />
        </Wizard>
    )
}

export default FreeLoaclMoveDefault
