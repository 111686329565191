import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)
let messaging = null

if ('serviceWorker' in navigator && 'PushManager' in window) {
    messaging = getMessaging(app)
} else {
    console.log('Firebase Messaging is not supported in this browser.')
}

export const getClientToken = async () => {
    try {
        if (messaging) {
            const token = await getToken(messaging, { vapidKey: process.env.FIREBASE_WPC_KEY_PAIR })
            localStorage.setItem('FCM_T', token)
            return token
        } else {
            return null
        }
    } catch (error) {
        console.log(error)
        return false
    }
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    })
