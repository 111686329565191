import styled, { css } from 'styled-components'
import { Button } from 'shared/components'
import { color, font, breakPoints, mixin } from 'shared/utils/styles'
import { Link } from 'react-router-dom'

export const NavCont = styled.div`
    position: sticky;
    top: 0;
    background-color: white;
    padding: 10px 30px;
    border-bottom: 2px solid #efefef;
    z-index: 999;
    @media (max-width: 820px) {
        padding: 10px 20px;
    }
    ${(props) =>
        !!props.isDashboard &&
        props.isDashboard &&
        css`
            position: unset;
            display: flex;
            justify-content: end;
            background-color: transparent;
            border-bottom: none;
            width: 100%;
            margin-bottom: -67px;
        `}
`

export const ResCont = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    ${(props) =>
        !!props.isDashboard &&
        props.isDashboard &&
        css`
            justify-content: end;
        `}
    @media screen and (min-width: 1423px) {
        width: 100%;
        max-width: 1347px;
        margin: 0 auto;
    }
`

export const NavIntCont = styled.div`
    z-index: 2;
    display: flex;
    ${(props) =>
        !!props.gap &&
        css`
            gap: ${props.gap}px;
        `}

    ${(props) =>
        !!props.tab &&
        css`
            position: sticky;
            top: 92px;
            display: none;
            @media (max-width: 820px) {
                display: flex !important;
                width: 100%;
                background-color: #f7fcfe;
                padding: 10px 30px 0px 30px;
                justify-content: space-between;
            }
        `}
`

export const NavBarDropDownCont = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    ${mixin.clickable};
    @media (max-width: 820px) {
        display: none;
    }
`

export const NavButtons = styled(Button)`
    display: flex;
    align-items: center;
    ${font.size(17)};
    ${font.medium};
    padding: 10px 5px;
    height: 36px;
    color: #898989;
    background-color: white;
    border-bottom: 5px solid #f7fcfe;
    border-radius: 0;
    ${font.medium};
    @media (max-width: 820px) {
        display: none;
    }
    ${(props) =>
        props.active &&
        css`
            background-color: #1b6775;
            color: white;
            ${font.bold};
        `}
    &:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        @media (max-width: 820px) {
            display: none;
        }
    }

    &:hover {
        background-color: white !important;
    }

    ${(props) =>
        !!props.active &&
        css`
            border-bottom: 5px solid ${color.primary};
            background-color: white;
            color: black;
        `}

    ${(props) =>
        !!props.tab &&
        css`
            padding-bottom: 10px !important;
            &:hover {
                background-color: transparent !important;
            }
            ${(props) =>
                !!props.active &&
                css`
                    background-color: transparent !important;
                `}
            display: none;
            @media (max-width: 820px) {
                display: flex !important;
                background-color: transparent;
            }
        `}
`

export const DropCont = styled.div`
    width: auto;
    height: auto;
    padding: 20px 30px;
`

export const DropHeading = styled.div`
    ${font.bold};
    ${font.size(15)};
    line-height: 18px;
    color: #000;
`

export const UserDetailCont = styled.div`
    display: flex;
    margin-top: 17px;
    gap: 18px;
    align-items: center;
`

export const UserNameCont = styled.div`
    background-color: ${color.primary};
    ${font.bold};
    ${font.size(14)};
    color: white;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) =>
        !!props.color &&
        css`
            color: ${props.color};
        `}
    ${(props) =>
        !!props.backgroundColor &&
        css`
            background-color: ${props.backgroundColor};
        `}
`

export const UserNameAneEmailCont = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    ${(props) =>
        !!props.clickable &&
        css`
            ${mixin.clickable};
        `}
`

export const UserName = styled.div`
    ${font.bold};
    ${font.size(16)};
    color: #363948;
    ${(props) =>
        props.fs &&
        css`
            font-size: ${props.fs}px;
        `}
    ${(props) =>
        !!props.normal &&
        css`
            ${font.medium}
        `}
`

export const UserEmail = styled.div`
    ${font.medium};
    ${font.size(14)};
    color: #8d8d8d;
`
export const MenuButtonCont = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;
`

export const MenuIconAndTextCont = styled.div`
    display: flex;
    gap: 18px;
    align-items: center;
`
export const MenuIconCont = styled.div`
    background-color: #f3f7f8;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    ${(props) =>
        !!props.borderRadius &&
        css`
            border-radius: ${props.borderRadius}px;
        `}
    ${(props) =>
        !!props.clickable &&
        css`
            ${mixin.clickable};
        `}
`

export const MenuIcon = styled.img``

export const MenuText = styled.div`
    ${font.medium};
    ${font.size(15)};
    color: #363948;
    margin-right: 43px;

    ${(props) =>
        !!props.danger &&
        css`
            color: #e74136;
        `}
`

export const Divider = styled.hr`
    border: 1px solid #e6e6e6;
    margin-top: 30px;
    margin-bottom: 21px;
`

export const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70px;
    width: 100%;
    background-color: whitw;
    @media (max-width: ${breakPoints.sm}) {
        min-height: 70px;
    }
`
export const StyledLogo = styled.img`
    width: 200px;
    transition: all 0.3s;
    cursor: pointer;
    @media (max-width: ${breakPoints.sm}) {
        width: 70px;
    }
    @media (max-width: 1000px) {
        width: 161px;
    }
`
export const NavbarCircleBtn = styled.div`
    position: relative;
    width: 47px;
    height: 47px;
    background-color: #f7f7f7;
    border-radius: 50%;
    border: 1px solid #dbe5e7;
    display: flex;
    justify-content: center;
    align-items: center;
    ${font.bold}
    ${font.size(17)};
    line-height: 20.4px;
    ${mixin.clickable};
    color: #595959;
    ${(props) =>
        !!props.badge &&
        css`
            &:after {
                content: '${(props) => props.badge}';
                position: absolute;
                right: -3px;
                top: 0px;
                width: 22px;
                height: 22px;
                border: 2px solid #ffffff;
                background-color: ${color.primary};
                border-radius: 50%;
                ${font.size(12)};
                line-height: 14.4px;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        `}
    @media (max-width: 820px) {
        display: none !important;
    }
    ${(props) =>
        !!props.onlyMobile &&
        css`
            display: none;
            @media (max-width: 820px) {
                display: flex !important;
            }
        `}

    ${(props) =>
        !!props.fixed &&
        css`
            @media (max-width: 480px) {
                position: sticky;
                top: 10px;
            }
        `}
`

export const NavBarCircleIcon = styled.img`
    ${(props) =>
        !!props.isDashboard &&
        props.isDashboard &&
        css`
            margin-left: 10px;
        `}
`

export const NavBarIcon = styled.img`
    position: absolute;
    top: -20px;
    left: -20px;
    transform: translate(15px, 5px);
`
export const AnchorLink = styled(Link)`
    ${font.medium};
    color: ${color.primary};
    ${font.size(12)};
    line-height: 14.4px;
    display: flex;
    justify-content: end;
    text-decoration: underline;
    margin-top: 20px;
`

export const DayCont = styled.div`
    padding: 0 30px;
    ${(props) =>
        !!props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`

export const DayText = styled.div`
    ${font.medium};
    ${font.size(11)};
    line-height: 21px;
    color: #8e91a0;
    margin-bottom: 8px;
`

export const NotiCont = styled.div`
    position: relative;
    display: flex;
    padding: 13px 30px;
    max-width: 393px;
    border-bottom: 1px solid #efeaff;
    ${(props) =>
        !!props.nobb &&
        css`
            border-bottom: none;
        `}
    transition: all 0.3s;
    ${mixin.clickable};
    ${font.medium};
    &:hover {
        ${font.bold};
        background-color: #e7f0f1;
        border-bottom: 1px solid #d3dbdc;
    }
`

export const NotiIcon = styled.img`
    height: 40px;
    width: 40px;
`

export const NotiText = styled.div`
    margin-left: 30px;
    ${font.size(14)};
    line-height: 20px;
    color: #363948;
    margin-right: 40px;
`

export const NotiTime = styled.div`
    position: absolute;
    bottom: 13px;
    right: 30px;
    ${font.medium};
    ${font.size(10)};
    line-height: 12px;
    color: #8e91a0;
`

export const SubHeader = styled.div`
    ${font.size(12)};
    color: ${color.trackerCurrentStatusColor};
    ${font.medium};
    line-height: 14px;
    margin-top: 7px;
`

export const MessageText = styled.div`
    ${font.medium};
    ${font.size(15)};
    color: ${color.trackerCurrentStatusColor};
    margin-right: 43px;

    ${(props) =>
        !!props.danger &&
        css`
            color: #e74136;
        `}
`

export const Badge = styled.div`
    background-color: #f6693d;
    color: #fff;
    height: 32px;
    width: 32px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid #ffffff;
    ${mixin.clickable};
`
