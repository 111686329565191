import styled, { css } from 'styled-components'
import { font } from 'shared/utils/styles'

export const Cont = styled.div`
    padding: 70px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 480px) {
        padding: 75px 40px 32px 40px;
        display: flex;
        flex-direction: column;
    }
    h1 {
        ${font.black};
        ${font.size(22)};
        line-height: 26.4px;
        color: #000000;
    }
    .coe-info-cont {
        display: flex;
        flex-direction: column;
        margin-top: 33px;
        p {
            text-align: center;
            ${font.medium};
            ${font.size(17)};
            line-height: 26px;
            color: #525659;
            span {
                color: #ec7625;
            }
        }
    }

    .bellhop {
        background: rgba(231, 240, 241, 0.5);
        border: 1px solid #dbe5e7;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 35px 56px;
        margin-top: 30px;
        @media (max-width: 480px) {
            padding: 35px 20px;
        }
        h1 {
            ${font.black};
            ${font.size(22)};
            line-height: 26.4px;
            color: #000000;
            text-align: center;
        }
        p {
            text-align: center;
            ${font.medium};
            ${font.size(16)};
            line-height: 20px;
            margin-top: 12px;
            color: #1b6775;
        }
        h2 {
            ${font.bold};
            ${font.size(16)};
            line-height: 19px;
            color: #000000;
            text-align: center;
            margin-top: 8px;
        }
        img {
            margin-top: 22px;
            width: 150px;
            @media (max-width: 480px) {
                width: 101px;
            }
        }
        .bellhop-info-cont {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 67px;
            margin-top: 30px;
            @media (max-width: 820px) {
                gap: 25px;
            }
            div {
                display: flex;
                align-items: center;
                gap: 9px;
                img {
                    width: 13px;
                    margin-top: 0px;
                }
                a {
                    ${font.medium};
                    ${font.size(14)}
                    line-height: 14px;
                    text-decoration-line: underline;
                    color: #ec7625;
                }
            }
        }
    }

    .btn-cont {
        margin-top: 39px;
        display: flex;
        gap: 22px;
        @media (max-width: 480px) {
            margin: 0 auto;
        }
        button {
            height: 62px;
            padding: 20px 44px;
            width: 129px;
            background: #ffffff;
            border: 1px solid #bdbdbd;
            border-radius: 4px;
            ${font.size(18)};
            ${font.regular};
            line-height: 22px;
            color: #525659;
            cursor: pointer;
            @media (max-width: 480px) {
                width: 100%;
            }
        }
        .next {
            width: 180px;
            background: #ec7625;
            color: white;
            @media (max-width: 480px) {
                width: 100%;
            }
        }
    }
`
