import { Step } from '../Styles'
import CheckIcon from 'shared/asstes/checkIcon.svg'
import { useSelector } from 'react-redux'

const Step2 = ({ close }) => {
    const user = useSelector((state) => state.user)

    const isAgent = user?.agent?.toLowerCase() === 'true'

    const heading = isAgent ? 'We’re excited to connect.' : 'We’re on it.'

    const body = isAgent
        ? 'Your info has been sent over to the Offerpad local buying team. We’ll be in touch soon.'
        : 'Thanks for the info. One of our local buying experts will reach out to you soon!'

    return (
        <Step>
            <div className="success">
                <img src={CheckIcon} alt="" />
                <h2>{heading}</h2>
                <p>{body}</p>
                <button id="fs_buy_dashboard_modalSubmit" className="buy-button" onClick={close}>
                    OK
                </button>
            </div>
        </Step>
    )
}

export default Step2
