import styled, { css, keyframes } from 'styled-components'
import { font } from 'shared/utils/styles'
import Button from '../Button'
import Modal from '../Modal'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

export const EmbeddedSigningModal = styled(Modal)`
    overflow: hidden;
`

export const EmbeddedSigningCont = styled.div`
    margin-left: auto;
    @media (max-width: 480px) {
        margin-left: 0px;
    }
`

export const Cont = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 60px 50px;
    height: 100%;
    @media (max-width: 480px) {
        padding: 75px 40px 32px 40px;
    }
    h1 {
        ${font.size(25)}
        line-height: 26px;
        ${font.black};
        color: black;
        @media (max-width: 480px) {
            ${font.size(17)}
            line-height: 18px;
        }
    }
    p {
        margin-top: 8px;
        ${font.size(14)}
        line-height: 17px;
        ${font.medium};
        color: #898989;
    }
    .embedded-signing-user-info {
        ${font.size(12)}
        line-height: 22px;
        ${font.medium};
        color: #ec7625;
        padding: 6px 25px;
        background-color: #f7fcfe;
        border: 0.25px solid #ec7625;
        border-radius: 15px;
        width: fit-content;
        animation: ${slideLeft} ease 0.7s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        @media (max-width: 480px) {
            padding: 10px 10px;
            text-align: center;
            width: 100%;
        }
    }
    .embedded-sinning-user {
        position: relative;
        border-radius: 8px;
        ${font.size(18)}
        line-height: 26px;
        ${font.medium};
        color: #525659;
        margin-top: 35px;
        @media (max-width: 480px) {
            margin-top: 20px;
        }
        .user-name {
            ${font.size(16)}
            line-height: 22px;
            ${font.bold};
            color: #525659;
        }
        .user-email {
            ${font.size(12)}
            line-height: 22px;
            ${font.medium};
            color: #525659;
            margin-top: -4px;
        }
        .disabled {
            opacity: 0.5;
        }
        .check-icon {
            width: 20px;
            height: 20px;
        }
        .embedded-sinning-cont {
            display: flex;
            align-items: flex-start;
            gap: 23px;
            width: 100%;
        }
        .embedded-sinning-name-status-cont {
            display: flex;
            align-items: center;
            gap: 32px;
            margin-top: -5px;
            @media (max-width: 480px) {
                flex-direction: column;
                gap: 3px;
                align-items: flex-start;
            }
        }
        .embedded-sinning-name-email-container {
            display: flex;
            flex-direction: column;
        }
        .embedded-sinning-user-status {
            ${font.size(12)}
            line-height: 14.4px;
            ${font.medium};
            padding: 8px 21px;
            border-radius: 15px;
            width: fit-content;
        }
        .pending {
            color: #ec7625;
            background-color: rgba(236, 118, 37, 0.24);
            border: 1px solid #ffd1b1;
        }
        .completed {
            color: #1b6775;
            background-color: #f6f6f6;
            border: 1px solid #dbe5e7;
        }
    }
    .embedded-sinning-user-loader {
        right: 10px;
        left: auto;
        top: 4px;
    }
    .no-cursor {
        cursor: default !important;
    }
`

export const ActionButton = styled(Button)`
    padding: 10px 17px;
    border-radius: 3px;
    ${font.size(13)};
    ${font.medium};
    height: fit-content;
    margin: 0px 0px 0px auto;
    width: 168px !important;
    @media (max-width: 480px) {
        margin: 0px;
    }
    ${(props) =>
        props.review === true &&
        css`
            margin: 0 0 0 auto;
            border-radius: 3px;
            border: 1px solid #1b6775;
            background-color: #f6f6f6;
            color: #1b6775;
            padding: 10px 31px;
            ${font.medium};
            ${font.size(13)};
            line-height: 15.6px;
            &:hover {
                background-color: #f6f6f6 !important;
            }
            @media (max-width: 650px) {
                margin: 0px;
                width: 180px;
                height: 47px;
            }
        `}
`

export const ActionButtonModal = styled(Button)`
    padding: 20px 56px;
    width: 243px;
    margin-top: 46px;
    ${font.bold};
    ${font.size(18)};
    line-height: 21.6px;
    @media (max-width: 480px) {
        width: 100%;
        margin-top: 40px;
        padding: 20px 30px;
        ${font.bold};
        ${font.size(17)};
    }
`
