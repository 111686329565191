import {
    ProfileCont,
    Heading,
    FormCont,
    DeleteAccount,
    ActionButton,
    HeadingCont,
    ButtonCont,
} from './Styles'
import { Form, PageContainer, ScreenLoader, Button } from 'shared/components'
import { formatPhoneNumber } from 'shared/utils/formatter'
import { useLocation, useNavigate } from 'react-router-dom'
import alert from 'shared/utils/alert'
import { useEffect, useState } from 'react'
import AnimatedBin from 'shared/asstes/bin_animated.svg'
import Info from 'shared/asstes/info.svg'
import Pen from 'shared/asstes/pen.svg'
import PenWhite from 'shared/asstes/pen_white.svg'
import CheckIcon from 'shared/asstes/checkIcon.svg'
import api from 'shared/utils/api'
import { logout, refreshToken } from 'shared/utils/auth'
import toast from 'shared/utils/toast'
import { useDispatch, useSelector } from 'react-redux'
import { decaodeJWT } from 'shared/utils/jwt'
import { setUser } from 'features/userSlice'

const Profile = () => {
    const [isWorking, setisWorking] = useState(false)
    const [canEdit, setCanEdit] = useState(false)

    const user = useSelector((state) => state.user)

    const { pathname } = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const showDeleteAccountAlert = pathname === '/profile/delete-account'
    const deleteAccoutnURL = 'api/customer-auth/v2/customer-user-request-delete'
    const updateAccountURL = 'api/customer-auth/v2/profile'

    const handleonClick = () => navigate('/profile/delete-account', { replace: true })

    const showConfirmAlert = () => {
        alert.confirm(
            'Are you sure you wish to delete your account?',
            'Please keep in mind that deleting your account will lose entire data of this account and this process cannot be reversible.<br/> <br/> Are you sure to delete?',
            AnimatedBin,
            async (confirm) => {
                if (confirm) {
                    setisWorking(true)
                    try {
                        await api.post(deleteAccoutnURL)
                        setisWorking(false)
                        showConfirmSuccessAlert()
                    } catch (error) {
                        setisWorking(false)
                        showCofirmFailAlert()
                    }
                } else {
                    navigate('/profile', { replace: true })
                }
            },
        )
    }

    const showConfirmSuccessAlert = () =>
        alert.info(
            'Deletion Requested!',
            'Thank you for your request to delete your account and user-generated content. Please allow up to forty-five (45) days from the date of your request for your account deletion to be complete. Please note that if you have a pending transaction that has not been closed, the date of your request will be deemed the date your transaction closes. We will notify you when your request is complete.',
            AnimatedBin,
            () => {
                logout(false)
            },
        )

    const showCofirmFailAlert = () =>
        alert.info(
            'Account Deletion Failed!',
            `We're sorry, we had trouble submitting the request to delete your account. Please try again and contact our Support ('Report a bug') team if you continue to have issues.`,
            Info,
            () => {
                navigate('/profile', { replace: true })
            },
        )

    const showProfileUpdatedAlert = () =>
        alert.info(
            'We have updated your profile settings.',
            'Please be aware that if you have any transactions in-process with Offerpad and you need to change the name used on contracts, you will need to contact your Success Manager.',
            CheckIcon,
        )

    const isButtonEnabled = ({ updatedFirstName, updatedLastName }) =>
        updatedLastName?.toLowerCase() !== user?.lastName?.toLowerCase() ||
        updatedFirstName?.toLowerCase() !== user?.firstName?.toLowerCase()

    useEffect(() => {
        if (showDeleteAccountAlert) {
            showConfirmAlert()
        }
    }, [showDeleteAccountAlert])

    return (
        <PageContainer>
            <ProfileCont>
                <ScreenLoader sL={isWorking} size={100} full={false} />
                <HeadingCont>
                    <Heading>My Profile</Heading>
                    <ActionButton active={canEdit} onClick={() => setCanEdit(!canEdit)}>
                        <img src={canEdit ? PenWhite : Pen} alt="" />
                    </ActionButton>
                </HeadingCont>
                <Form
                    enableReinitialize
                    initialValues={{
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        email: user?.email,
                        phoneNumber: formatPhoneNumber(user?.phoneNumber),
                    }}
                    onSubmit={async (values) => {
                        try {
                            setisWorking(true)
                            await api.post(updateAccountURL, {
                                firstName: values.firstName,
                                lastName: values.lastName,
                            })
                            const { authToken } = await refreshToken(dispatch)
                            const decodedToken = decaodeJWT(authToken)
                            dispatch(setUser(decodedToken))
                            showProfileUpdatedAlert()
                        } catch (error) {
                            console.log(error)
                            toast.error('Something went wrong. Unable to update the profile.')
                        } finally {
                            setCanEdit(false)
                            setisWorking(false)
                        }
                    }}
                >
                    {(props) => (
                        <Form.Element>
                            <FormCont>
                                <Form.Field.Input
                                    type="text"
                                    disabled={!canEdit}
                                    label="First Name"
                                    size="lg"
                                    iconType="name"
                                    name="firstName"
                                />
                                <Form.Field.Input
                                    type="text"
                                    disabled={!canEdit}
                                    label="Last Name"
                                    size="lg"
                                    iconType="name"
                                    name="lastName"
                                />
                                <Form.Field.Input
                                    type="email"
                                    disabled={true}
                                    label="Email"
                                    size="lg"
                                    name="email"
                                />
                                <Form.Field.Input
                                    type="text"
                                    disabled={true}
                                    label="Phone Number (Optional)"
                                    size="lg"
                                    iconType="phone"
                                    name="phoneNumber"
                                />
                            </FormCont>
                            {canEdit && (
                                <ButtonCont>
                                    <Button
                                        variant="primary"
                                        size={'lg'}
                                        type="submit"
                                        disabled={
                                            !isButtonEnabled({
                                                updatedFirstName: props.values.firstName,
                                                updatedLastName: props.values.lastName,
                                            })
                                        }
                                    >
                                        Update
                                    </Button>
                                </ButtonCont>
                            )}
                        </Form.Element>
                    )}
                </Form>
                {!canEdit && (
                    <DeleteAccount onClick={handleonClick}>
                        <img src={AnimatedBin} alt="Delete Account" />
                        Delete Account
                    </DeleteAccount>
                )}
            </ProfileCont>
        </PageContainer>
    )
}

export default Profile
