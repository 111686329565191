import React from 'react'
import {
    Cont,
    ImageCont,
    Circle,
    CheckMark,
    Header,
    SubHeader,
    ActionButton,
    TextContainer,
    SuccessCont,
} from './Styles'
import Tick from './assets/tick-s.svg'
import Background from './assets/tick-background-s.svg'
import { useNavigate } from 'react-router'

function Success({ role }) {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate('/sell/overview')
    }
    return (
        <Cont>
            <SuccessCont>
                <ImageCont>
                    <Circle src={Background} alt="" />
                    <CheckMark src={Tick} alt="" />
                </ImageCont>
                <TextContainer>
                    <Header>Thank you!</Header>
                    <SubHeader>
                        We have received your message. Our Tech Support team will reach out to you
                        soon.
                    </SubHeader>
                </TextContainer>
                <ActionButton id="fs_tech_support_success" onClick={handleClick}>
                    Ok
                </ActionButton>
            </SuccessCont>
        </Cont>
    )
}

export default Success
