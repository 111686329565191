import { Cont, Img } from './Styles'
import CalendarCoe from 'OnDemandActions/Sell/CodDateChange/shared/assets/calendar-coe.svg'
import MaskCoe from 'OnDemandActions/Sell/CodDateChange/shared/assets/mask-coe.svg'
import { Calendar, ScreenLoader } from 'shared/components'
import { useWizard } from 'react-use-wizard'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Step1 = ({ data, setData, coe = {}, es }) => {
    const { nextStep, goToStep } = useWizard()
    const [newDate, setNewDate] = useState(data.date ? data.date : null)
    const loading = useSelector((state) => state.coe.loading)

    const handleClick = () => {
        es?.extendedStayEligible ? nextStep() : goToStep(2)
    }

    useEffect(() => {
        const date = { date: newDate }
        const d = { currentData: coe.currentCoeDate }
        setData({ ...data, ...date, ...d })
    }, [newDate])

    return (
        <Cont>
            <ScreenLoader sL={loading} full={false} size={100} />
            <div className="first">
                <h1>Request Change to Closing Date</h1>
                <button id="fs_sell_cod_dateChange_sa_closingDate">
                    Current Closing Date:{' '}
                    {moment(coe.currentCoeDate, 'MM/DD/YYYY').format('MMM, DD YYYY')}
                </button>
                <Img src={CalendarCoe} alt="" />
            </div>
            <Img src={MaskCoe} alt="" mask="true" />
            <div className="second">
                <div className="calendar-cont">
                    <Calendar
                        calendarClassName="coe-calendar"
                        onEventClick={(date) => setNewDate(date)}
                        dateToShow={coe.currentCoeDate}
                        selectType="border"
                        stayOnCurrent={true}
                        selectedBackgroundColor="#1B6775"
                        currentSelectedDate={newDate}
                        disabledDateWeak={['Sun', 'Sat']}
                        enabledDates={coe.enabledDates}
                    />
                </div>
                {newDate !== null && (
                    <span>
                        Requested Date: {moment(newDate, 'MM/DD/YYYY').format('MMM, DD YYYY')}
                    </span>
                )}
                <button
                    id="fs_sell_cod_dateChange_sa_next"
                    disabled={newDate === null ? true : false}
                    onClick={handleClick}
                >
                    Next
                </button>
            </div>
        </Cont>
    )
}

export default Step1
