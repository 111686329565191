import React, { forwardRef, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
    StyledInput,
    InputElement,
    StyledIcon,
    PasswordStrengthBox,
    Bar,
    InputIcon,
} from './Styles'
import EyeOff from './assets/eye-off.svg'
import EyeOn from './assets/eye-on.svg'
import Circle from './assets/circle.svg'
import CheckedCircle from './assets/checked-circle.svg'
import LockIcon from './assets/lock-input.svg'
import Avatar from './assets/avatar.svg'
import EmailIcon from './assets/inputmail.svg'
import PhoneIcon from './assets/tel.svg'
import { measurePasswordStrength } from 'shared/utils/validation'

const propTypes = {
    className: PropTypes.string,
    inputClass: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.oneOf(['sm', 'normal', 'lg']),
    invalid: PropTypes.bool,
    filter: PropTypes.instanceOf(RegExp),
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onChangeEvent: PropTypes.func,
    onKeyUp: PropTypes.func,
    showStrengthBox: PropTypes.bool,
    iconType: PropTypes.string,
}

const defaultProps = {
    className: undefined,
    inputClass: undefined,
    type: 'text',
    size: 'normal',
    invalid: false,
    filter: undefined,
    placeholder: '',
    onChange: () => {},
    onChangeEvent: () => {},
    onKeyUp: () => {},
    showStrengthBox: false,
    iconType: '',
}

const Input = forwardRef(
    (
        {
            className,
            inputClass,
            type,
            size,
            invalid,
            filter,
            placeholder,
            onChange,
            onChangeEvent,
            onKeyUp,
            showStrengthBox,
            iconType,
            ...inputProps
        },
        ref,
    ) => {
        const [inputType, setInputType] = useState(type)
        const [isFocused, setIsFocused] = useState(false)
        const [strengthValidation, setStrengthValidation] = useState(measurePasswordStrength(''))
        const [popupPosition, setPopupPosition] = useState({
            top: 'auto',
            bottom: 'calc(100% + 5px)',
            left: '50%',
            transform: 'translateX(-50%)',
        })

        const $passwordStrengthBoxRef = useRef(null)

        const $contRef = useRef()

        const handleChange = (event) => {
            if (!filter || filter.test(event.target.value)) {
                onChange(event.target.value, event)
                onChangeEvent(event.target.value, event)
            }
        }

        const handleTypeChange = () => {
            if (type === 'password') {
                inputType === 'password' ? setInputType('text') : setInputType('password')
            }
        }

        const renderInputIcon = () => {
            if (iconType === 'name') {
                return <InputIcon src={Avatar} alt="" />
            }
            if (type === 'password') {
                return <InputIcon src={LockIcon} alt="" />
            }
            if (type === 'email') {
                return <InputIcon src={EmailIcon} alt="" />
            }
            if (iconType === 'phone') {
                return <InputIcon src={PhoneIcon} alt="" />
            }
            return null
        }

        const onFocusEvent = () => {
            setIsFocused(true)
        }

        const onBlurEvent = () => {
            setIsFocused(false)
        }

        const onKeyupEvent = (e) => {
            onKeyUp(e)
            inputType === 'password' &&
                setStrengthValidation(measurePasswordStrength(e.target.value))
        }

        useEffect(() => {
            const updateStrengthBoxPosition = () => {
                if (!$passwordStrengthBoxRef.current) return
                const popupRect = $passwordStrengthBoxRef.current.getBoundingClientRect()
                const viewportWidth = window.innerWidth

                // Check if the popup is near the top of the viewport
                if (popupRect.top < 50) {
                    setPopupPosition({
                        top: 'calc(100% + 5px)',
                        bottom: 'auto',
                        left:
                            popupRect.left < 0
                                ? 0
                                : popupRect.left + popupRect.width > viewportWidth
                                ? 'auto'
                                : popupRect.left,
                        right: popupRect.left + popupRect.width > viewportWidth ? 0 : 'auto',
                        transform: 'translateY(-100%)',
                    })
                } else {
                    // Otherwise, position the popup below the input field
                    setPopupPosition({
                        top: 'calc(100% + 5px)',
                        bottom: 'auto',
                        left: '50%',
                        right: 'auto',
                        transform: 'translateX(-50%)',
                    })
                }
            }

            if (showStrengthBox) {
                updateStrengthBoxPosition()
                window.addEventListener('resize', updateStrengthBoxPosition)
                return () => {
                    window.removeEventListener('resize', updateStrengthBoxPosition)
                }
            }
        }, [isFocused, showStrengthBox])

        return (
            <StyledInput
                className={className}
                type={type}
                size={size}
                invalid={invalid}
                ref={$contRef}
            >
                <InputElement
                    {...inputProps}
                    placeholder={placeholder}
                    onChange={handleChange}
                    size={size}
                    ref={ref}
                    type={inputType}
                    className={inputClass}
                    onFocus={onFocusEvent}
                    onBlur={onBlurEvent}
                    onKeyUp={onKeyupEvent}
                />
                {isFocused && showStrengthBox && (
                    <PasswordStrengthBox ref={$passwordStrengthBoxRef} style={popupPosition}>
                        <h2>Password must have</h2>
                        <div className="strength-bar-cont">
                            <Bar color={strengthValidation.color}></Bar>
                            <Bar color={strengthValidation.color}></Bar>
                            <Bar color={strengthValidation.color}></Bar>
                            <Bar color={strengthValidation.color}></Bar>
                            <span>{strengthValidation.strength}</span>
                            <img src={Lock} alt="" />
                        </div>
                        <div className="strength-types">
                            <div>
                                <img
                                    src={
                                        strengthValidation.checks.length.passed
                                            ? CheckedCircle
                                            : Circle
                                    }
                                    alt=""
                                />
                                <span>6 characters long</span>
                            </div>
                            <div>
                                <img
                                    src={
                                        strengthValidation.checks.uppercase.passed
                                            ? CheckedCircle
                                            : Circle
                                    }
                                    alt=""
                                />
                                <span>Upper case</span>
                            </div>
                            <div>
                                <img
                                    src={
                                        strengthValidation.checks.lowercase.passed
                                            ? CheckedCircle
                                            : Circle
                                    }
                                    alt=""
                                />
                                <span>Lower case</span>
                            </div>
                            <div>
                                <img
                                    src={
                                        strengthValidation.checks.special.passed
                                            ? CheckedCircle
                                            : Circle
                                    }
                                    alt=""
                                />
                                <span>Special character</span>
                            </div>
                            <div>
                                <img
                                    src={
                                        strengthValidation.checks.number.passed
                                            ? CheckedCircle
                                            : Circle
                                    }
                                    alt=""
                                />
                                <span>Number</span>
                            </div>
                        </div>
                    </PasswordStrengthBox>
                )}
                {renderInputIcon()}
                {type === 'password' &&
                    (inputType === 'password' ? (
                        <StyledIcon src={EyeOff} alt="" onClick={handleTypeChange} />
                    ) : (
                        <StyledIcon src={EyeOn} alt="" onClick={handleTypeChange} />
                    ))}
                {/* {type === 'password' && inputType === 'password' && (
                    <InputIcon src={EyeOff} alt="" />
                )} */}
            </StyledInput>
        )
    },
)

Input.propTypes = propTypes
Input.defaultProps = defaultProps

export default Input
