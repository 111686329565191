import styled, { keyframes } from 'styled-components'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

const opacity = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const Wrapper = styled.div`
    animation: ${opacity} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    div {
        animation: ${slideLeft} ease 0.7s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
`
