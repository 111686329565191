import { Cont, LeftCont, HomeBtn, HomeBtnImg, NavCont, NavButton } from './Styles'
import ArrowLeft from 'shared/asstes/leftArrowIcon.svg'
import SelectPropertyModal from '../SelectPropertyModal'

import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import { useLocation } from 'react-router-dom'

const TabMenus = () => {
    const { pathname } = useLocation()
    const showBackToDashboard = pathname === '/sell/overview'
    const showSelectProperty = pathname === '/sell/overview'
    const showTabMenus = pathname !== '/sell/dashboard'

    if (!showTabMenus) {
        return null
    }

    return (
        <Cont>
            <LeftCont>
                {showBackToDashboard && (
                    <HomeBtn to="/sell/dashboard" web="true">
                        <HomeBtnImg src={ArrowLeft} alt="" web="true" />
                    </HomeBtn>
                )}
                {showSelectProperty && <SelectPropertyModal />}
            </LeftCont>

            <NavCont>
                <Splide
                    tag="div"
                    options={{
                        drag: 'free',
                        gap: '10px',
                        width: '100%',
                        perMove: 3,
                        dots: false,
                        mediaQuery: 'min',
                        breakpoints: {
                            630: {
                                destroy: true,
                            },
                        },
                    }}
                >
                    <SplideSlide>
                        <NavButton
                            active={pathname === '/sell/overview' ? 'true' : 'false'}
                            to={`/sell/overview`}
                        >
                            Overview
                        </NavButton>
                    </SplideSlide>
                    <SplideSlide>
                        <NavButton
                            active={pathname === '/sell/action-items' ? 'true' : 'false'}
                            to="/sell/action-items"
                        >
                            Action Items
                        </NavButton>
                    </SplideSlide>
                    <SplideSlide>
                        <NavButton
                            active={pathname === '/sell/calendar' ? 'true' : 'false'}
                            to="/sell/calendar"
                        >
                            Calendar
                        </NavButton>
                    </SplideSlide>
                    <SplideSlide>
                        <NavButton
                            active={pathname === '/sell/your-team' ? 'true' : 'false'}
                            to="/sell/your-team"
                        >
                            Your Team
                        </NavButton>
                    </SplideSlide>
                    <SplideSlide>
                        <NavButton
                            active={pathname === '/sell/documents' ? 'true' : 'false'}
                            to="/sell/documents"
                        >
                            Documents
                        </NavButton>
                    </SplideSlide>
                </Splide>
            </NavCont>
        </Cont>
    )
}

export default TabMenus
