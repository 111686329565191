import React, { forwardRef, useEffect, useRef } from 'react'
import Calendar from '../Calendar'
import {
    ActionButton,
    ButtonContainer,
    CloseIcon,
    Container,
    Div,
    Heading,
    Overlay,
    Points,
    Span,
} from './Styles'
import ArrowUpOrange from 'shared/asstes/curved-arrow-up-orange.svg'
import Truck from 'shared/asstes/truck.svg'
import Coffee from 'shared/asstes/coffee.svg'
import Icon from '../Icon'

const CashOfferCard = forwardRef(
    ({ state, resizeScreen, closeScreen, handleCalendarClick, standalone }, ref) => {
        const $overlayRef = useRef(null)

        const handleOverlayClick = (e) => {
            if ($overlayRef.current && ref.current && window.innerWidth <= 480) {
                if ($overlayRef.current === e.target) {
                    closeScreen()
                }
            }
        }

        const handleCalendarDateChange = () => {
            closeScreen({ calendar: false, newClosingDate: state.closingDate })
        }

        useEffect(() => {
            if (state.activeInfoScreen !== '' && !state.compare) {
                !standalone && resizeScreen(1115)
            }
        }, [state.activeInfoScreen])

        const MainContainer = ({ children }) => {
            return (
                <Overlay
                    id="extra-screen-overlay"
                    ref={$overlayRef}
                    onClick={handleOverlayClick}
                    cover={state.compare}
                    show={window.innerWidth <= 1115 || (standalone && !state.compare)}
                >
                    <Container
                        ref={ref}
                        cover={state.compare}
                        show={window.innerWidth <= 1115 || (standalone && !state.compare)}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                                width: '100%',
                                marginBottom: '15px',
                            }}
                        >
                            <CloseIcon onClick={closeScreen}>
                                <Icon type="close" />
                            </CloseIcon>
                        </div>
                        {children}
                    </Container>
                </Overlay>
            )
        }

        switch (state.activeInfoScreen) {
            case 'calendar':
                return (
                    <MainContainer>
                        <Div>
                            <Span>
                                Select a date that works for you, don't worry - you'll be able to
                                change it if needed.
                                <Span anchorColorOrange style={{ marginTop: '10px' }}>
                                    Not sure?{' '}
                                    <a
                                        onClick={handleCalendarDateChange}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Click here and we'll pick a temporary date.
                                    </a>{' '}
                                </Span>
                            </Span>
                            <img src={ArrowUpOrange} alt="" />
                        </Div>
                        <Calendar
                            enabledDates={state?.calendarEnableDates || []}
                            disabledDateWeak={['Sun', 'Sat']}
                            dateToShow={state.newClosingDate}
                            onEventClick={handleCalendarClick}
                            calendarClassName="viewoffer-calendar"
                            headerClassName="viewoffer-calendar-header"
                        />
                    </MainContainer>
                )
            case 'localMove':
                return (
                    <MainContainer>
                        <img
                            src={Truck}
                            alt=""
                            style={{
                                height: '80px',
                                transform: 'scaleX(-1)',
                                marginLeft: '-8px',
                            }}
                        />
                        <Heading>Free Local Move</Heading>
                        <Span style={{ marginBottom: '10px' }}>
                            Offerpad is all about removing the stress related to selling your home,
                            which is why we include a FREE local move.*
                        </Span>
                        <Points>Within 50 miles from your existing home</Points>
                        <Points>Homes up to 2,800 s.f. / 13,000 lbs</Points>
                        <Points>
                            Packing services not included but available as an add-on service
                        </Points>
                        <ButtonContainer>
                            <ActionButton
                                variant="primary"
                                size="sm"
                                bordered
                                onClick={() => closeScreen({ localMove: false })}
                            >
                                No thanks
                            </ActionButton>
                            <ActionButton
                                variant="primary"
                                size="sm"
                                onClick={() => closeScreen({ localMove: true })}
                            >
                                Yes, I want it!
                            </ActionButton>
                        </ButtonContainer>
                        <Span anchorColorOrange>
                            * Certain <a>restrictions & conditions</a> apply,
                        </Span>
                    </MainContainer>
                )
            case 'extendedStay':
                return (
                    <MainContainer>
                        <img
                            src={Coffee}
                            alt=""
                            style={{
                                height: '80px',
                                marginLeft: '-15px',
                            }}
                        />
                        <Heading>Extended Stay</Heading>
                        <Span style={{ marginBottom: '10px' }}>
                            Who wouldn't like a few extra days after closing to move in to your new
                            place? With Offerpad, you can stay up to 3 days after closing and relax
                            a bit!
                        </Span>
                        <ButtonContainer>
                            <ActionButton
                                variant="primary"
                                size="sm"
                                bordered
                                onClick={() => closeScreen({ extendedStay: false })}
                            >
                                No thanks
                            </ActionButton>
                            <ActionButton
                                variant="primary"
                                size="sm"
                                onClick={() => closeScreen({ extendedStay: true })}
                            >
                                Yes, I want it!
                            </ActionButton>
                        </ButtonContainer>
                        <Span anchorColorOrange>
                            * Certain <a>restrictions & conditions</a> apply,
                        </Span>
                    </MainContainer>
                )
            default:
                return null
        }
    },
)

export default CashOfferCard
