import { color, font, mixin } from 'shared/utils/styles'
import styled, { css, keyframes } from 'styled-components'
import Icon from 'shared/components/Icon'

const slideFromUp = keyframes`
    from {transform:translateY(-100%)}
    to {transform:translateY(0)}
`

const slideFromDown = keyframes`
    from {transform:translateY(100%)}
    to {transform:translateY(0)}
`

export const Cont = styled.div`
    padding: 30px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    position: relative;
    @media screen and (min-width: 1423px) {
        width: 100%;
        max-width: 1347px;
        margin: 0 auto;
    }
    @media screen and (max-width: 1422px) {
        padding: 10px;
    }
    @media (max-width: 480px) {
        height: 100%;
        padding: 0px 20px 0px 0px !important;
    }
    overflow: hidden;
    img {
        margin-top: 120px;
        height: 250px;
        @media screen and (max-width: 827px) {
            margin-left: -60px;
        }
        @media (max-width: 480px) {
            margin-top: 70px;
            height: 300px;
            margin-left: -110px;
        }
    }
    div {
        color: #1b6775;
        ${font.size(25)};
        ${font.black};
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-left: -45px;
        @media (max-width: 990px) {
            ${font.size(18)};
        }
        @media (max-width: 480px) {
            flex-direction: column;
            align-items: start;
            margin-left: -10px;
            ${font.size(20)};
            line-height: 24px;
        }
        span {
            @media (max-width: 480px) {
                display: inline-block;
            }
        }
        button {
            border-radius: 38px;
            height: 45px;
            width: 218px;
            @media (max-width: 990px) {
                height: 40px;
                width: 150px;
            }
            @media (max-width: 480px) {
                height: 45px;
                width: 100%;
            }
            div {
                ${font.size(14)};
                line-height: 17px;
                ${font.medium};
                color: white;
                text-align: center;
                margin-left: 0px !important;
            }
        }
    }
`

export const AppBannerCont = styled.div`
    width: 100%;
    height: auto;
    background-color: #fadecb;
    animation-name: ${slideFromUp};
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    @media (max-width: 480px) {
        position: absolute;
        bottom: 0px;
        z-index: 1000;
        height: 209px;
        background: white;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
        animation-name: ${slideFromDown};
    }
`

export const CloseIcon = styled(Icon)`
    position: relative;
    font-size: 18px;
    font-weight: 600;
    color: #6f6c78;
    transition: all 0.1s;
    padding: 19px 20px;
    background-color: #f7f7f7;
    height: 40px;
    width: 40px;
    border-radius: 50% !important;
    border: 1px solid #dbe5e7;
    ${mixin.clickable}
    ${(props) => closeIconStyles[props.variant]}
    z-index: 1;
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        line-height: 0%;
        ${font.size(20)}
    }

    @media (max-width: 480px) {
        position: absolute;
        top: 10px;
        right: 10px;
    }
`

const closeIconStyles = {
    center: css`
        top: 20px;
        right: 20px;
        padding: 3px 5px 0px 5px;
        border-radius: 4px;
        &:hover {
            background: ${color.backgroundLight};
        }
        @media (max-width: 480px) {
            right: 13px;
        }
    `,
    aside: css`
        top: 23px;
        right: 23px;
        width: 50px;
        height: 50px;
        padding-top: 10px;
        border-radius: 3px;
        text-align: center;
        background: #fff;
        border: 1px solid ${color.borderLightest};
        ${mixin.boxShadowMedium};
        &:hover {
            color: ${color.primary};
        }
    `,
}

export const ModalContainer = styled.div`
    padding: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 480px) {
        padding: 95px 25px 25px 25px;
    }

    .heading {
        ${font.size(22)};
        line-height: 26px;
        ${font.black};
        color: #525659;
    }

    .app-store-container {
        display: flex;
        align-items: center;
        gap: 14.2px;
        margin-top: 28px;
        margin-bottom: 37px;
        img {
            height: 44px;
            cursor: pointer;
        }
    }

    .sub-text {
        ${font.size(14)};
        line-height: 17px;
        ${font.medium};
        color: #898989;
    }

    .qr-container {
        position: relative;
        margin-top: 33px;
        .qr {
            height: 100px;
        }
        .arrow {
            position: absolute;
            top: -50%;
        }
    }
`
