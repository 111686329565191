import React from 'react'
import {
    Cont,
    ImageCont,
    Circle,
    CheckMark,
    Header,
    SubHeader,
    ActionButton,
    TextContainer,
    SuccessCont,
} from './Styles'
import Tick from 'MessageCenter/assets/tick.svg'
import Background from 'MessageCenter/assets/tick-background.svg'
import { useNavigate } from 'react-router'

function Step3({ role, title }) {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate('/sell/overview')
    }
    return (
        <Cont>
            <SuccessCont>
                <ImageCont>
                    <Circle src={Background} alt="" />
                    <CheckMark src={Tick} alt="" />
                </ImageCont>
                <TextContainer>
                    <Header>Thank you!</Header>
                    <SubHeader>
                        We have received your message. Our {role}
                        {title.length === 0 ? '' : `, ${title}`} will reach out to you soon.
                    </SubHeader>
                </TextContainer>
                <ActionButton id="fs_message_success" onClick={handleClick}>
                    Ok
                </ActionButton>
            </SuccessCont>
        </Cont>
    )
}

export default Step3
