import { useSelector } from 'react-redux'
import {
    StyledDiv,
    Agent,
    InfoContainer,
    InfoLocationIcon,
    InfoLocation,
    TransactionStatus,
    Container,
    InfoText,
} from './Styles'
import LocationPoint from './assets/location-pin.svg'
import { capitalizeFirstLetterEveryWord } from 'shared/utils/formatter'
import alert from 'shared/utils/alert'
import PlaceHolderImage from 'shared/asstes/default-property-image.svg'

const PropertyImage = () => {
    const t = useSelector((state) => state.singleTransaction.transaction)
    const agent = window.currentTransaction?.isListedByAgent
    const showTransactionStatus =
        t?.transactionStatus === 'Cancelled' || t?.transactionStatus === 'Sold'

    const showPictureNotRightAlert = () =>
        alert.info(
            'Not the right picture?',
            "We use Google images to populate the street view of your home, sometimes this doesn't show the right house. Don't worry, we don't use this image in evaluating your home's value.",
        )

    return (
        <Container>
            <StyledDiv
                borderRadius="lg"
                backgroundImage={t?.property?.streetViewUrl || PlaceHolderImage}
            >
                {agent === true && <Agent>Agent Represented</Agent>}
                {showTransactionStatus && (
                    <TransactionStatus>{t?.transactionStatus}</TransactionStatus>
                )}
                <InfoContainer>
                    <InfoLocationIcon src={LocationPoint} alt="" />
                    <InfoLocation>
                        {capitalizeFirstLetterEveryWord(t?.property?.streetAddress)} <br />
                        {t?.property?.city}, {t?.property?.state} {t?.property?.zip}
                    </InfoLocation>
                </InfoContainer>
            </StyledDiv>
            <InfoText onClick={showPictureNotRightAlert} id="fs_not_the_right_picture">
                Not the right picture?
            </InfoText>
        </Container>
    )
}

export default PropertyImage
