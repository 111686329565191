import styled, { css, keyframes } from 'styled-components'
import { font } from 'shared/utils/styles'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

const opacity = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const Cont = styled.div`
    animation: ${opacity} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: relative;
    display: flex;
    gap: 30px;
    padding-bottom: 50px;
    .card-wrap {
        animation: ${slideLeft} ease 0.7s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 40%;
        @media (max-width: 900px) {
            width: 100%;
        }
    }
    @media (max-width: 900px) {
        flex-direction: column;
    }
`

export const CalendarCont = styled.div`
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    width: 60%;
    height: fit-content;
    border-radius: 11px;
    border: 1px solid #e4e4f3;
    padding: 50px 23px;
    @media (max-width: 900px) {
        width: 100%;
    }
    @media (max-width: 480px) {
        border: none;
        padding: 0px;
    }
`

const transform = keyframes`
    from {
        bottom: -10px;
        opacity: 0;
    }
    to {
        bottom: 0px;
        opacity: 1;
    }
`

export const CalendarDetailCard = styled.div`
    position: relative;
    border: 1px solid #eeeef6;
    background-color: #f7fcfe;
    width: 100%;
    border-radius: 11px;
    position: relative;
    padding: 30px 38px;
    @media (max-width: 900px) {
        width: 100%;
    }
    .action-btns {
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
    }
    animation-name: ${transform};
    animation-duration: 0.3s;
    animation-fill-mode: both;
    ${(props) =>
        !!props.animationDelay &&
        css`
            animation-delay: ${props.animationDelay}s;
        `}
`

export const HeaderCont = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (max-width: 480px) {
        justify-content: start;
    }
`

export const Heading = styled.div`
    ${font.bold}
    ${font.size(24)};
    line-height: 28.8px;
    color: #50546a;
`

export const HeadingImg = styled.img`
    position: relative;
    top: -10px;
    left: 10px;
`

export const Divider = styled.hr`
    margin-top: 34px;
    margin-bottom: 28px;
    border: 1px solid #e5e5f1;
`

export const CardCont = styled.div`
    display: flex;
    flex-direction: column;
    gap: 31px;
`

export const Card = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 15px;
`

export const ImgCont = styled.div`
    height: 45px;
    width: 45px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #f7f7f7;
    border: 1px solid #dbe5e7;
    border-radius: 46px;
`

export const Image = styled.img`
    height: 20px;
    width: 45px;
`

export const HeadingCont = styled.div``

export const CardHeading = styled.div`
    ${font.bold};
    ${font.size(15)};
    line-height: 18px;
    color: #525659;
`

export const Tagline = styled.div`
    ${font.medium};
    ${font.size(15)}
    line-height: 18px;
    color: #525659;
    margin-top: 7px;
    a {
        color: #ef7724;
        text-decoration: underline;
    }
`

export const ActionButton = styled.a`
    height: 56px;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-top: 31px;
    padding: 19px 22px;
    ${font.medium};
    ${font.size(15)};
    line-height: 18px;
    color: #525659;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @media (max-width: 400px) {
        width: 100%;
    }
    &:hover {
        background-color: #ffffff !important;
    }
`

export const ActionButtonImg = styled.img`
    margin-right: 10px;
`

export const BgImage = styled.img`
    width: 400px;
    @media (max-width: 900px) {
        width: 100%;
        height: auto;
    }
`

export const DefaultCont = styled.div`
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    border: 1px solid #eeeef6;
    background-color: rgba(231, 240, 241, 0.5);
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 38px;
    transition: all 0.3s;
    @media (max-width: 480px) {
        display: initial;
        background-color: white;
        border: none;
        padding: 0px;
    }
`

export const DefaultImg = styled.img`
    width: 71.6px;
`

export const DefaultHeading = styled.div`
    color: #525659;
    ${font.size(24)};
    line-height: 28.8px;
    ${font.black};
    margin-top: 9px;
    text-align: center;
    @media (max-width: 480px) {
        color: black;
        text-align: left;
    }
`

export const DefaultTagLine = styled.div`
    color: #868686;
    ${font.size(16)};
    ${font.medium};
    line-height: 27px;
    margin-top: 10px;
    text-align: center;
    @media (max-width: 480px) {
        text-align: start;
    }
`
