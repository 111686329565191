import { useState, useEffect } from 'react'

const usePlatformDetection = () => {
    const [platform, setPlatform] = useState('')

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera

        if (/android/i.test(userAgent)) {
            setPlatform('Android')
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setPlatform('iOS')
        } else {
            setPlatform('Web')
        }
    }, [])

    return platform
}

export default usePlatformDetection
