import { useState, useEffect } from 'react'
import { getClientToken, onMessageListener } from 'shared/utils/firebase'
import Modal from '../Modal'
import toast from 'shared/utils/toast'
import { ContNotification } from './Styles'
import NOTIFICATIONBELLICON from 'shared/asstes/notificationBellIcon.svg'
import NOTIFICATIONSOUNDMP3 from 'shared/asstes/sounds/notification-sound.mp3'
import buzz from 'buzz'
import api from 'shared/utils/api'

const Notification = () => {
    const [show, setShow] = useState(false)
    var mySound

    const checkNotificationPermissionStatus = () => {
        const notificationState = localStorage.getItem('notificationState')
        if ('Notification' in window) {
            const permission = window.Notification.permission
            switch (permission) {
                case 'granted':
                    handleRequestNotification()
                    break
                case 'default':
                    if (!notificationState) {
                        setShow(true)
                    }
                    break
                default:
                    console.log('User has denied the notification permission.')
            }
        }
    }

    const registerDevice = async (deviceToken) => {
        try {
            await api.post('customer/v3/register-device', {
                deviceToken,
                deviceType: 'Web',
            })
        } catch (error) {
            toast.error('Something went wrong. Unable to register device.')
            console.error(error)
        }
    }

    const handleRequestNotification = async () => {
        setShow(false)
        const permission = await window.Notification.requestPermission()
        if (permission === 'granted') {
            const token = await getClientToken()
            if (token) {
                await registerDevice(token)
                startListeningForMessages()
            }
        }
    }

    const startListeningForMessages = () => {
        onMessageListener()
            .then((payload) => {
                toast.show({
                    type: 'notification',
                    message: `<b>${payload?.notification?.title}</b><div style='margin-top:5px'>${payload?.notification?.body}<div>`,
                    duration: 10,
                })
                startListeningForMessages()
                mySound.play()
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const handleModalClose = () => {
        localStorage.setItem('notificationState', true)
        setShow(false)
    }

    useEffect(() => {
        mySound = new buzz.sound(NOTIFICATIONSOUNDMP3)
        mySound.setVolume(100)
        checkNotificationPermissionStatus()
    }, [])

    return (
        <>
            {show && (
                <Modal
                    isOpen
                    variableWidth={true}
                    withCloseIcon={false}
                    floting
                    width="600px"
                    backgroundColor="white"
                    renderContent={() => (
                        <ContNotification>
                            <img src={NOTIFICATIONBELLICON} alt="notification_bell" />
                            <h3>Turn on Smart Notifications</h3>
                            <p>
                                Get updates when important things happen, like your offer is ready,
                                documents need to be signed, appointment reminders & more.
                            </p>
                            <p>Would you like to turn them on?</p>
                            <div className="button-container">
                                <button className="button-white" onClick={handleModalClose}>
                                    Not Now
                                </button>
                                <button
                                    className="button-primary"
                                    onClick={handleRequestNotification}
                                >
                                    Yes!
                                </button>
                            </div>
                        </ContNotification>
                    )}
                />
            )}
        </>
    )
}

export default Notification
