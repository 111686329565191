import React, { Fragment, useEffect, useState } from 'react'
import { HeaderBtn, HeaderBtnIcon, Table, Thead, TR, TH, Tbody, TD, TDText } from './Styles'
import LeftArrow from './assets/left-arrow-icon.svg'
import RightArrow from './assets/right-arrow-icon.svg'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

const propTypes = {
    events: PropTypes.array,
    onEventClick: PropTypes.func,
    dateToShow: PropTypes.string,
    onNextClick: PropTypes.func,
    onPrevClick: PropTypes.func,
    className: PropTypes.string,
    calendarClassName: PropTypes.string,
    selectType: PropTypes.string,
    stayOnCurrent: PropTypes.bool,
    selectedBackgroundColor: PropTypes.string,
    currentSelectedDate: PropTypes.string,
    disabledDateWeak: PropTypes.array,
    disabledDates: PropTypes.array,
    enabledDates: PropTypes.array,
    arrowBackgroundColor: PropTypes.string,
}

const defaultProps = {
    events: [],
    onEventClick: () => {},
    dateToShow: null,
    onNextClick: () => {},
    onPrevClick: () => {},
    headerClassName: '',
    calendarClassName: '',
    selectType: '',
    stayOnCurrent: false,
    selectedBackgroundColor: null,
    currentSelectedDate: null,
    disabledDateWeak: [],
    disabledDates: [],
    enabledDates: [],
    arrowBackgroundColor: '#f7f7f7',
}

const Calendar = ({
    events,
    onEventClick,
    dateToShow,
    headerClassName,
    calendarClassName,
    onNextClick,
    onPrevClick,
    selectType,
    stayOnCurrent,
    selectedBackgroundColor,
    currentSelectedDate,
    disabledDateWeak,
    disabledDates,
    enabledDates,
    arrowBackgroundColor,
}) => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]
    const [currentMonth, setCurrentMonth] = useState(0)
    const [currentYear, setCurrentYear] = useState(1995)
    const [activeDate, setActiveDate] = useState(null)
    const [selectedDate, setSelectedDate] = useState([])

    const handlePrevMonth = () => {
        onPrevClick()
        setCurrentMonth(currentMonth - 1)
        if (currentMonth === 0) {
            setCurrentMonth(11)
            setCurrentYear(currentYear - 1)
        }
    }

    const handleNextMonth = () => {
        onNextClick()
        setCurrentMonth(currentMonth + 1)
        if (currentMonth === 11) {
            setCurrentMonth(0)
            setCurrentYear(parseInt(currentYear) + 1)
        }
    }

    const getDaysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate()
    }

    const handleClick = (date) => {
        if (selectedDate !== date) {
            setSelectedDate(date)
            onEventClick(date)
        }
    }

    useEffect(() => {
        if (dateToShow !== null) {
            const parsedDate = moment(dateToShow, 'MM/DD/YYYY')
            setSelectedDate(dateToShow)
            setCurrentMonth(parsedDate.format('M') - 1)
            setCurrentYear(parsedDate.format('YYYY'))
            setActiveDate(dateToShow)
        } else {
            const todayFormatedDate = moment().format('MM/DD/YYYY')
            setSelectedDate(todayFormatedDate)
            setCurrentMonth(moment().format('M') - 1)
            setCurrentYear(moment().format('YYYY'))
        }
    }, [dateToShow])

    const renderCalendarBody = () => {
        let daysInMonth = getDaysInMonth(currentMonth, currentYear)
        let firstDayOfMonth = new Date(currentYear, currentMonth, 0).getDay()
        let days = []
        for (let i = 0; i < firstDayOfMonth; i++) {
            days.push(<TD key={i} />)
        }
        for (let day = 1; day <= daysInMonth; day++) {
            let formattedDate = `${
                currentMonth < 9 ? '0' + (currentMonth + 1) : currentMonth + 1
            }/${day < 10 ? '0' + day : day}/${currentYear}`

            const isDisabled =
                disabledDateWeak !== []
                    ? disabledDateWeak?.includes(moment(formattedDate, 'MM/DD/YYYY').format('ddd'))
                    : false

            const isDisabaledDate = () => {
                if (disabledDates.length > 0) {
                    return disabledDates.includes(formattedDate)
                }
                if (enabledDates.length > 0) {
                    return !enabledDates.includes(formattedDate)
                }
                return false
            }

            const selected =
                (!isDisabled || !isDisabaledDate()) && formattedDate === selectedDate ? true : false

            days.push(
                <TD key={`${day}-event`}>
                    <TDText
                        className={selected && 'current-selected-date'}
                        hasEvent={events?.includes(formattedDate) ? true : false}
                        selected={selected}
                        onClick={() => {
                            if (!isDisabled && formattedDate !== activeDate) {
                                if (!isDisabaledDate()) {
                                    handleClick(formattedDate)
                                }
                            }
                        }}
                        selectType={selectType}
                        stayOnCurrent={formattedDate === activeDate}
                        selectedBackgroundColor={
                            selectedBackgroundColor && formattedDate === dateToShow
                                ? null
                                : selectedBackgroundColor
                        }
                        selectAnother={formattedDate === currentSelectedDate ? true : false}
                        isDisabled={isDisabled || isDisabaledDate()}
                    >
                        {day}
                    </TDText>
                </TD>,
            )
        }

        let rows = []
        for (let i = 0; i < days.length; i += 7) {
            rows.push(<TR key={i}>{days.slice(i, i + 7)}</TR>)
        }

        return (
            <Table mb="true" className={calendarClassName}>
                <Thead>
                    <TR>
                        <TH>Mo</TH>
                        <TH>Tu</TH>
                        <TH>We</TH>
                        <TH>Th</TH>
                        <TH>Fr</TH>
                        <TH>Sa</TH>
                        <TH>Su</TH>
                    </TR>
                </Thead>
                <Tbody>{rows}</Tbody>
            </Table>
        )
    }

    return (
        <Fragment>
            <Table className={headerClassName}>
                <Thead>
                    <TR>
                        <TH>
                            <HeaderBtn
                                arrowBackgroundColor={arrowBackgroundColor}
                                onClick={handlePrevMonth}
                                left
                            >
                                <HeaderBtnIcon src={LeftArrow} alt="" />
                            </HeaderBtn>
                        </TH>
                        <TH textCenter header fw>
                            <span>{months[currentMonth]}</span>
                            <span> {currentYear}</span>
                        </TH>
                        <TH>
                            <HeaderBtn
                                arrowBackgroundColor={arrowBackgroundColor}
                                onClick={handleNextMonth}
                                right
                            >
                                <HeaderBtnIcon src={RightArrow} alt="" />
                            </HeaderBtn>
                        </TH>
                    </TR>
                </Thead>
            </Table>
            {renderCalendarBody()}
        </Fragment>
    )
}

Calendar.propTypes = propTypes
Calendar.defaultProps = defaultProps

export default Calendar
