import { Container, Menu } from './Styles'

const DashboardMenu = () => {
    return (
        <Container>
            <Menu to={'/sell/dashboard'} active={['sell', 'message-center']}>
                Sell
            </Menu>
            <Menu to={'/buy/dashboard'} active={'buy'}>
                Buy
            </Menu>
            <Menu to={'/loan/dashboard'} active={'loan'}>
                Loan
            </Menu>
        </Container>
    )
}

export default DashboardMenu
