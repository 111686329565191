import TagManager from 'react-gtm-module'

const useTracking = () => {
    const FS = window['FS']

    const anonymize = () => {
        if (FS) {
            FS.anonymize()
        }
    }

    const identifyUser = (id) => {
        if (id) {
            TagManager.dataLayer({
                dataLayer: {
                    opCustomerId: id,
                },
            })
            if (FS) {
                FS.identify(id)
            }
        }
    }

    const init = (gtmContainer) => {
        if (typeof navigator.webdriver === 'undefined') {
            TagManager.initialize({ gtmId: gtmContainer })
        }
    }

    return { anonymize, identifyUser, init }
}

export default useTracking
