import { font, mixin } from 'shared/utils/styles'
import styled, { css, keyframes } from 'styled-components'
import Button from '../Button'

const fadeIn = keyframes`
    from {
        opacity: 0;
    }to {
        opacity: 1;
    }
`

export const MainContainer = styled.div`
    position: relative;
    width: 600px;
    animation-name: ${fadeIn};
    animation-duration: 450ms;
    animation-fill-mode: forwards;
    margin-top: -95px;
    z-index: 1;
    background-color: white;
    @media screen and (max-width: 480px) {
        width: 100%;
        margin-top: -75px;
    }
    #file-select {
        position: absolute;
        pointer-events: none;
        visibility: hidden;
    }
    ${(props) =>
        props.standalone &&
        css`
            margin-top: -20px;
            @media screen and (max-width: 480px) {
                width: 100%;
                margin-top: -10px;
            }
        `}
`

export const Heading = styled.div`
    ${font.black};
    font-size: 38px;
    color: #ec7625;
    @media screen and (max-width: 480px) {
        font-size: 28px;
    }
`

export const SubHeading = styled.div`
    ${font.black};
    font-size: 25px;
    color: #525659;
    margin-top: 20px;
`

export const Container = styled.div`
    background-color: rgba(255, 209, 177, 0.15);
    border: 0.25px solid #1b6775;
    border-radius: 20px;
    padding: 20px 30px;
    margin-top: 20px;
    @media screen and (max-width: 480px) {
        margin-top: 10px;
        padding: 20px;
    }
`

export const Textarea = styled.textarea`
    border-radius: 16px;
    border: 0.5px solid #707070;
    background: #fff;
    resize: none;
    width: 100%;
    margin-top: 20px;
    padding: 15px;
    height: 150px;
    ${font.medium};
    color: #525659;
    font-size: 15px;
`
export const Span = styled.span`
    color: #525659;
    font-size: 16px;
    line-height: 20px;
    display: block;
    ${font.medium};
    ${(props) =>
        props.underline &&
        css`
            border-bottom: 1.5px dashed #525659;
            text-decoration: none;
        `}
    ${(props) =>
        props.center &&
        css`
            text-align: center;
        `}
    a {
        color: #1b6775;
        text-decoration: underline;
        cursor: pointer;
    }
    .orange {
        color: #ec7625;
    }
    ${(props) =>
        props.teal &&
        css`
            color: #1b6775;
        `}
    ${(props) =>
        props.orange &&
        css`
            color: #ec7625;
        `}
    ${(props) =>
        props.bold &&
        css`
            ${font.bold};
        `}
    ${(props) =>
        props.italic &&
        css`
            font-style: italic;
        `}
`

export const UploadCont = styled.div`
    margin-top: 20px;
    border-radius: 16px;
    border: 0.5px solid #707070;
    background: #fff;
    padding: 15px;
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const UploadPhotoCont = styled.div`
    width: 100%;
    flex: 1;
    overflow-y: auto;
`

export const ButtonContainer = styled.div`
    display: flex;
    gap: 15px;
    justify-content: end;
    ${(props) =>
        props.imageButton &&
        css`
            gap: 10px;
            position: absolute;
            bottom: 0px;
            right: 0px;
        `}
`

export const ActionButton = styled(Button)`
    width: 133px;
    border: 1px solid #ec7625;
    @media screen and (max-width: 480px) {
        flex: 1;
    }
    ${(props) =>
        props.bordered &&
        css`
            background-color: white;
            border-radius: 4px;
            border: 1px solid #1b6775;
            color: #1b6775;
            &:hover {
                background-color: white !important;
                opacity: 0.7;
            }
        `}
    ${(props) =>
        props.submit &&
        css`
            display: flex;
            margin: 30px auto;
            width: 60%;
            @media screen and (max-width: 480px) {
                width: 100%;
            }
        `}
    ${(props) =>
        props.imageButton &&
        css`
            width: 40px;
            height: 30px;
        `}
`

export const NoPhotoCont = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        height: 80px;
    }
`

export const ImagesCont = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr 1fr;
    }
    .card-photos {
        width: 100%;
        height: 80px;
        border-radius: 10px;
        border: none;
        object-fit: cover;
        gap: 10px;
        ${(props) =>
            !!props.disabled &&
            props.disabled &&
            css`
                opacity: 0.5;
            `}
        @media (max-width: 480px) {
            width: 100%;
        }
    }
    .on-top {
        bottom: 10px;
        right: 8px;
    }
`

export const ImagePreview = styled.div`
    background-image: url(${(props) => props.backgroundimage});
    transition: 0.3s ease-in-out;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80px;
    width: 100%;
    position: relative;
`

export const CloseIcon = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: #6f6c78;
    transition: all 0.1s;
    padding: 19px 20px;
    background-color: #f7f7f7;
    height: 55px;
    width: 55px;
    border-radius: 50% !important;
    border: 1px solid #dbe5e7;
    ${mixin.clickable}
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        line-height: 0%;
        ${font.size(20)}
    }
    i {
        font-size: 25px;
        margin-top: 5px;
    }
    @media screen and (max-width: 480px) {
        margin-left: auto;
    }
`
