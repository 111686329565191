import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { font } from 'shared/utils/styles'

export const Cont = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px 48px 0px;
    @media (max-width: 820px) {
        flex-direction: column-reverse;
        align-items: start;
        gap: 20px;
    }
    @media (min-width: 821px) {
        justify-content: space-between;
    }
    @media (max-width: 480px) {
        gap: 0px !important;
    }

    .splide {
        @media (max-width: 820px) {
            flex: 1;
        }
        .splide__arrows {
            .splide__arrow {
                height: 62px;
                width: 62px;
                background-color: white;
                border: 1px solid #eeeeee;
                opacity: 1 !important;
            }
            .splide__arrow--prev {
                left: -35px !important;
                &:disabled {
                    display: none;
                }
            }
            .splide__arrow--next {
                right: -35px !important;
                &:disabled {
                    display: none;
                }
            }
        }
        .splide__list {
            display: grid !important;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            gap: 10px;
        }
        .splide__pagination {
            display: none !important;
        }
    }
`

export const LeftCont = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    @media (max-width: 480px) {
        width: 100%;
    }
`

export const HomeBtn = styled(Link)`
    background-color: #f7fcfe;
    border: 1px solid #dbe5e7;
    padding: 18px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
    &:hover {
        box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.3);
    }
    @media (max-width: 480px) {
        display: none !important;
    }
    @media (max-width: 821px) {
        border-radius: 4px;
    }
    ${(props) =>
        !!props.tab &&
        css`
            display: none;
            height: 49px;
            width: 50px;
            @media (min-width: 481px) and (max-width: 820px) {
                display: flex !important;
            }
            @media (max-width: 480px) {
                display: none !important;
            }
        `}
    ${(props) =>
        !!props.web &&
        css`
            display: none;
            @media (min-width: 821px) {
                display: flex !important;
            }
            @media (max-width: 769px) {
                display: none !important;
            }
        `}
`

export const HomeBtnImg = styled.img`
    display: none;
    @media (min-width: 769px) {
        display: block !important;
    }
    @media (max-width: 1500px) {
        width: 15px;
        height: 15px;
    }
    ${(props) =>
        !!props.tab &&
        css`
            display: none;
            @media (max-width: 820px) {
                display: block !important;
            }
        `}
`

export const NavCont = styled.div`
    display: flex;
    gap: 10px;
    margin-left: auto;
    @media (max-width: 820px) {
        width: 100%;
    }
    @media (max-width: 480px) {
        display: flex !important;
        margin-bottom: 20px;
    }
`

export const NavButton = styled(Link)`
    display: flex;
    align-items: center;
    ${font.size(17)};
    ${font.medium};
    padding: 18px 30px;
    border-radius: 40px;
    color: #525659;
    background-color: white;
    border: 1px solid #c3c3c3;
    transition: 0.3s ease-in-out;
    white-space: nowrap;
    height: 55px;
    ${(props) =>
        props.active == 'true' &&
        css`
            background-color: #1b6775;
            border-color: #1b6775;
            color: white;
        `}
    &:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
    }
    @media (max-width: 1500px) {
        padding: 12px 20px;
    }
    @media (max-width: 950px) {
        padding: 8px;
        ${font.size(14)}
    }
    @media (max-width: 820px) {
        width: max-content;
        height: 50px;
        border-radius: 8px;
        padding: 9px 20px;
        ${font.size(15)};
        ${font.medium};
    }
`

export const NavButtonBadge = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1b6775;
    color: white;
    ${font.size(12)};
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 10px;
    line-height: 2;
    ${(props) =>
        props.active == 'true' &&
        css`
            background-color: white;
            color: black;
        `}
    @media (max-width: 480px) {
        display: none !important;
    }
`
