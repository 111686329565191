import styled, { css } from 'styled-components'
import { font } from 'shared/utils/styles'
import { Link } from 'react-router-dom'

export const Container = styled.div`
    display: none;
    @media (max-width: 480px) {
        display: block;
    }
`

export const ModalHead = styled.div`
    width: 85%;
    gap: 20px;
    display: flex;
    span {
        ${font.size(20)};
        ${font.black};
        line-height: 24px;
        color: #000000;
        display: flex;
        flex-direction: column;
        gap: 5px;
        position: relative;
        .email {
            display: inline-block;
            color: #525659;
            ${font.size(14)};
            ${font.medium};
            line-height: 16.8px;
            margin-left: 0px;
        }
    }

    .initials {
        flex: none;
        background-color: #ef7724;
        ${font.bold};
        font-size: 20px;
        color: white;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
`

export const DividerModal = styled.hr`
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid rgba(68, 18, 119, 0.1);
`
export const ModalCont = styled.div`
    padding: 28px 36px;
    height: 100vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;
`

export const ModalMenuCont = styled.div`
    @media (max-height: 590px) {
        height: auto;
    }
`

export const ModalLink = styled(Link)`
    color: #525659;
    ${font.size(18)};
    ${font.bold};
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${(props) =>
        !!props.logout &&
        css`
            color: #e74136;
        `}
    ${(props) =>
        !!props.badge &&
        css`
            &:after {
                display: flex;
                justify-content: center;
                align-items: center;
                content: '${(props) => props.badge}';
                width: 26px;
                height: 26px;
                background-color: #ef7724;
                border-radius: 50%;
                font-size: 12px;
                line-height: 14.4px;
                color: white;
            }
        `}
`

export const ModalFooter = styled.div`
    color: #363948;
    ${font.size(16)};
    ${font.medium};
`

export const ModalFooterBody = styled.div`
    margin-top: 19px;
    display: flex;
    align-items: center;
    gap: 14px;
    img {
        cursor: pointer;
    }
`
