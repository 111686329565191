export const getMailDetails = (identifier, link = '') => {
    switch (identifier) {
        case 'CashOfferTransactionPostInspectionSurvey':
            return {
                email: '',
                subject: 'Offerpad Survey',
                body: `Hi. Offerpad would like to check-in and see how things are going so far. Please visit this link to complete their quick survey.\n${link}`,
            }
        case 'CashOfferTransactionUtilitiesInformation':
            return {
                email: '',
                subject: 'Offerpad needs information about your utilities',
                body: `Hi. Offerpad has requested that you provide information about utilities and services at your home. This will help them ensure a timely transfer of utilities.\nPlease visit ${link}, it should only take 5 minutes.`,
            }
        case 'CashOfferTransactionExitSurvey':
            return {
                email: '',
                subject: 'Offerpad Final Survey',
                body: `Hi. Offerpad would like to get your feedback on how things went. Can you please complete the survey here: ${link} \n Thanks!`,
            }
        case 'CashOfferTransactionWhatToLeaveBehind':
            return {
                email: '',
                subject: 'Information about What to Leave Behind',
                body: `Hi. Offerpad wanted me share the following information with you about "What to Leave Behind". Please let me know if you have any questions.\n\n${link}`,
            }
        case 'CashOfferTransactionConditionRequirements':
            return {
                email: '',
                subject: 'Information about the Condition Requirements',
                body: `Hi. Offerpad wanted me share the following information with you about the condition requirements for your home for your final walk-through. Please let me know if you have any questions. \n\n${link}`,
            }
        case 'CashOfferTransactionAcknowledgeFreeLocalMove':
            return {
                email: '',
                subject: 'Information about your Free Local Move',
                body: `Hi. Offerpad wanted me share the following information with you about your Free Local Move. Please let me know if you have any questions.\n\n${link}`,
            }
        case 'CashOfferTransactionAcknowledgeExtendedStay':
            return {
                email: '',
                subject: 'Information about your Extended Stay',
                body: `Hi. Offerpad wanted me share the following information with you about your Extended Stay. Please let me know if you have any questions.\n\n${link}`,
            }
        case 'CashOfferTransactionPrepareForInspection':
            return {
                email: '',
                subject: 'Information about your upcoming inspection appointment',
                body: `Hi. Offerpad wanted me share the following information with you about the upcoming inspection appointment. Please let me know if you have any questions.\n\n${link}`,
            }
        default:
            return {}
    }
}
