import styled, { css, keyframes } from 'styled-components'
import { font } from 'shared/utils/styles'
import ArrowSupport from './assets/downArrow.svg'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

const opacity = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const Cont = styled.div`
    animation: ${opacity} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    ${(props) =>
        !!props.isDashboard && props.isDashboard
            ? css`
                  padding: 0 !important;
              `
            : css`
                  padding: 30px 0px;
                  @media screen and (min-width: 1423px) {
                      width: 100%;
                      max-width: 1347px;
                      margin: 0 auto;
                      padding-top: 52px;
                      padding-bottom: 72px;
                  }
                  @media screen and (max-width: 1422px) {
                      padding: 30px;
                  }
                  @media (max-width: 480px) {
                      padding: 30px 20px !important;
                  }
              `}
`

export const ChatCont = styled.div`
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: relative;
    width: 100%;
    border: 2px solid #eaeaea;
    border-radius: 8px;
    padding: 45px 47px;
    @media (max-width: 480px) {
        padding: 0px;
        border: none;
    }
    .button {
        margin-left: 0;
        padding: 20px 40px;
        display: flex;
        margin-bottom: 44px;
    }
`

export const HeaderCont = styled.div`
    display: flex;
    flex-direction: column;
`

export const Header = styled.p`
    ${font.bold};
    ${font.size(24)};
    color: #525659;
    line-height: 28px;
    display: flex;
    align-items: center;
    @media (max-width: 480px) {
        color: black;
        ${font.black};
    }
`

export const Select = styled.select`
    height: 60px;
    width: 700px;
    color: #898989;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    margin-top: 27px;
    padding: 19px 25px;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    background-image: url(${ArrowSupport});
    background-repeat: no-repeat;
    background-position: calc(100% - 25px);
    background-size: 10px;
    ${font.size(16)};
    ${font.medium};
    @media (max-width: 820px) {
        width: calc(100% - 25px);
    }
    @media (max-width: 480px) {
        width: 100%;
    }
    option {
        color: #898989;
        display: flex;
        padding: 0px 2px 1px;
    }
`
export const TextArea = styled.textarea`
    width: 700px;
    height: 204px;
    resize: none;
    padding: 20px 0px 0px 20px;
    color: #898989;
    border: 1px solid #dddddd;
    border-radius: 8px;
    margin-top: 40px;
    margin-bottom: 35px;
    ${font.medium}
    ${font.size(16)}
    line-height: 19.2px;
    @media (max-width: 820px) {
        width: calc(100% - 25px);
    }
    @media (max-width: 480px) {
        width: 100%;
    }
`
