import React, { useState } from 'react'
import { MenuCont } from './Styles'

function MenuButton({ title = '', handleClick = () => {}, isActive = false }) {
    return (
        <MenuCont onClick={handleClick} className={isActive ? 'active' : null}>
            {title}
        </MenuCont>
    )
}

export default MenuButton
