import { configureStore } from '@reduxjs/toolkit'
import documentsSlice from 'features/documentsSlice'
import offerpadSlice from 'features/offerpad/offerpadSlice'
import todosSlice from 'features/todosSlice'
import myTransactionSlice from 'features/sell/my-transactions'
import singleTransactionSlice from 'features/sell/single-transaction'
import myTeamsSlice from 'features/sell/teams'
import eventsSlice from 'features/sell/events'
import trackerSlice from 'features/sell/tracker'
import disabledDatesSlice from 'features/sell/disabled-dates'
import coeSlice from 'features/sell/coe'
import loadingSlice from 'features/sell/loading'
import userSlice from 'features/userSlice'
import { initializeUserFromToken } from 'shared/utils/auth'

const store = configureStore({
    reducer: {
        offerpad: offerpadSlice,
        documents: documentsSlice,
        todos: todosSlice,
        myTransaction: myTransactionSlice,
        singleTransaction: singleTransactionSlice,
        teams: myTeamsSlice,
        events: eventsSlice,
        tracker: trackerSlice,
        disabledDates: disabledDatesSlice,
        coe: coeSlice,
        loading: loadingSlice,
        user: userSlice,
    },
})

initializeUserFromToken(store.dispatch)

export default store
