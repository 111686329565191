import { createSlice } from '@reduxjs/toolkit'

const disabledDatesSlice = createSlice({
    name: 'disbaledDates',
    initialState: {
        disabledDates: [],
        loading: false,
    },
    reducers: {
        updateDisabledDates: (state, action) => ({
            ...state,
            disabledDates: action.payload,
        }),
        updateLoading: (state, action) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const { updateDisabledDates, updateLoading } = disabledDatesSlice.actions
export default disabledDatesSlice.reducer
