import PropType from 'prop-types'
import { color } from 'shared/utils/styles'

const propTypes = {
    className: PropType.string,
    size: PropType.number,
    color: PropType.string,
}

const defaultProps = {
    className: undefined,
    size: 32,
    color: color.textWhite,
}

const BtnSpinner = ({ className, size, color }) => {
    return (
        <span className={className}>
            <svg
                width={size}
                height={size}
                id="spinner"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                style={{ background: '0 0', shapeRendering: 'auto' }}
            >
                <g transform="rotate(0 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="-1.8666666666666667s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(24 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="-1.7333333333333334s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(48 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="-1.6s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(72 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="-1.4666666666666666s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(96 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="-1.3333333333333333s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(120 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="-1.2s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(144 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="-1.0666666666666667s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(168 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="-0.9333333333333333s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(192 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="-0.8s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(216 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="-0.6666666666666666s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(240 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="-0.5333333333333333s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(264 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="-0.4s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(288 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="-0.26666666666666666s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(312 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="-0.13333333333333333s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(336 50 50)">
                    <rect x="48.5" y="1.5" rx="0" ry="0" width="3" height="23" fill={color}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="2s"
                            begin="0s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
            </svg>
        </span>
    )
}

BtnSpinner.protoTypes = propTypes
BtnSpinner.defaultProps = defaultProps

export default BtnSpinner
