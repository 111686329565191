import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Form, Modal, ScreenLoader } from 'shared/components'
import toast from 'shared/utils/toast'
import Logo from 'shared/asstes/logo.svg'
import Lock from '../assets/lock-auth.svg'
import {
    StyledDiv,
    StyledLogo,
    FormCont,
    FormHeading,
    FormHeadingImage,
    FormElement,
    CheckboxContainer,
    StyledAnchor,
    ActionButton,
    FormFooterStyled,
    FormFooterStyledAnchorTag,
    Cont,
    Container,
    FormImage,
    TagLine,
} from '../shared/Styles'
import Clouds from '../assets/cloud-sun.svg'
import UnderLine from '../assets/underline.svg'
import useApi from 'shared/hooks/api'
import { storeAuthToken, storeTokenExpiryTime, removeStoredAuthToken } from 'shared/utils/authToken'
import { storeRefreshToken } from 'shared/utils/refreshToken'
import { decaodeJWT } from 'shared/utils/jwt'
import useMobileWebView from 'shared/hooks/mobileWebview'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import { setUser } from 'features/userSlice'

const Login = () => {
    const [isEmailVerified, setIsEmailVerified] = useState(true)
    const navigate = useNavigate()
    const { getPrams, deletePrams } = useMobileWebView()
    const location = useLocation()
    const dispatch = useDispatch()

    const header = { 'Content-Type': 'application/x-www-form-urlencoded' }
    const [{ isCreating }, login] = useApi.post('api/customer-auth/v2/token', header)
    const [{ isWorking }, reSendVerificationEmail] = useApi.post(
        'api/customer-auth/v2/send-email-confirmation',
    )

    const handleResendEmailClick = async (e, modal) => {
        const res = await reSendVerificationEmail({
            successUrl: `${process.env.MARKETING_URL}/auth/login?success=true`,
            failureUrl: `${process.env.MARKETING_URL}/auth/login?success=false&message=Error verifying email`,
        })
        if (res?.status === 503) {
            return
        }
        removeStoredAuthToken()
        toast.success('A confirmation email has been sent, please check your inbox.')
        modal.close()
    }

    const handleFormSubmit = async (values, form) => {
        try {
            const res = await login({
                ...values,
                client_id: 'OPTwirl',
                grant_type: 'password',
            })
            if (!!res.access_token) {
                const decodedToken = decaodeJWT(res?.access_token)
                const isEmailVarified = decodedToken?.emailConfirmed?.toLowerCase() === 'true'
                if (isEmailVarified) {
                    setIsEmailVerified(true)
                    dispatch(setUser(decodedToken))
                    storeAuthToken(res?.access_token)
                    storeRefreshToken(res?.refresh_token)
                    storeTokenExpiryTime(res?.expires_in)
                    const redirectURL = new URLSearchParams(location.search).get('redirect')
                    const navigateURL = isEmpty(redirectURL) ? '/sell/dashboard' : redirectURL
                    navigate(navigateURL, { replace: true })
                    toast.success(
                        `Happy days! You've successfully accessed your account, ${decodedToken?.firstName} ${decodedToken?.lastName}.`,
                    )
                } else {
                    storeAuthToken(res?.access_token)
                    dispatch(setUser(decodedToken))
                    setIsEmailVerified(false)
                }
            } else {
                setIsEmailVerified(true)
                toast.error('Something went wrong.')
            }
        } catch ({ error }) {
            if (error === 'invalid_grant') {
                toast.error(
                    'The email address and/or password you entered does not match any accounts on record.',
                )
                form.setFieldError('username', ' ')
                form.setFieldError(
                    'password',
                    'The email address and/or password you entered does not match any accounts on record.',
                )
            } else {
                error && toast.error(error)
            }
        }
    }

    useEffect(() => {
        !isEmailVerified && document.getElementById('emnv').click()
    }, [isEmailVerified])

    useEffect(() => {
        if (getPrams('success') !== null && getPrams('success') === 'true') {
            toast.success('Thank you for verifying your email. Please login')
            deletePrams('success')
        }
        if (
            getPrams('success') !== null &&
            getPrams('success') === 'false' &&
            getPrams('message') !== null
        ) {
            toast.error('Error verifying email. Please try again!')
            deletePrams('success')
            deletePrams('message')
        }
    }, [])

    return (
        <Fragment>
            <Container>
                <StyledDiv>
                    <StyledLogo src={Logo} alt="" />
                    <TagLine>We can help you do literally (almost) everything.</TagLine>
                </StyledDiv>
                <Modal
                    backgroundColor="white"
                    variableWidth={true}
                    width="888px"
                    clickOutside={false}
                    onCloseEvent={() => (setIsEmailVerified(true), removeStoredAuthToken())}
                    renderLink={(modal) => <span id="emnv" onClick={modal.open}></span>}
                    renderContent={(modal) => (
                        <Cont>
                            <ScreenLoader size={100} sL={isWorking} />
                            <div>
                                <img src={Lock} alt="" />
                                <br />
                                <h1>Hey there!</h1>
                                <br />
                                <p>
                                    Looks like you need to confirm your email address before signing
                                    in.
                                    <br />
                                    <br />
                                    Check your inbox for an email from Offerpad, click on “Confirm
                                    My Email Address” and you’re all set!
                                    <br />
                                    <br />
                                    Thanks for your cooperation in helping us keep your account
                                    secure!
                                </p>
                                <ActionButton
                                    variant="primary"
                                    isWorking={isWorking}
                                    size="lg"
                                    type="button"
                                    id="fs_auth_resend_verification_email"
                                    onClick={(e) => handleResendEmailClick(e, modal)}
                                >
                                    Resend Verification Email
                                </ActionButton>
                            </div>
                        </Cont>
                    )}
                />
                <Form
                    enableReinitialize
                    initialValues={{
                        username: '',
                        password: '',
                        remember: false,
                    }}
                    validations={{
                        username: [
                            Form.is.required('Enter a valid email'),
                            Form.is.email('Enter a valid email'),
                        ],
                        password: Form.is.required('Enter a valid password'),
                    }}
                    onSubmit={handleFormSubmit}
                >
                    {(props) => (
                        <FormCont>
                            <FormImage src={Clouds} alt="" />
                            <FormElement>
                                <>
                                    <FormHeading size="xl">
                                        Sign in
                                        <FormHeadingImage src={UnderLine} alt="" />
                                    </FormHeading>
                                    <Form.Field.Input
                                        name="username"
                                        type="email"
                                        placeholder="Email"
                                        size="normal"
                                        disabled={isCreating}
                                    />
                                    <Form.Field.Input
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                        size="lg"
                                        disabled={isCreating}
                                        autoComplete="on"
                                        inputClass="fs-mask"
                                    />
                                    <br />
                                    <CheckboxContainer showByDefault="true">
                                        <StyledAnchor
                                            to={`/auth/forgot-password${
                                                props.values.username
                                                    ? '?email=' + props.values.username
                                                    : ''
                                            }`}
                                            size="normal"
                                            id="fs_auth_login_forgot_password"
                                        >
                                            Forgot Password?
                                        </StyledAnchor>
                                    </CheckboxContainer>
                                    <ActionButton
                                        id="fs_auth_login_submit"
                                        type="submit"
                                        variant="primary"
                                        isWorking={isCreating}
                                        size="lg"
                                    >
                                        Sign in
                                    </ActionButton>
                                </>
                            </FormElement>
                            <FormFooterStyled mt="40px">
                                Don't have an account?
                                <FormFooterStyledAnchorTag
                                    to="/auth/register"
                                    id="fs_auth_login_signup"
                                >
                                    Sign up
                                </FormFooterStyledAnchorTag>
                            </FormFooterStyled>
                            <FormFooterStyled mt="10px">
                                By signing in, you are agreeing to Offerpad’s{' '}
                                <FormFooterStyledAnchorTag
                                    to={process.env.TERMS_OF_USE_URL}
                                    target="_blank"
                                >
                                    Terms of Use
                                </FormFooterStyledAnchorTag>{' '}
                                and
                                <FormFooterStyledAnchorTag
                                    to={process.env.PRIVACY_URL}
                                    target="_blank"
                                >
                                    Privacy Policy
                                </FormFooterStyledAnchorTag>
                                .
                            </FormFooterStyled>
                        </FormCont>
                    )}
                </Form>
            </Container>
        </Fragment>
    )
}

export default Login
