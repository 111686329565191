import React, { useRef, useState } from 'react'
import parse from 'html-react-parser'
import { Overlay, TooltipBody, TooltipContainer } from './Styles'

const Tooltip = ({ content, children, position, onResponsive }) => {
    const [showTooltip, setShowTooltip] = useState(false)

    const $tooltipRef = useRef(null)
    const $tooltipBodyRef = useRef(null)

    const handleMouseEnter = () => {
        setShowTooltip(true)
    }

    const handleMouseLeave = () => {
        setShowTooltip(false)
    }

    const handleOverlayClick = (e) => {
        if ($tooltipBodyRef.current) {
            if (e.target !== $tooltipBodyRef.current) {
                const animation = $tooltipBodyRef.current.animate(
                    [
                        { opacity: 1, bottom: 0 },
                        { opacity: 0, bottom: '-100%' },
                    ],
                    {
                        duration: 350,
                        easing: 'ease-in-out',
                        fill: 'forwards',
                    },
                )
                animation.onfinish = () => handleMouseLeave()
            }
        }
    }

    return (
        <TooltipContainer
            ref={$tooltipRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            {showTooltip && (
                <Overlay
                    onResponsive={onResponsive === 'bottomSheet' ? true : false}
                    onClick={handleOverlayClick}
                >
                    <TooltipBody
                        ref={$tooltipBodyRef}
                        onResponsive={onResponsive === 'bottomSheet' ? true : false}
                        height={$tooltipRef?.current ? $tooltipRef.current.offsetHeight : 6}
                        position={position}
                    >
                        {parse(content)}
                    </TooltipBody>
                </Overlay>
            )}
        </TooltipContainer>
    )
}

export default Tooltip
