import Radio from 'shared/components/Radio'
import {
    ActionButton,
    ButtonGroup,
    Container,
    DContainer,
    Error,
    Heading,
    InputContainer,
    Paragraph,
    ParagraphContainer,
    RadioContainer,
    RadioGroup,
} from '../styles'
import Input from 'shared/components/Input'
import { is } from 'shared/utils/validation'
import { useWizard } from 'react-use-wizard'
import { capitalizeFirstLetterEveryWord } from 'shared/utils/formatter'
import { useSelector } from 'react-redux'

const Step1 = ({ state, mergeState, todo, seller }) => {
    const { nextStep, goToStep } = useWizard()

    const user = useSelector((state) => state.user)

    return (
        <Container>
            <DContainer>
                <Heading>
                    We have the same email address for two parties on your transaction, would you
                    like to update{' '}
                    <span>
                        {capitalizeFirstLetterEveryWord(seller?.firstName?.toLowerCase())}'s
                    </span>{' '}
                    email address?
                </Heading>
                <ParagraphContainer>
                    <Paragraph>
                        {capitalizeFirstLetterEveryWord(todo?.metadata?.sellerName?.toLowerCase())}:{' '}
                        {todo?.metadata?.sellerEmail}
                    </Paragraph>
                    <Paragraph>
                        {' '}
                        {user?.firstName} {user?.lastName}: {user?.email}
                    </Paragraph>
                </ParagraphContainer>
                <RadioGroup>
                    <RadioContainer
                        active={state.updateEmail}
                        id="fs_yes_update"
                        onClick={() => mergeState({ updateEmail: true, btnDisable: true })}
                    >
                        <Radio
                            type="radio"
                            name="option"
                            value={'true'}
                            checked={state.updateEmail}
                            onChange={() => mergeState({ updateEmail: true, btnDisable: true })}
                        />
                        Yes, update!
                    </RadioContainer>
                    <RadioContainer
                        active={state.updateEmail === false}
                        id="fs_no_keep_same"
                        onClick={() =>
                            mergeState({
                                updateEmail: false,
                                email: null,
                                validEmail: null,
                                btnDisable: false,
                            })
                        }
                    >
                        <Radio
                            type="radio"
                            name="option"
                            value={'false'}
                            checked={state.updateEmail === false}
                            onChange={() =>
                                mergeState({
                                    updateEmail: false,
                                    email: null,
                                    validEmail: null,
                                    btnDisable: false,
                                })
                            }
                        />
                        No, keep the same
                    </RadioContainer>
                </RadioGroup>
                {state.updateEmail && (
                    <InputContainer>
                        Please enter email address for{' '}
                        {capitalizeFirstLetterEveryWord(todo?.metadata?.sellerName?.toLowerCase())}
                        <Input
                            size="lg"
                            type="email"
                            placeholder="Email address"
                            value={!state.email ? '' : state.email}
                            invalid={state.validEmail !== null && !state.validEmail}
                            onChange={(value) =>
                                mergeState({
                                    email: value,
                                    validEmail: is.validEmail(value),
                                    btnDisable: !is.validEmail(value),
                                })
                            }
                        />
                        {state.validEmail !== null && !state.validEmail && (
                            <Error>Email address is not valid.</Error>
                        )}
                    </InputContainer>
                )}
            </DContainer>
            <ButtonGroup>
                <ActionButton
                    variant="primary"
                    size="lg"
                    disabled={state.btnDisable}
                    onClick={() => (state.updateEmail ? nextStep() : goToStep(3))}
                >
                    Next
                </ActionButton>
            </ButtonGroup>
        </Container>
    )
}

export default Step1
