import styled, { css } from 'styled-components'
import { font, color } from 'shared/utils/styles'
import { Button } from 'shared/components'

export const Cont = styled.div`
    width: 100%;
    max-width: 314px;
    height: fit-content;
    background-color: #f7fcfe;
    border-radius: 10px;
    border: 1px solid #dbe5e7;
    padding: 23px 27px;
    position: relative;
    @media (max-width: 1250px) {
        max-width: 100%;
    }
    @media (max-width: 820px) {
        display: none;
        height: 500px;
    }
    @media (max-width: 690px) {
        width: 100%;
    }
    @media (max-width: 480px) {
        background-color: white;
        border: none;
        padding: 0px;
        height: auto;
    }
    @media (min-width: 1251px) {
        height: 100%;
    }
    ${(props) =>
        !!props.tab &&
        css`
            display: none;
            @media (max-width: 820px) {
                display: block;
                margin-top: 30px;
            }
        `}
`

export const HeadingImg = styled.img`
    display: none;
    @media (max-width: 480px) {
        display: block;
    }
`

export const Heading = styled.div`
    ${font.black};
    ${font.size(25)};
    line-height: 30px;
    color: #000000;
    @media (max-width: 480px) {
        display: flex;
        align-items: center;
        gap: 12px;
    }
`

export const Card = styled.div`
    margin-top: 21px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    @media (max-width: 480px) {
        background-color: white;
        border: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }
`

export const CardCont = styled.div`
    cursor: pointer;
    position: relative;
    ${font.size(15)};
    ${font.medium};
    text-decoration: none;
    border-radius: 6px;
    border: 1px solid #dbe5e7;
    padding: 18px 21px;
    color: #336573;
    line-height: 15.6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    overflow: hidden;
    @media (max-width: 480px) {
        padding: 10px 13px;
        ${font.size(13)};
        ${font.medium};
    }
`
export const ArrowImage = styled.img`
    display: block;
`

export const Image = styled.img`
    @media (max-width: 480px) {
        display: none;
    }
`

export const CardContPhotos = styled.div`
    position: relative;
    .card-photos {
        width: 185px;
        height: 210px;
        border-radius: 10px;
        border: none;
        object-fit: cover;
        gap: 10px;
        ${(props) =>
            !!props.disabled &&
            props.disabled &&
            css`
                opacity: 0.5;
            `}
        @media (max-width: 480px) {
            width: 100%;
        }
    }
`

export const CardPhotos = styled.div`
    width: 185px;
    height: 210px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: end;
    justify-content: end;
    border-radius: 10px;
    padding: 15px;
    border: none;
    ${(props) =>
        !!props.backgroundImage &&
        css`
            background-image: url(${props.backgroundImage});
            transition: 0.3s ease-in-out;
        `}
    gap:10px;
    ${(props) =>
        !!props.disabled &&
        props.disabled &&
        css`
            opacity: 0.5;
        `}
    @media (max-width: 480px) {
        width: auto;
        height: auto;
        aspect-ratio: 160/155;
    }
`

export const CheckImgIconPhotos = styled.img`
    position: absolute;
    top: -10px;
    right: -10px;
    height: 40px;
`

export const CardActionButtonPhotos = styled(Button)`
    transition: 0.2s ease-in-out;
    ${(props) =>
        !!props.primary &&
        css`
            background-color: ${color.primary};
        `}
    &:hover {
        transform: scale(1.1);
        background-color: #f4f5f7 !important;
        ${(props) =>
            !!props.primary &&
            `
        background-color:${color.primary} !important;
    `}
    }
`

export const CardActionButtonImgPhotos = styled.img``

export const ModalCont = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 25px;
    height: 100%;
    max-height: calc(100vh - 100px);
    @media (max-width: 480px) {
        overflow: hidden;
        max-height: 100%;
    }
`

export const ModalHeading = styled.div`
    ${font.black};
    ${font.size(27)};
    color: #363c4f;
`

export const ModalBody = styled.div`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 10px;
    @media (max-width: 480px) {
        height: 100%;
        overflow: scroll !important;
        -moz-scrollbars-none: none;
        &::-webkit-scrollbar {
            display: none;
        }
        display: flex;
        gap: 20px;
        // grid-template-columns: 1fr 1fr;
    }
    .default-screen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 30px 0px;
        p {
            margin-top: 10px;
            ${font.bold};
            ${font.size(16)};
            color: #525658;
        }
    }
`
