import { Wizard } from 'react-use-wizard'
import { Wrapper } from 'Surveys/shared/Styles'
import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'
import { useState } from 'react'

const SignedAddendum = ({ close = () => {}, extendedStay = {} }) => {
    const [data, setData] = useState({
        modify: null,
        noOfDays:
            extendedStay.extendedStayDayEligibleDays > extendedStay.currentExtendedStayDays
                ? extendedStay.extendedStayDayEligibleDays
                : extendedStay.currentExtendedStayDays,
    })

    return (
        <Wizard wrapper={<Wrapper />}>
            <Step1 data={data} setData={setData} es={extendedStay} />
            <Step2 data={data} setData={setData} es={extendedStay} />
            <Step3 data={data} setData={setData} es={extendedStay} />
            <Step4 data={data} setData={setData} es={extendedStay} close={close} />
        </Wizard>
    )
}

export default SignedAddendum
