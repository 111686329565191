import styled, { css, keyframes } from 'styled-components'
import { font } from 'shared/utils/styles'
import PlaceHolderImage from 'shared/asstes/default-property-image.svg'

const opacity = keyframes`
from {
opacity: 0;
transform: scale(0.8)
}
to {
opacity: 1;
transform: scale(1)
}
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 750px) {
        width: 100% !important;
        height: auto;
    }
`

export const InfoText = styled.div`
    ${font.size(14)};
    ${font.regular};
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
    color: #1b6775;
`

export const StyledDiv = styled.div`
    height: 230px;
    width: 414px;
    position: relative;
    ${(props) =>
        !!props.backgroundImage &&
        css`
            background-image: url(${props.backgroundImage}), url(${PlaceHolderImage});
        `}
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: end;
    border-radius: 10px;
    overflow: hidden;
    /* animation-name: ${opacity}; */
    animation-duration: 0.5s;
    animation-fill-mode: both;
    @media (max-width: 1000px) {
        width: 263px !important;
        height: 177px;
    }
    @media (max-width: 750px) {
        width: 100% !important;
        aspect-ratio: 342/192;
        height: auto;
    }
`

export const Agent = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #fadecb;
    color: #ec7625;
    border-radius: 22px;
    padding: 7px 17px;
    line-height: 17px;
    ${font.size(14)};
    ${font.bold};
`

export const TransactionStatus = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
    background: #ec7625;
    border-radius: 42px;
    color: #fff;
    padding: 5px 19px;
    line-height: 21px;
    ${font.size(14)};
    ${font.medium};
`

export const InfoContainer = styled.div`
    background: linear-gradient(to top, #000000, rgba(0, 0, 0, 0.3));
    height: 54px;
    width: 100%;
    padding: 10px 30px 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 15px;
`

export const InfoLocationIcon = styled.img`
    height: 30px;
`

export const InfoLocation = styled.span`
    color: #ffffff;
    ${font.size(15)};
    line-height: 18px;
    ${font.medium};
`
