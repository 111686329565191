import { Cont, IconCont } from './Styles'
import ErrorImg from 'shared/asstes/fallback.svg'
import { useErrorBoundary } from 'react-error-boundary'
import HomeIcon from 'shared/asstes/dashboard.svg'
import SupportIcon from 'shared/asstes/support_icon.svg'
import { useNavigate } from 'react-router-dom'

const Fallback = () => {
    const handleClick = (param) => {
        if (param === 'dashboard') {
            window.location.href = '/sell/dashboard'
        } else {
            window.location.href = '/tech-support'
        }
    }

    return (
        <Cont id="Tech_Support_Akward_Screen">
            <img src={ErrorImg} alt="" />
            <h1>Oops! This is awkward.</h1>
            <p>
                Uh-oh! It looks like there's been a hiccup. We'll have things back up and running in
                no time, so please hang tight.
            </p>
            <div>
                <IconCont>
                    <div onClick={() => handleClick('support')}>
                        <img src={SupportIcon} />
                        <span>Tech Support</span>
                    </div>
                </IconCont>
                <IconCont>
                    <div
                        onClick={() => handleClick('dashboard')}
                        id="Go_To_Dashboard_Akward_Screen"
                    >
                        <img src={HomeIcon} />
                        <span>Go to Dashboard</span>
                    </div>
                </IconCont>
            </div>
        </Cont>
    )
}

export default Fallback
