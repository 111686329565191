import { useEffect, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import {
    CloseIcon,
    Container,
    Header,
    TabContainer,
    TabButtons,
    Slider,
    HelpCont,
    ArrowUpImg,
    HelpSpan,
    Span,
    IconImg,
    MainConatiner,
    TeamCardContainer,
    TeamCard,
    Navbar,
} from './Styles'

import RightArrowIcon from 'shared/asstes/rightArrowIcon.svg'
import ArrowUpOrange from 'shared/asstes/curved-arrow-up-orange.svg'
import ArrowUpTeal from 'shared/asstes/curved-arrow-up-teal.svg'
import CheckIcon from 'shared/asstes/check_with_circle.svg'
import Logo from 'shared/asstes/logo.svg'
import DollorIcon from 'shared/asstes/dollor_icon.svg'
import ArrowOrange from 'shared/asstes/arrow_orange.svg'
import SUNWITHCLOUD from 'shared/asstes/sun_with_cloud.svg'
import Icon from '../Icon'
import useMergeState from 'shared/hooks/mergeState'
import api from 'shared/utils/api'
import { getCurrentDateInUTCFormat, getHourDifferenceWithTimezone } from 'shared/utils/formatter'
import moment from 'moment-timezone'
import ScreenLoader from '../ScreenLoader'
import alert from 'shared/utils/alert'
import toast from 'shared/utils/toast'
import CashCard from '../CashCard'
import ListCard from '../ListCard'
import CashOfferCard from '../CashOfferCard'
import CashOfferPrint from '../CashOfferPrint'
import CashOfferTeamCard from '../CashOfferTeamCard'
import CashOfferVideoCard from '../CashOfferVideoCard'
import useOverview from 'shared/hooks/sell/overview'
import useMyTransactions from 'shared/hooks/sell/my-transactions'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import CashOfferSpecificNumber from '../CashOfferSpecificNumber'
import CashOfferDefaultScreen from '../CashOfferDefaultScreen'

const defaultStateValue = {
    isReady: false,
    compare: false,
    blurLeft: false,
    blurRight: false,
    agent: false,
    showAllTabs: true,
    localMove: false,
    extendedStay: false,
    extendedStay: false,
    calendarDate: false,
    activeTab: 0,
    serviceFee: 0,
    calendar: false,
    closingDate: moment().format('MM/DD/YYYY'),
    newClosingDate: '',
    solution: '',
    seperatorText: '',
    activeInfoScreen: '',
    response: {},
    listEstimatedProceeds: {},
    isError: false,
    showSpecificNumberScreen: false,
    images: [],
    showPrintScreen: false,
    showDefaultScreen: false,
    defaultScreenData: {
        image: undefined,
        heading: undefined,
        description: undefined,
    },
}

const CashOffer = ({ close = () => {}, standalone = false }) => {
    const [state, mergeState] = useMergeState(defaultStateValue)

    const { pathname } = useLocation()

    const { init } = useOverview()
    const myTransaction = useMyTransactions()

    let transaction

    if (!standalone) {
        transaction = useSelector((state) => state.singleTransaction.transaction)
    } else {
        const { offerKey } = useParams()
        transaction = {
            offerKey,
        }
    }

    const $containerRef = useRef(null)
    const $extraScreenContainerRef = useRef(null)
    const $printRef = useRef(null)

    const fetchTransactions = async () => {
        await myTransaction.fetch()
        await init(true)
    }

    const handleTabClick = (index) => {
        if (index !== 0) {
            closeScreen()
        }
        let showSpecificNumberScreen = state.showSpecificNumberScreen
        if (index === 1) {
            showSpecificNumberScreen = false
        }
        mergeState({
            activeTab: index,
            compare: index === 2,
            showSpecificNumberScreen,
        })
    }

    const handleResize = () => {
        mergeState({ showAllTabs: window.innerWidth > 600 })
    }

    const closeScreen = (mergeOnClose = {}) => {
        if ($extraScreenContainerRef.current) {
            const animation = $extraScreenContainerRef.current.animate(
                window.innerWidth <= 480 && standalone
                    ? [{ transform: 'translateY(0)' }, { transform: 'translateY(100%)' }]
                    : [{ opacity: 1 }, { opacity: 0 }],
                {
                    duration: 300,
                    easing: 'ease-in-out',
                    fill: 'forwards',
                },
            )
            const overlay = document.getElementById('extra-screen-overlay')
            if (overlay) {
                overlay.animate([{ opacity: 1 }, { opacity: 0 }], {
                    duration: 300,
                    easing: 'ease-in-out',
                    fill: 'forwards',
                })
            }
            animation.onfinish = () => {
                mergeState({ activeInfoScreen: '', ...mergeOnClose })
                window.innerWidth > 480 && !state.compare && resizeScreen(715)
            }
        }
    }

    const resizeScreen = (size = null) => {
        if (!$containerRef.current) return
        if (window.innerWidth <= 1115) return
        const targetWidth = size
            ? `${size}px`
            : window.innerWidth <= 1365
            ? `715px`
            : window.innerWidth <= 1365
            ? `715px`
            : state.compare
            ? '1365px'
            : '715px'
            ? '1365px'
            : '715px'

        const currentWidth = parseInt($containerRef.current.offsetWidth, 10)

        const animation = $containerRef.current.animate(
            [{ width: currentWidth + 'px' }, { width: targetWidth }],
            {
                duration: 300,
                easing: 'ease-in-out',
                fill: 'forwards',
            },
        )

        animation.onfinish = () => {
            $containerRef.current.style.width = targetWidth
        }
    }

    const handlePrint = useReactToPrint({
        content: () => $printRef.current,
    })

    const handleCalendarClick = (newClosingDate) => {
        closeScreen({ newClosingDate, calendar: true })
    }

    function generateEnabledDates(data) {
        const { calendarStartDate, calendarEndDate, disabledDates } = data

        const startDate = moment(calendarStartDate)
        const endDate = moment(calendarEndDate)

        const allDates = []
        while (startDate.isSameOrBefore(endDate)) {
            allDates.push(startDate.format('YYYY-MM-DD'))
            startDate.add(1, 'days')
        }

        const enabledDates = allDates.filter((date) => !disabledDates.includes(date))

        const formattedDates = enabledDates.map((date) => moment(date).format('MM/DD/YYYY'))

        return formattedDates
    }

    function calculateEstimatedClosingDate(closingPeriod, enabledDates, timezone) {
        const currentDate = moment()
        const calendar = moment(currentDate).tz(timezone || 'America/Phoenix')

        switch (closingPeriod) {
            case 'None':
                break
            case 'FifteenDays':
            case 'TwentyFourHours':
            case 'Immediately':
                calendar.add(15, 'days')
                break
            case 'ThirtyDays':
                calendar.add(30, 'days')
                break
            case 'FortyFiveDays':
                calendar.add(45, 'days')
                break
            case 'SixtyDays':
            case 'JustShopping':
                calendar.add(60, 'days')
                break
            case 'NinetyDays':
            case 'NinetyPlusDays':
                calendar.add(90, 'days')
                break
            case 'OneHundredTwentyDays':
                calendar.add(120, 'days')
                break
            default:
                calendar
                break
        }

        while (isWeekend(calendar)) {
            calendar.add(1, 'day')
        }

        return calendar.format('MM/DD/YYYY')
    }

    function isWeekend(date) {
        const day = date.day()
        return day === 0 || day === 6
    }

    function isDateInArray(date, dateArray) {
        const dateToCheck = date.format('MM/DD/YYYY')
        return dateArray.includes(dateToCheck)
    }

    const fetchData = async (url) => {
        let ds = false
        try {
            let calendarEnableDates = []
            if (!standalone) {
                const { showNativeOfferExperience } = await api.get('customer/v3/feature-flags')
                const disabledDates = await api.get(
                    `customer/v3/cash-offer-transactions/${transaction?.transactionId}/disabled-dates`,
                )
                calendarEnableDates = generateEnabledDates(disabledDates)
                if (!showNativeOfferExperience) {
                    close()
                    openViewOfferWindow()
                    return
                }
            }

            const header = !standalone ? {} : { Authorization: '' }

            const response = await api.get(url, undefined, header)

            const agent = response?.listedByAgent

            if (standalone) {
                transaction = { ...transaction, ...response }
                let disabledDates = []
                const dates = JSON.parse(response?.dateTimePicker) || []
                dates.map((date) => (disabledDates = [...disabledDates, ...date.value]))
                const formattedDisabledDatesDates = disabledDates.map((date) =>
                    moment(date, 'M/D/YYYY').format('YYYY-MM-DD'),
                )
                calendarEnableDates = generateEnabledDates({
                    calendarStartDate: response?.calendarStartDate,
                    calendarEndDate: response?.calendarEndDate,
                    disabledDates: formattedDisabledDatesDates,
                })
            }

            if (response?.currentStep === 'RenewalRequest') {
                if (!standalone) {
                    alert.info(
                        agent
                            ? "We're working on your seller's new offer."
                            : "We're working on your<br />new offer.",
                        'This can take up to 24 hours.<br />Please hang tight!',
                        DollorIcon,
                        () => {},
                    )
                    close()
                    return
                } else {
                    mergeState({
                        showDefaultScreen: true,
                        defaultScreenData: {
                            slag: 'renewalRequested',
                            image: DollorIcon,
                            heading: agent
                                ? "We're working on your seller's new offer."
                                : "We're working on your<br />new offer.",
                            description: 'This can take up to 24 hours.<br />Please hang tight!',
                        },
                    })
                    ds = true
                }
            }

            if (response?.expired && response?.currentStep === 'OfferSent') {
                if (!standalone) {
                    alert.info(
                        'Offer expired.',
                        `Our offer for ${response?.property?.streetAddress} expired on ${moment(
                            response?.expirationDateTime,
                        )
                            .tz(transaction?.property?.timeZoneId || 'America/Phoenix')
                            .format('(MM-DD-YYYY)')}`,
                        ArrowOrange,
                        async () => {
                            await renewOffer()
                            close()
                        },
                        'Renew your offer',
                    )
                    return
                } else {
                    mergeState({
                        isReady: true,
                        showDefaultScreen: true,
                        defaultScreenData: {
                            slag: 'offerExpired',
                            image: ArrowOrange,
                            heading: 'Offer expired.',
                            description: `Our offer for ${
                                response?.property?.streetAddress
                            } expired on ${moment(response?.expirationDateTime)
                                .tz(transaction?.property?.timeZoneId || 'America/Phoenix')
                                .format('(MM-DD-YYYY)')}`,
                        },
                    })
                    ds = true
                }
            }

            const solution = response?.availableSolution
            const serviceFee = calculateServiceFee(response)
            const hoursLeft = calculateHoursLeft(response)
            const seperatorText = calculateSeperatorText(response, hoursLeft)
            const listEstimatedProceedsMin = response?.priceLow - (response?.priceLow * 6) / 100
            const listEstimatedProceedsMax = response?.priceHigh - (response?.priceHigh * 6) / 100
            // const closingDate = calculateClosingDate(response)
            const closingDate = calculateEstimatedClosingDate(
                response?.request?.closingPeriod,
                calendarEnableDates,
                transaction?.property?.timeZoneId,
            )
            const showVideoView =
                !['nosolution', 'arn', 'pending'].includes(solution) &&
                !response?.accepted &&
                !response?.listAccepted
            const hideTabBar = response?.accepted || response?.listAccepted
            const counterOffer = response?.currentStep === 'Counteroffer' || response?.inProgress
            const hideLocalView =
                state?.response?.isKellerOffer ||
                state?.response?.request?.referral == 'AiresRelocation'
            const hideExtendedStay =
                state?.response?.isKellerOffer || state?.response?.request?.isRentalProperty

            if (!ds) {
                let showDefaultScreen = state.showDefaultScreen
                let defaultScreenData = state.defaultScreenData

                if (counterOffer) {
                    showDefaultScreen = true
                    defaultScreenData = {
                        slag: 'counterOffer',
                        image: SUNWITHCLOUD,
                        heading: "We're taking another look at our offer.",
                        description:
                            'We received your request and we will get back to you soon via email.',
                    }
                }
                mergeState({ showDefaultScreen, defaultScreenData })
            }

            mergeState({
                response: !standalone
                    ? { ...response, isUnderContract: transaction?.isUnderContract }
                    : response,
                solution,
                isReady: true,
                serviceFee,
                seperatorText,
                listEstimatedProceedsMin,
                closingDate,
                hideTabBar,
                showVideoView,
                newClosingDate: closingDate,
                calendarEnableDates,
                agent,
                hideLocalView,
                hideExtendedStay,
                listEstimatedProceeds: {
                    low: listEstimatedProceedsMin,
                    high: listEstimatedProceedsMax,
                },
            })
        } catch (e) {
            console.log(e)
            !standalone ? openViewOfferWindow() : mergeState({ isReady: true, isError: true })
            close()
        }
    }

    const renewOffer = async () => {
        try {
            await api.post(`api/customer/v1/offers/${state?.response?.key}/renew`)
            alert.info(
                "We're on it.",
                "We'll let you know as soon as your refreshed offer is ready for your review.",
                CheckIcon,
                () => (!standalone ? fetchTransactions() : window.location.reload()),
            )
        } catch (e) {
            e?.error?.message && toast.error(e?.error?.message)
        }
    }

    const openViewOfferWindow = () => {
        window.open(`${process.env.VIEW_OFFER_URL}/${transaction?.offerKey}`, '_blank')
    }

    const calculateServiceFee = (response) => {
        const creditFactor = response?.offerPadCreditFactor || 0
        const closingFees = response?.closingFeesSum || 0
        const marketServiceFee = response?.marketServiceFee || 0

        return response?.availableSolution === 'List'
            ? -6
            : creditFactor + closingFees - marketServiceFee
    }

    const calculateHoursLeft = (response) => {
        const hoursLeft = getHourDifferenceWithTimezone(
            moment(),
            response?.expirationDateTime,
            transaction?.property?.timeZoneId,
        )
        return hoursLeft
    }

    const calculateSeperatorText = (response, hoursLeft) => {
        if (response?.expired) {
            return 'Expired'
        } else if (hoursLeft <= 0) {
            return 'Offer Expired'
        } else if (hoursLeft <= 48 && hoursLeft !== 0) {
            return `Expires in ${hoursLeft} hours ${moment(response?.expirationDateTime)
                .tz(transaction?.property?.timeZoneId || 'America/Phoenix')
                .format('(D/M/yyyy)')}`
        } else {
            return `Expires ${moment(response?.expirationDateTime)
                .tz(transaction?.property?.timeZoneId || 'America/Phoenix')
                .format('ddd, MMM D • h:mma zz')}`
        }
    }

    const calculateClosingDate = (response) => {
        return response?.defaultCloseDate
            ? moment(response?.defaultCloseDate)
                  .tz(transaction?.property?.timeZoneId || 'America/Phoenix')
                  .format('MM/DD/YYYY')
            : ''
    }

    const handleCashOfferButtonClick = async () => {
        try {
            mergeState({ isReady: false })
            await api.post(`api/customer/v1/offers/${transaction?.offerKey}/accept`, {
                closeDate: state.newClosingDate,
                flexClose: state.extendedStay,
                freeMove: state.localMove,
            })
        } catch (e) {
            console.log(e)
        } finally {
            mergeState({ isReady: true })
            close()
            alert.info(
                'Your purchase agreement has been sent.',
                `We have sent the purchase agreement via email through our digital signing partner, HelloSign. Please check your email.`,
                CheckIcon,
                () => {
                    standalone && window.location.reload()
                },
            )
        }
    }

    const handleListViewButtonClick = async () => {
        try {
            mergeState({ isReady: false })
            await api.post(`api/customer/v1/offers/${transaction?.offerKey}/flexinterest`)
        } catch (e) {
        } finally {
            mergeState({ isReady: true })
            close()
            alert.info(
                "We're on it.",
                `Our team will be reaching out shortly to review your home's comparative marketing analysis.`,
                CheckIcon,
                () => {},
            )
        }
    }

    useEffect(() => {
        if (!state.compare) {
            resizeScreen(715)
            mergeState({ activeInfoScreen: '' })
        } else {
            resizeScreen()
        }
    }, [state.compare])

    useEffect(() => {
        let activeTab
        if (state.response.accepted) {
            activeTab = 0
        } else if (state.response.listAccepted) {
            activeTab = 1
        } else if (state.solution === 'Cash' || state.solution === 'List') {
            activeTab = 0
        } else if (state.solution === 'CashPlusList') {
            activeTab = 0
        }
        mergeState({ activeTab })
    }, [state.solution])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
        fetchData(
            !standalone
                ? `customer/v3/cash-offer-transactions/${transaction?.transactionId}/offer/${transaction?.offerKey}`
                : `api/customer/v1/offers/${transaction?.offerKey}`,
        )
        if (pathname.includes('/print')) {
            mergeState({ showPrintScreen: true })
        }
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const renderAgentText = () => {
        return (
            <TeamCardContainer>
                <TeamCard orange column center>
                    <Span>Here is our offer for your seller's home.</Span>
                    <Span mt={-15}>
                        Feel free to <a onClick={handlePrint}>print it here</a>.
                    </Span>
                </TeamCard>
            </TeamCardContainer>
        )
    }

    const renderTabMenu = () => {
        return (
            <TabContainer activeTab={state.activeTab} standalone={standalone}>
                {['Cash Offer', 'List it!', 'Compare Options'].map((label, index) => (
                    <TabButtons
                        key={index}
                        active={state.activeTab === index}
                        onClick={() => handleTabClick(index)}
                    >
                        {label}
                    </TabButtons>
                ))}
                <Slider activeTab={state.activeTab} showAllTabs={state.showAllTabs} />
                {state.activeTab !== 2 && (
                    <HelpCont activeTab={state.activeTab}>
                        <HelpSpan>
                            {state.activeTab === 1 ? 'BACK TO CASH' : 'You have 2 options!'}
                        </HelpSpan>
                        <ArrowUpImg
                            src={state.activeTab === 1 ? ArrowUpTeal : ArrowUpOrange}
                            alt=""
                            activeTab={state.activeTab}
                        />
                    </HelpCont>
                )}
            </TabContainer>
        )
    }

    const renderNavbarOnStanalonePage = () => {
        return (
            <Navbar>
                <div>
                    <img src={Logo} alt="logo" />
                    <Span>
                        {state?.response?.property?.streetAddress}
                        <Span>
                            {state?.response?.property?.city}, {state?.response?.property?.state}{' '}
                            {state?.response?.property?.zipCode}
                        </Span>
                    </Span>
                </div>
            </Navbar>
        )
    }

    if (!state.isReady) return <ScreenLoader full sL={true} backgroundColor="transparent" />

    if (state.isError) return null

    return (
        <>
            {standalone && !state.showPrintScreen && renderNavbarOnStanalonePage()}
            <Container ref={$containerRef} compare={state.compare} standalone={standalone}>
                <Header alignRightOnMobile standalone={standalone}>
                    {!standalone && (
                        <CloseIcon onClick={close}>
                            {window.innerWidth > 480 ? (
                                <IconImg src={RightArrowIcon} alt="" />
                            ) : (
                                <Icon type="close" />
                            )}
                        </CloseIcon>
                    )}
                    {state.solution === 'CashPlusList' &&
                        !state.agent &&
                        !state.hideTabBar &&
                        !state.showDefaultScreen &&
                        !state.showSpecificNumberScreen &&
                        renderTabMenu()}
                </Header>
                {!state.showDefaultScreen && !state.showSpecificNumberScreen && (
                    <>
                        {!state.showPrintScreen && (
                            <MainConatiner
                                compare={state.compare}
                                marginTop={state.solution !== 'CashPlusList' || state.hideTabBar}
                                marginBottom={state.hideTabBar}
                            >
                                {state.agent && state.activeTab === 0 && renderAgentText()}
                                {(state.activeTab === 0 || state.compare) && (
                                    <CashCard
                                        state={state}
                                        mergeState={mergeState}
                                        closeScreen={closeScreen}
                                        buttonClick={handleCashOfferButtonClick}
                                        standalone={standalone}
                                    />
                                )}
                                {(state.activeTab === 1 || state.compare) && (
                                    <ListCard
                                        state={state}
                                        standalone={standalone}
                                        buttonClick={handleListViewButtonClick}
                                    />
                                )}
                                <CashOfferCard
                                    state={state}
                                    mergeState={mergeState}
                                    resizeScreen={resizeScreen}
                                    ref={$extraScreenContainerRef}
                                    closeScreen={closeScreen}
                                    handleCalendarClick={handleCalendarClick}
                                    standalone={standalone}
                                />
                            </MainConatiner>
                        )}
                        {state.compare && !state.showPrintScreen && (
                            <CashOfferTeamCard state={state} />
                        )}
                        {state.compare &&
                            !state.showPrintScreen &&
                            state.showVideoView &&
                            !state?.agent && <CashOfferVideoCard state={state} />}
                    </>
                )}
                {state.showSpecificNumberScreen && !state.showPrintScreen && (
                    <CashOfferSpecificNumber
                        transaction={transaction}
                        parentState={state}
                        parentMergeState={mergeState}
                        standalone={standalone}
                        closeScreen={close}
                        resize={resizeScreen}
                    />
                )}
                {state.showDefaultScreen && !state.showPrintScreen && (
                    <CashOfferDefaultScreen {...state.defaultScreenData} renewOffer={renewOffer} />
                )}
                <CashOfferPrint
                    ref={$printRef}
                    state={state}
                    showPrintScreen={state.showPrintScreen}
                />
            </Container>
        </>
    )
}

export default CashOffer
