import {
    ButtonCont,
    ActionButton,
    Heading,
    ExtendedTagLine,
    SelectDropDown,
    WrapperDiv,
    ExtTagLine,
    ReqWrapper,
    DynamicIcons,
    ExtRequestedLine,
    ExtendedCont,
    DividerModal,
} from 'OnDemandActions/Sell/ExtendedStay/shared/Styles'
import { useWizard } from 'react-use-wizard'
import TickIcon from 'OnDemandActions/Sell/ExtendedStay/shared/assets/Tick_icon.svg'
import useApi from 'shared/hooks/api'
import { ScreenLoader } from 'shared/components'
import moment from 'moment-timezone'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import useEvents from 'shared/hooks/sell/events'

const Step3 = ({ data, setData, es = {} }) => {
    const { previousStep, nextStep } = useWizard()
    const transaction = useSelector((state) => state.singleTransaction.transaction)
    const { getEventByRole } = useEvents()
    const { eventDate } = getEventByRole('ClearedToClose')

    const [date, setDate] = useState(
        es.currentExtendedStayDateTime
            ? moment(es.currentExtendedStayDateTime)
                  .tz(transaction.property.timeZoneId)
                  .format('dddd, MMMM DD, YYYY')
            : null,
    )

    const ExtendedStayPoint = `customer/v3/cash-offer-transactions/${localStorage.currentTransactionId}/change-extended-stay`

    const [{ isWorking }, sendExtendedData] = useApi.post(ExtendedStayPoint)

    const maxDays =
        es.extendedStayDayEligibleDays > es.currentExtendedStayDays
            ? es.extendedStayDayEligibleDays
            : es.currentExtendedStayDays

    const array = []
    for (let i = 0; i < maxDays; i++) {
        array.push(i + 1)
    }

    const handleClick = async () => {
        const res = await sendExtendedData({
            operation: es.extendedStayDayEligibleDays ? 'Update' : 'Add',
            extendedStayDays: data.noOfDays,
        })
        res?.status !== 503 && nextStep()
    }

    const handleOnChange = (e) => {
        let date = moment(es.currentExtendedStayDateTime)
            .tz(transaction.property.timeZoneId)
            .subtract(maxDays, 'day')

        date = es.currentExtendedStayDateTime ? date : moment(eventDate, 'MM/DD/YYYY')
        const date2 = es.currentExtendedStayDateTime
            ? moment(date)
                  .tz(transaction.property.timeZoneId)
                  .add(e.target.value, 'day')
                  .format('dddd, MMMM DD, YYYY')
            : moment(date).add(e.target.value, 'day').format('dddd, MMMM DD, YYYY')
        setData((prevData) => ({
            ...prevData,
            noOfDays: e.target.value === '' ? '' : parseInt(e.target.value),
            dateTodisplay: date2,
            operation: es.currentExtendedStayDateTime ? 'modify' : 'add',
        }))
        e.target.value === '' ? setDate(null) : setDate(date2)
    }

    return (
        <ExtendedCont width="888" height="488">
            <ScreenLoader sL={isWorking} />
            <Heading mt="0">Extended Stay</Heading>
            <ReqWrapper mt="11">
                <DynamicIcons width="17" height="17" src={TickIcon} />
                <ExtRequestedLine>Requested</ExtRequestedLine>
            </ReqWrapper>
            <DividerModal />
            <ExtendedTagLine mt="31">Select # of days:</ExtendedTagLine>
            <div className="select-wrapper">
                <select
                    onChange={handleOnChange}
                    defaultValue={es.currentExtendedStayDateTime && maxDays}
                >
                    <option value="">Select</option>
                    {array.length &&
                        array.map((arr) => (
                            <option key={arr} value={arr}>
                                {arr} {arr > 1 ? 'days' : 'day'}
                            </option>
                        ))}
                </select>
            </div>
            <WrapperDiv mt="18" ml="0">
                {date !== null && (
                    <ExtTagLine>
                        {data.noOfDays === maxDays ? 'Current' : 'New'} Extended Stay End Date:
                        <span style={{ color: '#EC7625' }}> {date}</span>
                    </ExtTagLine>
                )}
            </WrapperDiv>
            <ButtonCont mt="100">
                <ActionButton id="fs_sell_extendedStay_pa_previous3" prev onClick={previousStep}>
                    Back
                </ActionButton>
                <ActionButton
                    continue
                    variant="primary"
                    id="fs_sell_extendedStay_pa_next3"
                    onClick={handleClick}
                    disabled={
                        es.currentExtendedStayDateTime
                            ? date === null || data.noOfDays === maxDays
                            : date === null
                    }
                >
                    Submit
                </ActionButton>
            </ButtonCont>
        </ExtendedCont>
    )
}

export default Step3
