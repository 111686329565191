import { Cont, Img, Heading, Tag } from './Styles'
import UP from './assets/under-process.svg'

const UnderProcess = () => {
    return (
        <Cont>
            <Img src={UP} alt="" />
            <Heading>Work in Progress</Heading>
            <Tag>We are currently working on it. Please try visiting after sometimes.</Tag>
        </Cont>
    )
}

export default UnderProcess
