import { font } from 'shared/utils/styles'
import styled, { css, keyframes } from 'styled-components'

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const slideInDown = keyframes`
    from {
        transform: translateY(-30px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
`

const fadeInAnimation = css`
    animation-name: ${fadeIn};
    animation-duration: 1000ms;
    animation-timing-function: ease;
    animation-fill-mode: both;
    animation-direction: normal;
`

const slideInDownAnimation = css`
    animation-name: ${slideInDown};
    animation-duration: 700ms;
    animation-timing-function: ease;
    animation-fill-mode: both;
    animation-direction: normal;
`

export const TopContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0px;
    gap: 50px;
    @media screen and (max-width: 820px) {
        flex-direction: column;
        padding: 0px 0px 60px 0px;
    }
    @media screen and (max-width: 480px) {
        gap: 0px;
    }
`

export const ImageContainer = styled.div`
    @media screen and (max-width: 1423px) {
        margin-left: -137px;
    }
    @media screen and (max-width: 820px) {
        margin-left: 0px;
    }
    @media (max-width: 480px) {
        width: 100%;
        padding: 0px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
    }
`

export const Image = styled.img`
    ${slideInDownAnimation};
    animation-delay: 200ms;
    @media screen and (max-width: 1423px) {
        height: 628px;
    }
    @media screen and (max-width: 1000px) {
        height: 470px;
    }
    @media (max-width: 480px) {
        width: -webkit-fill-available;
        height: auto;
    }
`

export const Conatiner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    @media screen and (max-width: 480px) {
        gap: 20px;
    }
`

export const Heading = styled.div`
    color: #231f20;
    font-size: 30px;
    ${font.black};
    line-height: 40px;
    ${slideInDownAnimation};
    animation-delay: 100ms;
    @media screen and (max-width: 820px) {
        text-align: center;
    }
    @media screen and (max-width: 480px) {
        font-size: 22px;
        line-height: 32px;
    }
`

export const SubHeading = styled.div`
    ${font.medium};
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    ${slideInDownAnimation};
    animation-delay: 300ms;
    a {
        color: #ec7625;
        text-decoration: underline;
    }
    @media screen and (max-width: 820px) {
        text-align: center;
    }
    @media screen and (max-width: 480px) {
        font-size: 15px;
        line-height: 25px;
    }
`

export const CTAContainer = styled.div`
    position: relative;
    display: flex;
    gap: 23.5px;
    ${slideInDownAnimation};
    animation-delay: 600ms;
    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
    @media screen and (max-width: 480px) {
        margin-top: 7px;
    }
`

export const ArrowDownImage = styled.img`
    margin-top: -76px;
    height: 161px;
    @media screen and (max-width: 820px) {
        display: none;
    }
`

export const CTAButton = styled.div`
    border-radius: 4px;
    background-color: #ec7625;
    padding: 20px 40px;
    color: #fff;
    font-size: 16px;
    ${font.bold};
    line-height: normal;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-align: center;
    height: fit-content;
    position: relative;
    ${(props) =>
        !!props.secondary &&
        css`
            background-color: #1b6775;
        `}
    &:hover {
        opacity: 0.85;
    }
`

export const Footer = styled.div`
    border-radius: 10px 0px 0px 10px;
    background: rgba(236, 118, 37, 0.05);
    padding: 29px 42px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    cursor: pointer;
    ${slideInDownAnimation};
    animation-delay: 900ms;
    @media screen and (max-width: 1423px) {
        margin-right: -20px;
    }
    @media screen and (max-width: 820px) {
        gap: 9px;
        margin-top: 10px;
        margin-left: -20px;
        margin-bottom: -60px;
        border-radius: 0;
    }
`

export const FooterHeading = styled.div`
    color: #000;
    font-size: 22px;
    ${font.black};
    line-height: 24px;
    @media screen and (max-width: 480px) {
        text-align: left;
        font-size: 16px;
        line-height: 24px;
    }
`

export const FooterSubHeading = styled.div`
    color: #000;
    font-size: 18px;
    ${font.medium};
    line-height: 26px;
    a {
        color: #ec7625;
        text-decoration: underline;
    }
    @media screen and (max-width: 480px) {
        text-align: left;
        font-size: 12px;
        line-height: 17px;
    }
`
