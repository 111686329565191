import { useState } from 'react'
import api from 'shared/utils/api'
import { getSpacedName } from 'shared/utils/formatter'
import toast from 'shared/utils/toast'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

const useResourceCenter = () => {
    const [loading, setLoading] = useState(false)

    const user = useSelector((state) => state.user)

    const getResourceForOverview = async (status, limit = 0) => {
        if (isEmpty(status)) return
        const res = await fetch()
        if (!Object.keys(res).length) return
        const temp = []
        const d = res.data
        Object.keys(d).forEach((key) => {
            d[key].map((data) => {
                data.applicableStatuses.includes(status) && temp.push(data)
            })
        })
        return limit === 0 ? temp : temp.slice(0, limit)
    }

    const getResourceByCategory = (data, category) => {
        if (category === 'All') {
            return data.data
        }
        let temp = {}
        const d = data.data
        Object.keys(d).forEach((key) => {
            if (key === category) {
                temp = d[key]
            }
        })
        return temp
    }

    const generateCategoryArray = (data) => {
        if (isEmpty(Object.keys(data))) return
        const temp = []
        temp.push({
            category: 'All',
            title: 'All',
        })
        Object.keys(data).forEach((key) => {
            temp.push({
                category: key,
                title: getSpacedName(key),
            })
        })
        return temp
    }

    const sortByCategory = (res) => {
        if (isEmpty(res)) return
        const temp = []
        res.forEach((data) => {
            const filteredData = res.filter((r) => r.resourceCategory === data.resourceCategory)
            temp[data.resourceCategory] = filteredData
        })
        return temp
    }

    const fetch = async () => {
        setLoading(true)
        const URL = `/Customer/v3/resources?Persona=${
            user?.agent?.toLowerCase() === 'true' ? 'Agent' : 'Customer'
        }&TransactionType=CashOffer`

        try {
            const res = await api.get(URL)
            let temp = []
            res.forEach((item) => {
                if (item.isHelpfulInfo) {
                    temp.push(item)
                }
            })
            const data = sortByCategory(temp)
            const categories = generateCategoryArray(data)
            setLoading(false)
            return {
                data,
                categories,
            }
        } catch (e) {
            setLoading(false)
            toast.error('Something went wrong.')
            console.log(e)
        }
    }

    const fetchResources = async (resourceId) => {
        setLoading(true)
        const URL = `/Customer/v3/resources/${resourceId}`

        try {
            const res = await api.get(URL)
            setLoading(false)
            return res
        } catch (e) {
            setLoading(false)
            toast.error('Something went wrong.')
            console.error(e)
            return null
        }
    }

    return { loading, getResourceByCategory, getResourceForOverview, fetch, fetchResources }
}

export default useResourceCenter
