import api from 'shared/utils/api'
import { useDispatch } from 'react-redux'
import { updateDisabledDates, updateLoading } from 'features/sell/disabled-dates'
import { useNavigate } from 'react-router'
import toast from 'shared/utils/toast'
import moment from 'moment'
import { useErrorBoundary } from 'react-error-boundary'

const useDisabledDates = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { showBoundary } = useErrorBoundary()

    const fetchDisabledDates = async () => {
        if (localStorage.currentTransactionId === undefined) {
            navigate('/sell/dashboard')
            return
        }
        try {
            const temp = []
            const disabledDatesURL = `customer/v3/cash-offer-transactions/${localStorage.currentTransactionId}/disabled-dates`
            const res = await api.get(disabledDatesURL)
            res.disabledDates.forEach((date) => {
                temp.push(moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY'))
            })
            const calendarStartDate = res.calendarStartDate
            const calendarEndDate = res.calendarEndDate
            dispatch(
                updateDisabledDates({ calendarStartDate, calendarEndDate, disabledDates: temp }),
            )
            return { calendarStartDate, calendarEndDate, disabledDates: temp }
        } catch (e) {
            dispatch(updateLoading(false))
            toast.error('Something went wrong.')
            console.error(e)
            showBoundary(e)
        }
    }

    return {
        fetch: fetchDisabledDates,
    }
}

export default useDisabledDates
