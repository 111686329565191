import { updateCoe, updateLoading } from 'features/sell/coe'
import { useSelector } from 'react-redux'
import useSingleTransactions from 'shared/hooks/sell/single-transaction'
import useTeams from 'shared/hooks/sell/teams'
import useDisabledDates from './disabledDates'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { isEmpty } from 'lodash'

const useCoe = () => {
    const dispatch = useDispatch()

    const st = useSingleTransactions()
    const dd = useDisabledDates()
    const teamsData = useTeams()

    const singleTransaction = useSelector((state) => state.singleTransaction.transaction)
    const dD = useSelector((state) => state.disabledDates.disabledDates)
    const teamsD = useSelector((state) => state.teams.teams)

    const get = async () => {
        dispatch(updateLoading(true))

        let response = singleTransaction
        let disabledDates = dD
        let teams = teamsD

        if (isEmpty(singleTransaction)) {
            response = await st.fetch()
        }

        if (isEmpty(dD)) {
            disabledDates = await dd.fetch()
        }

        if (isEmpty(teams)) {
            teams = await teamsData.fetch()
        }

        const temp = []
        const comparisonRoleArray = [
            'OfferRequested',
            'OfferReady',
            'PurchaseAgreementSent',
            'RenewalRequested',
            'OfferExpired',
        ]
        const coeStatusArray = ['NoAddendum', 'PendingAddendum', 'SignedAddendum']
        const showCoe = comparisonRoleArray.includes(response.transactionStatus)
        temp['showCoe'] = !showCoe
        temp['coeStatus'] = coeStatusArray[0]
        if (!showCoe) {
            temp['currentCoeDate'] = moment(response.closeOfEscrowDate, 'YYYY-MM-DD').format(
                'MM/DD/YYYY',
            )
            temp['newCoeDate'] = null
            temp['extendedStayDays'] = 0
            temp['movingCompany'] = false
            temp['movingCompanyDetails'] = {}
            const endDate = moment(disabledDates.calendarEndDate, 'YYYY-MM-DD')
            const startDate = moment(disabledDates.calendarStartDate, 'YYYY-MM-DD')
            const d = []
            while (startDate <= endDate) {
                if (disabledDates.disabledDates.includes(startDate.format('MM/DD/YYYY'))) {
                    startDate.add(1, 'days')
                } else {
                    d.push(startDate.format('MM/DD/YYYY'))
                    startDate.add(1, 'days')
                }
            }
            temp['enabledDates'] = d
            teams.forEach((team) => {
                if (team.role === 'MovingCompanyContact') {
                    temp['movingCompany'] = true
                    temp['movingCompanyDetails'] = team
                }
            })
        }
        dispatch(updateCoe(temp))
        dispatch(updateLoading(false))
        return temp
    }

    return {
        fetch: get,
    }
}

export default useCoe
