import styled, { css } from 'styled-components'

export const Label = styled.label`
    position: relative;
    display: inline-block;
    width: calc(${(props) => props.size}px + calc(${(props) => props.size}px + 8px));
    height: calc(${(props) => props.size}px + 8px);
    input:checked + span {
        background-color: ${(props) => props.backgroundColor};
    }
    input:checked + span:before {
    }
`

export const Span = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: calc(${(props) => props.size}px + 8px);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &::before {
        position: absolute;
        content: '';
        height: ${(props) => props.size}px;
        width: ${(props) => props.size}px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    ${(props) =>
        props.checked &&
        css`
            background-color: ${(props) => props.backgroundcolor};
            &::before {
                -webkit-transform: translateX(${(props) => props.size}px);
                -ms-transform: translateX(${(props) => props.size}px);
                transform: translateX(${(props) => props.size}px);
            }
        `}
`

export const Input = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
`
