import moment from 'moment-timezone'
import { isNilOrEmptyString } from './validation'
import { isEmpty } from 'lodash'

const isNumericInput = (event) => {
    const key = event.keyCode
    return (key >= 48 && key <= 57) || (key >= 96 && key <= 105)
}

const isModifierKey = (event) => {
    const key = event.keyCode
    return (
        event.shiftKey === true ||
        key === 35 ||
        key === 36 ||
        key === 8 ||
        key === 9 ||
        key === 13 ||
        key === 46 ||
        (key > 36 && key < 41) ||
        ((event.ctrlKey === true || event.metaKey === true) &&
            (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
    )
}

export const enforceFormat = (event) => {
    if (!isNumericInput(event) && !isModifierKey(event)) {
        event.preventDefault()
    }
}

export const formatToPhone = (event) => {
    if (isModifierKey(event)) {
        return
    }

    const target = event.target
    const input = event.target.value.replace(/\D/g, '').substring(0, event.target.value.length)
    const zip = input.substring(0, 3)
    const middle = input.substring(3, 6)
    const last = input.substring(6, input.length)

    if (input.length > 6) {
        target.value = `(${zip}) ${middle} - ${last}`
    } else if (input.length > 3) {
        target.value = `(${zip}) ${middle}`
    } else if (input.length > 0) {
        target.value = `(${zip}`
    }
}

export const formatUserPhoneNumber = (phoneNumber) => {
    if (phoneNumber === null || phoneNumber === undefined || phoneNumber === '') return
    const cleaned = phoneNumber.replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (!match) {
        return null
    }
    return `(${match[1]}) ${match[2]}-${match[3]}`
}

export const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber === null || phoneNumber === undefined || phoneNumber === '') return ''
    const cleaned = phoneNumber.replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (!match) {
        return null
    }
    return `(${match[1]}) ${match[2]}-${match[3]}`
}

export const getInitials = (firstName = '', lastName = '') => {
    let initials = ''
    if (!isNilOrEmptyString(firstName) && firstName.trim().length > 0) {
        initials = firstName[0].toUpperCase()
    }

    if (!isNilOrEmptyString(lastName) && lastName.trim().length > 0) {
        initials += lastName[0].toUpperCase()
    }

    return initials
}

export const UpperCaseFirstletter = (word) => {
    if (isEmpty(word)) return ''
    const temp = word
    return temp.charAt(0).toUpperCase() + temp.slice(1)
}

export const getTrimmedString = (str) => {
    if (!str) return ''
    const trimmedStr = str.replace(/\s/g, '')
    return trimmedStr
}

export const getSpacedName = (str) => {
    if (!str) return ''
    const spacedStr = str.split(/(?=[A-Z])/).join(' ')
    return spacedStr
}

export const getBadgeTitle = (str) => {
    if (!str) return ''
    const badgeTitle = str.split(/(?=[A-Z])/)
    return badgeTitle[0]
}

export const sortByDate = (arr, dateProp, sortOrder, dateFormat) => {
    const copy = arr.slice()

    copy.sort((a, b) => {
        const dateA = parseDate(a[dateProp], dateFormat)
        const dateB = parseDate(b[dateProp], dateFormat)
        if (sortOrder === 'asc') {
            return dateA - dateB
        } else if (sortOrder === 'desc') {
            return dateB - dateA
        } else {
            throw new Error('Invalid sort order')
        }
    })

    return copy
}

export const parseDate = (dateStr, dateFormat) => {
    const parts = dateStr.split(/[^0-9]/)
    const formatParts = dateFormat.split(/[^YMD]/)
    const yearIndex = formatParts.indexOf('YYYY')
    const monthIndex = formatParts.indexOf('MM')
    const dayIndex = formatParts.indexOf('DD')
    const year = parseInt(parts[yearIndex], 10)
    const month = parseInt(parts[monthIndex], 10) - 1
    const day = parseInt(parts[dayIndex], 10)
    return new Date(year, month, day)
}

export const getDomainWithoutSubdomain = (url) => {
    const urlParts = new URL(url).hostname.split('.')

    return urlParts
        .slice(0)
        .slice(-(urlParts.length === 4 ? 3 : 2))
        .join('.')
}

export const encodeURIForMailTo = ({ email, subject = '', body = '' }) => {
    let params = subject || body ? '?' : ''
    if (subject) params += `subject=${encodeURIComponent(subject)}`
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`
    return `mailto:${email}${params}`
}

export const formatContactInfo = (infoString) => {
    const firstFullStopIndex = infoString.indexOf('.')
    const intro = infoString.substring(0, firstFullStopIndex + 1).trim()
    const contactInfo = infoString.substring(firstFullStopIndex + 1).trim()
    const [name, contactDetails] = contactInfo.split(':').map((item) => item.trim())

    const emailRegex = /[\w.-]+@[\w.-]+\.[\w]+/
    const extractedEmail = contactDetails.match(emailRegex)
    const extractedPhoneNumber = contactDetails.replace(emailRegex, '').trim()

    return (
        <>
            {intro}
            <br />
            {name}
            <br />
            {extractedEmail && (
                <>
                    <a href={`mailto:${extractedEmail[0]}`}>{extractedEmail[0]}</a>
                    <br />
                </>
            )}
            {extractedPhoneNumber && (
                <>
                    <a href={`tel:${extractedPhoneNumber}`}>{extractedPhoneNumber}</a>
                    <br />
                </>
            )}
        </>
    )
}

export const capitalizeFirstLetterEveryWord = (string) => {
    if (string === '' || string === null || string === undefined) return
    return string.replace(/\b\w/g, function (match) {
        return match.toUpperCase()
    })
}

export const formatInspectionDateTime = (inspectionDate) => {
    const date = new Date(inspectionDate)
    const options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'America/New_York',
        timeZoneName: 'short',
    }
    const formattedDate = date.toLocaleString('en-US', options)
    return formattedDate.replace(/(\d+:\d+)\s*(\w+)\s*(\w+)/, '$1 $2 ($3)')
}

export const isMatchingURL = (url, patterns) => {
    function matchesPattern(url, pattern) {
        if (pattern.endsWith('/*')) {
            const prefix = pattern.slice(0, -2)
            return url.startsWith(prefix)
        } else {
            return url === pattern
        }
    }

    return patterns.some((pattern) => matchesPattern(url, pattern))
}

export const formatMoney = (amount, currency = 'USD', locale = 'en-US') => {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 0,
    }).format(amount)
}

export const getDaysDifferenceWithTimezone = (dateString1, dateString2, timeZone) => {
    const date1 = new Date(dateString1)
    const date2 = new Date(dateString2)

    if (isNaN(date1) || isNaN(date2)) {
        return 'Invalid date input'
    }

    const options = { timeZone: timeZone }
    const formatter1 = new Intl.DateTimeFormat('en-US', options)
    const formatter2 = new Intl.DateTimeFormat('en-US', options)

    const formattedDate1 = formatter1.format(date1)
    const formattedDate2 = formatter2.format(date2)
    const date1InTimezone = new Date(formattedDate1)
    const date2InTimezone = new Date(formattedDate2)

    const timeDifference = date1InTimezone - date2InTimezone

    const daysDifference = Math.abs(Math.floor(timeDifference / (1000 * 60 * 60 * 24)))

    return daysDifference
}

export const getHourDifferenceWithTimezone = (dateString1, dateString2, timeZone) => {
    const startMoment = moment.tz(dateString1, timeZone)
    const endMoment = moment.tz(dateString2, timeZone)

    const duration = moment.duration(endMoment.diff(startMoment))
    const hoursLeft = duration.asHours()

    return hoursLeft
}

export const getCurrentDateInUTCFormat = () => {
    const now = new Date()
    const utcString = now.toISOString()
    return utcString
}

export const simplifyMoney = (value) => {
    var thousandNum = value / 1000
    var millionNum = value / 1000000

    if (value >= 1000 && value < 1000000) {
        if (Math.floor(thousandNum) === thousandNum) {
            return `${Math.round(thousandNum)}K`
        }
        return `${thousandNum.toFixed(0).replace(/(\.0+|0+)$/, '')}K`
    }

    if (value > 1000000) {
        if (Math.floor(millionNum) === millionNum) {
            return `${Math.round(millionNum)}M`
        }
        return `${millionNum.toFixed(2)}M`
    } else {
        if (value % 1 === 0) {
            return value.toFixed(0)
        } else {
            return value.toFixed(2)
        }
    }
}
