import { FormElement, FormFieldCol, FormFieldRow, Step } from '../Styles'
import { useWizard } from 'react-use-wizard'
import { Form, ScreenLoader } from 'shared/components'
import { enforceFormat, formatToPhone } from 'shared/utils/formatter'
import useApi from 'shared/hooks/api'
import { useState } from 'react'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

const Step1 = () => {
    const { nextStep } = useWizard()
    const user = useSelector((state) => state.user)
    const FormsEndPoint = `customer/v3/forms/`
    const [{ isCreating }, sendForm] = useApi.post(FormsEndPoint)
    const [preferred, setPreferred] = useState('')
    const [contact, setContact] = useState(user?.phoneNumber)

    const handleSubmit = async (values) => {
        await sendForm({
            formType: 'BuyLandingInterest',
            items: [
                { name: 'firstName', value: values.firstName },
                { name: 'lastName', value: values.lastName },
                { name: 'email', value: values.email },
                { name: 'phone', value: values.phoneNumber },
                { name: 'preferredContactMethod', value: preferred },
                { name: 'source', value: 'Connect.Web' },
                { name: 'markets', value: values.market },
            ],
        })
        nextStep()
    }

    const handleOnChange = (e) => setPreferred(e.target.value)

    return (
        <Step>
            <ScreenLoader sL={isCreating} full={false} size={100} />
            <h2>Hi {user?.firstName},</h2>
            <p>
                We're happy to connect you with additional information about how we can help you
                find your new home. Can you please confirm the info below?
            </p>
            <Form
                enableReinitialize
                initialValues={{
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    email: user?.email,
                    phoneNumber: user?.phoneNumber,
                    preferredText: false,
                    preferredEmail: false,
                    preferredCall: false,
                    market: null,
                }}
                validations={{
                    email: [
                        Form.is.required('Enter a valid email '),
                        Form.is.email('Enter a valid email '),
                    ],
                    firstName: Form.is.required('Enter your first name'),
                    lastName: Form.is.required('Enter your last name'),
                    phoneNumber: [
                        Form.is.required('Enter phone number'),
                        Form.is.validPhoneNumber(),
                    ],
                }}
                onSubmit={(values, form) => {
                    if (preferred === '') {
                        toast.error('Please select a preffered method')
                        return
                    }
                    if (!values.market) {
                        form.setFieldError('market', 'Please select the market name')
                        return
                    }
                    values.market = values.market.values
                    handleSubmit(values)
                }}
            >
                <FormElement>
                    <FormFieldRow>
                        <FormFieldCol>
                            <Form.Field.Input
                                name="firstName"
                                type="text"
                                placeholder="First Name"
                                size="lg"
                                iconType="name"
                            />
                        </FormFieldCol>
                        <FormFieldCol>
                            <Form.Field.Input
                                name="lastName"
                                type="text"
                                placeholder="Last Name"
                                size="lg"
                                iconType="name"
                            />
                        </FormFieldCol>
                    </FormFieldRow>
                    <FormFieldRow>
                        <FormFieldCol>
                            <Form.Field.Input
                                name="email"
                                type="email"
                                placeholder="Email"
                                size="lg"
                            />
                        </FormFieldCol>
                        <FormFieldCol>
                            <Form.Field.Input
                                name="phoneNumber"
                                type="text"
                                placeholder="Phone Number"
                                size="lg"
                                iconType="phone"
                                onKeyDown={enforceFormat}
                                onKeyUp={formatToPhone}
                                onChangeEvent={setContact}
                            />
                        </FormFieldCol>
                    </FormFieldRow>
                    <FormFieldRow>
                        <FormFieldCol>
                            <Form.Field.Select
                                async
                                url="customer/v3/cash-offer-transactions/markets"
                                name="market"
                                size="lg"
                                placeholder="Where are you buying?"
                                saperator={false}
                                placeholderColor={'#525659'}
                            />
                        </FormFieldCol>
                        <FormFieldCol></FormFieldCol>
                    </FormFieldRow>
                    <h2 className="method">Preferred Contact Method:</h2>
                    <FormFieldRow noSpaceBetween>
                        <FormFieldCol noSpaceBetween>
                            <Form.Field.Checkbox
                                name="preferredText"
                                checkboxLabel="Text"
                                labelSize="14"
                                size="lg"
                                labelColor="black"
                                value="Text"
                                checked={preferred === 'Text'}
                                onChangeEvent={handleOnChange}
                            />
                        </FormFieldCol>
                        <FormFieldCol noSpaceBetween>
                            <Form.Field.Checkbox
                                name="preferredEmail"
                                checkboxLabel="Email"
                                labelSize="14"
                                size="lg"
                                labelColor="black"
                                value="Email"
                                checked={preferred === 'Email'}
                                onChangeEvent={handleOnChange}
                            />
                        </FormFieldCol>
                        <FormFieldCol noSpaceBetween>
                            <Form.Field.Checkbox
                                name="preferredCall"
                                checkboxLabel="Call"
                                labelSize="14"
                                size="lg"
                                labelColor="black"
                                value="Call"
                                checked={preferred === 'Call'}
                                onChangeEvent={handleOnChange}
                            />
                        </FormFieldCol>
                    </FormFieldRow>

                    <div className="btn-cont">
                        <button
                            className="next no-extra stick-bottom"
                            type="submit"
                            disabled={isCreating || isEmpty(preferred) || isEmpty(contact)}
                            id="fs_buy_dashboard_modalSubmit"
                        >
                            Submit
                        </button>
                    </div>
                </FormElement>
            </Form>
        </Step>
    )
}

export default Step1
