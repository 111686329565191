import React from 'react'
import { forwardRef } from 'react'
import * as Styles from './Styles'
import Logo from 'shared/asstes/logo.svg'
import SunCloud from 'shared/asstes/sun_with_cloud.svg'
import { formatMoney } from 'shared/utils/formatter'

const CashOfferPrint = forwardRef(({ standalone, state, showPrintScreen }, ref) => {
    return (
        <Styles.DocumentContainer
            showPrintScreen={showPrintScreen}
            ref={ref}
            className={!standalone && !showPrintScreen && `hide-on-screen`}
        >
            <Styles.Page>
                <Styles.Navbar>
                    <Styles.Logo src={Logo} alt="" />
                    <Styles.NavbarText>
                        {state?.response?.property?.streetAddress} <br />
                        {state?.response?.property?.city}, {state?.response?.property?.state}{' '}
                        {state?.response?.property?.zipCode}
                    </Styles.NavbarText>
                </Styles.Navbar>
                <Styles.MainContainer>
                    <Styles.Badge>Cash Offer</Styles.Badge>
                    <Styles.OfferPrice>{formatMoney(state?.response?.price)}</Styles.OfferPrice>
                    <Styles.ContentContainer>
                        <Styles.OfferExpiresText>{state?.seperatorText}</Styles.OfferExpiresText>
                        <Styles.OfferInfo>
                            <Styles.OfferInfoText>Offer price</Styles.OfferInfoText>
                            <Styles.OfferInfoDetail>
                                {formatMoney(state?.response?.price)}
                            </Styles.OfferInfoDetail>
                        </Styles.OfferInfo>
                        <Styles.OfferInfo>
                            <Styles.OfferInfoText>Service Fee</Styles.OfferInfoText>
                            <Styles.OfferInfoDetail>-{state?.serviceFee}%</Styles.OfferInfoDetail>
                        </Styles.OfferInfo>
                        <Styles.OfferInfo>
                            <Styles.OfferInfoText>Repairs</Styles.OfferInfoText>
                            <Styles.OfferInfoDetail>pending inspection</Styles.OfferInfoDetail>
                        </Styles.OfferInfo>
                        <Styles.OfferInfo>
                            <Styles.OfferInfoText>
                                <Styles.Teal>Estimated Net Proceeds</Styles.Teal>
                            </Styles.OfferInfoText>
                            <Styles.OfferInfoDetail>
                                <Styles.Teal>
                                    <Styles.Bold>
                                        {formatMoney(state?.response?.estimatedProceeds)}
                                    </Styles.Bold>
                                </Styles.Teal>
                            </Styles.OfferInfoDetail>
                        </Styles.OfferInfo>
                        <Styles.Divider />
                        {(state?.response?.freeMove || !state?.hideLocalView) && (
                            <>
                                <Styles.OfferInfo>
                                    <Styles.OfferInfoText>Local Move</Styles.OfferInfoText>
                                    <Styles.OfferInfoDetail>
                                        <Styles.Bold>available</Styles.Bold>
                                    </Styles.OfferInfoDetail>
                                </Styles.OfferInfo>
                                <Styles.InfoText>
                                    Offerpad is all about removing the stress related to selling
                                    your home, which is why we include a FREE local move.
                                </Styles.InfoText>
                            </>
                        )}
                        {(state?.response?.request?.flexClose || !state?.hideExtendedStay) && (
                            <>
                                <Styles.OfferInfo>
                                    <Styles.OfferInfoText>
                                        {state?.response?.isPartnerTier2 ? 5 : 3}-day Extended
                                        Stay
                                    </Styles.OfferInfoText>
                                    <Styles.OfferInfoDetail>
                                        <Styles.Bold>available</Styles.Bold>
                                    </Styles.OfferInfoDetail>
                                </Styles.OfferInfo>
                                <Styles.InfoText>
                                    Who wouldn't like a few extra days after closing to move in to
                                    your new place? With Offerpad, you can stay up to 3 days after
                                    closing and relax a bit!
                                </Styles.InfoText>
                            </>
                        )}
                        <Styles.FooterContainer>
                            <Styles.FooterImage src={SunCloud} alt="" />
                            <Styles.FooterHeading>Next Steps:</Styles.FooterHeading>
                            <Styles.FooterText>
                                Your agent will forward your purchase agreement for your review and
                                digital signature. Don't worry - you're not under contract or
                                obligation until you have signed.
                            </Styles.FooterText>
                        </Styles.FooterContainer>
                    </Styles.ContentContainer>
                    <Styles.InfoText style={{ width: '100%', color: '#525659', marginTop: '10px' }}>
                        <Styles.Bold style={{ display: 'inline-block' }}>Note:</Styles.Bold> Buyer
                        and Seller will each be responsible for their own customary closing costs
                        typically assessed to each in the state where property is located.
                    </Styles.InfoText>
                </Styles.MainContainer>
                <Styles.Navbar style={{ marginTop: '15px' }}>
                    <div>
                        <Styles.OfferInfoText>
                            <Styles.DisplayBlock styles={{ fontSize: '18px', marginBottom: '3px' }}>
                                James Smith
                            </Styles.DisplayBlock>
                        </Styles.OfferInfoText>
                        <Styles.OfferInfoText style={{ marginBottom: '3px' }}>
                            T: 602.555.1212
                        </Styles.OfferInfoText>
                        <Styles.OfferInfoText>
                            <Styles.Orange>james.smith@abcrealtor.com</Styles.Orange>
                        </Styles.OfferInfoText>
                    </div>
                </Styles.Navbar>
            </Styles.Page>
        </Styles.DocumentContainer>
    )
})

export default CashOfferPrint
