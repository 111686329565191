import { BackButtonCont, Img } from './Style'
import BackButtonIcon from './assets/back-button-icon.svg'

const BackButton = ({ height = '', width = '', imageWidth = '', ...rest }) => {
    return (
        <BackButtonCont {...rest} height={height} width={width}>
            <Img id="fs_back_button" src={BackButtonIcon} alt="" width={imageWidth} />
        </BackButtonCont>
    )
}

export default BackButton
