import { createSlice } from '@reduxjs/toolkit'

const trackerSlice = createSlice({
    name: 'tracker',
    initialState: {
        tracker: [],
        loading: false,
    },
    reducers: {
        updateTracker: (state, action) => ({
            ...state,
            tracker: action.payload,
        }),
        updateLoading: (state, action) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const { updateTracker, updateLoading } = trackerSlice.actions
export default trackerSlice.reducer
