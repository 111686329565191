import { font } from 'shared/utils/styles'
import styled, { css, keyframes } from 'styled-components'
import Button from '../Button'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

export const Card = styled.div`
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    background-color: #eaeaea;
    border-radius: 8px;
    padding: 24px 22px;
    display: flex;
    gap: 16.5px;
    align-items: center;
    @media (max-width: 480px) {
        align-items: start;
    }
    div {
        display: flex;
        align-items: start;
        justify-content: center;
        gap: 10px;
        flex: 1;
    }
    @media (max-width: 480px) {
        flex-direction: column;
    }
`

export const Icon = styled.img`
    margin-top: -5px;
`

export const TaskAndTagLineCont = styled.div`
    margin-top: -4px;
    display: flex;
    flex-direction: column;
`

export const TaskHeading = styled.div`
    ${font.bold};
    ${font.size(16)};
    line-height: 22px;
    color: #525659;
`

export const TaskTagline = styled.div`
    ${font.medium};
    ${font.size(13)};
    line-height: 10px;
    color: #525659;
`

export const ActionButton = styled(Button)`
    height: fit-content;
    padding: 10px 17px;
    border-radius: 3px;
    ${font.size(13)};
    ${font.medium};
    margin: 0px 0px 0px auto;
    width: 168px;
    text-overflow: ellipsis;
    @media (max-width: 650px) {
        width: 168px;
        height: 47px;
        margin: 0px;
        text-overflow: ellipsis;
    }
    ${(props) =>
        props.review &&
        props.review === true &&
        css`
            margin: 0 0 0 auto;
            border-radius: 3px;
            border: 1px solid #1b6775;
            background-color: #f6f6f6;
            color: #1b6775;
            padding: 10px 31px;
            ${font.medium};
            font-size: 13px;
            line-height: 15.6px;
            width: 168px;
            text-overflow: ellipsis;
            &:hover {
                background: #f6f6f6 !important;
            }
        `}
`
