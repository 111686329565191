import styled, { css } from 'styled-components'
import { color, font, mixin } from 'shared/utils/styles'
import Background from './assets/bg.svg'

export const ResourceCont = styled.div`
    padding: 50px 50px 70px;
    width: 750px;
    max-width: calc(100vw - 100px);
    height: 100%;
    background: #fff;
    border-radius: 10px;
    position: relative;
    ${(props) =>
        props.isViewOffer &&
        css`
            .feature-container,
            hr,
            .terms-content {
                display: none !important;
            }
        `}

    @media (max-width: 480px) {
        padding: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 0;
        max-width: 100%;
        max-height: fit-content;
    }
`

export const Cont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #1b6775;
    padding: 40px 0;
    background-image: url(${Background});
    background-repeat: repeat;

    @media (max-width: 480px) {
        padding: 0;
    }

    .terms-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 0;
    }

    .terms-container > p {
        ${font.medium};
        ${font.size(16)};
        color: #525659;
    }

    .terms-heading {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }
    }

    a {
        color: #ef7724;
        -webkit-text-decoration: underline;
        text-decoration: underline;
    }

    i {
        ${font.medium};
    }

    .terms-heading > h1 {
        ${font.black};
        ${font.size(25)};
        line-height: 36px;
        color: #262833;
    }

    .terms-heading > h2 {
        ${font.black};
        ${font.size(25)};
        line-height: 36px;
        color: #262833;
        ${(props) =>
            props.resourceId === 48 &&
            css`
                ${font.size(18)};
                line-height: 22px;
                color: #525659;
                margin-top: 10px;
            `}
    }

    .terms-content > p {
        ${font.bold};
        ${font.size(17)};
        line-height: 22px;
        margin: 10px 0px;
    }

    .terms-info {
        ${font.black};
        ${font.size(18)};
        line-height: 25px;
        color: #525659;
        margin-top: 10px;
        ${(props) =>
            props.resourceId === 47 &&
            css`
                ${font.black};
                ${font.size(16)};
                line-height: 26px;
                color: #525659;
                margin-top: 10px;
            `}
    }

    .terms-info > ul {
        list-style-type: disc;
        ${font.medium};
        ${font.size(16)};
        margin-left: 20px;
        line-height: 26px;
        color: #525659;
        margin-top: 5px;
    }

    .terms-info > p {
        ${font.bold};
        ${font.size(16)};
        line-height: 26px;
        color: #525659;
        margin-top: 10px;
        ${(props) =>
            props.resourceId === 50 &&
            css`
                ${font.medium};
                ${font.size(16)};
            `}
        ${(props) =>
            props.resourceId === 47 &&
            css`
                ${font.medium};
                ${font.size(16)};
            `}
    }

    .terms-list > ul {
        list-style-type: disc;
        margin-left: 20px;
        ${font.medium};
        ${font.size(16)};
        line-height: 26px;
        color: #525659;
    }

    .terms-content-info > p {
        ${font.medium};
        ${font.size(16)};
        line-height: 26px;
        color: #525659;
        margin-top: 10px;
    }

    .footer-heading {
        ${font.bold};
        ${font.size(16)};
        line-height: 25px;
        color: #525659;
    }

    .footer-content {
        ${font.medium};
        ${font.size(16)};
        line-height: 26px;
        color: #525659;
        margin-top: 10px;
    }

    // FreeLocalMove & ExtendedStay

    .heading-container {
        display: flex;
        align-items: center;
        position: relative;

        @media (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
        }
    }

    .heading-container > h1 {
        ${font.black};
        ${font.size(25)};
        line-height: 36px;
        color: #262833;
    }

    .feature-container {
        display: grid;
        grid-template-columns: auto auto auto;
        align-items: center;
        justify-content: space-between;
        gap: 50px;

        @media (max-width: 820px) {
            gap: 20px;
        }

        @media (max-width: 480px) {
            grid-template-columns: auto;
        }
    }

    .feature-container > div {
        display: flex;
        align-items: center;
        gap: 14px;
    }

    .feature-container > div > img {
        @media (max-width: 480px) {
            width: 40px;
        }
    }

    .feature-container-img {
        width: 55px;
        height: 55px;
    }

    .feature-container > div > h2 {
        ${font.bold};
        ${font.size(15)};
        line-height: 18px;
        color: black;
    }

    .list-info {
        display: flex;
        align-items: flex-start;
        gap: 6px;
    }

    .list-info > img {
        margin-top: 2px;
    }

    .list-info > ul {
        ${font.medium};
        ${font.size(16)};
        line-height: 26px;
        color: #525659;
    }
`
