import PlaceHolderImage from 'shared/asstes/default-property-image.svg'

const Image = ({ src, alt, classname, ...rest }) => {
    const handleOnError = (e) => {
        e.target.src = PlaceHolderImage
    }

    return (
        <img
            src={src || PlaceHolderImage}
            alt={alt}
            className={classname}
            onError={handleOnError}
            loading="lazy"
            {...rest}
        />
    )
}

export default Image
