import PropTypes from 'prop-types'
import EmbeddedSigning from '../Embedded-Signing'
import { ActionButton, Cont, InfoCont, InfoHeading, TaskTagline } from './Styles'
import { useNavigate } from 'react-router'
import { encodeURIForMailTo } from 'shared/utils/formatter'
import { getMailDetails } from 'shared/constants/mail'
import ReadAndAcknowledge from '../ReadAndAcknowledge'
import api from 'shared/utils/api'
import toast from 'shared/utils/toast'
import { useState } from 'react'
import useOverview from 'shared/hooks/sell/overview'
import SecondarySellerEmailUpdateModal from '../SecondarySellerEmailUpdateModal'
import UploadDocuments from '../UploadDocuments'
import useMyTransactions from 'shared/hooks/sell/my-transactions'
import { useSelector } from 'react-redux'
import PhotosViewer from '../PhotosViewer'

const defaultProps = {
    todo: {},
    handleErrorEvent: () => {},
    handleOnCloseEvent: () => {},
    onMarkedTodoCompleteEvent: () => {},
    agent: false,
    overview: false,
}

const propTypes = {
    todo: PropTypes.object,
    handleErrorEvent: PropTypes.func,
    handleOnCloseEvent: PropTypes.func,
    onMarkedTodoCompleteEvent: PropTypes.func,
    agent: PropTypes.bool,
    overview: PropTypes.bool,
}

const PendingTodos = ({
    todo,
    handleErrorEvent,
    handleOnCloseEvent,
    agent,
    overview,
    onMarkedTodoCompleteEvent,
}) => {
    const identifier = todo.identifier
    const { transactionId, offerKey } = window.currentTransaction
    const navigate = useNavigate()
    const { init } = useOverview()
    const myTransaction = useMyTransactions()
    const [renewOfferLoading, setRenewOfferLoading] = useState(false)

    const transaction = useSelector((state) => state.singleTransaction.transaction)

    const handleRenewOffer = async () => {
        setRenewOfferLoading(true)
        const URL = transaction?.isPreContractInspection
            ? `/api/customer/v2/preliminaryoffers/${transaction?.preliminaryOfferKey}/renew`
            : `customer/v3/cash-offer-transactions/${transactionId}/renew-offer`

        try {
            await api.post(URL)
            toast.success('Your offer is renewed.')
            setRenewOfferLoading(false)
            await init(true)
        } catch (e) {
            e?.error?.message && toast.error(e?.error?.message)
            setRenewOfferLoading(false)
        }
    }

    switch (true) {
        case identifier === 'CashOfferTransactionAddendumPropertyDisclosure':
        case identifier === 'CashOfferTransactionAddendumCredit':
        case identifier === 'CashOfferTransactionAddendumCreditAndRepair':
        case identifier === 'CashOfferTransactionAddendumCloseOfEscrow':
        case identifier === 'CashOfferTransactionAddendumTrashOut':
        case identifier === 'CashOfferTransactionAddendumBuyerCreditToSeller':
        case identifier === 'CashOfferTransactionAddendumPostPossession':
        case identifier === 'CashOfferTransactionAddendumGeneral':
        case identifier === 'CashOfferTransactionAddendumPurchasePriceAdjustment':
        case identifier === 'CashOfferTransactionAddendumPostPossessionCoeChange':
        case identifier === 'CashOfferTransactionAddendumEscrowHoldBack':
        case identifier === 'CashOfferTransactionAddendumSellerCreditToBuyer':
        case identifier === 'CashOfferTransactionAddendumCancellation':
        case identifier === 'CashOfferTransactionAddendumPostPossessionCancellation':
        case identifier === 'CashOfferTransactionAddendumPostPossessionCancellationCOEChange':
            return (
                <Cont overview={overview}>
                    <InfoCont>
                        <InfoHeading>{todo.title}</InfoHeading>
                        {todo.bodyText !== null && <TaskTagline>{todo.bodyText}</TaskTagline>}
                    </InfoCont>
                    <EmbeddedSigning
                        title={todo.actionButtonLabel}
                        seller={todo.metadata}
                        onCloseEvent={handleOnCloseEvent}
                        onErrorEvent={handleErrorEvent}
                        id={`fs_sell_todo_${todo.identifier}`}
                    />
                </Cont>
            )

        case identifier === 'CashOfferTransactionUploadPhotos':
        case identifier === 'CashOfferTransactionPhotosRequired':
            return (
                <Cont>
                    <InfoCont>
                        <InfoHeading>{todo.title}</InfoHeading>
                        {todo.bodyText !== null && <TaskTagline>{todo.bodyText}</TaskTagline>}
                    </InfoCont>
                    <PhotosViewer
                        btnText={todo.actionButtonLabel}
                        id={`fs_sell_todo_${todo.identifier}`}
                    />
                </Cont>
            )

        case identifier === 'CashOfferTransactionReviewOffer':
        case identifier === 'CashOfferTransactionReviewBoomerangOffer':
            return (
                <Cont>
                    <InfoCont>
                        <InfoHeading>{todo.title}</InfoHeading>
                        {todo.bodyText !== null && <TaskTagline>{todo.bodyText}</TaskTagline>}
                    </InfoCont>
                    <a
                        href={`${process.env.VIEW_OFFER_URL}/${offerKey}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        id={`fs_sell_todo_${todo.identifier}`}
                    >
                        <ActionButton variant="primary">{todo.actionButtonLabel}</ActionButton>
                    </a>
                </Cont>
            )

        case identifier === 'CashOfferTransactionPresentOfferAppointment':
        case identifier === 'CashOfferTransactionScheduleInspectionResultReview':
        case identifier === 'CashOfferTransactionPostInspectionSurvey' && agent === false:
        case identifier === 'CashOfferTransactionUtilitiesInformation' && agent === false:
        case identifier === 'CashOfferTransactionExitSurvey' && agent === false:
        case identifier === 'CashOfferTransactionProvideSellerInformationToFirstAmerican' &&
            todo.actionButtonLabel !== null:
        case identifier === 'CashOfferTransactionReviewExpressOffer':
            return (
                <Cont>
                    <InfoCont>
                        <InfoHeading>{todo.title}</InfoHeading>
                        {todo.bodyText !== null && <TaskTagline>{todo.bodyText}</TaskTagline>}
                    </InfoCont>
                    <a
                        href={todo.metadata?.redirectUrl}
                        target="_blank"
                        id={`fs_sell_todo_${todo.identifier}`}
                    >
                        <ActionButton variant="primary" id={`fs_sell_todo_${todo.identifier}`}>
                            {todo.actionButtonLabel}
                        </ActionButton>
                    </a>
                </Cont>
            )

        case identifier === 'CashOfferTransactionPostInspectionSurvey' && agent === true:
        case identifier === 'CashOfferTransactionUtilitiesInformation' && agent === true:
        case identifier === 'CashOfferTransactionExitSurvey' && agent === true:
            return (
                <Cont>
                    <InfoCont>
                        <InfoHeading>{todo.title}</InfoHeading>
                        {todo.bodyText !== null && <TaskTagline>{todo.bodyText}</TaskTagline>}
                    </InfoCont>
                    <a
                        href={encodeURIForMailTo(
                            getMailDetails(todo.identifier, todo.metadata?.redirectUrl),
                        )}
                        target="_blank"
                        id={`fs_sell_todo_${todo.identifier}`}
                    >
                        <ActionButton variant="primary" id={`fs_sell_todo_${todo.identifier}`}>
                            {todo.actionButtonLabel}
                        </ActionButton>
                    </a>
                </Cont>
            )

        case identifier === 'CashOfferTransactionFinalizeMoveDateBellhops':
            return (
                <Cont>
                    <InfoCont>
                        <InfoHeading>{todo.title}</InfoHeading>
                        {todo.bodyText !== null && <TaskTagline>{todo.bodyText}</TaskTagline>}
                    </InfoCont>
                    <ActionButton
                        variant="primary"
                        onClick={() => navigate('/sell/your-team')}
                        id={`fs_sell_todo_${todo.identifier}`}
                    >
                        {todo.actionButtonLabel}
                    </ActionButton>
                </Cont>
            )

        case identifier === 'CashOfferTransactionReviewPurchaseAgreement':
        case identifier === 'CashOfferTransactionDiscoveryRequired':
        case identifier === 'CashOfferTransactionScheduleInspection':
        case identifier === 'CashOfferTransactionCreateFirstAmericanAccount':
        case identifier === 'CashOfferTransactionProvideSellerInformationToFirstAmerican' &&
            todo.actionButtonLabel === null:
            return (
                <Cont>
                    <InfoCont>
                        <InfoHeading>{todo.title}</InfoHeading>
                        {todo.bodyText !== null && <TaskTagline>{todo.bodyText}</TaskTagline>}
                    </InfoCont>
                </Cont>
            )

        case identifier === 'CashOfferTransactionWhatToLeaveBehind':
        case identifier === 'CashOfferTransactionConditionRequirements':
        case identifier === 'CashOfferTransactionAcknowledgeExtendedStay':
        case identifier === 'CashOfferTransactionAcknowledgeFreeLocalMove':
        case identifier === 'CashOfferTransactionPrepareForInspection':
            return (
                <Cont>
                    <InfoCont>
                        <InfoHeading>{todo.title}</InfoHeading>
                        {todo.bodyText !== null && <TaskTagline>{todo.bodyText}</TaskTagline>}
                    </InfoCont>
                    <ReadAndAcknowledge
                        todo={todo}
                        onMarkedTodoCompleteEvent={onMarkedTodoCompleteEvent}
                        id={`fs_sell_todo_${todo.identifier}`}
                    />
                </Cont>
            )

        case identifier === 'CashOfferTransactionRenewOffer':
        case identifier === 'CashOfferTransactionRenewExpressOffer':
            return (
                <Cont>
                    <InfoCont>
                        <InfoHeading>{todo.title}</InfoHeading>
                        {todo.bodyText !== null && <TaskTagline>{todo.bodyText}</TaskTagline>}
                    </InfoCont>
                    <ActionButton
                        isWorking={renewOfferLoading}
                        onClick={handleRenewOffer}
                        variant="primary"
                        id={`fs_sell_todo_${todo.identifier}`}
                    >
                        {todo.actionButtonLabel}
                    </ActionButton>
                </Cont>
            )

        case identifier === 'CashOfferTransactionUpdateSecondarySeller':
            return (
                <Cont>
                    <InfoCont>
                        <InfoHeading>{todo.title}</InfoHeading>
                        {todo.bodyText !== null && <TaskTagline>{todo.bodyText}</TaskTagline>}
                    </InfoCont>
                    <SecondarySellerEmailUpdateModal
                        todo={todo}
                        onMarkedTodoCompleteEvent={onMarkedTodoCompleteEvent}
                        renderLink={(open) => {
                            return (
                                <ActionButton
                                    onClick={open}
                                    variant="primary"
                                    id={`fs_sell_todo_${todo.identifier}`}
                                >
                                    {todo.actionButtonLabel}
                                </ActionButton>
                            )
                        }}
                    />
                </Cont>
            )
        case identifier === 'CashOfferTransactionUploadDocumentTrust':
        case identifier === 'CashOfferTransactionUploadDocumentLLC':
        case identifier === 'CashOfferTransactionUploadDocumentSigningAuthority':
        case identifier === 'CashOfferTransactionUploadDocumentHeirship':
        case identifier === 'CashOfferTransactionUploadDocumentPermit':
        case identifier === 'CashOfferTransactionUploadDocumentSurvey':
        case identifier === 'CashOfferTransactionUploadDocumentSolar':
        case identifier === 'CashOfferTransactionUploadDocumentHOA':
        case identifier === 'CashOfferTransactionUploadDocumentProofOfPayment':
        case identifier === 'CashOfferTransactionUploadDocumentWellAgreement':
        case identifier === 'CashOfferTransactionUploadDocumentOther':
            return (
                <Cont>
                    <InfoCont>
                        <InfoHeading>{todo.title}</InfoHeading>
                        {todo.bodyText !== null && <TaskTagline>{todo.bodyText}</TaskTagline>}
                    </InfoCont>
                    <UploadDocuments
                        todo={todo}
                        onMarkedTodoCompleteEvent={onMarkedTodoCompleteEvent}
                        renderContent={(browseDocuments) => {
                            return (
                                <ActionButton
                                    onClick={browseDocuments}
                                    variant="primary"
                                    id={`fs_sell_todo_${todo.identifier}`}
                                >
                                    {todo.actionButtonLabel}
                                </ActionButton>
                            )
                        }}
                    />
                </Cont>
            )
        default:
            return null
    }
}

PendingTodos.propTypes = propTypes
PendingTodos.defaultProps = defaultProps

export default PendingTodos
