import styled from 'styled-components'
import { borderRadius, color, font, fontSize } from 'shared/utils/styles'

export const StyledDiv = styled.div`
    flex: 2;
    width: auto;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    border: 1px solid #dbe5e7;
    background-color: #f7fcfe;
    padding: 7px 26px 26px 26px;
    border-radius: 10px;
    position: relative;
    @media (max-width: 690px) {
        margin-left: 0px !important;
    }
    @media (max-width: 820px) {
        background-color: #fcefe5;
    }
    @media (max-width: 480px) {
        margin-top: 20px;
        margin-left: -20px !important;
        margin-right: -20px !important;
        border-radius: 0px;
        border: none;
    }
`

export const DivMask = styled.img`
    display: none;
    @media (max-width: 480px) {
        display: block;
        width: 120%;
        margin-left: -26px;
        margin-top: -30px;
    }
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    overflow: hidden;
    align-items: center;
    padding-right: 20px;
    @media (max-width: 820px) {
        gap: 0px;
    }
    @media (max-width: 480px) {
        padding-right: 0px;
    }
    div {
        display: flex;
        align-items: center;
    }
`

export const HeaderArrowImg = styled.img`
    transform: rotate(-40deg);
    @media (max-width: 820px) {
        width: 20%;
    }
`

export const Heading = styled.div`
    position: relative;
    padding: 6px 0;
    ${font.size(25)};
    color: ${color.textBlack};
    ${font.black};
    white-space: nowrap;
    margin-top: -10px;
    @media (max-width: 820px) {
        ${font.size(20)};
    }
`

export const Anchor = styled.a`
    color: ${color.primary};
    ${font.size(13)};
    line-height: 15.6px;
    ${font.regular};
    text-decoration: underline;
    margin-top: -10px;
    cursor: pointer;
`

export const BodyCont = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    .body {
        position: relative;
        border: 1px solid #c5dce0;
        border-left: 8px solid #1b6775;
        border-radius: 4px;
        cursor: pointer;
        padding: 25px;
        background-color: white;
        .image {
            width: 30px;
            height: 30px;
            @media (max-width: 480px) {
                width: 35px;
                height: 35px;
            }
        }
        .title {
            ${font.size(16)};
            line-height: 19.2px;
            ${font.bold};
            color: #525659;
            width: 100%;
        }
        .badge {
            margin-left: auto;
            background-color: #f6f6f6;
            border: 1px solid #dbe5e7;
            border-radius: 15px;
            padding: 8px 22px;
            color: #1b6775;
            ${font.size(13)};
            line-height: 15.6px;
            ${font.bold};
            @media (max-width: 480px) {
                position: absolute;
                top: 0px;
                left: 25px;
                border-top: none;
                border-radius: 0px 0px 8px 8px;
                ${font.size(12)};
                line-height: 14.4px;
            }
        }
        .resource-badge {
            background-color: rgba(236, 118, 37, 0.24);
            border: 1px solid #ffd1b1;
            color: #ec7625;
        }
    }

    .body-resource {
        border: 1px solid #fadecb;
        border-left: 8px solid #ec7625;
    }

    .content {
        ${font.size(16)};
        line-height: 26px;
        ${font.medium};
        color: #525659;
        padding: 10px 0px 0px 24px;
        @media (max-width: 480px) {
            padding: 10px 0px 0px 0px;
        }
    }

    .heading-cont {
        display: flex;
        align-items: center;
        gap: 19px;
        @media (max-width: 480px) {
            margin-top: 15px;
            flex-direction: row-reverse;
        }
    }
`
