import { getStoredAuthToken } from './authToken'
import { UpperCaseFirstletter, formatUserPhoneNumber } from './formatter'

const decodeJWT = (token) => {
    try {
        const [header, payload, signature] = token.split('.')
        const decodedHeader = JSON.parse(atob(header))
        const decodedPayload = JSON.parse(atob(payload))

        return {
            header: decodedHeader,
            payload: decodedPayload,
        }
    } catch (error) {
        console.error(`Error decoding JWT: ${error}`)
        return null
    }
}

export const user = () => {
    const token = getStoredAuthToken()
    if (!token || token === ' ' || token === undefined || token === null)
        return {
            email: '',
        }
    const { payload } = decodeJWT(token)
    payload['firstName'] = UpperCaseFirstletter(payload.firstName)
    payload['lastName'] = UpperCaseFirstletter(payload.lastName)
    payload['phoneNumber'] = formatUserPhoneNumber(payload.phoneNumber)
    return payload
}

export const decaodeJWT = (token) => {
    const { payload } = decodeJWT(token)
    return payload
}
