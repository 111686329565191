import { useEffect } from 'react'
import { Button, ButtonContainer, Container, Div, InfoContainer, MemberImage, Span } from './Styles'
import DefaultMemberImage from 'shared/asstes/default_offerpad_member_img.svg'

const CashOfferTeamCard = ({ state }) => {
    const handleScheduleLinkOpen = (link) => window.open(link, '_blank')

    const memberData = state?.response?.homeOwnerConsultant
        ? {
              ...state?.response?.homeOwnerConsultant,
              imageUrl:
                  state?.response?.homeOwnerConsultan?.imageUrl === 'NULL'
                      ? null
                      : state?.response?.homeOwnerConsultan?.imageUrl,
              position: 'Offerpad Solutions Advisor',
          }
        : state?.response?.conciergeAgent
        ? {
              ...state?.response?.conciergeAgent,
              imageUrl:
                  state?.response?.conciergeAgent?.imageUrl === 'NULL'
                      ? null
                      : state?.response?.conciergeAgent?.imageUrl,
              position: 'Offerpad Solutions Expert',
          }
        : {
              firstName: 'Offerpad Customer Success',
              imageUrl:
                  state?.response?.offerpadContactEmail?.imageUrl === 'NULL'
                      ? null
                      : state?.response?.offerpadContactEmail?.imageUrl,
              email: state?.response?.offerpadContactEmail,
              phoneNumber: state?.response?.offerpadContactPhoneNumber,
          }

    return (
        <Container compare={state.compare}>
            <MemberImage>
                <img src={memberData?.imageUrl || DefaultMemberImage} alt="" />
            </MemberImage>
            <InfoContainer compare={state.compare}>
                <Div>
                    <Span bold>
                        {memberData?.firstName || ''} {memberData?.lastName || ''}
                    </Span>
                    <Span>{memberData?.position}</Span>
                </Div>
                <Div>
                    {memberData?.email && (
                        <Span teal>
                            <a href={`mailto:${memberData?.email}`}>{memberData?.email}</a>
                        </Span>
                    )}
                    {memberData?.phoneNumber && (
                        <Span bold>
                            <a href={`tel:${memberData?.phoneNumber}`}>
                                T: {memberData?.phoneNumber}
                            </a>
                        </Span>
                    )}
                </Div>
            </InfoContainer>
            <ButtonContainer compare={state.compare}>
                {memberData?.schedulingLink && (
                    <Button
                        onClick={() => handleScheduleLinkOpen(memberData?.schedulingLink)}
                        id="View_Offer_Purchase_Agreement_Sent_Schedule_Call"
                    >
                        Schedule a call
                    </Button>
                )}
                {/* <Button teal onClick={handleLiveChatClick} id="View_Offer_Purchase_Agreement_Sent_Chat" style={{display: none}}>
                    Live chat
                </Button> */}
            </ButtonContainer>
        </Container>
    )
}

export default CashOfferTeamCard
