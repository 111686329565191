import React, { useEffect, useState } from 'react'
import ArrowDown from './assets/arrow-down-resource.svg'
import {
    Cont,
    HeaderCont,
    Content,
    HeaderArrowImg,
    Heading,
    CardCont,
    HeadTop,
    ButtonCont,
    SubHeading,
    BodyCont,
} from './Styles'
import MenuButton from 'shared/components/MenuButton'
import { Collapsible, ScreenLoader } from 'shared/components'
import useResourceCenter from 'shared/hooks/resourceCenter'
import { getSpacedName } from 'shared/utils/formatter'
import PlusIcon from 'ResourceCenter/assets/plus_icon.svg'
import MinusIcon from 'ResourceCenter/assets/minus_icon.svg'
import parse from 'html-react-parser'

function ResourceCenterDetails() {
    const [resourcesData, setResourcesData] = useState({})
    const [selectedCategory, setSelectedCategory] = useState('')
    const [activeData, setActiveData] = useState({})

    const { loading, getResourceByCategory, fetch } = useResourceCenter()

    const getResources = async () => {
        const res = await fetch()
        setSelectedCategory('All')
        setActiveData(res.data)
        setResourcesData(res)
    }

    const handleClick = (category) => {
        const data = getResourceByCategory(resourcesData, category)
        setSelectedCategory(category)
        setActiveData(data)
    }

    useEffect(() => {
        getResources()
    }, [])

    if (loading) {
        return <ScreenLoader sL={loading} backgroundColor="rgba(0,0,0,.65)" />
    }

    return (
        <Cont>
            <HeaderCont>
                <HeadTop>
                    <HeaderArrowImg src={ArrowDown} alt="" />
                    <Heading>Helpful info</Heading>
                    <SubHeading>Learn more about Offerpad here</SubHeading>
                </HeadTop>
            </HeaderCont>

            <CardCont>
                <Content>
                    <ButtonCont>
                        {!loading &&
                            resourcesData?.categories?.map((category) => (
                                <MenuButton
                                    title={category.title}
                                    key={category.category}
                                    handleClick={() => handleClick(category.category)}
                                    isActive={selectedCategory === category.category}
                                />
                            ))}
                    </ButtonCont>
                    <hr />
                    {!loading &&
                        Object.keys(resourcesData).length > 0 &&
                        selectedCategory === 'All' &&
                        Object.keys(activeData).map((key) => (
                            <div key={key}>
                                <h1 className="heading">{getSpacedName(key)}</h1>
                                <BodyCont>
                                    {activeData[key]?.map((data, i) => (
                                        <Collapsible
                                            className={`body ${data.resourceType === 'ResourceArticle' &&
                                                'body-resource'
                                                }`}
                                            animationDelay={0.3 * i}
                                            key={Date.now() + i}
                                            renderLink={(collaps) => (
                                                <div
                                                    onClick={collaps.toggle}
                                                    className="heading-cont"
                                                >
                                                    <img
                                                        src={collaps.isOpen ? MinusIcon : PlusIcon}
                                                        alt=""
                                                        className="image"
                                                    />
                                                    <div className="title">{data.title}</div>
                                                    <div
                                                        className={`badge ${data.resourceType ===
                                                            'ResourceArticle' &&
                                                            'resource-badge'
                                                            }`}
                                                    >
                                                        {data.resourceType === 'ResourceArticle'
                                                            ? 'RESOURCE'
                                                            : data.resourceType.toUpperCase()}
                                                    </div>
                                                </div>
                                            )}
                                            renderContent={() => (
                                                <div className="content">
                                                    {parse(data.contents)}
                                                </div>
                                            )}
                                        />
                                    ))}
                                </BodyCont>
                            </div>
                        ))}

                    {!loading &&
                        Object.keys(resourcesData).length > 0 &&
                        selectedCategory !== 'All' && (
                            <div>
                                <h1 className="heading">{getSpacedName(selectedCategory)}</h1>
                                <BodyCont>
                                    {activeData.map((data, i) => (
                                        <Collapsible
                                            className={`body ${data.resourceType === 'ResourceArticle' &&
                                                'body-resource'
                                                }`}
                                            key={Date.now() + i}
                                            renderLink={(collaps) => (
                                                <div
                                                    onClick={collaps.toggle}
                                                    className="heading-cont"
                                                >
                                                    <img
                                                        src={collaps.isOpen ? MinusIcon : PlusIcon}
                                                        alt=""
                                                        className="image"
                                                    />
                                                    <div className="title">{data.title}</div>
                                                    <div
                                                        className={`badge ${data.resourceType ===
                                                            'ResourceArticle' &&
                                                            'resource-badge'
                                                            }`}
                                                    >
                                                        {data.resourceType === 'ResourceArticle'
                                                            ? 'RESOURCE'
                                                            : data.resourceType.toUpperCase()}
                                                    </div>
                                                </div>
                                            )}
                                            renderContent={() => (
                                                <div className="content">
                                                    {parse(data.contents)}
                                                </div>
                                            )}
                                        />
                                    ))}
                                </BodyCont>
                            </div>
                        )}
                </Content>
            </CardCont>
        </Cont>
    )
}

export default ResourceCenterDetails
