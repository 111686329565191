import {
    ButtonCont,
    ActionButton,
    Heading,
    ExtendedTagLine,
    OptionsCont,
    Option,
    ExtOptionCont,
    ReqWrapper,
    DynamicIcons,
    ExtRequestedLine,
    ExtendedCont,
    DividerModal,
} from 'OnDemandActions/Sell/ExtendedStay/shared/Styles'
import { useWizard } from 'react-use-wizard'
import TickIcon from 'OnDemandActions/Sell/ExtendedStay/shared/assets/Tick_icon.svg'
import { useEffect, useState } from 'react'
import useApi from 'shared/hooks/api'
import { Radio, ScreenLoader } from 'shared/components'

const Step2 = ({ data, setData }) => {
    const { previousStep, nextStep, goToStep } = useWizard()

    const [modify, setModify] = useState(null)

    const ExtendedStayPoint = `customer/v3/cash-offer-transactions/${localStorage.currentTransactionId}/change-extended-stay`

    const [{ isWorking }, sendExtendedData] = useApi.post(ExtendedStayPoint)

    const handleClick = async () => {
        if (!modify) {
            const res = await sendExtendedData({
                operation: 'Cancel',
                extendedStayDays: 0,
            })
            res?.status !== 503 && goToStep(2)
        }
        nextStep()
    }

    useEffect(() => {
        setData((prevData) => ({ ...prevData, modify }))
    }, [modify])

    return (
        <ExtendedCont width="888" height="466">
            <ScreenLoader sL={isWorking} />
            <Heading mt="0">Extended Stay</Heading>
            <ReqWrapper mt="11">
                <DynamicIcons width="17" height="17" src={TickIcon} />
                <ExtRequestedLine>Requested</ExtRequestedLine>
            </ReqWrapper>
            <DividerModal />
            <ExtendedTagLine>
                How would you like to change your Extended Stay benefit?
            </ExtendedTagLine>
            <ExtOptionCont mt="40">
                <OptionsCont>
                    <Radio
                        type="radio"
                        name="option"
                        value="true"
                        onChange={() => setModify(true)}
                    />
                    Modify # of Days
                </OptionsCont>
            </ExtOptionCont>
            <ExtOptionCont mt="33">
                <OptionsCont>
                    <Radio
                        type="radio"
                        name="option"
                        value="false"
                        onChange={() => setModify(false)}
                    />
                    Cancel, no longer needed
                </OptionsCont>
            </ExtOptionCont>
            <ButtonCont mt="100">
                <ActionButton id="fs_sell_extendedStay_pa_previous2" prev onClick={previousStep}>
                    Back
                </ActionButton>
                <ActionButton
                    continue
                    variant="primary"
                    id="fs_sell_extendedStay_pa_next2"
                    onClick={handleClick}
                    disabled={data.modify === null}
                >
                    Next
                </ActionButton>
            </ButtonCont>
        </ExtendedCont>
    )
}

export default Step2
