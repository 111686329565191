import { borderRadius, font } from 'shared/utils/styles'
import styled, { keyframes } from 'styled-components'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

export const Container = styled.div`
    padding-bottom: 50px;
`

export const TodoCard = styled.div`
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    padding: 30px ${(props) => props.borderRadius && borderRadius[props.borderRadius]};
    background-color: rgba(231, 240, 241, 0.5);
    @media (max-width: 480px) {
        padding: 0px;
        background-color: white;
        border: none;
    }
    .card-cont {
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        @media (max-width: 1000px) {
            grid-template-columns: 1fr;
        }
    }
`

export const Heading = styled.div`
    ${font.size(24)};
    ${font.black};
    color: #525659;
    @media (max-width: 650px) {
        color: black;
    }
`

export const Header = styled.div`
    ${font.bold};
    ${font.size(20)};
    line-height: 24px;
    color: #525659;
    margin-top: 46px;
`

export const CardCont = styled.div`
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    width: 100%;
    border: 2px solid #eaeaea;
    border-radius: 8px;
    margin-top: 31px;
    padding: 20px;
    display: grid;
    gap: 26px 39px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
    @media (max-width: 650px) {
        background-color: white;
        padding: 0;
        border: none;
    }
`
export const DefaultCont = styled.div`
    width: 100%;
    height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 17px;
    h1 {
        margin-bottom: 8px;
        font-size: 24px;
        font-family: MuseoSans-black;
        font-weight: 900;
        line-height: 29px;
        color: #525659;
        text-align: center;
    }
    @media (max-width: 480px) {
        background: #f0f8fb;
        border: 1px solid #dbe5e7;
        border-radius: 10px;
        padding: 10px 10px;
        margin-top: 25px;
        gap: 12px;
    }
`
