import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Toast, Alert, Fallback } from 'shared/components'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import useTracking from 'shared/hooks/useTracking'

useTracking().init(process.env.TAG_MANAGER_CONTAINER_ID)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <ErrorBoundary FallbackComponent={Fallback}>
        <BrowserRouter>
            <Toast />
            <Alert />
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </BrowserRouter>
    </ErrorBoundary>,
)
