import styled, { css } from 'styled-components'
import { mixin, font, color } from 'shared/utils/styles'

export const HeaderBtn = styled.div`
    height: 46px;
    width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
        props.arrowBackgroundColor &&
        css`
            background-color: ${props.arrowBackgroundColor};
        `}
    border: 1px solid #DBE5E7;
    border-radius: 50%;
    ${mixin.clickable};
    margin: 0 auto;
`

export const HeaderBtnIcon = styled.img``

export const Table = styled.table`
    width: 100%;

    ${(props) =>
        !!props.mb &&
        css`
            border-spacing: 0px 40px;
            @media (max-width: 480px) {
                border-spacing: 0px 23px;
            }
        `}
`

export const Thead = styled.thead``

export const TR = styled.tr``

export const TH = styled.th`
    ${font.medium};
    ${font.size(20)};
    line-height: 24px;
    color: #a7a7a7;
    text-align: center;
    @media (max-width: 480px) {
        ${font.size(16)};
        line-height: 19.2px;
    }
    ${(props) =>
        !!props.textCenter &&
        css`
            text-align: center;
        `}
    ${(props) =>
        !!props.header &&
        css`
            ${font.size(24)}
            ${font.bold};
            line-height: 28.8px;
            color: #50546a;
        `}
    ${(props) =>
        !!props.fw &&
        css`
            width: 70%;
        `}
`

export const Tbody = styled.tbody`
    /* &:before {
        font-size: 19.17px;
        content:"_";
        color:white;
        display:block;
    } */
`

export const TD = styled.td`
    ${font.medium};
    ${font.size(20)};
    line-height: 24.2px;
    text-align: center;
    height: 68px;
    border-radius: 5px;
    color: #3e454b;
    position: relative;
    height: 45px;
    width: 45px;
    @media (max-width: 480px) {
        ${font.size(16)};
        line-height: 19.36px;
        height: 40px;
        width: 40px;
    }
`

export const TDText = styled.p`
    background-color: white;
    line-height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 45px;
    width: 45px;
    cursor: pointer;
    ${(props) =>
        props.selected &&
        css`
            background-color: ${color.primary};
            color: white;
            ${(props) =>
                props.selectType === 'border' &&
                css`
                    border: 2px solid ${color.primary};
                    background-color: white;
                    color: initial;
                `}
            ${(props) =>
                props.selectedBackgroundColor !== null &&
                css`
                    background-color: ${props.selectedBackgroundColor};
                    border: none;
                    color: white;
                `}
        `}
    ${(props) =>
        props.hasEvent &&
        css`
            border: 1px solid ${color.primary};
        `}
        @media (max-width: 480px) {
        height: 40px;
        width: 40px;
    }
    ${(props) =>
        props.stayOnCurrent &&
        css`
            border: 2px solid ${color.primary};
        `}
    ${(props) =>
        props.selectAnother &&
        css`
            background-color: ${props.selectedBackgroundColor};
            border: none;
        `}
    ${(props) =>
        props.isDisabled &&
        css`
            cursor: initial;
            color: #a7a7a7;
        `}
`

export const Event = styled.p`
    ${font.medium};
    ${font.size(14)};
    line-height: 16.8px;
    color: ${color.primary};
    text-align: left;
    margin-top: 9px;
`
