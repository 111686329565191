import { useEffect, useState } from 'react'
import Button from '../Button'
import { AppBannerCont, Cont, CloseIcon, ModalContainer } from './Style'
import AppBannerImage from 'shared/asstes/appbanner.png'
import Modal from '../Modal'
import AndroidLiteImage from 'shared/asstes/androidlite.svg'
import iOSLiteImage from 'shared/asstes/ioslite.svg'
import ArrowDownCurvedImage from 'shared/asstes/arrow-down-curved.svg'
import QRImage from 'shared/asstes/qr-code.png'
import usePlatformDetection from 'shared/hooks/platformDetection'

const AppBanner = () => {
    const [open, setOpen] = useState(false)
    const { platform } = usePlatformDetection()

    const handleClick = (platform) => {
        switch (platform) {
            case 'Android':
                window.location.href = process.env.ANDROID_APP_STORE_LINK
                break
            case 'iOS':
                window.location.href = process.env.IOS_APP_STORE_LINK
                break
            default:
                window.location.href = process.env.ANDROID_APP_STORE_LINK
        }
    }

    const handleOpen = (open) => {
        switch (platform) {
            case 'Android':
                window.location.href = process.env.ANDROID_APP_STORE_LINK
                break
            case 'iOS':
                window.location.href = process.env.IOS_APP_STORE_LINK
                break
            default:
                open()
        }
    }

    useEffect(() => {
        const showBanner = localStorage.getItem('banner')
        if (!showBanner) {
            setTimeout(() => setOpen(true), 2000)
            localStorage.setItem('banner', true)
        }
    }, [])

    return (
        open && (
            <AppBannerCont>
                <Cont>
                    <img src={AppBannerImage} alt="" />
                    <div>
                        <span>
                            All the features,<span> in our convenient app.</span>
                        </span>
                        <Modal
                            backgroundColor="white"
                            width="600px"
                            floting
                            clickOutside={false}
                            renderLink={({ open }) => (
                                <Button variant="primary" onClick={() => handleOpen(open)}>
                                    Download our app
                                </Button>
                            )}
                            renderContent={() => (
                                <ModalContainer>
                                    <h2 className="heading">Getting our App is easy!</h2>
                                    <div className="app-store-container">
                                        <img
                                            src={iOSLiteImage}
                                            alt=""
                                            onClick={() => handleClick('iOS')}
                                        />
                                        <img
                                            src={AndroidLiteImage}
                                            alt=""
                                            onClick={() => handleClick('Android')}
                                        />
                                    </div>

                                    <span className="sub-text">
                                        Scan the QR code to download our app
                                    </span>
                                    <div className="qr-container">
                                        <img src={QRImage} alt="" className="qr" />
                                        <img src={ArrowDownCurvedImage} alt="" className="arrow" />
                                    </div>
                                </ModalContainer>
                            )}
                        />
                    </div>
                    <CloseIcon type="close" variant="secondary" onClick={() => setOpen(false)} />
                </Cont>
            </AppBannerCont>
        )
    )
}

export default AppBanner
