import {
    getStoredAuthToken,
    removeStoredAuthToken,
    removeStoredTokenExpiryTime,
    storeAuthToken,
    storeTokenExpiryTime,
} from './authToken'
import { getStoredRefreshToken, removeStoredRefreshToken, storeRefreshToken } from './refreshToken'
import alert from './alert'
import api from './api'
import { decaodeJWT } from './jwt'
import { setUser } from 'features/userSlice'

const makeLogout = async () => {
    const token = localStorage.getItem('FCM_T')
    if (token) {
        try {
            await api.delete(`customer/v3/remove-device`, {
                deviceToken: token,
                deviceType: 'Web',
            })
        } catch (e) {
            console.log(e)
        }
    }

    delete window.currentTransaction
    delete window.transactionInfo
    delete window.currentEvents
    delete window.currentTeams
    delete window.currentTodos
    delete window.offerExpired
    removeStoredAuthToken()
    removeStoredTokenExpiryTime()
    removeStoredRefreshToken()
    localStorage.removeItem('currentTransactionId')
    localStorage.removeItem('confetti')
    localStorage.removeItem('banner')
    localStorage.removeItem('notificationState')
    localStorage.removeItem('FCM_T')

    window.location.href = '/auth/login'
}

export const logout = (confirm = true) => {
    if (confirm) {
        alert.confirm(
            'Sign out your account',
            `Before you go, are you sure you want to sign out?`,
            '',
            (confirm) => {
                if (confirm) {
                    makeLogout()
                }
            },
        )
    } else {
        makeLogout()
    }
}

export const refreshToken = async () => {
    const header = { 'Content-Type': 'application/x-www-form-urlencoded' }
    const url = 'api/customer-auth/v2/token'
    try {
        const res = await api.post(
            url,
            {
                client_id: 'OPTwirl',
                grant_type: 'refresh_token',
                refresh_token: getStoredRefreshToken(),
            },
            header,
        )
        storeAuthToken(res.access_token)
        !!res.refresh_token && storeRefreshToken(res.refresh_token)
        !!res.expires_in && storeTokenExpiryTime(res.expires_in)
        return {
            success: true,
            error: null,
            authToken: res.access_token,
        }
    } catch (error) {
        return {
            success: false,
            error,
            authToken: null,
        }
    }
}

export const initializeUserFromToken = (dispatch) => {
    const authToken = getStoredAuthToken()
    if (authToken) {
        try {
            const decodedToken = decaodeJWT(authToken)
            dispatch(setUser(decodedToken))
        } catch (error) {
            console.error('Error decoding authentication token:', error)
        }
    }
}
