import React, { useState, useEffect } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import pubsub from 'sweet-pubsub'
import { uniqueId } from 'lodash'
import parse from 'html-react-parser'

import SuccessIcon from 'shared/asstes/successIcon.svg'
import InfoIcon from 'shared/asstes/infoIcon.svg'
import WarningIcon from 'shared/asstes/warningIcon.svg'
import DangerIcon from 'shared/asstes/dangerIcon.svg'
import NotificationIcon from 'shared/asstes/notification-icon.png'

import {
    Container,
    StyledToast,
    IconContainer,
    IconImg,
    TextContainer,
    CloseIcon,
    Message,
} from './Styles'

const Toast = () => {
    const [toasts, setToasts] = useState([])

    useEffect(() => {
        const addToast = ({ type = 'success', message, duration = 5 }) => {
            const id = uniqueId('toast-')
            setToasts([{ id, type, message }])
            if (duration) {
                setTimeout(() => removeToast(), duration * 1000)
            }
        }
        pubsub.on('toast', addToast)
        return () => {
            pubsub.off('toast', addToast)
        }
    }, [])

    const removeToast = () => {
        setToasts([])
    }

    const TostIcon = {
        success: SuccessIcon,
        info: InfoIcon,
        warning: WarningIcon,
        danger: DangerIcon,
        notification: NotificationIcon,
    }

    return (
        <Container>
            <TransitionGroup>
                {toasts.map((toast) => (
                    <CSSTransition key={toast.id} classNames="offerpad-toast" timeout={200}>
                        <StyledToast key={toast.id} type={toast.type}>
                            <CloseIcon
                                type="close"
                                grey={toast.type === 'notification'}
                                onClick={() => removeToast(toast.id)}
                            />
                            <IconContainer type={toast.type}>
                                <IconImg src={TostIcon[toast.type]} alt="" />
                            </IconContainer>
                            <TextContainer type={toast.type}>
                                {toast.message && <Message>{parse(toast.message)}</Message>}
                            </TextContainer>
                        </StyledToast>
                    </CSSTransition>
                ))}
            </TransitionGroup>
        </Container>
    )
}

export default Toast
