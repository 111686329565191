import pubsub from 'sweet-pubsub'

const show = (toast) => pubsub.emit('toast', toast)

const success = (message) => show({ message })

const info = (message) => show({ type: 'info', message })

const warning = (message) => show({ type: 'warning', message })

const error = (message) => show({ type: 'danger', message })

const notification = (message) => show({ type: 'notification', message })

export default { show, info, error, success, warning, notification }
