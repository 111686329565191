import { useSelector } from 'react-redux'
import { BuyDashboardModal, DefaultDashboard } from 'shared/components'
import dashboard from 'shared/constants/dashboard'

const Dashboard = () => {
    const user = useSelector((state) => state.user)
    const { buy } = dashboard

    const isAgent = user?.agent?.toLowerCase() === 'true'

    const dataToShow = isAgent ? buy.agent : buy.customer

    return (
        <DefaultDashboard
            {...dataToShow}
            renderCustomSecondaryButton={() => !isAgent && <BuyDashboardModal />}
        />
    )
}

export default Dashboard
