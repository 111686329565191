import styled from 'styled-components'
import { font } from 'shared/utils/styles'

export const Audio = styled.audio``

export const AudioPlayBtn = styled.button``

export const ContNotification = styled.div`
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 25px;
    img {
        width: 100px;
        @media (max-width: 480px) {
            width: 90px;
        }
    }

    h3 {
        ${font.size(26)};
        ${font.black};
        line-height: 28px;
        color: #000;
        @media (max-width: 480px) {
            ${font.size(22)};
        }
    }

    p {
        ${font.size(20)};
        ${font.regular};
        line-height: 32px;
        color: #000;
        @media (max-width: 480px) {
            ${font.size(18)};
            line-height: 24px;
        }
    }

    .button-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    button {
        padding: 12px 25px;
        border-radius: 5px;
        border: 1px solid #808080;
        width: 153px;
        max-width: 173px;
        cursor: pointer;
        ${font.size(18)};
        ${font.medium};
        @media (max-width: 480px) {
            width: 120px;
            ${font.size(14)};
        }
    }

    .button-primary {
        color: white;
        background-color: #ec7625;
        border: none;
    }

    .button-white {
        color: #808080;
        background-color: white;
    }
`
