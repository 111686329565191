import styled, { css } from 'styled-components'
import { font, color } from 'shared/utils/styles'
import { Link } from 'react-router-dom'

export const Cont = styled.div`
    width: 100%;
    height: fit-content;
    background-color: #f7fcfe;
    border-radius: 10px;
    border: 1px solid #dbe5e7;
    padding: 23px 25px;
    display: none;
    position: relative;
    ${(props) =>
        !!props.mobile && props.mobile
            ? css`
                  display: none;
                  @media (max-width: 480px) {
                      display: block;
                      margin-top: 0px !important;
                      margin-bottom: 30px !important;
                  }
              `
            : css`
                  @media (min-width: 481px) {
                      display: block;
                  }
              `}

    @media (min-width: 481px) and (max-width: 820px) {
        margin-left: 30px;
        width: auto;
        height: 403px;
    }
    @media (max-width: 690px) {
        margin-left: 0px;
        margin-top: 30px;
    }
    @media (max-width: 480px) {
        border: none;
        background-color: white;
        padding: 0px;
    }
    @media (min-width: 1251px) {
        height: 100%;
    }
    .splide {
        @media (max-width: 480px) {
            height: 152px;
        }
        .splide__pagination {
            display: flex;
            align-items: center;
            gap: 10px;
            li {
                .splide__pagination__page {
                    width: 9px;
                    height: 9px;
                    background-color: #d9d9d9;
                    opacity: 1;
                    margin: 0px;
                }
                .is-active {
                    width: 27px;
                    height: 9px;
                    background-color: #ec7625;
                    border-radius: 117px;
                    transform: scale(1.1);
                }
            }
        }
    }
    .splide__slide {
        ${(props) =>
            !!props.slides &&
            props.slides > 1 &&
            css`
                @media (max-width: 480px) {
                    width: 80% !important;
                }
            `}
    }
`

export const HeadCont = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 3px;
`

export const Heading = styled.div`
    ${font.black};
    ${font.size(25)};
    line-height: 30px;
    color: #000000;
    @media (max-width: 820px) {
        ${font.size(20)};
    }
`

export const Anchorlink = styled(Link)`
    ${font.size(13)};
    line-height: 15.6px;
    ${font.regular};
    color: ${color.primary};
    text-decoration: underline;
`

export const Card = styled.div`
    margin-top: 14px;
    border-radius: 8px;
    background-color: white;
    padding: 10px 11px;
    display: flex;
    align-items: center;
    gap: 15px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    @media (max-width: 480px) {
        width: 100% !important;
        margin-bottom: 10px;
    }
    @media (min-width: 481px) {
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 7px;
    }
    ${(props) =>
        !!props.modal &&
        css`
            @media (max-width: 480px) {
                width: inherit !important;
                margin-left: 10px;
                margin-right: 10px;
            }
        `}
    ${(props) =>
        !!props.onWeb &&
        css`
            display: none !important;
        `}
        ${(props) =>
        !!props.first &&
        props.first &&
        css`
            margin-top: 4px;
        `}
`

export const DateCont = styled.div`
    height: 74px;
    width: 64px;
    background-color: rgba(236, 118, 37, 0.33);
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 11px;
    ${(props) =>
        !!props.green &&
        css`
            background-color: #ccdbdd;
        `}
    &:after {
        content: ${(props) => `'${props.date}'`};
        ${font.size(36)};
        line-height: 43.2px;
        ${font.black};
        color: #ec7625;
        margin-top: -5px;
        ${(props) =>
            !!props.green &&
            css`
                color: #1b6775;
            `}
    }
    &:before {
        content: ${(props) => `'${props.month}'`};
        ${font.size(16)};
        line-height: 19.2px;
        ${font.black};
        color: #ec7625;
        margin-top: 5px;
        ${(props) =>
            !!props.green &&
            css`
                color: #1b6775;
            `}
    }
`

export const CardCont = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
    ${font.black};
    ${font.size(16)};
    line-height: 19.2px;
    color: #525659;
    width: 100%;
    @media (max-width: 480px) {
        width: 71%;
    }
    div {
        ${font.black};
        ${font.size(16)};
        line-height: 19.2px;
        color: #525659;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    span {
        ${font.medium};
        ${font.size(14)};
        line-height: 20px;
        .dash {
            font-family: 'Courier New', Courier, monospace;
            color: #ec7625;
            margin-left: 3px;
            margin-right: 3px;
        }
        .green {
            color: #1b6775;
        }
    }

    @media (max-width: 820px) {
        ${font.size(14)};
    }
`
export const ModalCont = styled.div`
    height: 100%;
    max-height: 80vh;
    margin: 90px 30px 30px 30px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    @media (max-width: 480px) {
        margin: 90px 10px 30px 10px;
        max-height: calc(100vh - 90px);
    }
`

export const ModalHeading = styled.div`
    position: absolute;
    top: 25px;
    left: 20px;
    ${font.size(27)};
    ${font.black};
    line-height: 29px;
`

export const DefaultDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 28px;
    @media (max-width: 1250px) {
        padding: 60px 0px;
    }
`
export const DefaultImg = styled.img`
    width: 71.6px;
    @media (max-width: 480px) {
        display: none;
    }
`

export const DefaultTitle = styled.div`
    text-align: center;
    ${font.bold};
    ${font.size(20)};
    line-height: 24px;
    color: #525659;
    @media (max-width: 1250px) {
        ${font.size(18)};
        line-height: 21.6px;
    }
`

export const DefaultTagLine = styled.div`
    text-align: center;
    ${font.medium};
    ${font.size(14)};
    line-height: 22px;
    color: #868686;
    margin-top: 14px;
    @media (max-width: 1250px) {
        margin-top: 9px;
    }
`
