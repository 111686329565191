import { Cont } from './Styles'
import Truck from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/truck-free-local.svg'
import RoundCheck from 'OnDemandActions/Sell/FreeLocalMove/shared/assets/check-free-local-round.svg'
import useTeams from 'shared/hooks/sell/teams'
import useTodos from 'shared/hooks/sell/todos'

const Step2 = ({ close, isTrue }) => {
    const { getTeamByRole } = useTeams()
    const successManager = getTeamByRole('SuccessManager')

    const { fetch } = useTodos()

    return (
        <Cont>
            <img src={!isTrue ? Truck : RoundCheck} alt="" />
            {!isTrue ? <h1>Request Submitted</h1> : <h1>Request Received</h1>}
            {!isTrue ? (
                <p>
                    Your request to add free local move has been received. Please hang tight! Your
                    Success Manager, {successManager?.firstName + ' ' + successManager?.lastName}{' '}
                    will notify you upon approval.
                </p>
            ) : (
                <p>
                    Your request to cancel free local move has been received and submitted to your
                    Success Manager, {successManager?.firstName + ' ' + successManager?.lastName}{' '}
                    will notify you upon approval!
                </p>
            )}
            <div className="btn-cont">
                <button
                    id="fs_sell_freeLocalMove_submit"
                    className="next"
                    onClick={async () => {
                        close()
                        await fetch()
                    }}
                >
                    Got It!
                </button>
            </div>
        </Cont>
    )
}

export default Step2
