import {
    DefaultOutlineBox,
    HeadingImage,
    HeaderText,
    DescriptionText,
    ConnectWithUs,
    DefaultFooterDiv,
    DefaultFooterImg,
    DefaultFooterAnchor,
    DefaultFooter,
    MainDiv,
    YourTeamsPic,
    LineDivider,
} from './Styles'

import LogoImage from 'shared/asstes/logo.svg'
import PhoneLogo from './assets/phone_icon.svg'
import EmailLogo from './assets/email_icon.svg'
import Atlanta from './assets/market_atlanta.png'
import Austin from './assets/market_austin.png'
import Birmingham from './assets/market_birmingham.png'
import Charlotte from './assets/market_charlotte.png'
import Colorado from './assets/market_coloradosprings.png'
import Columbia from './assets/market_columbia.png'
import Dallas from './assets/market_dallas.png'
import Denver from './assets/market_denver.png'
import Ftmyers from './assets/market_ftmyers.png'
import Houston from './assets/market_houston.png'
import Indy from './assets/market_indy.png'
import Jacksonville from './assets/market_jacksonville.png'
import Kansascity from './assets/market_kansascity.png'
import lasvegas from './assets/market_lasvegas.png'
import Nashville from './assets/market_nashville.png'
import Orlando from './assets/market_orlando.png'
import Phoenix from './assets/market_phoenix.png'
import Raleigh from './assets/market_raleigh.png'
import Sanantonio from './assets/market_sanantonio.png'
import Stlouis from './assets/market_stlouis.png'
import Tampa from './assets/market_tampa.png'
import { useSelector } from 'react-redux'

const Default = () => {
    const loading = useSelector((state) => state.loading.loading)

    if (loading) {
        return null
    }

    const transaction = window.currentTransaction

    const MarketImage = () => {
        if (transaction?.property?.market === 'Atlanta') {
            return <YourTeamsPic src={Atlanta} alt="" />
        } else if (transaction?.property?.market === 'Austin') {
            return <YourTeamsPic src={Austin} alt="" />
        } else if (transaction?.property?.market === 'Birmingham') {
            return <YourTeamsPic src={Birmingham} alt="" />
        } else if (transaction?.property?.market === 'SouthCharlotte') {
            return <YourTeamsPic src={Charlotte} alt="" />
        } else if (transaction?.property?.market === 'Colorado') {
            return <YourTeamsPic src={Colorado} alt="" />
        } else if (transaction?.property?.market === 'Columbia') {
            return <YourTeamsPic src={Columbia} alt="" />
        } else if (transaction?.property?.market === 'Dallas') {
            return <YourTeamsPic src={Dallas} alt="" />
        } else if (transaction?.property?.market === 'Denver') {
            return <YourTeamsPic src={Denver} alt="" />
        } else if (transaction?.property?.market === 'FortMyers') {
            return <YourTeamsPic src={Ftmyers} alt="" />
        } else if (transaction?.property?.market === 'Houston') {
            return <YourTeamsPic src={Houston} alt="" />
        } else if (transaction?.property?.market === 'Indianapolis') {
            return <YourTeamsPic src={Indy} alt="" />
        } else if (transaction?.property?.market === 'Jacksonville') {
            return <YourTeamsPic src={Jacksonville} alt="" />
        } else if (transaction?.property?.market === 'KansasCity') {
            return <YourTeamsPic src={Kansascity} alt="" />
        } else if (transaction?.property?.market === 'lasvegas') {
            return <YourTeamsPic src={lasvegas} alt="" />
        } else if (transaction?.property?.market === 'Nashville') {
            return <YourTeamsPic src={Nashville} alt="" />
        } else if (transaction?.property?.market === 'Orlando') {
            return <YourTeamsPic src={Orlando} alt="" />
        } else if (transaction?.property?.market === 'Phoenix') {
            return <YourTeamsPic src={Phoenix} alt="" />
        } else if (transaction?.property?.market === 'Raleigh') {
            return <YourTeamsPic src={Raleigh} alt="" />
        } else if (transaction?.property?.market === 'SanAntonio') {
            return <YourTeamsPic src={Sanantonio} alt="" />
        } else if (transaction?.property?.market === 'StLouis') {
            return <YourTeamsPic src={Stlouis} alt="" />
        } else if (transaction?.property?.market === 'Tampa') {
            return <YourTeamsPic src={Tampa} alt="" />
        } else {
            return <YourTeamsPic src={Phoenix} alt="" />
        }
    }

    return (
        <DefaultOutlineBox>
            <MainDiv>
                <HeadingImage src={LogoImage} alt="" />
                <HeaderText>{transaction?.property?.market} Solutions Team </HeaderText>
                <LineDivider />
                <DescriptionText>
                    When we send your offer, Offerpad will assign you a dedicated Solution Advisor.
                    In the meantime, feel free to connect with any of our friendly and helpful
                    advisors serving {transaction?.property?.market}.
                </DescriptionText>
                <ConnectWithUs>Connect with us</ConnectWithUs>
                <DefaultFooter>
                    <DefaultFooterDiv>
                        <DefaultFooterImg src={PhoneLogo} alt="" />
                        <DefaultFooterAnchor href="tel:+1 844-388-4539">
                            +1 844-388-4539
                        </DefaultFooterAnchor>
                    </DefaultFooterDiv>
                    <DefaultFooterDiv>
                        <DefaultFooterImg src={EmailLogo} alt="" />
                        <DefaultFooterAnchor href="mailto:info@offerpad.com">
                            info@offerpad.com
                        </DefaultFooterAnchor>
                    </DefaultFooterDiv>
                </DefaultFooter>
            </MainDiv>
            {MarketImage()}
        </DefaultOutlineBox>
    )
}

export default Default
