import {
    ActionButton,
    ButtonGroup,
    Container,
    DContainer,
    Heading,
    Paragraph,
    ParagraphContainer,
} from '../styles'

const Step5 = ({ close }) => {
    return (
        <Container>
            <DContainer>
                <Heading>Preferences updated</Heading>
                <ParagraphContainer>
                    <Paragraph>
                        We have removed this action item from your account. You’re all set.
                    </Paragraph>
                </ParagraphContainer>
            </DContainer>
            <ButtonGroup>
                <ActionButton variant="primary" size="lg" onClick={close}>
                    Close
                </ActionButton>
            </ButtonGroup>
        </Container>
    )
}

export default Step5
