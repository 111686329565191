import styled, { css } from 'styled-components'
import Button from '../Button'
import { color, font, mixin } from 'shared/utils/styles'

export const ModalCont = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 25px;
    max-height: calc(100vh - 110px);
    @media (max-width: 480px) {
        height: 100%;
        max-height: 100%;
    }
`

export const ModalHeading = styled.div`
    ${font.black};
    ${font.size(27)};
    color: #363c4f;
`

export const ModalBody = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 10px;
    ${(props) =>
        !!props.defaultScreen &&
        css`
            grid-template-columns: 1fr;
            height: 100%;
        `}
    @media (max-width: 740px) {
        grid-template-columns: 1fr 1fr 1fr;
        ${(props) =>
            !!props.defaultScreen &&
            css`
                grid-template-columns: 1fr;
            `}
    }
    @media (max-width: 480px) {
        grid-template-columns: 1fr 1fr;
        ${(props) =>
            !!props.defaultScreen &&
            css`
                grid-template-columns: 1fr;
            `}
    }
    .default-screen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 30px 0px;
        p {
            margin-top: 10px;
            ${font.bold};
            ${font.size(16)};
            color: #525658;
            text-align: center;
        }
    }
`

export const CardModalCont = styled.div`
    position: relative;
    .card-photos {
        width: 100%;
        height: 210px;
        border-radius: 10px;
        border: none;
        object-fit: cover;
        gap: 10px;
        ${(props) =>
            !!props.disabled &&
            props.disabled &&
            css`
                opacity: 0.5;
            `}
        @media (max-width: 480px) {
            width: 100%;
        }
    }
    .action-buttons {
        display: flex;
        gap: 11px;
    }
`

export const CardModal = styled.div`
    width: 185px;
    height: 210px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: end;
    justify-content: end;
    border-radius: 10px;
    padding: 15px;
    ${(props) =>
        !!props.backgroundImage &&
        css`
            background-image: url(${props.backgroundImage});
            transition: 0.3s ease-in-out;
        `}
    gap:10px;
    ${(props) =>
        !!props.disabled &&
        props.disabled &&
        css`
            opacity: 0.5;
        `}
    .card-photos {
        width: 185px;
        height: 210px;
        border-radius: 10px;
        border: none;
        object-fit: cover;
        gap: 10px;
        ${(props) =>
            !!props.disabled &&
            props.disabled &&
            css`
                opacity: 0.5;
            `}
        @media (max-width: 480px) {
            width: 100%;
        }
    }
`

export const CheckImgIcon = styled.img`
    position: absolute;
    top: -10px;
    right: -10px;
    height: 40px;
`

export const CardActionButton = styled(Button)`
    transition: 0.2s ease-in-out;
    ${(props) =>
        !!props.primary &&
        css`
            background-color: ${color.primary};
        `}
    &:hover {
        transform: scale(1.1);
        background-color: #f4f5f7 !important;
        ${(props) =>
            !!props.primary &&
            `
        background-color:${color.primary} !important;
    `}
    }
`

export const ReviewButton = styled(Button)`
    margin: 0 0 0 auto;
    border-radius: 3px;
    border: 1px solid #1b6775;
    background-color: #f6f6f6;
    color: #1b6775;
    padding: 10px 31px;
    ${font.medium};
    ${font.size(13)};
    line-height: 15.6px;
    width: 168px;
    &:hover {
        background-color: #f6f6f6 !important;
    }
    @media (max-width: 650px) {
        margin: 0px;
        width: 180px;
        height: 47px;
    }
`

export const ActionButton = styled(Button)`
    height: fit-content;
    padding: 10px 17px;
    border-radius: 3px;
    ${font.size(13)};
    ${font.medium};
    margin: 0px 0px 0px auto;
    width: 168px;
    text-overflow: ellipsis;
    @media (max-width: 650px) {
        width: 168px;
        height: 47px;
        margin: 0px;
        text-overflow: ellipsis;
    }
    ${(props) =>
        props.review &&
        props.review === true &&
        css`
            margin: 0 0 0 auto;
            border-radius: 3px;
            border: 1px solid #1b6775;
            background-color: #f6f6f6;
            color: #1b6775;
            padding: 10px 31px;
            ${font.medium};
            font-size: 13px;
            line-height: 15.6px;
            width: 168px;
            text-overflow: ellipsis;
            &:hover {
                background: #f6f6f6 !important;
            }
        `}
`

export const CardActionButtonImg = styled.img``

export const ModalFooter = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px;
    gap: 20px;
    @media (max-width: 480px) {
        margin-top: auto;
        padding: 0px;
    }
`

export const InputWrapper = styled.div`
    width: 133px;
    height: 61px;
    position: relative;
    overflow: hidden;
    ${mixin.clickable};
    background-color: white;
    border: 1px solid #bdbdbd;
    border-radius: 4.8px;
    margin-top: 16px;
    @media (max-width: 480px) {
        margin-top: 0px;
        width: 100%;
    }
`

export const InputText = styled.span`
    color: #525659;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

export const Input = styled.input`
    width: 100%;
    height: 100%;
    ${mixin.clickable};
    opacity: 0;
`

export const ActionUploadButton = styled(Button)`
    width: 190px;
    margin-top: 15px;
    ${font.size(14)};
    padding: 20px 34px;
    @media (max-width: 480px) {
        margin-top: 0px;
        width: 100%;
    }
`
