import {
    SurveyCont,
    ActionButton,
    Heading,
    ExtShowinmid,
} from 'OnDemandActions/Sell/ExtendedStay/shared/Styles'
import Cup from '../../../shared/assets/cup-es.svg'
import useTeams from 'shared/hooks/sell/teams'
import { Bellhops } from 'shared/components'

const Step4 = ({ close = () => {}, data }) => {
    const { getTeamByRole } = useTeams()
    const { fetch } = useTeams()
    const successManager = getTeamByRole('SuccessManager')

    return (
        <SurveyCont height="90%">
            <ExtShowinmid>
                <div>
                    <center>
                        <img src={Cup} alt="" />
                    </center>
                    <center>
                        <Heading>Extended Stay</Heading>
                    </center>
                    {!data.modify && (
                        <p>
                            Your request to <span>cancel your Extended Stay</span> has been
                            submitted and will be reviewed by your Success Manager,{' '}
                            {successManager?.firstName} {successManager?.lastName}.
                            <br />
                            <br />
                            Once approved, you will need to sign an addendum for the cancellation.
                        </p>
                    )}
                    {data.modify && data.operation === 'modify' && (
                        <p>
                            Your request to <span>{data.operation} your Extended Stay</span> to{' '}
                            {data.noOfDays} day(s), ending {data.dateTodisplay} has been submitted
                            and will be reviewed by your Success Manager,{' '}
                            {successManager?.firstName} {successManager?.lastName}.
                            <br />
                            <br />
                            Hang tight, once approved, you will receive a modified addendum to sign.
                        </p>
                    )}
                    {data.modify && data.operation === 'add' && (
                        <p>
                            Your request to <span>add the Extended Stay</span> benefit of{' '}
                            {data.noOfDays} {data.noOfDays > 1 ? 'days' : 'day'} with a new move-out
                            date of {data.dateTodisplay} has been submitted and will be reviewed by
                            your Success Manager, {successManager?.firstName}{' '}
                            {successManager?.lastName}.
                            <br />
                            <br />
                            Hang tight, once approved, you will receive a modified addendum to sign.
                        </p>
                    )}
                </div>

                <Bellhops />
            </ExtShowinmid>
            <br />
            <br />
            <center>
                <ActionButton
                    id="fs_sell_extendedStay_pa_submit"
                    continue
                    variant="primary"
                    onClick={async () => {
                        close()
                        await fetch()
                    }}
                >
                    Got it!
                </ActionButton>
            </center>
        </SurveyCont>
    )
}

export default Step4
