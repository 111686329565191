import styled, { css } from 'styled-components'
import { color, font, fontSize, padding, size, borderRadius, mixin } from 'shared/utils/styles'

export const StyledInput = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    transition: background 0.1s;
    border: 1px solid #979797;
    height: 53px;
    border-radius: 5px;
    &:focus-within {
        background: #fff;
        ${(props) =>
            !props.invalid &&
            css`
                border: 1px solid ${color.primary};
                box-shadow: 0 0 0 1px ${color.primary};
            `}
    }
    ${(props) =>
        props.invalid &&
        css`
            &,
            &:focus {
                border: 1px solid ${color.danger};
                box-shadow: 0 0 0 1px ${color.danger};
            }
        `}

    ${(props) =>
        props.type === 'password' &&
        css`
            display: flex;
            align-items: center;
            padding-right: 10px;
        `}

        &:has(:disabled) {
        background-color: #f7fcfe;
        cursor: not-allowed;
    }
`

export const InputElement = styled.input`
    width: 80%;
    height: 100%;
    border-radius: 3px;
    line-height: 1.5;
    font-weight: 500;
    background-clip: padding-box;
    border: none;
    border-radius: 5px;
    color: #525659;
    background: ${color.backgroundWhite};
    ${font.regular}
    font-size: 17px !important;
    margin-left: 25px;
    ${(props) => inputSize[props.size]}
    ${(props) => borderRadius[props.size]}
    
    &::placeholder {
        margin-left: 15px;
        color: #979797;
        padding-left: ${(props) => (props.iconPresent ? '30px' : '10px')};
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
        -webkit-text-fill-color: #525659;
        background-color: #fff;
    }

    &:disabled {
        background-color: #f7fcfe;
        cursor: not-allowed;
        color: rgba(82, 86, 89, 0.7);
    }
`

const inputSize = {
    sm: css`
        ${fontSize.sm};
        ${padding.sm};
        min-height: calc(1.5em + (0.5rem + 2px));
    `,
    normal: css`
        ${fontSize.normal};
        ${padding.normal};
    `,
    lg: css`
        ${fontSize.lg};
        ${padding.lg};
        min-height: calc(1.5em + (1rem + 2px));
    `,
}

export const StyledIcon = styled.img`
    color: ${color.backgroundLight};
    ${(props) => size[props.iconsize]};
    ${mixin.clickable}
    height: 10px;
    width: 10px;
    transform: scale(2);
    right: 15px;
    position: absolute;
`

export const InputIcon = styled.img`
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    filter: invert(63%) sepia(5%) saturate(11%) hue-rotate(98deg) brightness(95%) contrast(86%);
`

export const PasswordStrengthBox = styled.div`
    position: absolute;
    background-color: white;
    padding: 23px 37px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    width: 100%;
    z-index: 999;
    h2 {
        color: #595959;
        ${font.size(15)};
        line-height: 19px;
        ${font.bold};
    }
    .strength-bar-cont {
        display: flex;
        gap: 4px;
        margin-top: 18px;
        align-items: center;
        span {
            margin-left: 18px;
            color: #767676;
            ${font.size(12)};
            line-height: 15px;
            ${font.medium};
        }
        img {
            margin-left: 8px;
        }
    }
    .strength-types {
        display: flex;
        flex-direction: column;
        gap: 11px;
        margin-top: 19px;
        div {
            display: flex;
            align-items: center;
            gap: 11px;
            span {
                color: #575757;
                ${font.size(14)};
                line-height: 6px;
                ${font.medium};
            }
        }
    }
`

export const Bar = styled.div`
    height: 3px;
    width: 33px;
    ${(props) =>
        !!props.color &&
        css`
            background-color: ${props.color};
        `}
`
