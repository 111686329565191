import { Calendar, Modal, ScreenLoader } from 'shared/components'
import {
    Cont,
    CalendarCont,
    CalendarDetailCard,
    HeaderCont,
    Heading,
    Divider,
    CardCont,
    Card,
    ImgCont,
    Image,
    HeadingCont,
    CardHeading,
    Tagline,
    ActionButton,
    ActionButtonImg,
} from './Styles'
import CalendarIcon from './assets/calendarIcon.svg'
import Question from './assets/questionMark.svg'
import Reload from './assets/reload.svg'
import Bin from './assets/calbin.svg'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Default from './default'
import moment from 'moment'
import CodDateChange from 'OnDemandActions/Sell/CodDateChange'
import useEvents from 'shared/hooks/sell/events'
import { isEmpty } from 'lodash'
import parse from 'html-react-parser'

const CalendarPage = () => {
    const { events, loading } = useSelector((state) => state.events)
    const transcation = useSelector((state) => state.singleTransaction.transaction)
    const { fetch } = useEvents()
    const [currentEvent, setCurrentEvent] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [eventsArray, setEventsArray] = useState(null)

    const { search } = useLocation()

    const prams = new URLSearchParams(search)
    const queryDate = prams.get('date')

    const handleEventClick = (date) => {
        setSelectedDate(date)
        const selectedDateEvent = Object.keys(events).map((event) => {
            if (event === date) {
                return events[event]
            } else {
                return []
            }
        })
        let sDe = selectedDateEvent.filter((e) => e.length != 0)[0]
        sDe = sDe === undefined ? null : sDe
        setCurrentEvent(sDe)
    }

    const makeEventArray = (e) => {
        const temp = []
        Object.keys(e).forEach((event) => {
            temp.push(event)
        })
        setEventsArray(temp)
        if (queryDate !== null) {
            setSelectedDate(queryDate)
            handleEventClick(queryDate)
        } else {
            const date = moment().format('MM/DD/YYYY')
            setSelectedDate(date)
            handleEventClick(date)
        }
    }

    const initEvents = async () => {
        let e = events
        if (isEmpty(Object.keys(e))) {
            e = await fetch()
        }
        makeEventArray(e)
    }

    useEffect(() => {
        if (!isEmpty(localStorage.currentTransactionId)) {
            initEvents()
        }
    }, [events])

    return (
        <Cont>
            {loading && (
                <ScreenLoader size={100} full={false} sL={loading} backgroundColor="white" />
            )}
            <CalendarCont>
                <Calendar
                    onEventClick={handleEventClick}
                    events={eventsArray}
                    dateToShow={queryDate}
                />
            </CalendarCont>
            <div className="card-wrap">
                {currentEvent !== null ? (
                    currentEvent.map((event, i) => (
                        <CalendarDetailCard key={Date.now() + i} animationDelay={0.2 * i}>
                            <HeaderCont>
                                <Heading>{event.title}</Heading>
                            </HeaderCont>
                            <Divider />
                            <CardCont>
                                {event.description !== '' && (
                                    <Card>
                                        <ImgCont>
                                            <Image src={Question} alt="" />
                                        </ImgCont>

                                        <HeadingCont>
                                            <CardHeading>Details</CardHeading>
                                            <Tagline>{parse(event.description)}</Tagline>
                                        </HeadingCont>
                                    </Card>
                                )}
                                <Card>
                                    <ImgCont>
                                        <Image src={CalendarIcon} alt="" />
                                    </ImgCont>
                                    <HeadingCont>
                                        <CardHeading>When:</CardHeading>
                                        <Tagline>
                                            {event.calenderFormatedDate}{' '}
                                            {event.time !== '' &&
                                                event.eventIdentifier !== 'WalkthroughEvent' &&
                                                `(${event.timeZone})`}
                                        </Tagline>
                                    </HeadingCont>
                                </Card>
                            </CardCont>
                            <div className="action-btns">
                                {event.cancelEventUrl && (
                                    <ActionButton
                                        id="fs_sell_calendar_cancel"
                                        href={event.cancelEventUrl}
                                        target="_blank"
                                    >
                                        <ActionButtonImg ab src={Bin} alt="" />
                                        Cancel
                                    </ActionButton>
                                )}
                                {event.rescheduleEventUrl && (
                                    <ActionButton
                                        id="fs_sell_calendar_reschedule"
                                        href={event.rescheduleEventUrl}
                                        target="_blank"
                                    >
                                        <ActionButtonImg ab src={Reload} alt="" />
                                        Reschedule
                                    </ActionButton>
                                )}
                                {event.eventIdentifier === 'ClosingDayEvent' && (
                                    <Modal
                                        testid="Modal:CoeDate"
                                        withCloseIcon={true}
                                        clickOutside={false}
                                        variableWidth={true}
                                        width="auto"
                                        backgroundColor="white"
                                        renderLink={(modal) => (
                                            <ActionButton
                                                id="fs_sell_calendar_request_dateChange"
                                                target="_blank"
                                                onClick={modal.open}
                                            >
                                                <ActionButtonImg ab src={Reload} alt="" />
                                                Request COE Date Change
                                            </ActionButton>
                                        )}
                                        renderContent={(modal) => (
                                            <CodDateChange
                                                close={modal.close}
                                                extendedStay={transcation?.extendedStay}
                                            />
                                        )}
                                    />
                                )}
                            </div>
                        </CalendarDetailCard>
                    ))
                ) : (
                    <Default date={selectedDate} />
                )}
            </div>
        </Cont>
    )
}

export default CalendarPage
