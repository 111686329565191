import Notepad from './assets/notepad-todos.svg'
import { DefaultCont } from './Styles'

const Default = () => {
    return (
        <DefaultCont>
            <img src={Notepad} alt="" />
            <h1>You don’t have any pending action items.</h1>
        </DefaultCont>
    )
}

export default Default
