import PropTypes from 'prop-types'
import ZoomIcon from 'shared/asstes/zoom.svg'
import Camera from 'shared/asstes/camera-todos.svg'
import { Modal, Lightbox, ImageWithLoader } from 'shared/components'
import {
    ModalCont,
    ModalHeading,
    ModalBody,
    CardModalCont,
    CardActionButton,
    CardActionButtonImg,
    ActionButton,
    ReviewButton,
} from './Styles'
import { useState } from 'react'

const defaultProps = {
    review: false,
    overviewQuickLink: false,
    title: 'Your Photos',
    btnText: 'Upload Photos',
    renderCustomLink: () => {},
}

const propTypes = {
    overviewQuickLink: PropTypes.bool,
    title: PropTypes.string,
    btnText: PropTypes.string,
    renderCustomLink: PropTypes.func,
}

const PhotosViewer = ({ id, review, overviewQuickLink, title, btnText, renderCustomLink }) => {
    const [images, setImages] = useState([])

    const initImages = async () => {
        setImages([])
        const transaction = window.currentTransaction
        if (transaction?.images?.length) {
            const temp = []
            transaction.images.forEach((image) => {
                temp.push({
                    id: image.imageId,
                    backgroundImage: image.imageUrl,
                    fileName: '',
                    fileType: '',
                    uploaded: true,
                    uploadError: false,
                })
            })
            setImages(temp)
        }
    }

    const renderImage = (image) => {
        return (
            <Lightbox
                image={image.backgroundImage || image}
                renderLink={({ open }) => (
                    <ImageWithLoader
                        image={image.backgroundImage || image}
                        className="card-photos"
                        renderOnTop={() => (
                            <div className="action-buttons">
                                <CardActionButton primary onClick={open}>
                                    <CardActionButtonImg src={ZoomIcon} alt="" />
                                </CardActionButton>
                            </div>
                        )}
                    />
                )}
            />
        )
    }

    const renderDefaultScreen = () => {
        return (
            <div className="default-screen">
                <img src={Camera} alt="" />
                <p>No photos uploaded.</p>
            </div>
        )
    }

    return (
        <Modal
            testid="modal:PhotosViewer"
            width="875px"
            backgroundColor="white"
            withCloseIcon={true}
            variableWidth={true}
            onOpenEvent={initImages}
            renderLink={(modal) => {
                return review ? (
                    <ReviewButton id={id} onClick={modal.open}>
                        {btnText}
                    </ReviewButton>
                ) : overviewQuickLink ? (
                    renderCustomLink(modal)
                ) : (
                    <ActionButton id={id} variant="primary" onClick={modal.open}>
                        {btnText}
                    </ActionButton>
                )
            }}
            renderContent={(modal) => (
                <ModalCont>
                    <ModalHeading>
                        {title} {images?.length > 0 && `(${images.length})`}
                    </ModalHeading>
                    <ModalBody id="card-body" defaultScreen={!images?.length}>
                        {images?.length
                            ? images.map((image, i) => (
                                  <CardModalCont key={Date.now() + i}>
                                      {renderImage(image)}
                                  </CardModalCont>
                              ))
                            : renderDefaultScreen()}
                    </ModalBody>
                </ModalCont>
            )}
        />
    )
}

PhotosViewer.defaultProps = defaultProps
PhotosViewer.propTypes = propTypes

export default PhotosViewer
