import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useScrollTo from 'shared/hooks/scrollTo'
import { getStoredAuthToken, getStoredTokenExpiryTime } from 'shared/utils/authToken'
import { isEmpty } from 'lodash'
import protectedUrls from 'shared/constants/protectedUrls'
import { isMatchingURL } from 'shared/utils/formatter'
import alert from 'shared/utils/alert'
import InfoIcon from 'shared/asstes/info.svg'
import useMobileWebView from 'shared/hooks/mobileWebview'
import { convertBase64ToString } from 'shared/utils/converter'
import { decaodeJWT } from 'shared/utils/jwt'
import useTracking from 'shared/hooks/useTracking'
import { logout, refreshToken } from 'shared/utils/auth'
import { useDispatch } from 'react-redux'
import { setUser } from 'features/userSlice'

const RouteMiddleware = ({ children }) => {
    const [isReady, setIsReady] = useState(false)

    const { pathname, search } = useLocation()
    const { scrollToTop } = useScrollTo()
    const navigate = useNavigate()
    const authToken = getStoredAuthToken()
    const { deletePrams } = useMobileWebView()
    const dispatch = useDispatch()

    const pathnameComparessionArray = [
        '/auth/login',
        '/auth/register',
        '/auth/forgot-password',
        'auth/reset-password',
    ]

    const handleCommonFunction = () => {
        const transactionId = new URLSearchParams(search).get('transactionId')
        if (!isEmpty(transactionId)) {
            localStorage.setItem('currentTransactionId', transactionId)
        }
        const errorMessage = new URLSearchParams(search).get('error')
        setTimeout(() => {
            if (errorMessage) {
                const msg = convertBase64ToString(errorMessage)
                alert.info('Alert!', msg, InfoIcon)
                deletePrams('error')
            }
        }, 1000)
    }

    const handleLoggedIn = () => {
        setIsReady(false)

        const user = decaodeJWT(authToken)
        useTracking().identifyUser(user.sub)

        const redirectURL = new URLSearchParams(location.search).get('redirect')

        redirectURL && navigate(redirectURL, { replace: true })

        pathname === '/' && navigate('/sell/dashboard')

        setIsReady(true)
    }

    const handleNotLoggedIn = () => {
        setIsReady(false)

        useTracking().anonymize()

        if (isMatchingURL(pathname, protectedUrls)) {
            let navigateURL = '/auth/login'

            !pathnameComparessionArray.includes(pathname) &&
                (navigateURL = `${navigateURL}?redirect=${pathname}`)

            navigate(navigateURL)
        }

        pathname === '/' && navigate('/auth/login')

        setIsReady(true)
    }

    const updateToken = async () => {
        const { authToken } = await refreshToken(dispatch)
        if (authToken) {
            const decodedToken = decaodeJWT(authToken)
            dispatch(setUser(decodedToken))
        }
    }

    const checkTokenExpiry = () => {
        const tokenExpiresAt = getStoredTokenExpiryTime() * 1000
        const currentTime = new Date().getTime()
        const timeRemaining = tokenExpiresAt - currentTime
        if (timeRemaining > 0) {
            setTimeout(() => {
                alert.info(
                    'Alert!',
                    'Your session has expired. Please log in again.',
                    InfoIcon,
                    () => logout(false),
                )
            }, timeRemaining)
        }
    }

    useEffect(() => {
        scrollToTop()
        handleCommonFunction()
        if (authToken) {
            const decodedToken = decaodeJWT(authToken)
            if (decodedToken?.emailConfirmed?.toLowerCase() === 'false') {
                logout(false)
            } else {
                handleLoggedIn()
            }
        } else {
            handleNotLoggedIn()
        }
    }, [pathname])

    useEffect(() => {
        authToken && (updateToken(), checkTokenExpiry())
    }, [])

    return isReady ? children : null
}

export default RouteMiddleware
