import { Wizard } from 'react-use-wizard'
import Modal from '../Modal'
import { Wrapper } from './styles'
import Step1 from './Step1'
import useMergeState from 'shared/hooks/mergeState'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import api from 'shared/utils/api'
import toast from 'shared/utils/toast'
import { Fragment } from 'react'
import ScreenLoader from '../ScreenLoader'
import useTeams from 'shared/hooks/sell/teams'

const INITIAL_VALUE = {
    updateEmail: null,
    email: null,
    validEmail: null,
    btnDisable: true,
    loading: false,
}

const SecondarySellerEmailUpdateModal = ({
    todo = {},
    renderLink = {},
    onMarkedTodoCompleteEvent,
}) => {
    const { getTeamByRole } = useTeams()
    const successManager = getTeamByRole('SuccessManager')
    const [state, mergeState] = useMergeState(INITIAL_VALUE)
    const currentTransaction = window.currentTransaction

    const seller = currentTransaction?.sellers?.find(
        (seller) => seller.id === todo?.metadata?.sellerId,
    )

    const handleSubmit = async () => {
        const payload = {
            ...seller,
            email: state.email === null ? seller?.email : state.email,
        }

        try {
            mergeState({ loading: true })
            await api.post(
                `customer/v3/cash-offer-transactions/${currentTransaction.transactionId}/update-secondary-seller`,
                payload,
            )
            return true
        } catch (e) {
            if (e?.error?.message) {
                toast.error(e?.error?.message)
            } else {
                toast.error('Something went wrong. Unable to update the seconday seller email.')
            }
            mergeState({ loading: false })
            return false
        } finally {
            mergeState({ loading: false })
        }
    }

    return (
        <Modal
            testid="modal:SecondarySellerEmailUpdateModal"
            withCloseIcon={true}
            width="600px"
            variableWidth={true}
            clickOutside={false}
            backgroundColor="white"
            onCloseEvent={() => {
                mergeState(INITIAL_VALUE)
                onMarkedTodoCompleteEvent()
            }}
            renderLink={({ open }) => renderLink(open)}
            renderContent={({ close }) => (
                <Fragment>
                    <ScreenLoader size={100} sL={state.loading} />
                    <Wizard wrapper={<Wrapper />}>
                        <Step1 state={state} mergeState={mergeState} todo={todo} seller={seller} />
                        <Step2 state={state} todo={todo} handleSubmit={handleSubmit} />
                        <Step3 state={state} close={close} todo={todo} />
                        <Step4
                            state={state}
                            todo={todo}
                            handleSubmit={handleSubmit}
                            successManager={successManager}
                        />
                        <Step5 state={state} close={close} />
                    </Wizard>
                </Fragment>
            )}
        />
    )
}

export default SecondarySellerEmailUpdateModal
