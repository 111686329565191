import { Link } from 'react-router-dom'
import { font } from 'shared/utils/styles'
import styled, { css } from 'styled-components'
import { useLocation } from 'react-router-dom'

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 85px;
    box-sizing: border-box;
    @media screen and (min-width: 851px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @media screen and (max-width: 850px) {
        position: absolute;
        bottom: 0px;
        width: 100%;
        gap: 0px;
        justify-content: space-between;
        margin-top: 50px;
        background: #f7fcfe;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
        margin-left: -20px;
        padding: 18px 20px;
    }
`

export const Menu = styled(Link)`
    color: #4a5e88;
    font-size: 18px;
    line-height: normal;
    ${font.bold};
    transition: all 0.3s ease-in-out;
    ${(props) =>
        !!props.to &&
        !!props.active &&
        props.active.includes(useLocation().pathname.split('/')[1]) &&
        css`
            color: #ec7625;
            text-decoration-line: underline;
            text-decoration-color: #ec7625;
            text-decoration-thickness: 3px;
            text-underline-offset: 8px;
            @media screen and (max-width: 850px) {
                text-underline-offset: 20px;
            }
        `}
`
