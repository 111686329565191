import styled from 'styled-components'

export const ImageCont = styled.div`
    position: relative;
    .on-top {
        position: absolute;
        bottom: 15px;
        right: 15px;
    }
`
