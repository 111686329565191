import { font } from 'shared/utils/styles'
import styled, { keyframes } from 'styled-components'

const opacity = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const CardContainer = styled.div`
    border-radius: 10px 10px 4px 4px;
    overflow: hidden;
    height: 100vh;
    max-height: 472px;
    position: relative;
    border: 1px solid #d4d4d4;
    animation: ${opacity} 750ms both;
`

export const CardImage = styled.img`
    height: 100%;
    width: 100%;
    max-height: 251px;
    object-fit: cover;
`

export const CardBadge = styled.div`
    position: absolute;
    top: 30px;
    left: 24px;
    border-radius: 22px;
    background: #e7f1ff;
    padding: 8px 15px;
    color: #1b6775;
    font-size: 12px;
    ${font.bold};
    line-height: normal;
`

export const CardBody = styled.div`
    padding: 33px 31px;
`

export const CardHeading = styled.div`
    color: #231f20;
    font-size: 22px;
    ${font.bold};
    line-height: normal;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const CardSubHeading = styled.div`
    color: #61657e;
    font-size: 14px;
    ${font.bold};
    line-height: normal;
`

export const ActionButton = styled.button`
    width: 100%;
    height: 55px;
    background-color: #1b6775;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    font-size: 18px;
    ${font.bold};
    line-height: normal;
    margin: 24px 0px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        opacity: 0.85;
    }
`

export const FooterText = styled.div`
    color: #898989;
    font-size: 12px;
    ${font.regular};
    line-height: normal;
    text-align: right;
`
