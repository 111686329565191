import React, { useEffect, useState } from 'react'
import useResourceCenter from 'shared/hooks/resourceCenter'
import parse from 'html-react-parser'
import { Cont, ResourceCont } from './Styles'
import { useParams, useNavigate } from 'react-router-dom'
import { convertBase64ToJSON } from 'shared/utils/converter'
import toast from 'shared/utils/toast'

function ResourceRenderer() {
    const [resourceContent, setResourceContent] = useState(null)
    let params = useParams()
    const data = convertBase64ToJSON(params.data)
    const resourceId = data && parseInt(data?.id)
    const isViewOffer = 'isViewOffer' in data
    const navigate = useNavigate()

    const { fetchResources } = useResourceCenter()

    const getResources = async () => {
        const res = await fetchResources(parseInt(resourceId, 10))
        setResourceContent(res.contents)
    }

    function modifyString(string) {
        let titleCompany = `The title company or attorney's office`
        let extStay = `3`
        let homeMarketingConsultantPhone = ``
        let homeMarketingConsultantName = ``
        let homeMarketingConsultantEmail = ``
        let inspectionDate

        if (data) {
            if (data.titleCompany) titleCompany = data.titleCompany
            if (data.extStay) extStay = data.extStay
            if (data.homeMarketingConsultantPhone)
                homeMarketingConsultantPhone = data.homeMarketingConsultantPhone
            if (data.homeMarketingConsultantEmail)
                homeMarketingConsultantEmail = data.homeMarketingConsultantEmail
            if (data.homeMarketingConsultantName)
                homeMarketingConsultantName = data.homeMarketingConsultantName
            if (data.inspectionDate) inspectionDate = data.inspectionDate
        }

        let modifiedString = string
            .replaceAll('assets/coe-info-pointer-free.png', '/images/coe-info-pointer-free.png')
            .replace('assets/calender_logo.svg', '/images/Calender_logo.svg')
            .replace('assets/cup-es.svg', '/images/cup-es.svg')
            .replace('assets/truck-free-local.svg', '/images/truck-free-local.svg')
            .replace('assets/location-pin-free-local.svg', '/images/location-pin-free-local.svg')
            .replace('assets/5m-free-local.svg', '/images/5m-free-local.svg')
            .replaceAll('[ExtStay]', extStay)
            .replaceAll('[titleCompanyName]', titleCompany)
            .replaceAll('{3 days}', `${extStay} days`)
            .replaceAll('[extendedStayDays]', extStay)
            .replace(
                'and an independent third-pa.',
                'and an independent third-party inspector will be at the appointment.',
            )
            .replace(
                '<a href="[path-to-pdf-file]">',
                `<a href='https://${process.env.MARKETING_URL}/public/file/offerpad-home-inspection-guide'>`,
            )

        if (inspectionDate) {
            modifiedString = modifiedString.replace(
                '[inspectionDate] at [inspectionTime] ([inspectionTimeZone])',
                inspectionDate,
            )
        } else {
            modifiedString = modifiedString.replace(
                'Your inspection has been scheduled for [inspectionDate] at [inspectionTime] ([inspectionTimeZone]).',
                'Your inspection has been scheduled.',
            )
        }

        if (
            homeMarketingConsultantName !== null &&
            homeMarketingConsultantName !== undefined &&
            homeMarketingConsultantName !== ''
        ) {
            modifiedString = modifiedString.replace(
                '[HomeMarketingConsultantName]',
                homeMarketingConsultantName,
            )
            if (
                homeMarketingConsultantPhone !== null &&
                homeMarketingConsultantPhone !== undefined &&
                homeMarketingConsultantPhone !== ''
            ) {
                modifiedString = modifiedString.replace(
                    '[HomeMarketingConsultantPhone]',
                    `<a href="tel:${homeMarketingConsultantPhone}">${homeMarketingConsultantPhone}</a>`,
                )
            } else if (
                homeMarketingConsultantEmail !== null &&
                homeMarketingConsultantEmail !== undefined &&
                homeMarketingConsultantEmail !== ''
            ) {
                modifiedString = modifiedString.replace(
                    '[HomeMarketingConsultantPhone]',
                    `<a href={"mailto:${homeMarketingConsultantEmail}"}>${homeMarketingConsultantEmail}</a>`,
                )
            } else {
                modifiedString.replace('[HomeMarketingConsultantPhone]', '')
            }
        } else {
            modifiedString = modifiedString.replace('[HomeMarketingConsultantName]', '')
            modifiedString = modifiedString.replace(
                ', [HomeMarketingConsultantName] at [HomeMarketingConsultantPhone]',
                '',
            )
        }
        return modifiedString
    }

    useEffect(() => {
        if (!resourceId) {
            navigate('/auth/login')
            toast.error('Requested page not found.')
            return
        }
        getResources()
    }, [resourceId, navigate])

    useEffect(() => {
        if (resourceContent && resourceId) {
            let modifiedString = modifyString(resourceContent)
            setResourceContent(modifiedString)
            console.log(resourceContent)
        }
    }, [resourceContent, resourceId])

    return (
        <Cont resourceId={resourceId}>
            <ResourceCont isViewOffer={isViewOffer}>
                {resourceContent && parse(resourceContent)}
            </ResourceCont>
        </Cont>
    )
}

export default ResourceRenderer
