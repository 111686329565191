import {
    ModalCont,
    ModalMenuCont,
    ModalHead,
    DividerModal,
    ModalLink,
    ModalFooter,
    ModalFooterBody,
    Container,
} from './Styles'
import { user } from 'shared/utils/jwt'
import { getInitials } from 'shared/utils/formatter'
import { logout } from 'shared/utils/auth'
import IOSLITEIMAGE from 'shared/asstes/ioslite.svg'
import ANDROIDLITEIMAGE from 'shared/asstes/androidlite.svg'
import HandBurgerIcon from 'shared/asstes/handburgerIcon.svg'
import Modal from '../Modal'
import NavbarRoundMenu from '../NavbarRoundMenu'
import { useSelector } from 'react-redux'

const MobileNavigation = () => {
    const user = useSelector((state) => state.user)

    const handleClick = (type) => {
        switch (type) {
            case 'Android':
                window.location.href = process.env.ANDROID_APP_STORE_LINK
                break
            case 'iOS':
                window.location.href = process.env.IOS_APP_STORE_LINK
                break
        }
    }

    return (
        <Modal
            variant="aside"
            width="100%"
            variableWidth={true}
            backgroundColor="white"
            renderLink={(modal) => (
                <Container>
                    <NavbarRoundMenu icon={HandBurgerIcon} onClick={modal.open} />
                </Container>
            )}
            renderContent={(modal) => (
                <ModalCont>
                    <div className="container">
                        <ModalHead>
                            <div className="initials">
                                {getInitials(user?.firstName, user?.lastName)}
                            </div>
                            <span>
                                Hi {user?.firstName} {user?.lastName}
                                <span className="email">Here you can manage all settings</span>
                            </span>
                        </ModalHead>
                        <ModalMenuCont>
                            {/* <DividerModal />
                            <ModalLink to="/resource-center" onClick={modal.close}>
                                Helpful Info
                            </ModalLink> */}
                            <DividerModal />
                            <ModalLink to="/message-center" onClick={modal.close}>
                                Messages
                            </ModalLink>
                            <DividerModal />
                            <ModalLink badge="" to="/tech-support" onClick={modal.close}>
                                Tech Support
                            </ModalLink>
                            <DividerModal />
                            <ModalLink to="/profile" onClick={modal.close}>
                                My Profile
                            </ModalLink>
                            <DividerModal />
                            <ModalLink to="/change-password" onClick={modal.close}>
                                Change Password
                            </ModalLink>
                            <DividerModal />
                            <ModalLink
                                to="#"
                                logout="true"
                                onClick={(e) => {
                                    logout(), e.preventDefault()
                                }}
                            >
                                Logout
                            </ModalLink>
                        </ModalMenuCont>
                    </div>
                    <ModalFooter>
                        Download our app
                        <ModalFooterBody>
                            <img
                                src={IOSLITEIMAGE}
                                alt=""
                                id="fs_app_download_ios"
                                onClick={() => handleClick('iOS')}
                            />
                            <img
                                src={ANDROIDLITEIMAGE}
                                alt=""
                                id="fs_app_download_android"
                                onClick={() => handleClick('Android')}
                            />
                        </ModalFooterBody>
                    </ModalFooter>
                </ModalCont>
            )}
        />
    )
}

export default MobileNavigation
