import { useWizard } from 'react-use-wizard'
import {
    ActionButton,
    ButtonGroup,
    Container,
    DContainer,
    Heading,
    List,
    Paragraph,
    ParagraphContainer,
    UnorderList,
} from '../styles'
import { capitalizeFirstLetterEveryWord } from 'shared/utils/formatter'
import { useSelector } from 'react-redux'

const Step4 = ({ todo = {}, handleSubmit, successManager }) => {
    const { goToStep } = useWizard()
    const user = useSelector((state) => state.user)

    const handleClickSubmit = async () => {
        const res = await handleSubmit()
        console.log(res)
        if (res) {
            goToStep(4)
        }
    }

    return (
        <Container>
            <DContainer>
                <Heading>Got it!</Heading>
                <ParagraphContainer>
                    <Paragraph>
                        You are electing to use the same email address for both{' '}
                        <b>
                            {capitalizeFirstLetterEveryWord(
                                todo?.metadata?.sellerName?.toLowerCase(),
                            )}
                        </b>{' '}
                        and{' '}
                        <b>
                            {user?.firstName} {user?.lastName}
                        </b>
                        .
                    </Paragraph>
                </ParagraphContainer>
                <UnorderList>
                    <List>
                        Please make sure to pay attention to any signature requests, both parties
                        will need to sign in this portal account
                    </List>
                    <List>
                        If you decide to change email addresses in the future, please connect with
                        your Success Manager
                        {successManager?.length !== 0 &&
                            ', ' +
                                capitalizeFirstLetterEveryWord(successManager?.firstName) +
                                ' ' +
                                capitalizeFirstLetterEveryWord(successManager?.lastName)}
                        .
                    </List>
                </UnorderList>
            </DContainer>
            <ButtonGroup>
                <ActionButton variant="empty" size="lg" onClick={() => goToStep(0)}>
                    Back
                </ActionButton>
                <ActionButton variant="primary" size="lg" onClick={handleClickSubmit}>
                    Submit
                </ActionButton>
            </ButtonGroup>
        </Container>
    )
}

export default Step4
