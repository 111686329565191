import { Image, Modal } from 'shared/components'
import { useSelector } from 'react-redux'
import {
    PropertySelectionButton,
    StyledButtonArrow,
    ModalCont,
    ModalContHeading,
    ModalContFooterButton,
    ModalBodyCont,
    ModalBodyData,
    ModalBodyDataHeading,
    ModalFooter,
    ModalBodyDataStatus,
    ModalContFooter,
    PropertyViewCreated,
} from './Styles'
import ArrowDownIcon from './assets/arrow-down.svg'
import useOverview from 'shared/hooks/sell/overview'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { capitalizeFirstLetterEveryWord } from 'shared/utils/formatter'

const SelectProperty = () => {
    const myTransactions = useSelector((state) => state.myTransaction.myTransactions)
    const { init } = useOverview()
    const navigate = useNavigate()

    const handleClick = async (data, modal) => {
        modal.close()
        if (data.transactionId !== localStorage.currentTransactionId) {
            localStorage.currentTransactionId = data?.transactionId
            localStorage.setItem('currentTransactionId', data?.transactionId)
            window.confetti = false
            navigate('/sell/overview')
            await init(true)
        }
    }

    return (
        <Modal
            testid="modal:tracking"
            withCloseIcon={true}
            backgroundColor="white"
            width="600px"
            variableWidth={true}
            renderLink={(modal) => (
                <PropertySelectionButton onClick={modal.open} className="select-property">
                    Select Property <StyledButtonArrow src={ArrowDownIcon} alt="" />
                </PropertySelectionButton>
            )}
            renderContent={(modal) => (
                <ModalCont>
                    <ModalContHeading>Select your property</ModalContHeading>
                    <div className="m-body">
                        {myTransactions &&
                            myTransactions?.map((t, i) => (
                                <ModalBodyCont
                                    borderTop={i !== 0 ? true : false}
                                    key={Date.now() + i}
                                >
                                    <Image
                                        classname="modal-img"
                                        src={t?.property?.streetViewUrl}
                                        alt="Property Image"
                                    />
                                    <ModalBodyData>
                                        <ModalBodyDataHeading onClick={() => handleClick(t, modal)}>
                                            {capitalizeFirstLetterEveryWord(
                                                t?.property?.streetAddress,
                                            )}{' '}
                                            <br />
                                            <div>
                                                {t?.property?.city}, {t?.property?.state}{' '}
                                                {t?.property?.zip}
                                            </div>
                                        </ModalBodyDataHeading>
                                        <ModalBodyDataStatus>
                                            {t?.displayToCustomer}
                                        </ModalBodyDataStatus>
                                        <PropertyViewCreated>
                                            Created:
                                            {moment(t?.offerRequestedDateTime).format('MM/DD/YYYY')}
                                        </PropertyViewCreated>
                                    </ModalBodyData>
                                </ModalBodyCont>
                            ))}
                    </div>
                    <ModalFooter>
                        <ModalContFooter>Have another property?</ModalContFooter>
                        <ModalContFooterButton
                            href={process.env.REQUEST_PROPERTY_URL}
                            target="_blank"
                        >
                            Request another offer
                        </ModalContFooterButton>
                    </ModalFooter>
                </ModalCont>
            )}
        />
    )
}

export default SelectProperty
