import styled, { css, keyframes } from 'styled-components'

import { color, mixin, zIndexValues, font } from 'shared/utils/styles'
import Icon from 'shared/components/Icon'

const opacityAndScale = keyframes`
    from {
        opacity: 0;
        transform: scale(0.8)
    }
    to {
        opacity: 1;
        transform: scale(1)
    }
`

const slideLeft = keyframes`
    from{
        left:-1000px;
    }
    to{
        left:0px;
    }
`

export const ScrollOverlay = styled.div`
    z-index: ${zIndexValues.modal};
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    ${mixin.scrollableY}
`

export const ClickableOverlay = styled.div`
    min-height: 100%;
    background: rgba(9, 30, 66, 0.54);
    ${(props) => clickOverlayStyles[props.variant]}
    @media (max-width: 480px) {
        background-color: transparent;
    }
    ${(props) =>
        props.floting === true &&
        css`
            @media (max-width: 480px) {
                background: rgba(9, 30, 66, 0.54);
            }
        `}
`

const clickOverlayStyles = {
    center: css`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px;
        @media (max-width: 480px) {
            padding: 0px;
        }
    `,
    aside: '',
}

export const StyledModal = styled.div`
    display: inline-block;
    position: relative;
    width: auto;
    background: ${(props) => props.backgroundColor};
    animation-duration: 0.3s;
    overflow: hidden;
    ${(props) =>
        props.variant === 'center'
            ? css`
                  animation-name: ${opacityAndScale};
                  animation-fill-mode: both;
              `
            : css`
                  animation-name: ${slideLeft};
                  animation-timing-function: ease-in-out;
              `}
    ${(props) => modalStyles[props.variant]}
    ${(props) =>
        !!props.variableWidth &&
        props.variableWidth &&
        css`
            @media (max-width: 480px) {
                width: 100% !important;
                max-width: 100%;
            }
        `}
    ${(props) =>
        props.floting === true &&
        css`
            @media (max-width: 480px) {
                height: auto;
                border-radius: 10px;
                position: static;
                margin: 0 auto;
            }
        `}
`

const modalStyles = {
    center: css`
        width: ${(props) => props.width};
        max-width: calc(100vw - 40px);
        vertical-align: middle;
        border-radius: 10px;
        ${mixin.boxShadowMedium};

        @media (max-width: 480px) {
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            border-radius: 0px;
        }
    `,
    aside: css`
        min-height: 100vh;
        max-width: ${(props) => props.width};
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
        @media (min-width: 480px) and (max-width: 820px) {
            width: 100%;
        }
    `,
}

export const CloseIcon = styled(Icon)`
    position: absolute;
    font-size: 18px;
    font-weight: 600;
    color: #6f6c78;
    transition: all 0.1s;
    padding: 19px 20px;
    background-color: #f7f7f7;
    height: 55px;
    width: 55px;
    border-radius: 50% !important;
    border: 1px solid #dbe5e7;
    ${mixin.clickable}
    ${(props) => closeIconStyles[props.variant]}
    z-index: 1;
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        line-height: 0%;
        ${font.size(20)}
    }
`

const closeIconStyles = {
    center: css`
        top: 20px;
        right: 20px;
        padding: 3px 5px 0px 5px;
        border-radius: 4px;
        &:hover {
            background: ${color.backgroundLight};
        }
        @media (max-width: 480px) {
            right: 13px;
        }
    `,
    aside: css`
        top: 23px;
        right: 23px;
        width: 50px;
        height: 50px;
        padding-top: 10px;
        border-radius: 3px;
        text-align: center;
        background: #fff;
        border: 1px solid ${color.borderLightest};
        ${mixin.boxShadowMedium};
        &:hover {
            color: ${color.primary};
        }
    `,
}
