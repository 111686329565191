import styled from 'styled-components'
import { Button } from 'shared/components'
import { font } from 'shared/utils/styles'

export const StyledDiv = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 1001;
`

export const Body = styled.iframe`
    width: 100%;
    height: 100vh;
`
