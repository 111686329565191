import PropType from 'prop-types'
import { color } from 'shared/utils/styles'

const propTypes = {
    className: PropType.string,
    size: PropType.number,
    color: PropType.string,
}

const defaultProps = {
    className: undefined,
    size: 32,
    color: color.textWhite,
}

const Spinner = ({ className, size, color, ...props }) => {
    return (
        <span className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                preserveAspectRatio="xMidYMid"
                style={{
                    margin: 'auto',
                    background: 'transparent',
                    display: 'block',
                    shapeRendering: 'auto',
                }}
                viewBox="0 0 100 100"
                {...props}
            >
                <rect width={6} height={12} x={47} y={24} fill="#ec7625" rx={3} ry={3.6}>
                    <animate
                        attributeName="opacity"
                        begin="-0.9166666666666666s"
                        dur="1s"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                        values="1;0"
                    />
                </rect>
                <rect
                    width={6}
                    height={12}
                    x={47}
                    y={24}
                    fill="#1b6775"
                    rx={3}
                    ry={3.6}
                    transform="rotate(30 50 50)"
                >
                    <animate
                        attributeName="opacity"
                        begin="-0.8333333333333334s"
                        dur="1s"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                        values="1;0"
                    />
                </rect>
                <rect
                    width={6}
                    height={12}
                    x={47}
                    y={24}
                    fill="#adc2da"
                    rx={3}
                    ry={3.6}
                    transform="rotate(60 50 50)"
                >
                    <animate
                        attributeName="opacity"
                        begin="-0.75s"
                        dur="1s"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                        values="1;0"
                    />
                </rect>
                <rect
                    width={6}
                    height={12}
                    x={47}
                    y={24}
                    fill="#ffc709"
                    rx={3}
                    ry={3.6}
                    transform="rotate(90 50 50)"
                >
                    <animate
                        attributeName="opacity"
                        begin="-0.6666666666666666s"
                        dur="1s"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                        values="1;0"
                    />
                </rect>
                <rect
                    width={6}
                    height={12}
                    x={47}
                    y={24}
                    fill="#ec7625"
                    rx={3}
                    ry={3.6}
                    transform="rotate(120 50 50)"
                >
                    <animate
                        attributeName="opacity"
                        begin="-0.5833333333333334s"
                        dur="1s"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                        values="1;0"
                    />
                </rect>
                <rect
                    width={6}
                    height={12}
                    x={47}
                    y={24}
                    fill="#1b6775"
                    rx={3}
                    ry={3.6}
                    transform="rotate(150 50 50)"
                >
                    <animate
                        attributeName="opacity"
                        begin="-0.5s"
                        dur="1s"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                        values="1;0"
                    />
                </rect>
                <rect
                    width={6}
                    height={12}
                    x={47}
                    y={24}
                    fill="#adc2da"
                    rx={3}
                    ry={3.6}
                    transform="rotate(180 50 50)"
                >
                    <animate
                        attributeName="opacity"
                        begin="-0.4166666666666667s"
                        dur="1s"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                        values="1;0"
                    />
                </rect>
                <rect
                    width={6}
                    height={12}
                    x={47}
                    y={24}
                    fill="#ffc709"
                    rx={3}
                    ry={3.6}
                    transform="rotate(210 50 50)"
                >
                    <animate
                        attributeName="opacity"
                        begin="-0.3333333333333333s"
                        dur="1s"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                        values="1;0"
                    />
                </rect>
                <rect
                    width={6}
                    height={12}
                    x={47}
                    y={24}
                    fill="#ec7625"
                    rx={3}
                    ry={3.6}
                    transform="rotate(240 50 50)"
                >
                    <animate
                        attributeName="opacity"
                        begin="-0.25s"
                        dur="1s"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                        values="1;0"
                    />
                </rect>
                <rect
                    width={6}
                    height={12}
                    x={47}
                    y={24}
                    fill="#1b6775"
                    rx={3}
                    ry={3.6}
                    transform="rotate(270 50 50)"
                >
                    <animate
                        attributeName="opacity"
                        begin="-0.16666666666666666s"
                        dur="1s"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                        values="1;0"
                    />
                </rect>
                <rect
                    width={6}
                    height={12}
                    x={47}
                    y={24}
                    fill="#adc2da"
                    rx={3}
                    ry={3.6}
                    transform="rotate(300 50 50)"
                >
                    <animate
                        attributeName="opacity"
                        begin="-0.08333333333333333s"
                        dur="1s"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                        values="1;0"
                    />
                </rect>
                <rect
                    width={6}
                    height={12}
                    x={47}
                    y={24}
                    fill="#ffc709"
                    rx={3}
                    ry={3.6}
                    transform="rotate(330 50 50)"
                >
                    <animate
                        attributeName="opacity"
                        begin="0s"
                        dur="1s"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                        values="1;0"
                    />
                </rect>
            </svg>
        </span>
    )
}

Spinner.protoTypes = propTypes
Spinner.defaultProps = defaultProps

export default Spinner
