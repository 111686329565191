import {
    SurveyCont,
    ActionButton,
    Heading,
    ExtShowinmid,
} from 'OnDemandActions/Sell/ExtendedStay/shared/Styles'
import Bellhop from 'OnDemandActions/Sell/ExtendedStay/shared/assets/bellhop.svg'
import TelephoneIcon from 'OnDemandActions/Sell/ExtendedStay/shared/assets/phone_icon.svg'
import Email from 'OnDemandActions/Sell/ExtendedStay/shared/assets/email_icon.svg'
import map from 'OnDemandActions/Sell/ExtendedStay/shared/assets/map.svg'
import Cup from 'OnDemandActions/Sell/ExtendedStay/shared/assets/cup-es.svg'
import moment from 'moment'
import useEvents from 'shared/hooks/sell/events'
import useTeams from 'shared/hooks/sell/teams'
import { Bellhops } from 'shared/components'
import useTodos from 'shared/hooks/sell/todos'

const Step3 = ({ close = () => {}, data = {} }) => {
    const { getEventByRole } = useEvents()
    const { fetch } = useTodos()
    const { eventDate } = getEventByRole('ClearedToClose')
    const agent = window.currentTransaction?.isListedByAgent
    const { getTeamByRole } = useTeams()
    const successManager = getTeamByRole('SuccessManager')

    const date = moment(eventDate).add(data, 'day').format('MMMM DD, YYYY')

    return (
        <SurveyCont>
            <ExtShowinmid>
                <div>
                    <center>
                        <img src={Cup} alt="" />
                    </center>
                    <center>
                        <Heading>Request Extended Stay</Heading>
                    </center>
                    <p>
                        Your request to <span>add the Extended Stay</span> benefit of {data}{' '}
                        {data > 1 ? 'days' : 'day'} with a new move-out date of {date} has been
                        submitted and will be reviewed
                        <br /> by your Success Manager, {successManager?.firstName}{' '}
                        {successManager?.lastName}.
                        <br />
                        <br />
                        {agent === true
                            ? `Hang tight, once approved, we will email you an addendum, please forward to
                            your seller(s) for signature.`
                            : `Hang tight, once approved, you will received an addendum to sign.`}
                    </p>
                </div>
                <Bellhops />
            </ExtShowinmid>
            <ExtShowinmid mt="42">
                <ActionButton
                    continue
                    id="fs_sell_extendedStay_na_next3"
                    variant="primary"
                    onClick={async () => {
                        close()
                        await fetch()
                    }}
                >
                    Got it!
                </ActionButton>
            </ExtShowinmid>
        </SurveyCont>
    )
}

export default Step3
