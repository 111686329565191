import { font } from 'shared/utils/styles'
import styled, { css, keyframes } from 'styled-components'
import Button from '../Button'

export const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const Container = styled.div`
    width: ${(props) => (props.standalone ? '100%' : '600px')};
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: stretch;
    animation: ${fadeIn} 0.6s ease-in-out;
    @media screen and (max-width: 524px) {
        width: 100%;
    }
`

export const OfferPrice = styled.div`
    color: #ec7625;
    font-size: 60px;
    ${font.black};
    margin-bottom: 4px;
    text-align: center;
    transition: all 0.3s ease-in-out;
    @media screen and (max-width: 480px) {
        font-size: 47px;
    }
    ${(props) =>
        props.teal &&
        css`
            color: #1b6775;
        `}
`

export const Span = styled.span`
    color: #525659;
    font-size: 16px;
    line-height: 20px;
    display: block;
    ${font.medium};
    ${(props) =>
        props.underline &&
        css`
            border-bottom: 1.5px dashed #525659;
            text-decoration: none;
        `}
    ${(props) =>
        props.center &&
        css`
            text-align: center;
        `}
    a {
        color: #1b6775;
        text-decoration: underline;
        cursor: pointer;
    }
    .orange {
        color: #ec7625;
    }
    ${(props) =>
        props.teal &&
        css`
            color: #1b6775;
        `}
    ${(props) =>
        props.orange &&
        css`
            color: #ec7625;
        `}
    ${(props) =>
        props.bold &&
        css`
            ${font.bold};
        `}
    ${(props) =>
        props.black &&
        css`
            ${font.black};
        `}   
    ${(props) =>
        props.italic &&
        css`
            font-style: italic;
        `}
`

export const OfferCard = styled.div`
    flex: 1;
    align-self: stretch;
    padding: 36px 36px 0px 36px;
    background-color: rgba(255, 209, 177, 0.22);
    border-radius: 20px;
    margin-top: 19px;
    flex: 1;
    position: relative;
    transition: all 0.3s ease-in-out;
    border: 0.25px solid #1b6775;
    @media screen and (max-width: 480px) {
        margin: 0px -20px;
        border-radius: 0;
        border: none;
        margin-top: 30px;
        padding: 36px 20px 0px 20px;
    }
`

export const Seprator = styled.div`
    display: flex;
    flex-direction: row;
    color: #1b6775;
    font-size: 14px;
    line-height: 26px;
    ${font.medium};
    font-style: italic;
    text-align: center;
    &::before {
        content: '';
        height: 0.25px;
        border-bottom: 0.25px solid #1b6775;
        flex: 1;
        margin: 12px 0px 0 0;
    }
    &::after {
        content: '';
        height: 0.25px;
        border-bottom: 0.25px solid #1b6775;
        flex: 1;
        margin: 12px 0 0 0px;
    }
    @media screen and (max-width: 480px) {
        margin: 12px 0px;
    }
    span {
        background-color: inherit;
        padding: 0px 15px;
        background-color: inherit;
        color: #1b6775;
        border-radius: 6px;
        ${font.tag};
        font-size: 31px;
        text-transform: uppercase;
    }
`

export const Details = styled.div`
    margin: 20px 0px;
`

export const CardDetails = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
`

export const OptionMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0px;
`

export const OptionCardContainer = styled.div`
    border-radius: 11px;
    border: 0.5px solid #898989;
    background: rgba(255, 255, 255, 0.4);
    width: 100%;
    padding: 17px 13px 17px 55px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

export const NumberBadge = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    border-top-left-radius: 11px;
    border-bottom-right-radius: 11px;
    border: 0.5px solid #898989;
    border-left: 0px;
    border-top: 0px;
    background: #fff;
    padding: 5px 13px;
    color: #1b6775;
    ${font.black};
    font-size: 21px;
`
export const OptionContainer = styled.div`
    width: 100%;
`

export const Options = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;
`

export const Option = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 40%;
    cursor: pointer;
    input {
        position: relative;
    }
    input:before {
        margin: 0px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @media screen and (max-width: 600px) {
        width: 100%;
        margin-top: 10px;
    }
    ${(props) =>
        props.checked &&
        css`
            span {
                color: #ec7625;
                ${font.black};
            }
        `}
`

export const RoundedButton = styled.div`
    background-color: #fff;
    border: 1px solid #dbe5e7;
    height: 37px;
    width: 37px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
    img {
        height: 15px;
        pointer-events: none;
    }
`

export const ActionButton = styled(Button)`
    display: flex;
    font-size: 16px;
    overflow: hidden;
    min-width: 70%;
    margin: 50px auto;
    ${font.bold};
    @media screen and (max-width: 480px) {
        min-width: 100%;
    }
    ${(props) =>
        props.height &&
        css`
            height: ${props.height}px;
            @media screen and (max-width: 480px) {
                height: auto;
            }
        `}
    ${(props) =>
        props.teal &&
        css`
            background-color: #1b6775;
            &:not(:disabled):hover {
                background-color: #1b6775 !important;
                opacity: 0.9;
            }
        `}
    ${(props) =>
        props.disabledColor &&
        css`
            &:disabled {
                background-color: ${props.disabledColor};
                opacity: 0.5;
                cursor: not-allowed;
            }
        `}
`

export const Badge = styled.div`
    background-color: #ec7625;
    ${font.black};
    font-size: 16px;
    line-height: 21px;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    width: fit-content;
    margin: 0 auto 10px auto;
`

export const DefaultScreen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
    gap: 10px;
    img {
        height: 80px;
        margin-bottom: 10px;
    }
    span {
        text-align: center;
    }
`

export const ModalBody = styled.div`
    height: 100%;
    padding: 100px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    iframe {
        z-index: 1000;
    }
`
