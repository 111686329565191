import { useState } from 'react'

const { Container, ActionButton } = require('./Styles')

const CashOfferDefaultScreen = ({ slag, image, heading, description, renewOffer }) => {
    const [isLoading, setIsLoading] = useState(false)

    const handleRenewOffer = async () => {
        setIsLoading(true)
        await renewOffer()
        setIsLoading(false)
    }

    return (
        <Container>
            {image && <img src={image} alt="" />}
            {heading && <h1>{heading}</h1>}
            {description && <p>{description}</p>}
            {slag === 'offerExpired' && (
                <ActionButton isWorking={isLoading} onClick={handleRenewOffer}>
                    Renew your offer
                </ActionButton>
            )}
        </Container>
    )
}

export default CashOfferDefaultScreen
