import pubsub from 'sweet-pubsub'

const alert = {
    confirm: function (heading, message, icon, callback) {
        pubsub.emit('alert', { type: 'confirm', heading, message, icon, callback })
    },
    info: function (heading, message, icon, callback, buttonText) {
        pubsub.emit('alert', { type: 'info', heading, message, icon, callback, buttonText })
    },
    custom: function (props) {
        pubsub.emit('alert', { type: 'custom', ...props })
    },
}

export default alert
