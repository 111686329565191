import { font } from 'shared/utils/styles'
import styled, { css } from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: ${(props) => props.backgroundColor};
    ${(props) =>
        props.elevation &&
        css`
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        `}
`

export const Icon = styled.img`
    height: 20px;
`

export const Text = styled.span`
    font-size: 17px;
    line-height: normal;
    ${font.bold};
    color: ${(props) => props.textColor};
`
