import React, { Fragment, useState, useRef, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import useOnOutsideClick from 'shared/hooks/onOutsideClick'
import useOnEscapeKeyDown from 'shared/hooks/onEscapeKeyDown'

import { ScrollOverlay, ClickableOverlay, StyledModal, CloseIcon } from './Styles'

const $root = document.getElementById('root')

const propTypes = {
    backgroundColor: PropTypes.string,
    className: PropTypes.string,
    testid: PropTypes.string,
    variant: PropTypes.oneOf(['center', 'aside']),
    floting: PropTypes.bool,
    width: PropTypes.string,
    withCloseIcon: PropTypes.bool,
    isOpen: PropTypes.bool,
    showOnLoad: PropTypes.bool,
    onClose: PropTypes.func,
    onCloseEvent: PropTypes.func,
    onOpenEvent: PropTypes.func,
    renderLink: PropTypes.func,
    renderContent: PropTypes.func.isRequired,
    variableWidth: PropTypes.bool,
    clickOutside: PropTypes.bool,
}

const defaultProps = {
    backgroundColor: '',
    className: undefined,
    testid: 'modal',
    variant: 'center',
    width: '600px',
    floting: false,
    withCloseIcon: true,
    isOpen: undefined,
    showOnLoad: false,
    onClose: () => {},
    onCloseEvent: () => {},
    onOpenEvent: () => {},
    renderLink: () => {},
    renderToTopLevel: () => {},
    variableWidth: false,
    clickOutside: true,
}

const Modal = ({
    backgroundColor,
    className,
    testid,
    variant,
    width,
    floting,
    withCloseIcon,
    isOpen: propsIsOpen,
    onClose: tellParentToClose,
    showOnLoad,
    onCloseEvent,
    onOpenEvent,
    renderLink,
    renderContent,
    renderToTopLevel,
    variableWidth,
    clickOutside,
}) => {
    const [stateIsOpen, setStateOpen] = useState(false)
    const isControlled = typeof propsIsOpen === 'boolean'
    const isOpen = isControlled ? propsIsOpen : stateIsOpen

    const $modalRef = useRef()
    const $clickableOverlayRef = useRef()

    const closeModal = useCallback(() => {
        if (!isControlled) {
            if (variant === 'aside') {
                $modalRef.current.animate([{ left: '0' }, { left: '-1000px' }], {
                    duration: 350,
                    easing: 'ease-in-out',
                })
                setTimeout(() => {
                    setStateOpen(false)
                    onCloseEvent()
                }, [300])
            } else {
                if ($modalRef.current !== null && $modalRef.current !== undefined) {
                    $modalRef.current.animate(
                        [
                            { opacity: 1, transform: 'scale(1)' },
                            { opacity: 0, transform: 'scale(0.8)' },
                        ],
                        {
                            duration: 150,
                            easing: 'ease-in-out',
                        },
                    )
                    setTimeout(() => {
                        setStateOpen(false)
                        onCloseEvent()
                    }, [100])
                }
            }
        } else {
            tellParentToClose()
        }
    }, [isControlled, tellParentToClose])

    useOnOutsideClick($modalRef, isOpen, closeModal, $clickableOverlayRef, clickOutside)
    useOnEscapeKeyDown(isOpen, closeModal)

    const openModal = useCallback(() => {
        setStateOpen(true)
        onOpenEvent()
    }, [isControlled])

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'visible'
        }
    }, [isOpen])

    useEffect(() => {
        if (showOnLoad) {
            openModal()
        }
    }, [showOnLoad])

    return (
        <Fragment>
            {!isControlled &&
                renderLink({
                    open: openModal,
                    isOpen: stateIsOpen,
                })}
            {isOpen &&
                ReactDOM.createPortal(
                    <ScrollOverlay>
                        {renderToTopLevel()}
                        <ClickableOverlay
                            variant={variant}
                            ref={$clickableOverlayRef}
                            floting={floting}
                        >
                            <StyledModal
                                className={className}
                                variant={variant}
                                width={width}
                                data-testid={testid}
                                ref={$modalRef}
                                variableWidth={variableWidth}
                                backgroundColor={backgroundColor}
                                floting={floting}
                            >
                                {withCloseIcon && (
                                    <CloseIcon
                                        type="close"
                                        variant={variant}
                                        onClick={closeModal}
                                    />
                                )}
                                {renderContent({ close: closeModal })}
                            </StyledModal>
                        </ClickableOverlay>
                    </ScrollOverlay>,
                    $root,
                )}
        </Fragment>
    )
}

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

export default Modal
