import {
    Container,
    Heading,
    MainContainer,
    Span,
    SubHeading,
    Textarea,
    UploadPhotoCont,
    UploadCont,
    ButtonContainer,
    ActionButton,
    NoPhotoCont,
    ImagesCont,
    CloseIcon,
} from './Styles'
import Camera from 'shared/asstes/camera-todos.svg'
import BinIcon from 'shared/asstes/bin.svg'
import ZoomIcon from 'shared/asstes/zoom.svg'
import CheckIcon from 'shared/asstes/check_with_circle.svg'
import { convertBase64 } from 'shared/utils/converter'
import { useEffect, useRef, useState } from 'react'
import Lightbox from '../Lightbox'
import toast from 'shared/utils/toast'
import ScreenLoader from '../ScreenLoader'
import api from 'shared/utils/api'
import AnimatedBin from 'shared/asstes/bin_animated.svg'
import Icon from '../Icon'
import ImageWithLoader from '../ImageWithLoader'
import alert from 'shared/utils/alert'
import useSingleTransactions from 'shared/hooks/sell/single-transaction'
import React from 'react'

const CashOfferSpecificNumber = ({
    transaction,
    parentMergeState,
    parentState,
    standalone,
    closeScreen,
    resize,
}) => {
    const [reason, setReason] = useState('')
    const [isWorking, setIsWorking] = useState(false)
    const [images, setImages] = useState([])

    const { fetch } = useSingleTransactions()
    const $inputRef = useRef(null)

    const handleBrowseClick = () => $inputRef.current && $inputRef.current.click()

    const handleOnFileSelect = async (e) => {
        const newFiles = e.target.files
        const temp = []
        for (var i = 0; i < newFiles.length; i++) {
            const base64 = await convertBase64(newFiles[i])
            const fileName = newFiles[i].name
            const fileType = newFiles[i].type
            const file = newFiles[i]
            temp.push({
                id: Math.floor(Date.now() + Math.random() * 1000),
                backgroundImage: base64,
                file,
                fileName,
                fileType,
                uploaded: false,
                canDelete: true,
            })
        }
        setImages([...images, ...temp])
        resetInput()
    }

    const resetInput = () => {
        if ($inputRef.current) {
            $inputRef.current.value = ''
        }
    }

    const handleImageDelete = async (data) => {
        const { uploaded, id } = data
        if (uploaded) {
            alert.confirm(
                'Delete photo?',
                `Are you sure you want to delete this photo?`,
                AnimatedBin,
                async (confirm) => {
                    if (confirm) {
                        try {
                            setIsWorking(true)
                            await api.delete(
                                `customer/v3/cash-offer-transactions/${transaction.transactionId}/images/${id}`,
                            )
                            await fetch()
                            initImages()
                            toast.success('Image deleted successfully.')
                        } catch (e) {
                            if (e?.error?.message) {
                                toast.error(e?.error?.message)
                            } else {
                                toast.error('Something went wrong. Unable to delete image.')
                            }
                        } finally {
                            setIsWorking(false)
                        }
                    }
                },
            )
        } else {
            const updatedList = images.filter((image) => image.id !== id)
            setImages(updatedList)
        }
    }

    const handlePhotosUpload = async () => {
        const uploadPhotos = new Promise((resolve, reject) => {
            images.forEach(async (image) => {
                if (!image.uploaded) {
                    setIsWorking(true)
                    try {
                        await api.post(
                            `customer/v3/cash-offer-transactions/${transaction?.transactionId}/upload-photo-v2`,
                            {
                                file: image.file,
                            },
                            {
                                'Content-Type': 'multipart/form-data',
                            },
                        )
                        await uploadPhotos(image)
                        resolve()
                    } catch (e) {
                        reject(e)
                    }
                }
            })
        })
        await fetch()
        closeScreen()
        uploadPhotos.then(
            () => {
                setIsWorking(false)
                toast.success('Images uploaded successfully.')
            },
            (error) => {
                //   toast.error(error?.error?.message ? error?.error?.message : 'Something went wrong. Unable to delete image.')
                setIsWorking(false)
            },
        )
    }

    const handleSpecificScreenClose = () => {
        if (!standalone) {
            setIsWorking(false)
            parentMergeState({
                showSpecificNumberScreen: false,
            })
            setImages([])
            const activeTab = parentState.activeTab
            if (activeTab === 2) {
                resize()
            }
        } else {
            window.location.reload()
        }
    }

    const handleTextareaChanged = (e) => {
        const reason = e.target.value.trim()
        setReason(reason)
    }

    const handleSubmit = async () => {
        try {
            setIsWorking(true)
            await api.post(`api/customer/v1/offers/${transaction.offerKey}/counter`, { reason })
            closeScreen()
            alert.info(
                "We're taking another look at our offer.",
                'We received your request and we will get back to you soon via email. ',
                CheckIcon,
                () => {},
            )
        } catch (error) {
            toast.error(error?.error?.message ? error?.error?.message : 'Something went wrong.')
        } finally {
            setIsWorking(false)
        }
    }

    const initImages = () => {
        const temp = []
        transaction.images.forEach((image) => {
            temp.push({
                id: image.imageId,
                backgroundImage: image.imageUrl,
                uploaded: true,
                file: undefined,
                fileName: undefined,
                canDelete: transaction.canCustomerDeletePhotos,
            })
        })
        setImages([...images, ...temp])
    }

    useEffect(() => {
        if (transaction && transaction.images && transaction.images.length > 0) {
            initImages()
        }
        resize(715)
    }, [])

    return (
        <MainContainer standalone={standalone}>
            <ScreenLoader sL={isWorking} full={false} />
            <CloseIcon onClick={handleSpecificScreenClose}>
                <Icon type="close" />
            </CloseIcon>
            <input
                type="file"
                id="file-select"
                ref={$inputRef}
                multiple
                onChange={handleOnFileSelect}
            />
            <Heading>Did we miss something?</Heading>
            <Container>
                <Span>
                    Buying and selling homes is our passion, and our goal is to present the most
                    competitive cash offer possible. Anything else we should know about your home?
                    Let us know and we'll take another look at your offer.
                </Span>
                <Textarea
                    placeholder="Enter any additional info here"
                    onChange={handleTextareaChanged}
                ></Textarea>
                <SubHeading>Upload photos:</SubHeading>
                <UploadCont>
                    <RenderImage images={images} handleImageDelete={handleImageDelete} />
                    <ButtonContainer>
                        <ActionButton bordered onClick={handleBrowseClick} disabled={isWorking}>
                            Browse
                        </ActionButton>
                        <ActionButton
                            variant="primary"
                            onClick={handlePhotosUpload}
                            disabled={images.length === 0 || isWorking}
                            isWorking={isWorking}
                        >
                            Upload Photos
                        </ActionButton>
                    </ButtonContainer>
                </UploadCont>
            </Container>
            <ActionButton
                submit
                variant="primary"
                size="lg"
                disabled={reason.length <= 3}
                onClick={handleSubmit}
                id="View_Offer_Purchase_Agreement_Sent_Counter_Offer_Requested"
            >
                Submit
            </ActionButton>
        </MainContainer>
    )
}

const RenderImage = ({ images, handleImageDelete }) => (
    <UploadPhotoCont>
        {!images.length ? (
            <NoPhotoCont>
                <img src={Camera} alt="" />
                <Span bold>No photos uploaded.</Span>
            </NoPhotoCont>
        ) : (
            <ImagesCont>
                {images.map((image) => (
                    <div key={image.id}>
                        <Lightbox
                            image={image.backgroundImage}
                            renderLink={({ open }) => (
                                <ImageWithLoader
                                    image={image.backgroundImage}
                                    className="card-photos"
                                    renderOnTop={() => (
                                        <ButtonContainer imageButton>
                                            {image.canDelete && (
                                                <ActionButton
                                                    imageButton
                                                    style={{ borderColor: '#F4F5F7' }}
                                                    onClick={() => handleImageDelete(image)}
                                                >
                                                    <img src={BinIcon} alt="" />
                                                </ActionButton>
                                            )}
                                            <ActionButton
                                                variant="primary"
                                                imageButton
                                                onClick={open}
                                            >
                                                <img src={ZoomIcon} alt="" />
                                            </ActionButton>
                                        </ButtonContainer>
                                    )}
                                ></ImageWithLoader>
                            )}
                        />
                    </div>
                ))}
            </ImagesCont>
        )}
    </UploadPhotoCont>
)

export default CashOfferSpecificNumber
