const AppleAppSiteAssociation = () => {
    const appleAppSiteAssociation = {
        applinks: {
            apps: [],
            details: [
                {
                    appID: '677WLZZMDB.com.connect.mobile.dev',
                    paths: ['/auth/verify-email*', '/auth/reset-password*', '/homes/*'],
                },
                {
                    appID: '677WLZZMDB.com.offerpad.connectapp',
                    paths: ['/auth/verify-email*', '/auth/reset-password*', '/homes/*'],
                },
                {
                    appID: '677WLZZMDB.com.offerpad.mobile',
                    paths: ['/auth/verify-email*', '/auth/reset-password*', '/homes/*'],
                },
            ],
        },
    }
    return (
        <pre style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(appleAppSiteAssociation, null, 2)}
        </pre>
    )
}

export default AppleAppSiteAssociation
