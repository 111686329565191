import AsyncSelect from 'react-select/async'
import Reselect from 'react-select'
import PropTypes from 'prop-types'
import api from 'shared/utils/api'

const propTypes = {
    options: PropTypes.array,
    size: PropTypes.oneOf(['sm', 'normal', 'lg']),
    invalid: PropTypes.bool,
    saperator: PropTypes.bool,
    placeholderColor: PropTypes.string,
    async: PropTypes.bool,
    url: PropTypes.string,
}

const defaultProps = {
    options: [],
    size: 'normal',
    invalid: false,
    saperator: true,
    placeholderColor: 'inherit',
    async: false,
    url: null,
}

const customStyles = (size, saperator, placeholderColor, invalid) => {
    return {
        control: (styles, state) => ({
            ...styles,
            fontSize: '17px',
            padding: size === 'sm' ? '4px 8px' : size === 'normal' ? '6px 12px' : '8px 16px',
            borderRadius: size === 'sm' ? '3.2px' : size === 'normal' ? '4px' : '4.8px',
            minHeight:
                size === 'sm'
                    ? 'calc(1.5em + (0.5rem + 2px))'
                    : size === 'normal'
                    ? 'auto'
                    : 'calc(1.5em + (1rem + 2px))',
            marginTop: '20px',
            border: invalid
                ? '1px solid #E13C3C'
                : state.isFocused
                ? '1px solid #ec7625'
                : '1px solid #979797',
            boxShadow: invalid
                ? '0 0 0 1px #E13C3C'
                : state.isFocused
                ? '0 0 0 1px #EF7724'
                : 'none',
            cursor: 'pointer',
            ':hover': {
                borderColor: state.isFocused ? '#ec7625' : '#979797',
            },
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            display: saperator ? 'block' : 'none',
        }),
        option: (styles, { isFocused }) => ({
            ...styles,
            cursor: 'pointer',
            backgroundColor: isFocused ? 'rgba(236,118,37,0.5)' : 'white',
            fontFamily: 'MuseoSans-regular',
            fontWeight: '300',
            color: 'black',
            ':hover': {
                backgroundColor: 'rgba(236,118,37,0.5)',
            },
        }),
        placeholder: (styles) => ({ ...styles, color: placeholderColor }),
    }
}

const Select = ({ async, url, options, size, invalid, saperator, placeholderColor, ...rest }) => {
    const fetchDataFromUurl = async () => {
        if (!url) return

        try {
            const response = await api.get(url)
            return response
        } catch (e) {
            console.log(e)
            return
        }
    }

    return async ? (
        <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={fetchDataFromUurl}
            styles={customStyles(size, saperator, placeholderColor, invalid)}
            {...rest}
        />
    ) : (
        <Reselect
            options={options}
            styles={customStyles(size, saperator, placeholderColor, invalid)}
            {...rest}
        />
    )
}

Select.propTypes = propTypes
Select.defaultProps = defaultProps

export default Select
