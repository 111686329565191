import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, FormHeadingImage, FormImage, StyledDiv } from '../shared/Styles'
import { Form } from 'shared/components'
import toast from 'shared/utils/toast'
import Logo from 'shared/asstes/logo.svg'
import Clouds from '../assets/cloud-sun.svg'
import UnderLine from '../assets/underline.svg'
import {
    StyledLogo,
    FormCont,
    FormHeading,
    FormElement,
    ActionButton,
    FormFooterStyled,
    FormFooterStyledAnchorTag,
} from '../shared/Styles'
import useApi from 'shared/hooks/api'
import { storeAuthToken, storeTokenExpiryTime } from 'shared/utils/authToken'
import { storeRefreshToken } from 'shared/utils/refreshToken'
import { decaodeJWT } from 'shared/utils/jwt'
import { useDispatch } from 'react-redux'
import { setUser } from 'features/userSlice'

const ResetPassword = () => {
    const navigate = useNavigate()
    const [{ isCreating }, resetPassword] = useApi.post('api/customer-auth/v2/reset-password')
    const queryParameters = new URLSearchParams(window.location.search)
    const header = { 'Content-Type': 'application/x-www-form-urlencoded' }
    const [log, login] = useApi.post('api/customer-auth/v2/token', header)
    const code = queryParameters.get('code')
    const email = queryParameters.get('email')
    const dispatch = useDispatch()

    useEffect(() => {
        if (!code && !email) {
            navigate('/auth/login')
        }
    }, [])

    return (
        <Fragment>
            <Container>
                <StyledDiv>
                    <StyledLogo src={Logo} alt="" />
                </StyledDiv>
                <Form
                    enableReinitialize
                    initialValues={{
                        password: '',
                        confirmPassword: '',
                    }}
                    validations={{
                        password: [
                            Form.is.required('Enter a valid password'),
                            Form.is.strongPassword(
                                'Use 6 or more characters, a combination of uppercase, lowercase, a number and a symbol',
                            ),
                        ],
                        confirmPassword: Form.is.required('Enter a valid password'),
                    }}
                    onSubmit={async (values, form) => {
                        try {
                            await resetPassword({
                                newPassword: values.password,
                                email,
                                code,
                            })
                            const res = await login({
                                password: values.password,
                                username: email,
                                client_id: 'OPTwirl',
                                grant_type: 'password',
                            })
                            const decodedToken = decaodeJWT(res?.access_token)
                            dispatch(setUser(decodedToken))
                            storeAuthToken(res?.access_token)
                            storeRefreshToken(res?.refresh_token)
                            storeTokenExpiryTime(res?.expires_in)
                            toast.success('Password reset successful.')
                            navigate('/sell/overview')
                        } catch (error) {
                            if (error === 'User not found or invalid password') {
                                toast.error('User not found.')
                                form.setFieldError('password', ' ')
                                form.setFieldError('confirmPassword', 'User not found.')
                            }
                            if (error?.succeeded === false) {
                                toast.error('Invalid token.')
                                form.setFieldError('password', ' ')
                                form.setFieldError('confirmPassword', 'Invalid token.')
                            }
                        }
                    }}
                >
                    <FormCont>
                        <FormImage src={Clouds} alt="" />
                        <Fragment>
                            <FormElement>
                                <FormHeading size="xl">
                                    Change Password
                                    <FormHeadingImage src={UnderLine} alt="" />
                                </FormHeading>
                                <Form.Field.Input
                                    name="password"
                                    type="password"
                                    placeholder="New Password"
                                    size="lg"
                                    disabled={isCreating || log.isCreating}
                                    inputClass="fs-mask"
                                    showStrengthBox={true}
                                    autoComplete={'false'.toString()}
                                />
                                <Form.Field.Input
                                    name="confirmPassword"
                                    type="password"
                                    placeholder="Confirm Password"
                                    size="lg"
                                    disabled={isCreating || log.isCreating}
                                    inputClass="fs-mask"
                                    autoComplete={'false'.toString()}
                                />
                                <ActionButton
                                    type="submit"
                                    variant="primary"
                                    isWorking={isCreating || log.isCreating}
                                    size="lg"
                                    id="fs_auth_resetPassword_submit"
                                >
                                    Reset
                                </ActionButton>
                            </FormElement>
                            <FormFooterStyled mt="55px">
                                Don't have an account?
                                <FormFooterStyledAnchorTag
                                    to="/auth/register"
                                    id="fs_auth_resetPassword_signup"
                                >
                                    Sign up
                                </FormFooterStyledAnchorTag>
                            </FormFooterStyled>
                        </Fragment>
                    </FormCont>
                </Form>
            </Container>
        </Fragment>
    )
}

export default ResetPassword
