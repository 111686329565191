import { Container, Nav, RightContainer, StyledLogo } from './Styles'
import Logo from 'shared/asstes/logo.svg'
import { Link } from 'react-router-dom'
import DashboardMenu from '../DashboardMenu'
import MessageNavbarMenu from '../MessageNavbarMenu'
import ProfileNavbarMenu from '../ProfileNavbarMenu'
import MobileNavigation from '../MobileNavigation'

const Navbar = () => {
    return (
        <Nav>
            <Container>
                <Link to={'/sell/dashboard'}>
                    <StyledLogo src={Logo} alt="logo" />
                </Link>
                <DashboardMenu />
                <RightContainer>
                    <MessageNavbarMenu />
                    <ProfileNavbarMenu />
                </RightContainer>
                <MobileNavigation />
            </Container>
        </Nav>
    )
}

export default Navbar
