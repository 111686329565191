import { Cont } from 'Surveys/shared/Styles'
import NoAddendum from './NoAddendum'
import SignedAddendum from './SignedAddendum'
import PendingAddendum from './PendingAddendum'

const ExtendedSurvey = ({ close = () => {}, extendedStay = {} }) => {
    return (
        <Cont>
            {(extendedStay.extendedStayStatus === 'NoAddendum' ||
                extendedStay.extendedStayStatus === 'Canceled') && (
                <NoAddendum close={close} extendedStay={extendedStay} />
            )}
            {(extendedStay.extendedStayStatus === 'SignedAddendum' ||
                extendedStay.extendedStayStatus === 'Canceling') && (
                <SignedAddendum close={close} extendedStay={extendedStay} />
            )}
            {extendedStay.extendedStayStatus === 'PendingAddendum' && (
                <PendingAddendum close={close} extendedStay={extendedStay} />
            )}
        </Cont>
    )
}

export default ExtendedSurvey
