import styled, { css, keyframes } from 'styled-components'

import { font, mixin, zIndexValues } from 'shared/utils/styles'
import Icon from '../Icon'

const slideLeftWithOpacity = keyframes`
    from{
        opacity: 0;
        left:-1000px;
    }
    to{
        opacity: 1;
        left:0px;
    }
`

const slideRightWithOpacity = keyframes`
    from{
        opacity: 0;
        right:-1000px;
    }
    to{
        opacity: 1;
        right:0px;
    }
`

const slideTopWithOpacity = keyframes`
    from{
        opacity: 0;
        top:-1000px;
    }
    to{
        opacity: 1;
        top:0px;
    }
`

const slideBottomWithOpacity = keyframes`
    from{
        opacity: 0;
        bottom:-1000px;
    }
    to{
        opacity: 1;
        bottom:0px;
    }
`

export const ScrollOverlay = styled.div`
    z-index: ${zIndexValues.modal};
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    ${mixin.scrollableY}
`

export const ClickableOverlay = styled.div`
    min-height: 100%;
    background: rgba(9, 30, 66, 0.54);
`

export const CanvasBody = styled.div`
    background-color: ${(props) => props.backgroundColor};
    ${(props) => canvasVariant[props.variant]};
    animation-duration: 0.3s;
    animation-fill-mode: both;
    position: absolute;
    ${mixin.scrollableY};
    max-width: 100vw;
    @media screen and (max-width: 600px) {
        width: 100%;
        max-width: 100vh;
    }
`

const canvasVariant = {
    left: css`
        width: ${(props) => (props.size === 'auto' ? 'auto' : props.size + 'px')};
        top: 0;
        left: 0;
        height: 100vh;
        animation-name: ${slideLeftWithOpacity};
    `,
    top: css`
        width: 100vw;
        height: ${(props) => props.size}px;
        left: 0;
        top: 0;
        animation-name: ${slideTopWithOpacity};
    `,
    right: css`
        width: ${(props) => (props.size === 'auto' ? 'auto' : props.size + 'px')};
        top: 0;
        right: 0;
        height: 100vh;
        animation-name: ${slideRightWithOpacity};
    `,
    bottom: css`
        width: 100vw;
        height: ${(props) => props.size}px;
        left: 0;
        bottom: 0;
        animation-name: ${slideBottomWithOpacity};
    `,
}

export const CloseIcon = styled(Icon)`
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #6f6c78;
    transition: all 0.1s;
    padding: 19px 20px;
    background-color: #f7f7f7;
    height: 55px;
    width: 55px;
    border-radius: 50% !important;
    border: 1px solid #dbe5e7;
    ${mixin.clickable}
    z-index: 1;
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        line-height: 0%;
        ${font.size(20)}
    }
`
