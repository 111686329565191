import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import { StyledCheckbox, StyledInput, ActualInput, StylesLabel, InputCont } from './Styles'

const propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'normal', 'lg', 'xl']),
    labelSize: PropTypes.string,
    invalid: PropTypes.bool,
    checkboxLabel: PropTypes.string,
    onChangeEvent: PropTypes.func,
    checked: PropTypes.bool,
    value: PropTypes.any,
    agentCheck: PropTypes.bool,
}

const defaultProps = {
    className: undefined,
    size: 'normal',
    labelSize: '16',
    invalid: false,
    checkboxLabel: '',
    checked: false,
    value: '',
    agentCheck: false,
    onChangeEvent: () => {},
}

const Checkbox = forwardRef(
    (
        {
            className,
            size,
            checkboxLabel,
            invalid,
            labelSize,
            labelColor,
            checked,
            value,
            onChangeEvent,
            agentCheck,
            ...rest
        },
        ref,
    ) => {
        return (
            <StyledCheckbox className={className}>
                <InputCont>
                    <StyledInput
                        invalid={invalid}
                        checked={checked}
                        size={size}
                        agentCheck={agentCheck}
                        className={checked && 'checkbox-checked'}
                    />
                    <ActualInput
                        type="checkbox"
                        size={size}
                        ref={ref}
                        value={value}
                        defaultChecked={checked}
                        onClick={onChangeEvent}
                        {...rest}
                    />
                </InputCont>
                {!!checkboxLabel && (
                    <StylesLabel
                        htmlFor={checkboxLabel}
                        labelSize={labelSize}
                        labelColor={labelColor}
                    >
                        {checkboxLabel}
                    </StylesLabel>
                )}
            </StyledCheckbox>
        )
    },
)

Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps

export default Checkbox
