import styled, { css } from 'styled-components'

export const BackButtonCont = styled.div`
    height: 42px;
    width: 42px;
    background-color: #f6f6f6;
    border: 1px solid #dbe5e7;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${(props) =>
        props.height !== '' &&
        css`
            height: ${props.height};
        `}
    ${(props) =>
        props.width !== '' &&
        css`
            width: ${props.width};
        `}
`

export const Img = styled.img`
    width: 8px;
    ${(props) =>
        props.width !== '' &&
        css`
            width: ${props.width};
        `}
`
