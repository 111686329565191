import { useState, useRef, createRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { LightboxOverlay, LightboxOverlayImage, CloseIcon, ErrorText } from './Styles'
import PropTypes from 'prop-types'
import ErrorSvg from 'shared/components/Lightbox/assets/error.svg'
import ScreenLoader from '../ScreenLoader'

const propTypes = {
    className: PropTypes.string,
    onCloseEvent: PropTypes.func,
    renderLink: PropTypes.func,
}

const defaultProps = {
    className: undefined,
    onCloseEvent: () => {},
}

const Lightbox = ({ className, onCloseEvent, image, renderLink }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isError, setIsError] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const lightboxRef = useRef(createRef())

    useEffect(() => {
        const lightboxNode = lightboxRef.current
        document.body.appendChild(lightboxNode)
        return () => {
            document.body.removeChild(lightboxNode)
        }
    }, [])

    const handleClick = () => {
        setIsOpen(true)
    }

    const handleError = () => {
        setIsError(true)
    }

    const handleClose = () => {
        setIsOpen(false)
        onCloseEvent()
    }

    return (
        <>
            {renderLink({ open: handleClick })}
            {isOpen &&
                createPortal(
                    <LightboxOverlay className={className}>
                        <LightboxOverlayImage
                            src={isError ? ErrorSvg : image}
                            alt=""
                            onLoad={() => setLoaded(true)}
                            onError={handleError}
                        />
                        {isError && <ErrorText>Error loading image.</ErrorText>}
                        <ScreenLoader sL={!loaded} full={false} backgroundColor="transparent" />
                        <CloseIcon type="close" title="Close" onClick={handleClose} />
                    </LightboxOverlay>,
                    lightboxRef.current,
                )}
            <div ref={lightboxRef} />
        </>
    )
}

Lightbox.propTypes = propTypes
Lightbox.defaultProps = defaultProps

export default Lightbox
