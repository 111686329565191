import styled, { css, keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import { font, borderRadius, color, fontSize } from 'shared/utils/styles'
import ArrowResourceCenter from 'ResourceCenter/assets/arrow-down-resourceCenter.svg'

const opacity = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
`

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

export const Cont = styled.div`
    animation: ${opacity} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    ${(props) =>
        !!props.isDashboard && props.isDashboard
            ? css`
                  padding: 0 !important;
              `
            : css`
                  padding: 30px 0px;
                  @media screen and (min-width: 1423px) {
                      width: 100%;
                      max-width: 1347px;
                      margin: 0 auto;
                  }
                  @media screen and (max-width: 1422px) {
                      padding: 30px;
                  }
                  @media (max-width: 480px) {
                      padding: 30px 20px !important;
                  }
              `}
`

export const HeadTop = styled.div`
    display: flex;
    @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`

export const Wrapper = styled.div`
    width: 280px;
    position: relative;
    display: flex;
    border-radius: 2rem;
    overflow: hidden;
    border: none;
    margin-top: 2rem;
    margin-bottom: 1rem ${font.size(20)};
    ${font.medium};
    line-height: 18px;

    input[type='radio'] {
        display: none;
    }

    input[type='radio'] + label {
        width: 50%;
        font-size: 1rem;
        padding: 0.6rem 0;
        text-align: center;
        color: ${color.textMessage};
        cursor: pointer;
        transition: all 0.5s;
        z-index: 2;
        border-radius: 1rem;
    }

    input[type='radio']:checked + label {
        color: #ffffff;
        background: #1b6775;
        border-radius: 25px;
        z-index: 1;
        ${font.bold};
        line-height: 18px;
    }
`

export const HeaderCont = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 20px 0;
    @media (max-width: 480px) {
        flex-direction: column;
        align-items: start;
    }
`

export const Header = styled.div`
    display: flex;
    justify-content: start;
    gap: 30px;
    width: 100%;
    overflow: hidden;
    align-items: center;
    @media (max-width: 820px) {
        gap: 0px;
    }
`

export const HeaderArrowImg = styled.img`
    width: 51px;
    margin-top: -20px;
    @media (max-width: 820px) {
        width: 20%;
    }
    @media (max-width: 480px) {
        margin-right: 10px;
        display: none;
    }
`

export const Heading = styled.div`
    position: relative;
    padding: 6px 0;
    ${font.size(32)};
    color: ${color.textBlack};
    ${font.black};
    white-space: nowrap;
    margin-top: -10px;
    @media (max-width: 820px) {
        ${font.size(20)};
    }
    @media (max-width: 480px) {
        ${font.bold};
        line-height: 18px;
    }
`

export const SubHeading = styled.div`
    display: none;
    @media (max-width: 480px) {
        display: block;
        ${font.medium};
        ${font.size(14)};
        line-height: 17px;
        color: #525659;
    }
`

export const Select = styled.select`
    display: none;
    width: 340px;
    height: 50px;
    color: ${color.primary};
    border: 1px solid #eaeaea;
    border-radius: 8px;
    padding: 15px;
    ${font.size(17)};
    ${font.medium};
    cursor: pointer;
    appearance: none;
    background-image: url(${ArrowResourceCenter});
    background-repeat: no-repeat;
    background-position: calc(100% - 25px);
    background-size: 10px;
    @media (max-width: 480px) {
        width: 100%;
    }
    option {
        color: ${color.primary};
        display: flex;
        // min-height: 20px;
        padding: 0px 2px 1px;
    }
`

export const CardCont = styled.div`
    animation: ${slideLeft} ease 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    background: #ffffff;
    border: 2px solid #eaeaea;
    border-radius: 8px;
    padding: 30px 52px;
    @media (max-width: 480px) {
        padding: 0;
        border: none;

        hr {
            margin-top: -1rem;
            margin-bottom: 1rem;
            color: rgba(217, 217, 217, 0.3);
        }
    }
    .heading {
        font-size: 24px;
        color: #525659;
        font-family: ${font.bold};
        line-height: 29px;
        white-space: nowrap;
        margin: 20px 0;
    }
    .body {
        position: relative;
        border: 1px solid #c5dce0;
        border-left: 8px solid #1b6775;
        border-radius: 4px;
        cursor: pointer;
        padding: 25px;
        .image {
            width: 30px;
            height: 30px;
            @media (max-width: 480px) {
                width: 35px;
                height: 35px;
            }
        }
        .title {
            ${font.size(16)};
            line-height: 19.2px;
            ${font.bold};
            color: #525659;
            width: 100%;
        }
        .badge {
            margin-left: auto;
            background-color: #f6f6f6;
            border: 1px solid #dbe5e7;
            border-radius: 15px;
            padding: 8px 22px;
            color: #1b6775;
            ${font.size(13)};
            line-height: 15.6px;
            ${font.bold};
            @media (max-width: 480px) {
                position: absolute;
                top: 0px;
                left: 25px;
                border-top: none;
                border-radius: 0px 0px 8px 8px;
                ${font.size(12)};
                line-height: 14.4px;
            }
        }
        .resource-badge {
            background-color: rgba(236, 118, 37, 0.24);
            border: 1px solid #ffd1b1;
            color: #ec7625;
        }
    }

    .body-resource {
        border: 1px solid #fadecb;
        border-left: 8px solid #ec7625;
    }

    .content {
        ${font.size(16)};
        line-height: 26px;
        ${font.medium};
        color: #525659;
        padding: 10px 0px 0px 24px;
        @media (max-width: 480px) {
            padding: 10px 0px 0px 0px;
        }
        a {
            color: #ef7724;
            text-decoration: underline;
        }
    }

    .content ul {
        list-style: disc;
    }

    .content .container {
        gap: 20px;
        display: flex;
        flex-direction: column;
    }

    .content .terms-container {
        margin-top: 10px;
    }

    .content .terms-heading {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .heading-cont {
        display: flex;
        align-items: center;
        gap: 19px;
        @media (max-width: 480px) {
            margin-top: 15px;
            flex-direction: row-reverse;
        }
    }
`

export const BodyCont = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`
export const Content = styled.div`
    hr {
        display: none;
        @media (max-width: 480px) {
            display: block;
            border-top: 1px solid rgba(217, 217, 217, 0.3);
            margin: 31px -20px 20px -20px;
        }
    }
`

export const SubHeader = styled.div`
    ${font.size(24)};
    color: ${color.trackerCurrentStatusColor};
    ${font.bold};
    line-height: 29px;
    white-space: nowrap;
    margin: 20px 0;
    @media (max-width: 820px) {
        ${font.size(20)};
    }
`
export const CollapseCont = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-right: 20px;
    @media (max-width: 480px) {
        padding: 0px;
        margin: 0px;
        margin-left: 0.5rem;
    }
`

export const ButtonCont = styled.div`
    display: flex;
    gap: 10px;

    .active {
        background: #1b6775;
        color: #fff;
    }

    @media (max-width: 900px) {
        flex-wrap: wrap;
    }

    @media (max-width: 480px) {
        flex-wrap: inherit;
        margin-top: 5px;
        overflow-x: scroll;
        margin-left: -0.5rem;
        .active {
            background: #fff;
            border-radius: 0;
            color: #ec7625;
        }
    }
`

export const LeftCont = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    @media (max-width: 480px) {
        width: 100%;
    }
`

export const HomeBtn = styled(Link)`
    background-color: #f7fcfe;
    border: 1px solid #dbe5e7;
    padding: 18px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
    &:hover {
        box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.3);
    }
    @media (max-width: 480px) {
        display: none !important;
    }
    @media (max-width: 821px) {
        border-radius: 4px;
    }
    ${(props) =>
        !!props.tab &&
        css`
            display: none;
            height: 49px;
            width: 50px;
            @media (min-width: 481px) and (max-width: 820px) {
                display: flex !important;
            }
            @media (max-width: 480px) {
                display: none !important;
            }
        `}
    ${(props) =>
        !!props.web &&
        css`
            display: none;
            @media (min-width: 821px) {
                display: flex !important;
            }
            @media (max-width: 769px) {
                display: none !important;
            }
        `}
`

export const HomeBtnImg = styled.img`
    display: none;
    @media (min-width: 769px) {
        display: block !important;
    }
    @media (max-width: 1500px) {
        width: 15px;
        height: 15px;
    }
    ${(props) =>
        !!props.tab &&
        css`
            display: none;
            @media (max-width: 820px) {
                display: block !important;
            }
        `}
`

export const NavCont = styled.div`
    display: flex;
    gap: 10px;
    margin-left: auto;
    @media (max-width: 820px) {
        width: 100%;
    }
    @media (max-width: 480px) {
        display: flex !important;
        margin-bottom: 20px;
    }
`

export const NavButton = styled(Link)`
    display: flex;
    align-items: center;
    ${font.size(17)};
    ${font.medium};
    padding: 18px 30px;
    border-radius: 40px;
    color: #525659;
    background-color: white;
    border: 1px solid #c3c3c3;
    transition: 0.3s ease-in-out;
    white-space: nowrap;
    height: 55px;
    ${(props) =>
        props.active == 'true' &&
        css`
            background-color: #1b6775;
            border-color: #1b6775;
            color: white;
        `}
    &:hover {
        background-color: #1b6775 !important;
        color: white;
    }
    &:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
    }
    @media (max-width: 1500px) {
        padding: 12px 20px;
    }
    @media (max-width: 950px) {
        padding: 8px;
        ${font.size(14)}
    }
    @media (max-width: 820px) {
        width: max-content;
        height: 50px;
        border-radius: 8px;
        padding: 9px 20px;
        ${font.size(15)};
        ${font.medium};
    }
`

export const NavButtonBadge = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1b6775;
    color: white;
    ${font.size(12)};
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 10px;
    line-height: 2;
    ${(props) =>
        props.active == 'true' &&
        css`
            background-color: white;
            color: black;
        `}
    @media (max-width: 480px) {
        display: none !important;
    }
`
