import { Cont } from './Styles'
import CoeCheck from 'OnDemandActions/Sell/CodDateChange/shared/assets/coe-check.svg'
import moment from 'moment'
import { Bellhops } from 'shared/components'

const Step4 = ({ data, close, es = {} }) => {
    return (
        <Cont>
            <img src={CoeCheck} alt="" />
            <h1>Request Submitted</h1>
            <div className="coe-info-cont">
                <p>
                    Your request to change the closing date to{' '}
                    <span>{moment(data.date, 'MM/DD/YYYY').format('MMMM DD, YYYY')}</span>
                    {!data.extendedStay &&
                        es.extendedStayEligible &&
                        ' and remove Extended Stay '}{' '}
                    has been submitted and is in review.
                </p>
                {data.extendedStay && (
                    <p>
                        Your addition of a{' '}
                        <span>
                            {data.extendedStayDays}-{data.extendedStayDays > 1 ? 'days' : 'day'}{' '}
                            Extended Stay
                        </span>{' '}
                        has also been submitted and is in review.
                    </p>
                )}
                <p>Hang tight, we will notify you upon approval.</p>
            </div>
            <Bellhops />
            <br />
            <br />
            <div className="btn-cont">
                <button id="fs_sell_cod_dateChange_sa_submit" className="next" onClick={close}>
                    Got it!
                </button>
            </div>
        </Cont>
    )
}

export default Step4
