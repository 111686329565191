import { font, mixin } from 'shared/utils/styles'
import styled, { css, keyframes } from 'styled-components'
import Button from '../Button'

const slideRightWithOpacity = keyframes`
    from{
        opacity: 0;
        transform: translateX(100%) translateY(-50%);
    }
    to{
        opacity: 1;
        transform: translateX(0) translateY(-50%);
    }
`

export const slideBottomWithOpacity = keyframes`
    from{
        opacity: 0;
        transform: translateY(100%);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
`

export const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to{
        opacity: 1;
    }
`

export const Overlay = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    z-index: 3;
    ${(props) =>
        props.show &&
        css`
            background-color: rgba(0, 0, 0, 0.7);
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100vw;
            animation: ${fadeIn} 0.3s ease-in-out;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
        `}
    ${(props) =>
        props.cover &&
        css`
            background-color: rgba(255, 255, 255, 0.95);
            width: 650px;
        `}
`

export const Container = styled.div`
    position: relative;
    width: 400px;
    background-color: #f7fcfe;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0px 40px 40px 0px;
    padding: 20px 40px;
    opacity: 0;
    animation: ${fadeIn} 0.3s ease-in;
    animation-delay: ${(props) => (props.cover ? '0.1s' : '0.3s')};
    animation-fill-mode: forwards;
    ${(props) =>
        props.show &&
        css`
            position: absolute;
            top: auto;
            bottom: 0;
            width: 100%;
            max-width: 480px;
            border-radius: 40px 40px 0px 0px;
            animation: ${slideBottomWithOpacity} 0.3s ease-in-out;
            animation-fill-mode: forwards;
            padding: 20px 20px 20px 20px;
        `}
`

export const Div = styled.div`
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 0.25px solid #1b6775;
    background: #fff;
    padding: 10px;
    margin-bottom: 20px;
    img {
        transform: rotate(160deg) scaleX(-1);
    }
    span {
        font-size: 15px;
    }
`

export const Span = styled.span`
    color: #525659;
    font-size: 15px;
    line-height: 18px;
    display: block;
    ${font.medium};
    ${(props) =>
        props.underline &&
        css`
            border-bottom: 1.5px dashed #525659;
            text-decoration: none;
        `}
    a {
        color: #1b6775;
        text-decoration: underline;
        cursor: pointer;
    }
    ${(props) =>
        props.anchorColorOrange &&
        css`
            a {
                color: #ec7625 !important;
            }
        `}
    .orange {
        color: #ec7625;
    }
    ${(props) =>
        props.teal &&
        css`
            color: #1b6775;
        `}
    ${(props) =>
        props.bold &&
        css`
            ${font.bold};
        `}
    ${(props) =>
        props.italic &&
        css`
            font-style: italic;
        `}
`

export const Heading = styled.div`
    ${font.bold};
    font-size: 20px;
    line-height: 21px;
    color: #525659;
    margin-bottom: 10px;
`

export const Points = styled.div`
    ${font.medium};
    font-size: 15px;
    line-height: 18px;
    color: #525659;
    margin-bottom: 5px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    &::before {
        content: '';
        height: 5px;
        width: 5px;
        background-color: #ec7625;
        flex-shrink: 0;
        margin-top: 6px;
    }
`

export const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    margin: 20px 0px;
`
export const ActionButton = styled(Button)`
    flex: 1;
    border-radius: 5px;
    border: 2px solid #ec7625;
    height: 33px;
    ${font.bold};
    ${(props) =>
        props.bordered &&
        css`
            background-color: white;
            color: #ec7625;
            border: 2px solid #ec7625;
            &:hover {
                background-color: white !important;
                opacity: 0.7;
            }
        `}
`
export const CloseIcon = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: #6f6c78;
    transition: all 0.1s;
    padding: 19px 20px;
    background-color: #f7f7f7;
    height: 55px;
    width: 55px;
    border-radius: 50% !important;
    border: 1px solid #dbe5e7;
    ${mixin.clickable}
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        line-height: 0%;
        ${font.size(20)}
    }
    ${(props) =>
        props.leftContainerCloseButton &&
        css`
            display: none;
            @media screen and (max-width: 480px) {
                display: flex;
                float: right;
                margin-bottom: 20px;
                position: absolute;
                top: 10px;
                right: 10px;
            }
        `}
    ${(props) =>
        props.showDefault &&
        css`
            display: flex !important;
        `}
    i {
        font-size: 25px;
        margin-top: 5px;
    }
    ${(props) =>
        props.top &&
        css`
            position: absolute;
            right: 20px;
            top: 20px;
        `}
`
