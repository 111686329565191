import styled, { css } from 'styled-components'
import { font, mixin, color, breakPoints } from 'shared/utils/styles'
import ArrowMessageCenter from 'MessageCenter/assets/arrow-down-messageCenter.svg'

export const Cont = styled.div`
    position: relative;
    ${(props) =>
        !!props.isDashboard && props.isDashboard
            ? css`
                  padding: 0 !important;
              `
            : css`
                  padding: 30px 0px;
                  @media screen and (min-width: 1423px) {
                      width: 100%;
                      max-width: 1347px;
                      margin: 0 auto;
                      padding-top: 52px;
                      padding-bottom: 72px;
                  }
                  @media screen and (max-width: 1422px) {
                      padding: 30px;
                  }
                  @media (max-width: 480px) {
                      padding: 30px 20px !important;
                  }
              `}
`

export const MessageCont = styled.div`
    width: 100%;
    background: #f7fcfe;
    border: 2px solid #eaeaea;
    border-radius: 8px;
    padding: 45px 45px 70px 52px;
    @media (max-width: 480px) {
        padding: 0px;
        border: none;
        background-color: transparent;
    }
`

export const Header = styled.p`
    ${font.bold};
    ${font.size(24)};
    color: ${color.trackerCurrentStatusColor};
    line-height: 29px;
    @media (max-width: 480px) {
        color: black;
    }
`

export const SubHeader = styled.span`
    ${font.regular};
    ${font.size(13)};
    color: ${color.testSubHeading};
    line-height: 21px;
`

// export const CardCont = styled.div`
//     margin-top: 26px;
//     margin-bottom: 70px;
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     gap: 10%;
//     @media (max-width: 820px) {
//         grid-template-columns: 1fr;
//     }
// `

export const Text = styled.p`
    ${font.regular};
    ${font.size(16)};
    color: ${color.trackerCurrentStatusColor};
    line-height: 22px;
    margin: 26px 0px;
`

export const Select = styled.select`
    height: 78px;
    width: 600px;
    color: #898989;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin: 15px 0px 40px 0px;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    background-image: url(${ArrowMessageCenter});
    background-repeat: no-repeat;
    background-position: calc(100% - 25px);
    background-size: 10px;
    //padding: 10px;
    padding: 19px 25px;
    ${font.size(16)};
    ${font.regular};

    option {
        color: #898989;
        display: flex;
        // min-height: 20px;
        padding: 0px 2px 1px;
    }

    @media (max-width: 820px) {
        width: calc(100% - 25px);
    }
`
