import { useEffect } from 'react'
import usePlatformDetection from 'shared/hooks/platformDetection'

const SmartRedirect = () => {
    const platform = usePlatformDetection()

    function startTimer() {
        setTimeout(() => {
            window.location.href = '/auth/register'
        }, 2000)
    }

    useEffect(() => {
        switch (platform) {
            case 'Android':
                window.location.href = process.env.ANDROID_APP_STORE_LINK
                startTimer()
                break
            case 'iOS':
                window.location.href = process.env.IOS_APP_STORE_LINK
                startTimer()
                break
            default:
                window.location.href = '/auth/register'
        }
    }, [platform])

    return null
}

export default SmartRedirect
