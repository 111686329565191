import useSingleTransactions from 'shared/hooks/sell/single-transaction'
import useTeams from 'shared/hooks/sell/teams'
import useEvents from 'shared/hooks/sell/events'
import useTracker from 'shared/hooks/sell/tracker'
import useTodos from 'shared/hooks/sell/todos'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateLoading } from 'features/sell/loading'
import { useNavigate, useLocation } from 'react-router'
import { isEmpty } from 'lodash'
import useDocuments from './documents'

const useOverview = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(false)

    const transaction = useSelector((state) => state.singleTransaction.transaction)
    const teams = useSelector((state) => state.teams.teams)
    const events = useSelector((state) => state.events.events)
    const todos = useSelector((state) => state.todos.todos)
    const tracker = useSelector((state) => state.tracker.tracker)
    const documents = useSelector((state) => state.documents.documents)

    const singleTransaction = useSingleTransactions()
    const teamsData = useTeams()
    const eventsData = useEvents()
    const trackerData = useTracker()
    const todosData = useTodos()
    const documentData = useDocuments()

    const init = async (state = false) => {
        const transactionId = new URLSearchParams(location.search).get('transactionId')
        if (transactionId) {
            localStorage.setItem('currentTransactionId', transactionId)
            location.search = ''
        }

        if (localStorage.currentTransactionId === undefined) {
            navigate('/sell/dashboard')
            return
        }

        localStorage.removeItem('confetti')

        setLoading(true)
        dispatch(updateLoading(true))

        if (state) {
            Promise.all([
                await singleTransaction.fetch(),
                await teamsData.fetch(),
                await eventsData.fetch(),
                await todosData.fetch(),
                await trackerData.get(),
                await documentData.fetch(),
            ])
        } else {
            if (isEmpty(transaction)) {
                await singleTransaction.fetch()
            }
            if (isEmpty(teams)) {
                await teamsData.fetch()
            }
            if (isEmpty(Object.keys(events))) {
                await eventsData.fetch()
            }
            if (isEmpty(Object.keys(todos))) {
                await todosData.fetch()
            }
            if (isEmpty(tracker)) {
                await trackerData.get()
            }
            if (isEmpty(documents)) {
                await documentData.fetch()
            }
        }
        dispatch(updateLoading(false))
        setLoading(false)
    }

    return {
        init,
        loading,
    }
}

export default useOverview
