import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

const PDFViewer = ({ src = '' }) => {
    const defaultScale = window.innerWidth >= 688 ? 1 : 0

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => [defaultTabs[0]],
    })

    return (
        <div style={{ height: '90%', width: '100%' }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={src}
                    plugins={[defaultLayoutPluginInstance]}
                    defaultScale={defaultScale}
                />
            </Worker>
        </div>
    )
}

export default PDFViewer
