import { font } from 'shared/utils/styles'
import styled, { css, keyframes } from 'styled-components'
import Button from '../Button'

const slideLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1.5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
     }
`

export const Cont = styled.div`
    ${(props) =>
        props.overview
            ? css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  gap: 15px;
                  background-color: white;
                  border-radius: 8px;
                  border: 1px solid #d6d6d6;
                  padding: 16px 24px;
                  transition: all 0.3s;
                  ${(props) =>
                      !!props.mt &&
                      css`
                          margin-top: ${props.mt}px !important;
                      `}
                  @media (max-width: 1000px) {
                      flex-direction: column;
                      justify-content: start;
                      align-items: flex-start;
                  }
                  @media (max-width: 480px) {
                      margin-top: 0 !important;
                      padding: 0px;
                      background-color: transparent;
                      border: none;
                      ${(props) =>
                          !!props.last &&
                          css`
                              border-top: 1px solid #e6e6e6;
                              margin-top: 27px !important;
                              padding-top: 27px;
                              border-radius: 0px;
                          `}
                  }

                  a {
                      margin: 0px 0px 0px auto;
                      @media (max-width: 480px) {
                          margin: 0px;
                      }
                  }
                  @media (max-width: 480px) {
                      background-color: #f0f8fb;
                      padding: 20px 29px;
                      margin-top: 15px;
                      border-radius: 10px;
                      border: 1px solid #dbe5e7;
                  }
              `
            : css`
                  animation: ${slideLeft} ease 0.7s;
                  animation-iteration-count: 1;
                  animation-fill-mode: forwards;
                  flex: 1;
                  display: flex;
                  gap: 20px;
                  background-color: white;
                  padding: 20px;
                  border-radius: 10px;
                  border: 1px solid #d6d6d6;
                  align-items: center;
                  @media (max-width: 480px) {
                      flex-direction: column;
                      border: 1px solid #eaeaea;
                      background-color: rgba(231, 240, 241, 0.5);
                      width: 100%;
                      align-items: start;
                  }
                  a {
                      margin: 0px 0px 0px auto;
                      @media (max-width: 480px) {
                          margin: 0px;
                      }
                  }
                  button {
                      max-width: 168px;
                      margin: 0px 0px 0px auto;
                      @media (max-width: 480px) {
                          margin: 0px;
                      }
                  }
              `}
`

export const InfoCont = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
`

export const InfoHeading = styled.div`
    ${font.black};
    ${font.size(16)};
    color: #525659;
`

export const ActionButton = styled(Button)`
    height: fit-content;
    padding: 10px 17px;
    border-radius: 3px;
    ${font.size(13)};
    ${font.medium};
    margin: 0px 0px 0px auto;
    width: 168px;
    text-overflow: ellipsis;
    @media (max-width: 650px) {
        width: 168px;
        height: 47px;
        margin: 0px;
        text-overflow: ellipsis;
    }
    ${(props) =>
        props.review &&
        props.review === true &&
        css`
            margin: 0 0 0 auto;
            border-radius: 3px;
            border: 1px solid #1b6775;
            background-color: #f6f6f6;
            color: #1b6775;
            padding: 10px 31px;
            ${font.medium};
            font-size: 13px;
            line-height: 15.6px;
            width: 168px;
            text-overflow: ellipsis;
            &:hover {
                background: #f6f6f6 !important;
            }
        `}
`

export const TaskTagline = styled.div`
    ${font.medium};
    ${font.size(15)};
    line-height: 22px;
    color: #525659;
    a {
        color: #ef7724;
    }
`
