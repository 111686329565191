import styled from 'styled-components'

export const Cont = styled.div`
    background-color: white;
    position: absolute;
    border-radius: 8px;
    border: 1px solid #d6d6d6;
    box-shadow: 0 0 0.25rem rgb(0 0 0 / 30%);
    right: -4px;
    top: 70px;
    width: max-content;
    &:after {
        content: ' ';
        z-index: -1 !important;
        position: absolute;
        top: -6px;
        right: 5px;
        height: 30px;
        width: 37px;
        transform: rotate(45deg);
        box-shadow: 0 0 0.25rem rgb(0 0 0 / 30%);
        background: white;
        border: 1px solid #d6d6d6;
    }
    &:before {
        content: ' ';
        position: absolute;
        top: -6px;
        right: 5px;
        height: 30px;
        width: 37px;
        transform: rotate(45deg);
        background: white;
    }
`

export const DropdownCont = styled.div`
    position: relative;
`
