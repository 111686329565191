import { createSlice } from '@reduxjs/toolkit'

const offerpadSlice = createSlice({
    name: 'offerpad',
    initialState: {
        transactions: [],
        transcation: [],
        images: [],
        events: [],
        teams: [],
        tracker: [],
        coe: [],
        disabledDates: [],
        todos: [],
        loading: false,
    },
    reducers: {
        updateTransactions: (state, action) => ({
            ...state,
            transactions: action.payload,
        }),
        updateTransaction: (state, action) => ({
            ...state,
            transcation: action.payload,
        }),
        updateImages: (state, action) => ({
            ...state,
            images: action.payload,
        }),
        updateEvents: (state, action) => ({
            ...state,
            events: action.payload,
        }),
        updateTeams: (state, action) => ({
            ...state,
            teams: action.payload,
        }),
        updateTracker: (state, action) => ({
            ...state,
            tracker: action.payload,
        }),
        updateCoe: (state, action) => ({
            ...state,
            coe: action.payload,
        }),
        updateDisabledDates: (state, action) => ({
            ...state,
            disabledDates: action.payload,
        }),
        updateTodos: (state, action) => ({
            ...state,
            todos: action.payload,
        }),
        updateLoading: (state, action) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const {
    updateTransactions,
    updateTransaction,
    updateTeams,
    updateImages,
    updateEvents,
    updateTracker,
    updateCoe,
    updateDisabledDates,
    updateTodos,
    updateLoading,
} = offerpadSlice.actions
export default offerpadSlice.reducer
