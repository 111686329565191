import React, { Fragment, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container, FormHeadingImage, FormImage, StyledDiv, TagLine } from '../shared/Styles'
import { Form } from 'shared/components'
import toast from 'shared/utils/toast'
import Logo from 'shared/asstes/logo.svg'
import CheckIcon from 'Auth/assets/check-auth.svg'
import UnderLine from '../assets/underline.svg'
import Clouds from '../assets/cloud-sun.svg'
import {
    StyledLogo,
    FormCont,
    FormHeading,
    FormElement,
    ActionButton,
    StyledBackButton,
    BackArrowIcon,
    ForgotPasswordSuccessDiv,
    ForgotPasswordSuccessImage,
    ForgotPasswordUserEmail,
    ForgotPasswordOkButton,
} from '../shared/Styles'
import useApi from 'shared/hooks/api'

const ForgotPassword = () => {
    const navigate = useNavigate()
    const [{ isCreating }, forgotPaswword] = useApi.post('api/customer-auth/v2/forgot-password')
    const [success, setSuccess] = useState(false)
    const [userEmail, setUserEmail] = useState('')

    const { search } = useLocation()

    const prams = new URLSearchParams(search)
    const email = prams.get('email')

    return (
        <Fragment>
            <Container>
                <StyledDiv>
                    <StyledLogo src={Logo} alt="" />
                    <TagLine>We can help you do literally (almost) everything.</TagLine>
                </StyledDiv>
                <Form
                    enableReinitialize
                    initialValues={{
                        email: email ? email : '',
                    }}
                    validations={{
                        email: [
                            Form.is.required('Enter a valid email'),
                            Form.is.email('Enter a valid email'),
                        ],
                    }}
                    onSubmit={async (values, form) => {
                        try {
                            await forgotPaswword({
                                ...values,
                                linkUrl: `${process.env.MARKETING_URL}/auth/reset-password`,
                            })
                            toast.success('Email sent successful.')
                            setUserEmail(values.email)
                            setSuccess(true)
                        } catch (error) {
                            error === 'User not found' &&
                                toast.error(
                                    'The email address you entered do not match any accounts on record.',
                                )
                            form.setFieldError(
                                'email',
                                'The email address you entered do not match any accounts on record.',
                            )
                        }
                    }}
                >
                    {!success ? (
                        <FormCont>
                            <FormImage src={Clouds} alt="" />
                            <Fragment>
                                <FormElement>
                                    <StyledBackButton
                                        to="/auth/login"
                                        id="fs_auth_forgotPassword_backButton"
                                    >
                                        <BackArrowIcon type="chevron-left" size={20} />
                                    </StyledBackButton>
                                    <FormHeading size="xl">
                                        Forgot Password
                                        <FormHeadingImage src={UnderLine} alt="" />
                                    </FormHeading>
                                    <Form.Field.Input
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        size="lg"
                                        disabled={isCreating}
                                    />
                                    <ActionButton
                                        type="submit"
                                        variant="primary"
                                        isWorking={isCreating}
                                        size="lg"
                                        id="fs_auth_forgotPassword_submit"
                                    >
                                        Send Reset Link
                                    </ActionButton>
                                </FormElement>
                                {/* <FormFooterStyled mt="54px">
                                    Don't have an account?
                                    <FormFooterStyledAnchorTag
                                        to="/auth/register"
                                        id="fs_auth_forgotPassword_signup"
                                    >
                                        Sign up
                                    </FormFooterStyledAnchorTag>
                                </FormFooterStyled> */}
                            </Fragment>
                        </FormCont>
                    ) : (
                        <ForgotPasswordSuccessDiv>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <ForgotPasswordSuccessImage src={CheckIcon} alt="" />
                            We have sent you an email containing password reset link to <br />
                            <ForgotPasswordUserEmail>{userEmail}</ForgotPasswordUserEmail>
                            <br />
                            <br />
                            <ForgotPasswordOkButton
                                size="lg"
                                onClick={() => navigate('/auth/login')}
                            >
                                Ok
                            </ForgotPasswordOkButton>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </ForgotPasswordSuccessDiv>
                    )}
                </Form>
            </Container>
        </Fragment>
    )
}

export default ForgotPassword
