import axios from 'axios'
const axiosInstance = axios.create()
import toast from 'shared/utils/toast'
import { getStoredAuthToken, removeStoredAuthToken } from 'shared/utils/authToken'
import { getStoredRefreshToken } from 'shared/utils/refreshToken'
import { refreshToken } from './auth'

const defaults = {
    baseURL: process.env.API_URL || 'http://localhost:3000',
    headers: () => ({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        Authorization: getStoredAuthToken() ? `Bearer ${getStoredAuthToken()}` : undefined,
    }),
    error: {
        code: 'INTERNAL_ERROR',
        message:
            'Something went wrong. Please check your internet connection or try again after sometime.',
        status: 503,
        data: {},
    },
}

const api = (method, url, variables, customHeader) =>
    new Promise((resolve, reject) => {
        axiosInstance({
            url: `${defaults.baseURL}${url}`,
            method,
            headers: { ...defaults.headers(), ...customHeader },
            params: method === 'get' ? variables : undefined,
            data: method !== 'get' ? variables : undefined,
        })
            .then((response) => {
                resolve(response.data)
                return response.status
            })
            .catch((error) => {
                if (error.response) {
                    if (error?.response?.data?.error?.code === 'INVALID_TOKEN') {
                        removeStoredAuthToken()
                    } else {
                        reject(error.response.data)
                    }
                    reject(error.response.data)
                } else {
                    toast.error(defaults.error.message)
                    reject(defaults.error)
                }
            })
    })

axiosInstance.interceptors.response.use(
    function (response) {
        return response
    },
    async function (error) {
        const originalRequest = error.config
        if (error.response.status === 401 && !originalRequest._retry) {
            if (getStoredAuthToken() && getStoredRefreshToken()) {
                originalRequest._retry = true
                const { success, error, authToken } = await refreshToken()
                if (success) {
                    window.location.reload()
                    originalRequest.headers.Authorization = `Bearer ${authToken}`
                } else {
                    Promise.reject(error)
                    toast.error('Something went wrong.')
                }
            } else {
                window.location.href = `/auth/login`
            }
        }

        return Promise.reject(error)
    },
)

const optimisticUpdate = async (url, { updatedFields, currentFields, setLocalData }) => {
    try {
        setLocalData(updatedFields)
        await api('put', url, updatedFields)
    } catch (error) {
        setLocalData(currentFields)
        toast.error(error)
    }
}

export default {
    get: (...args) => api('get', ...args),
    post: (...args) => api('post', ...args),
    put: (...args) => api('put', ...args),
    patch: (...args) => api('patch', ...args),
    delete: (...args) => api('delete', ...args),
    optimisticUpdate,
}
